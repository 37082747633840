import {useEffect, useMemo, useState} from "react";
import Http from "@utilities/Http";
import {API_ROOT} from "@config";


export const usePlacementStyles = ({outlineId}) => {

    const [placementStyles, setPlacementStyles] = useState([]);

    const getStyle = useMemo(()=>{
        return placementStyles.find((placementStyle)=>{
            return placementStyle[0]?.product_outline_image_id === outlineId;
        });
    },[outlineId,placementStyles])


    useEffect(()=>{
        if(placementStyles.find((placementStyle)=>{
            return placementStyle[0]?.product_outline_image_id === outlineId;
        })){
            return;
        }
        const getStyles = async () => {
            Http().get(`${API_ROOT}/sketch-configurator/get-outline-lettering-styles/${outlineId}`).then(response => {
                if(!response.data.letteringPlacementStyles){
                    return;
                }
                setPlacementStyles(()=>[...placementStyles,response.data.letteringPlacementStyles]);
            });
        }
        getStyles();
    },[outlineId])

    return getStyle;
}
