import React, { useMemo } from 'react';
import { formatCurrency } from '@utilities/currency';
import {
	getProductOptionDisplayCode,
	getProductOptionValueLabel,
} from '@utilities/methods';
import { useSelector } from 'react-redux';
import styles from './OrderItemDetails.module.scss';
import { letteringTextOptions } from '@components/LetteringModal/config';

const OrderItemDetailCategories = ({ quote }) => {
	const demoModeActive = useSelector((state) => state.demoMode.active);

	const { patch: letteringPatchId, color: outerShellColorId } = useSelector(
		(state) => state.staticData.letteringOptions,
	);

	/**
	 * Get product option sections
	 */

	const productOptionSections = useSelector(
		(state) => state.staticData.productOptionSections,
	);
	/**
	 * Loop through product option values -
	 * Combine items on product_option_group_id
	 */

	const productOptionValues = useMemo(() => {
		const groups = [];

		const outerShellColor = quote?.productOptionValues.find(
			(value) =>
				value.product_option_id === outerShellColorId?.toString(),
		);

		const items = quote?.productOptionValues.reduce(
			(result, item, _, items) => {
				// if not grouped

				if (!item.product_option_group_id) {
					const price = quote.prices.find(
						(price) =>
							price.product_option_id === item.product_option_id
							&&
							price.product_option_value_id ===
							item.product_option_value_id,
					);

					let label = item.product_option_description;
					let value = getProductOptionValueLabel(item);
					let itemPrice = parseFloat(price?.price || 0);

					// add lettering placement to label
					if (item.lettering_placement_id) {
						label += `\r\nPosition ${item.lettering_placement_index}`;
					}

					// if lettering patch
					if (
						item.product_option_id === letteringPatchId?.toString()
					) {
						const type = letteringTextOptions.find(
							(type) =>
								type.value ===
								item.quote_product_option_value_lettering_type,
						);

						if(item.lettering_row_number !== '0') {
							itemPrice = 0;
						}

						// add display option code
						value =
							`${item.product_option_value_display_option_code} - ` +
							value;

						// add patch color if not sewn direct
						if (
							item.product_option_value_lettering_is_sewn_direct !==
							'1'
						) {
							const color =
								outerShellColor?.product_option_value_description;

							// 'Main Shell Color' if matches outer shell color
							const label =
								color === item.lettering_patch_color
									? 'Main Shell Color'
									: item.lettering_patch_color;

							value = value + ` - ${label}`;
						}

						// add lettering type
						value = value + `\r\n${type?.label}`;

						// add custom text
						if (type?.value === 'custom') {
							value += `: "${item.quote_product_option_value_lettering_custom_text}"`;
						}

						if (item?.lettering_style_description) {
							value += ` - ${item.lettering_style_description}`;
						}
					} else if (item.product_option_value_display_option_code) {
						value =
							`(${item.product_option_value_display_option_code}) ` +
							value;
					}

					if (item.quote_product_option_value_quantity > 1)
						value =
							item.quote_product_option_value_quantity +
							' x ' +
							value;

					if (item.product_option_value_position_title && item.position_option_token !== 'static')
						value =
							value +
							' ' +
							item.product_option_value_position_title;

					result.push({
						label,
						value,
						message: item.quote_product_option_value_message,
						category: item.product_option_category_id,
						price: itemPrice,
					});

					return result;
				}

				// check if group has already been handled
				if (groups.includes(item.product_option_group_id)) {
					return result;
				}

				// find sibling items
				const siblings = items.filter(
					(i) =>
						i.product_option_group_id ===
						item.product_option_group_id,
				);
				// join sibling value labels with commas, sum prices
				result.push({
					label: item.product_option_group_title,
					message: item.quote_product_option_value_message,
					category: item.product_option_category_id,
					value: siblings
						.map((sibling) => {
							let return_value =
								getProductOptionValueLabel(sibling);
							if (getProductOptionDisplayCode(sibling) !== null) {
								return_value = `(${getProductOptionDisplayCode(
									sibling,
								)}) ${return_value}`;
							}

							if (item.quote_product_option_value_quantity > 1)
								return_value =
									item.quote_product_option_value_quantity +
									' x ' +
									return_value;
							return return_value;
						})
						.join(', '),
					price: siblings.reduce((combinedPrice, sibling) => {
						const price = quote.prices.find(
							(price) =>
								price.product_option_id ===
								sibling.product_option_id
								&&
								price.product_option_value_id ===
								sibling.product_option_value_id,
						);

						// Configurable items are summed already
						if (['3', '5'].includes(sibling.product_option_type_id)) {
							return parseFloat(price?.price || 0);
						}

						return (combinedPrice += parseFloat(price?.price || 0));
					}, 0),
				});

				groups.push(item.product_option_group_id);

				return result;
			},
			[],
		);

		return items || [];
	}, [quote, letteringPatchId, outerShellColorId]);

	/**
	 * Loop through sections and assemble categories
	 */

	const categories = useMemo(() => {
		return productOptionSections.reduce((result, section) => {
			const { productOptionCategories: sectionCategories } = section;

			if (!sectionCategories?.length) {
				return result;
			}

			result.push(
				...sectionCategories.map((sectionCategory) => {
					// get items in category
					const items = productOptionValues.filter((option) => {
						return (
							option.category ===
							sectionCategory.product_option_category_id
						);
					});

					for(const sectionChildCategory of sectionCategory.product_option_category_children) {
						const childItems = productOptionValues.filter((option) => {
							return (
								option.category ===
								sectionChildCategory.product_option_category_id
							);
						});

						items.push(...childItems);
					}

					// get total price for category
					const price = items.reduce((result, item) => {
						return (result += item.price || 0);
					}, 0);

					return {
						id: sectionCategory.product_option_category_id,
						title: sectionCategory.product_option_category_title,
						items,
						price,
					};
				}),
			);

			return result;
		}, []);
	}, [productOptionSections, productOptionValues]);
	const getCategoryTitle = (prodName, catName) => {

		if (prodName.toLowerCase() === catName.toLowerCase().substring(0, prodName.length)) {
			return catName;
		}
		return prodName + ' ' +catName;
	}
	return (
		<>
			{categories
				.filter((category) => !!category.items.length)
				.map((category, c) => (
					<section key={c} className={styles.orderItemDetailsSection}>
						<h5>
							{getCategoryTitle(quote.product_title, category.title)}
						</h5>
						<ul>
							{category.items.map((option, o) => (
								<React.Fragment key={o}>
								<li key={o}>
									<div>
										<span className={styles.optionTitle}>
											{option.label}
										</span>
										<span className={styles.optionValue}>
											{option.value}
										</span>
									</div>
									{!demoModeActive && (
										<span className={styles.optionPrice}>
											{formatCurrency(option.price)}
										</span>
									)}
								</li>
								{option.message && (
									<li className={styles.message}>
										<span className={styles.messageTitle}>Custom Instruction:</span>
										{option.message}
									</li>
									)}
								</React.Fragment>
							))}
							{!demoModeActive && (
								<li>
									<div>
										<span className={styles.optionTitle}>
											{category.title} Subtotal{' '}
											{quote.quote_product_quantity > 1
												? 'x ' +
												  quote.quote_product_quantity
												: null}
										</span>
									</div>
									<span className={styles.optionPrice}>
										{formatCurrency(
											category.price *
												quote.quote_product_quantity,
										)}
									</span>
								</li>
							)}
						</ul>
					</section>
				))}
		</>
	);
};

export default OrderItemDetailCategories;
