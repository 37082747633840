import React, {useState, useEffect, useCallback, useRef} from 'react';
import classNames from "classnames";
import {CancelToken} from "axios";

import Http from '@utilities/Http';
import date from "@utilities/date";
import styles from './Messenger.module.scss';
import Avatar from "@components/Avatar/Avatar";
import OrderStatusNotification from '../NotificationsListItem/OrderStatus/OrderStatusNotification';
import QuoteShareNotification from '../NotificationsListItem/QuoteShare/QuoteShareNotification';
import QuoteCopyNotification from '../NotificationsListItem/QuoteCopy/QuoteCopyNotification';
import QuoteUpdateNotification from '../NotificationsListItem/QuoteUpdate/QuoteUpdateNotification';
import QuoteSubmitNotification from '../NotificationsListItem/QuoteSubmit/QuoteSubmitNotification';
import {API_ROOT} from "@config";
import MessageWindow from "./components/MessageWindow";

const notificationComponents = {
    'order-status': OrderStatusNotification,
    'quote-update': QuoteUpdateNotification,
    'quote-share': QuoteShareNotification,
    'quote-copy': QuoteCopyNotification,
    'quote-submitted': QuoteSubmitNotification,
};

const Messenger = ({user, orderId = null, quoteId = null, functional = false, openLabel = "Open messenger", forLeft = false, title="Order Support"}) => {
    const fetchRequestSource = useRef(null);
    const fetchTimeOut = useRef(null);
    const [active, setActive] = useState(false);
    const [messageItems, setMessageItems] = useState([]);
    const [newMessageText, setNewMessageText] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fetchMessageItems = useCallback(() => {
        if (!isSubmitting) {
            // cancel any pending request
            fetchRequestSource.current?.cancel();
            // create new cancel token
            fetchRequestSource.current = CancelToken.source();

            let messagesEndpoint;
            if(orderId) {
                messagesEndpoint = `${API_ROOT}/messages/order/${orderId}`;
            } else if(quoteId) {
                messagesEndpoint = `${API_ROOT}/messages/quote/${quoteId}`;
            }

            Http().get(messagesEndpoint, {cancelToken: fetchRequestSource.current?.token})
                .then(({data}) => {
                    setMessageItems(data.messageItems)
                    clearTimeout(fetchTimeOut.current);
                    fetchTimeOut.current = setTimeout(fetchMessageItems, 15000);
                })
                .catch((e) => {
                    clearTimeout(fetchTimeOut.current);
                    if(e.response === undefined) {
                        return [];
                    }
                    if(e.response.status == 401){
                        return [];
                    }
                    fetchTimeOut.current = setTimeout(fetchMessageItems, 15000);
                    return [];
                });
        }

    }, [isSubmitting, orderId, quoteId]);

    const submitMessage = (e) => {
        e.preventDefault();
        if (fetchTimeOut.current) {
            clearTimeout(fetchTimeOut.current);
            fetchTimeOut.current = null;
        }
        //Add message to current list
        setMessageItems(prevMessageItems => {
            const newMessageItems = [...prevMessageItems];

            newMessageItems.push({
                'message_id': Math.random(),
                'message_text': newMessageText,
                'user_id': user.user_id,
                'user_display_name': user.user_display_name,
                'profile_title': user.profile_title,
                'message_timestamp': Date.now(),
            });

            return newMessageItems;
        });

        setNewMessageText('');

        // cancel any pending request
        fetchRequestSource.current?.cancel();
        // create new cancel token
        fetchRequestSource.current = CancelToken.source();

        setIsSubmitting(true);

        let messagesEndpoint;
        if(orderId) {
            messagesEndpoint = `${API_ROOT}/messages/order/${orderId}`;
        } else if(quoteId) {
            messagesEndpoint = `${API_ROOT}/messages/quote/${quoteId}`;
        }

        Http().post(messagesEndpoint,
            {message: {message_text: newMessageText}},
            {cancelToken: fetchRequestSource.current?.token})
            .then(({data}) => {
                setMessageItems(data.messageItems)
            })
            .catch(() => {
                return [];
            })
            .finally(() => {
                setIsSubmitting(false);
                setTimeout(fetchMessageItems, 10000);
            });
    };

    useEffect(() => {
        fetchMessageItems();
        return () => {
            clearTimeout(fetchTimeOut.current);
        }
    }, [fetchMessageItems, quoteId, orderId])

    return (
        <>
            {
                active && (
                    <div
                        className={styles.messengerOutside}
                        onClick={() => setActive(false)}
                    />
                )
            }
            <div className={classNames({
                [styles.messengerWrapper]: true,
                [styles.active]: active,
                [styles.forLeft]: forLeft
            })}>
                <MessageWindow title={title}>
                    {
                        messageItems && messageItems.map(messageItem => {
                            const messageItemType = messageItem.message_id ? 'message' : 'notification';
                            switch (messageItemType) {
                                case 'message':
                                    const fromSelf = user.user_id === messageItem.user_id;
                                    return (
                                        <div key={`message-${messageItem.message_id}`}
                                             className={classNames({
                                                 [styles.messageWrapper]: true,
                                                 [styles.fromOther]: !fromSelf,
                                                 [styles.fromSelf]: fromSelf
                                             })}>
                                            {
                                                fromSelf ?
                                                    (<div className={styles.messageTimestamp}>
                                                        {date(messageItem.message_timestamp).format('hh:mm A')}
                                                    </div>)
                                                    :
                                                    (<Avatar user={user} className={styles.messageAvatar} avatarSize={29}/>)
                                            }
                                            <div className={styles.messageMain}>
                                                <div className={styles.messageTitle}>
                                                    {messageItem.user_display_name}
                                                </div>
                                                <div className={styles.messageSubtitle}>
                                                    {messageItem.profile_title}
                                                </div>
                                                <div className={styles.messageText}>
                                                    {messageItem.message_text}
                                                </div>
                                            </div>

                                            {
                                                !fromSelf ?
                                                    (<div className={styles.messageTimestamp}>
                                                        {date(messageItem.message_timestamp).format('hh:mm A')}
                                                    </div>)
                                                    :
                                                    (<Avatar user={user} className={styles.messageAvatar} avatarSize={29}/>)
                                            }
                                        </div>
                                    );
                                case 'notification':
                                    const NotificationComponent = notificationComponents[messageItem.notification_type];
                                    return (
                                        <div key={`notification-${messageItem.notification_id}`}
                                             className={styles.notificationWrapper}>
                                            <NotificationComponent
                                                notification={messageItem}
                                                previewSize={45}
                                                small={true}
                                            />
                                        </div>

                                    );
                            }
                        })
                    }
                </MessageWindow>
                <div
                    style={{width: '100%'}}
                    onClick={() => {
                        setActive(true);
                    }}>
                    <form className={styles.messengerForm}
                          onSubmit={submitMessage}
                    >
                        <label
                            htmlFor="message"
                            className={styles.messengerLabel}
                        >
                            <input
                                id="message"
                                name="message"
                                type="text"
                                className={styles.messengerInput}
                                placeholder={functional ? "Send a message" : openLabel}
                                value={newMessageText}
                                disabled={!functional}
                                onChange={(e) => setNewMessageText(e.target.value)}
                            />
                            {
                                functional &&
                                <button type="submit" className={styles.messengerButton} disabled={!functional}>
                                    <img src="/images/message-send.png" style={{width: 16, height: 'auto'}}/>
                                </button>
                            }
                        </label>
                    </form>
                </div>
                <div className={styles.closeIcon}
                     onClick={() => setActive(false)}
                >
                    <img src="/images/close-icon.png"/>
                </div>
            </div>
        </>
    );
};

export default Messenger;
