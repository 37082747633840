import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { API_ROOT } from '@config';
import classNames from 'classnames';
import Screen from '@components/Screen/Screen';
import CreateAccountHeader from '@components/CreateAccountHeader/CreateAccountHeader';
import carouselStyles from '@components/Carousel/Carousel.module.scss';
import styles from './CreateAccount.module.scss';
import Http from '@utilities/Http';
import AccountForm from '@components/AccountForm/AccountForm';
import { useSelector } from 'react-redux';

function CreateAccount() {
	const history = useHistory();
	const user = useSelector((state) => state.auth.user);
	const [isDistributor, setIsDistributor] = useState(false);

	const pageContent = useSelector(
		(state) => state.staticData.content['create-account-page'],
	);

	const contentItem = pageContent?.items[0];

	const distributorContent = pageContent?.items[1];

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (formData) => {
			await Http().post(`${API_ROOT}/users`, {
				user: formData,
			});

			history.push(`/verify?email=${formData.user_email}`);
		},
		[history],
	);

	useEffect(() => {
		if (user) {
			history.push('/dashboard');
		}
	}, [user, history]);

	return (
		<Screen
			hideNavigation={true}
			hideFooter={true}
			headerContent={<CreateAccountHeader />}>
			<div className={styles.columns}>
				<div className={styles.carouselContainer}>
					{!!contentItem && (
						<>
							<div
								className={classNames({
									'carousel-item-image': true,
									[carouselStyles.carouselItemImage]: true,
								})}
								style={{
									backgroundImage: `url("${API_ROOT}/uploaded/content/${contentItem?.content_item_image}")`,
								}}
							/>
							<div
								className={styles.contentContainer}
								dangerouslySetInnerHTML={{
									__html: contentItem.content_html,
								}}
							/>
						</>
					)}
				</div>
				<div className={styles.formContainer}>
					<h5>
						{isDistributor ? 'Distributor' : 'User'} Information
					</h5>
					<p>Complete the profile to create your new account</p>
					<AccountForm
						onSubmit={handleSubmit}
						onIsDistributorChange={(value) =>
							setIsDistributor(value)
						}
						submitButtonLabel={
							isDistributor ? 'Request Access' : 'Submit'
						}
						distributorContent={distributorContent}
					/>
				</div>
			</div>
		</Screen>
	);
}

export default CreateAccount;
