import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...props}>
			<g transform="rotate(-90 14 14)">
				<path
					d="M19.568 13.999L14 19.567 8.432 14h4.176V8.431h2.784v5.568zM14 .079C6.316.078.08 6.314.08 13.998S6.315 27.919 14 27.919 27.92 21.684 27.92 14 21.685.079 14 .079zm0 2.783c6.14 0 11.137 4.998 11.137 11.137 0 6.14-4.998 11.137-11.137 11.137-6.14 0-11.137-4.998-11.137-11.137C2.863 7.859 7.861 2.862 14 2.862"
					fill={props.fill || '#e87124'}
				/>
			</g>
		</svg>
	);
}

export default SvgComponent;
