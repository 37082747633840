import {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import PencilCircleIcon from '@components/Icons/PencilCircleIcon';
import Button from './Button/Button';

const QuoteHeaderInformation = ({
                                    productLineTitle,
                                    productTitle,
                                    quoteNumber,
                                    quoteName,
                                    quoteId,
                                    orderStatus,
                                    composite,
                                    updateQuote = async () => {
                                    },
                                    forMobile = false,
                                    isShared = false,
                                }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [name, setName] = useState(quoteName);
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        setName(quoteName);
    }, [quoteName]);

    /**
     * Handle Quote Name Change
     */

    const handleQuoteNameChange = useCallback((e) => {
        setName(e.target.value);
    }, []);

    /**
     * Handle Save Quote
     */

    const handleSaveQuote = useCallback(async () => {
        setIsSaving(true);
        try {
            await updateQuote({quote_id: quoteId, quote_name: name});
            setShowEdit(false);
        } catch (error) {
            console.log(error);
        } finally {
            setIsSaving(false);
        }
    }, [updateQuote, quoteId, name]);

    return (
        <>
            <div
                className={classNames({
                    'configurator-info': true,
                    'for-mobile': forMobile,
                })}>
                <div className="info-label">Product</div>
                <div className="info-value">
                    {productLineTitle} {productTitle}
                </div>
            </div>
            <div
                className={classNames({
                    'configurator-info': true,
                    'for-mobile': forMobile,
                })}>
                <div className="info-label">Quote Number</div>
                <div className="info-value">{quoteNumber}</div>
            </div>
            <div
                className={classNames({
                    'configurator-info': true,
                    'is-editing': showEdit,
                    'for-mobile': forMobile,
                })}>
                <div className="info-label">Name</div>

                {!showEdit && (
                    <div className="info-value">
                        {quoteName}
                        {!isShared &&
                        ![
                            'Submitted',
                            'In Production',
                            'Shipped',
                            'Cancelled'
                        ].includes(orderStatus) && (
                            <button
                                className="edit-button"
                                onClick={() => setShowEdit(true)}>
                                <PencilCircleIcon/>
                            </button>
                        )}
                    </div>
                )}

                {showEdit && (
                    <div className="info-value">
                        <div className="edit-quote-name">
                            <input
                                type="text"
                                value={name}
                                onChange={handleQuoteNameChange}
                            />
                            <Button
                                type="button"
                                onClick={handleSaveQuote}
                                loading={isSaving}>
                                Save
                            </Button>
                            <Button
                                type="button"
                                variant="solid-gray"
                                onClick={() => {
                                    setShowEdit(false);
                                    setName(quoteName);
                                }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {composite ? (
                <>
                    <div
                        className={classNames({
                            'configurator-info': true,
                            'for-mobile': forMobile,
                        })}>
                        <div className="info-label">TPP</div>
                        <div className="info-value">
                            {composite.composite_tpp}
                        </div>
                    </div>
                    <div
                        className={classNames({
                            'configurator-info': true,
                            'for-mobile': forMobile,
                        })}>
                        <div className="info-label">THL</div>
                        <div className="info-value">
                            {composite.composite_thl}
                        </div>
                    </div>
                    {
                        parseInt(composite.composite_ret) ?
                            <div
                                className={classNames({
                                    'configurator-info': true,
                                    'for-mobile': forMobile,
                                })}>
                                <div className="info-label">RET</div>
                                <div className="info-value">
                                    {composite.composite_ret}
                                </div>
                            </div>
                            : null
                    }
                </>
            ) : null}
        </>
    );
};

export default QuoteHeaderInformation;
