import React, { useState } from 'react';
import classNames from 'classnames';

import FixedHeader from '@components/FixedHeader';
import Button from '@components/Button/Button';
import ChevronIcon from '@components/Icons/ChevronIcon';
import { useSelector } from 'react-redux';
import { API_ROOT } from '@config';

function Header({ children, lowerContent = null, actions = [], ...props }) {
	const [popoutOpen, setPopoutOpen] = useState(false);
	const content = useSelector((state) => state.staticData.content);
	const logo = content?.logos?.indexed['logo-light'];

	return (
		<FixedHeader className="app-header" {...props}>
			<div className="app-header-wrapper">
				{!!logo && (
					<img
						className="app-logo"
						src={`${API_ROOT}/uploaded/content/${logo?.content_item_image}`}
					/>
				)}
				{children}
				<div className="header-actions">
					{actions.map(
						(
							{ onClick, actionInProcess, label, ...props },
							index,
						) => {
							return (
								<Button
									key={index}
									type="submit"
									onClick={() => onClick()}
									loading={actionInProcess}
									{...props}>
									{label}
								</Button>
							);
						},
					)}
				</div>
				<button
					onClick={() => setPopoutOpen(!popoutOpen)}
					className={classNames({
						'header-popout-toggle': true,
						active: popoutOpen,
					})}>
					<ChevronIcon />
				</button>
			</div>
			<div className="app-header-lower">{lowerContent}</div>
			<div
				className={classNames({
					'header-popout': true,
					open: popoutOpen,
				})}>
				<div className="popout-content">{lowerContent}</div>
				<div className="popout-actions">
					{actions.map(
						(
							{ onClick, actionInProcess, label, ...props },
							index,
						) => {
							return (
								<Button
									key={index}
									type="submit"
									onClick={() => onClick()}
									loading={actionInProcess}
									{...props}>
									{label}
								</Button>
							);
						},
					)}
				</div>
			</div>
		</FixedHeader>
	);
}

export default Header;
