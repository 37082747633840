import React from 'react';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import styles from './ScreenLoader.module.scss';
import classNames from 'classnames/bind';

const ScreenLoader = ({ className = '' }) => {
	return (
		<div className={classNames(styles.container, className)}>
			<SpinnerIcon fill="#e87124" />
		</div>
	);
};

export default ScreenLoader;
