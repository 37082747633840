import React from 'react';
import UserIcon from '../Icons/UserIcon';
import { API_ROOT } from '@config';
import styles from './Avatar.module.scss';
import classNames from 'classnames';

const Avatar = ({
	user,
	className = null,
	avatarSize = 36,
	innerAvatarSize = null,
}) => {
	if (innerAvatarSize === null) {
		innerAvatarSize = avatarSize;
	}
	return (
		<div
			className={classNames({
				[styles.avatar]: true,
				[className]: !!className,
			})}
			style={{
				width: avatarSize,
				minWidth: avatarSize,
				height: avatarSize,
			}}>
			{!user.profile_avatar && (
				<UserIcon className={styles.avatarPlaceholder} />
			)}
			{!!user.profile_avatar && (
				<img
					className={styles.avatarImage}
					src={`${API_ROOT}/uploaded/avatars/${user.profile_avatar}`}
					style={{
						width: innerAvatarSize,
						height: innerAvatarSize,
					}}
				/>
			)}
		</div>
	);
};

export default Avatar;
