import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			width={13}
			height={17}
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<g>
				<path d="M4.865 13.375c1.13-.275 1.711-1.091 1.868-1.813.259-1.122-.753-2.22-.07-3.994.258 1.467 2.565 2.385 2.565 3.986.063 1.985-2.087 3.688-4.363 1.82zm7.283-3.948c-1.232-3.201-5.62-3.374-4.56-8.027A.396.396 0 007 .968c-2.849 1.68-4.897 5.046-3.178 9.456.14.361-.283.698-.589.463-1.42-1.075-1.57-2.62-1.444-3.727.047-.408-.486-.605-.714-.267C.541 7.709 0 9.027 0 11.013c.298 4.394 4.01 5.744 5.344 5.916 1.907.244 3.97-.11 5.454-1.467 1.632-1.514 2.228-3.931 1.35-6.035z" />
			</g>
		</svg>
	);
}

export default SvgComponent;
