export const letteringReducer = (state, action) => {
	switch (action.type) {
		/**
		 * Replace all lettering data
		 */

		case 'replace': {
			return action.payload;
		}

		/**
		 * Remove item and related rows
		 */

		case 'remove': {
			const start = parseInt(action.placement);
			const rows = parseInt(action.rows);

			return state.filter((item) => {
				const itemPlacement = parseInt(item.lettering_placement_id);
				return itemPlacement < start || itemPlacement >= start + rows;
			});
		}

		/**
		 * Create new item and related rows
		 */

		case 'create': {
			const { payload, rows } = action;

			// starting placement
			const start = parseInt(payload.lettering_placement_id);

			return [
				...state,
				...new Array(rows).fill({}).map((_, i) => ({
					...payload,
					lettering_placement_id: (start + i).toString(),
					lettering_row_number: i,
				})),
			];
		}

		/**
		 * Change item patch
		 */

		case 'updatePatch': {
			const { payload, rows, prevRows } = action;
			const start = parseInt(action.placement);
			const end = start + parseInt(rows) - 1;
			const prevEnd = start + parseInt(prevRows) - 1;

			let result = [...state];

			// clear unnecessary rows
			if (rows < prevRows) {
				result = result.filter(
					(item) =>
						item.lettering_placement_id <= end ||
						item.lettering_placement_id > prevEnd,
				);
			}

			// add additional rows
			if (rows > prevRows) {
				const template = result.find(
					(item) => item.lettering_placement_id == start,
				);

				for (let i = 0; i < rows - prevRows; i++) {
					result.push({
						...template,
						quote_product_option_value_lettering_custom_text: null,
						lettering_row_number: prevRows + i,
						lettering_placement_id: prevEnd + i + 1,
					});
				}
			}

			return result
				.filter((item) => !!item)
				.map((item) => {
					const itemPlacement = parseInt(item.lettering_placement_id);

					// if within relevant rows
					if (itemPlacement >= start && itemPlacement <= end) {
						// apply change
						item = { ...item, ...payload };
					}

					return item;
				});
		}

		/**
		 * Change item details (synced to all patch rows)
		 */

		case 'updateDetails': {
			const payload = action.payload;
			const start = parseInt(action.placement);
			const end = start + parseInt(action.rows) - 1;

			return state.map((item) => {
				const itemPlacement = parseInt(item.lettering_placement_id);

				// if within relevant rows
				if (itemPlacement >= start && itemPlacement <= end) {
					// apply change
					item = { ...item, ...payload };

					// clear custom text if type != custom
					if (
						payload['quote_product_option_value_lettering_type'] &&
						payload['quote_product_option_value_lettering_type'] !==
							'custom'
					) {
						item[
							'quote_product_option_value_lettering_custom_text'
						] = '';
					}

					// set type to 'custom' if custom text was changed
					if (
						Object.keys(payload).includes(
							'quote_product_option_value_lettering_custom_text',
						)
					) {
						item.quote_product_option_value_lettering_type =
							'custom';
					}
				}

				return item;
			});
		}
	}
};
