import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import Modal from '@components/Modal/Modal';
import AccountForm from '@components/AccountForm/AccountForm';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';
import modalStyles from '@components/Modal/Modal.module.scss';
import styles from './CreateAccountModal.module.scss';
import { useSelector } from 'react-redux';

const CreateAccountModal = ({ active = false, onClose = () => {} }) => {
	const history = useHistory();
	const buildToken = useSelector((state) => state.build.token);
	const [isDistributor, setIsDistributor] = useState(false);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (formData) => {
			await Http().post(`${API_ROOT}/users`, {
				user: formData,
				buildToken,
			});

			const url = `/verify?email=${formData.user_email}`;

			if (history) {
				history.push(url);
			} else {
				window.location = url;
			}
		},
		[history, buildToken],
	);

	return (
		<Modal
			active={active}
			onClose={() => onClose()}
			className={styles.modal}>
			<h4
				className={classNames([
					modalStyles.modalTitle,
					styles.modalTitle,
				])}>
				{' '}
				Create Account
			</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<p>Complete the profile to create your new account.</p>
				<AccountForm
					onSubmit={handleSubmit}
					onIsDistributorChange={(value) => setIsDistributor(value)}
					submitButtonLabel={
						isDistributor ? 'Request Access' : 'Submit'
					}
				/>
			</div>
		</Modal>
	);
};

export default CreateAccountModal;
