import { useCallback, useState } from 'react';
import classNames from 'classnames';
import Button from '@components/Button/Button';
import QuoteAlertModal from '@components/QuoteAlertModal/QuoteAlertModal';
import styles from './OrderItemActions.module.scss';
import QuoteStatusIndicator from '@components/QuoteStatusIndicator/QuoteStatusIndicator';
import { useSelector } from 'react-redux';
import CheckboxField from "@components/CheckboxField/CheckboxField";
import {isFinalized} from "@utilities/orders";

const OrderItemActions = ({ quote = {}, updateQuote = async () => {}, isApproving = false, setIsApproving = () => {}, isUpdating = false }) => {
	const user = useSelector((state) => state.auth.user);
	const isCSRUser = user.permissions?.includes('csr_access');
	const [quoteAlertModalActive, setQuoteAlertModalActive] = useState(false);

	/**
	 * Handle Approve
	 */

	const handleApprove = useCallback(async () => {
		setIsApproving(true);

		try {
			await updateQuote({ ...quote, quote_status: 'approved' });
		} catch (error) {
			console.log(error);
		}
	}, [quote, updateQuote, setIsApproving]);

	/**
	 * Handle Reject
	 */

	const handleReject = useCallback(
		async (alert = {}) => {
			try {
				return updateQuote({
					...quote,
					quote_status: 'rejected',
					alert,
				});
			} catch (error) {
				console.log(error);
			}
		},
		[quote, updateQuote],
	);

	return (
		<div className={styles.container}>
			{isCSRUser && (
				<div
					className={styles.reorderCheckboxWrapper}
				>
					<CheckboxField
						name="quote_not_for_reorder"
						label={"Not for Reorder"}
						value={parseInt(quote.quote_not_for_reorder)}
						onChange={(e) => updateQuote({ ...quote, quote_not_for_reorder: e.target.value })}
					/>
				</div>
			)}
			{isCSRUser && !isFinalized({ order_status: quote.order_status }) && quote.quote_status === 'approved' && (
				<div
					className={classNames(
						styles.indicator,
						styles.indicatorApproved,
						styles.indicatorRow,
					)}>
					<QuoteStatusIndicator quote={quote}>
						<span>Approved</span>
					</QuoteStatusIndicator>
					<Button
						variant="outlined"
						onClick={() => {
							setQuoteAlertModalActive(true);
						}}>
						Requires Edits
					</Button>
				</div>
			)}
			{quote.quote_status === 'rejected' && (
				<div
					className={classNames(
						styles.indicator,
						styles.indicatorRejected,
					)}>
					<QuoteStatusIndicator
						quote={quote}
						onClick={() => setQuoteAlertModalActive(true)}>
						<span>Requires Edits</span>
					</QuoteStatusIndicator>
				</div>
			)}
			{isCSRUser && quote.quote_status === null && (
				<>
					<Button
						disabled={isUpdating}
						variant="solid"
						onClick={handleApprove}
						loading={isApproving}>
						Approve
					</Button>
					<Button
						disabled={isUpdating}
						variant="outlined"
						onClick={() => {
							setQuoteAlertModalActive(true);
						}}>
						Requires Edits
					</Button>
				</>
			)}
			<QuoteAlertModal
				quote={quote}
				active={quoteAlertModalActive}
				onSubmit={handleReject}
				onClose={() => setQuoteAlertModalActive(false)}
			/>
		</div>
	);
};

export default OrderItemActions;
