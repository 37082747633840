export const schema = {
	address_street_1: {
		initialValue: '',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your street address';
		},
	},
	address_city: {
		initialValue: '',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your city';
		},
	},
	address_state: {
		initialValue: '',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please select your state';
		},
	},
	address_county: {
		initialValue: 'US',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your county';
		},
	},
	address_zip: {
		initialValue: '',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your zip code';
		},
	},
	fire_department_id: {
		initialValue: '',
		validate: (value, formData) => {
			if (formData.manual_department || formData.user_id) {
				return null;
			}
			return value ? null : 'Please select your fire department';
		},
	},
	user_email: {
		initialValue: '',
		validate: () => null,
	},
	products: {
		initialValue: [],
		validate: (value) => {
			return value.length
				? null
				: "Please select the products you're interested in";
		},
	},
	notes: {
		initialValue: '',
		validate: () => {
			return null;
		},
	},
	manual_department: {
		initialValue: false,
		validate: () => null,
	},
	fire_department_name: {
		initialValue: false,
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your Fire Department';
		},
	},
};
