import React, { useCallback } from 'react';
import classNames from 'classnames';
import styles from './Switch.module.scss';

const Switch = ({
	label = null,
	value = false,
	onChange = () => {},
	className = null,
}) => {
	const handleChange = useCallback(() => {
		onChange(!value);
	}, [onChange, value]);

	return (
		<div
			className={classNames({
				[styles.container]: true,
				[className]: !!className,
			})}>
			{!!label && <label onClick={handleChange}>{label}</label>}
			<button
				type="button"
				onClick={handleChange}
				className={classNames({
					[styles.switch]: true,
					[styles.active]: !!value,
				})}>
				<i />
			</button>
		</div>
	);
};

export default Switch;
