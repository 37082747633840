import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	error: false,
	errorInformation: {
		errorTile: null,
		errorMessage: null,
		errorType: null,
		errorAction() {},
		errorIgnoreAction() {}
	},
};

const errorSlice = createSlice({
	name: 'error',
	initialState,
	reducers: {
		setError(state, action) {
			return {
				...state,
				error: true,
				errorInformation: action.payload
			};
		},
		resetError() {
			return initialState;
		},
	},
});

export const { setError, resetError } = errorSlice.actions;
export default errorSlice.reducer;
