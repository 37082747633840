import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * Sync lettering-related option values (patch, patch color, style)
 */

export const useSyncLetteringValues = (
	dispatch,
	productOptionValues = [],
	active,
) => {
	// lettering option category ID
	const letteringCategoryId = useSelector(
		(state) => state.staticData.letteringCategoryId,
	);

	// filter lettering option values
	useEffect(() => {
		const values = productOptionValues
			.filter(
				(value) =>
					value.product_option_category_id === letteringCategoryId,
			)
			.map((value) => ({ ...value }));

		dispatch({ type: 'replace', payload: values });
	}, [dispatch, productOptionValues, letteringCategoryId, active]);
};
