import Screen from '@components/Screen/Screen';
import DashboardHeader from './components/DashboardHeader/DashboardHeader';
import FeaturedProducts from './components/FeaturedProducts/FeaturedProducts';
import News from './components/News/News';
import ProductCarousel from './components/ProductCarousel/ProductCarousel';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
	return (
		<Screen headerContent={<DashboardHeader />}>
			<div className={styles.contentRow}>
				<News />
				<ProductCarousel />
			</div>
			<FeaturedProducts />
		</Screen>
	);
};

export default Dashboard;
