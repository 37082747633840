import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToken } from '../../store/reducers/auth';
import { fetchUser } from '@store/thunks/fetch-user';
import { Link } from 'react-router-dom';
import AuthHeader from '@components/Auth/AuthHeader';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import AuthService from '@services/AuthService';
import Button from '@components/Button/Button';
import { useQueryParams } from '@hooks/useQueryParams';
import { validateEmail } from '@utilities/validateEmail';
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";

function Login({ location }) {
	const dispatch = useDispatch();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [error, setError] = useState('');
	const params = useQueryParams(location);
	const [showPasswordAlertModal, setShowPasswordAlertModal] = useState(false);

	/**
	 * Flash Message
	 */

	const flashMessage = useMemo(() => {
		switch (params.msg) {
			case 'reset-success': {
				return 'Your password has been updated!';
			}
			default: {
				return null;
			}
		}
	}, [params]);

	/**
	 * Handle Login
	 */

	const handleLogin = useCallback(
		async (e) => {
			e.preventDefault();

			if (!validateEmail(email)) {
				setError('Please enter a valid email address');
				return;
			}

			if (!password) {
				setError('Please enter your password');
				return;
			}

			setIsSubmitting(true);

			try {
				const loginData = await AuthService.login(email, password);
				const { token, showUpdatePasswordAlert } = loginData;

				if(showUpdatePasswordAlert) {
					setShowPasswordAlertModal(true);
					setIsSubmitting(false);
				} else {
					dispatch(setToken(token));
					dispatch(fetchUser());
				}
			} catch (error) {
				setError(
					error.response?.data?.meta?.message ||
						'Something went wrong.',
				);
				setIsSubmitting(false);
			}
		},
		[dispatch, email, password],
	);

	return (
		<>
			<AuthHeader />
			<Hero heroTitle="LOG IN" />
			<section className="content-wrapper">
				<div className="content-container flex-center-content">
					<form
						className="form-container"
						onSubmit={handleLogin}
						noValidate>
						{!!flashMessage && (
							<p className="form-text">{flashMessage}</p>
						)}
						<div className="input-container">
							<label>User Email*</label>
							<input
								autoComplete="email"
								type="email"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="input-container">
							<label>Password*</label>
							<input
								autoComplete="current-password"
								type="password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						{!!error && (
							<div className="form-errors">
								<p>{error}</p>
							</div>
						)}
						<div className="form-actions login-form-actions">
							<Button
								type="submit"
								loading={isSubmitting}
								className="login-submit-button">
								Log In
							</Button>
							<Link to="/forgot" className="forgot-password-link">
								Forgot Password?
							</Link>
						</div>
					</form>
				</div>
			</section>
			<Footer className="auth-footer" />
			<ConfirmationModal
				active={showPasswordAlertModal}
				title="Check your email to set a new password for FireWriter3!"
				confirmLabel="Okay"
				message=""
				showClose={false}
				onConfirm={() => setShowPasswordAlertModal(false)}
			/>
		</>
	);
}

export default Login;
