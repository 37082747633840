import { API_ROOT } from '@config';

const configurableProducts = {
	'full-set':{
		ids: [1, 2],
		key: 'full-set',
		label: 'Full Set',
		image: `url(${API_ROOT}/images/full-set-circle.png)`,
	},
	'coat':{
		ids: [1],
			key: 'coat',
			label: 'Only Coat',
			image: `url(${API_ROOT}/images/coat-circle.png)`,
	},
	'pants': {
		ids: [2],
		key: 'pants',
		label: 'Only Pants',
		image: `url(${API_ROOT}/images/pants-circle.png)`,
	},
	'coveralls': {
		ids: [3],
		key: 'coveralls',
		label: 'Coveralls',
		image: `url(${API_ROOT}/images/pants-circle.png)`,
	}
}
export const configurableProductOptions = new Map(Object.entries(configurableProducts));

export const getAvailableProducts = (productLine) => {
	if (!productLine || !Object.getOwnPropertyDescriptor(productLine, 'products') || !Array.isArray(productLine.products)) {
		return []
	}
	let availableOptions = [];
	const productIds = productLine.products.map(prod=>parseInt(prod.product_id));
	const hasFullSet = productIds.includes(1) && productIds.includes(2);
	if(hasFullSet){
		availableOptions.push(configurableProductOptions.get('full-set'))
	}
	if(productIds.includes(1)){
		let option = configurableProductOptions.get('coat');
		if(!hasFullSet){
			option.label = 'Coat';
		}
		availableOptions.push(option)
	}
	if(productIds.includes(2)){
		let option = configurableProductOptions.get('pants');
		if(!hasFullSet){
			option.label = 'Pants';
		}
		availableOptions.push(option)
	}
	if(productIds.includes(3)){
		availableOptions.push(configurableProductOptions.get('coveralls'))
	}

	return availableOptions;
}