import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ROOT } from '@config';

export const fetchStaticData = createAsyncThunk(
	'staticData/fetch',
	async () => {
		return axios
			.get(`${API_ROOT}/static`)
			.then((response) => response.data);
	},
);
