import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import calendarjs from 'calendar-js';
import date from '@utilities/date';
import { useClickOutside } from '@hooks/useClickOutside';
import CalendarIcon from '@components/Icons/CalendarIcon';
import ChevronIcon from '@components/Icons/ChevronIcon';
import fieldStyles from '../Field/Fields.module.scss';
import styles from './DateField.module.scss';
import SpinnerIcon from "@components/Icons/SpinnerIcon";

const months = calendarjs().months();

const DateField = ({
	label = null,
	value = '',
	name = '',
	onChange = () => {},
	className = null,
	inputClassName = null,
	calendarClassName = null,
	cellClassName = null,
	headerClassName = null,
	activeClassName = null,
	readonly = false,
	disabled = false,
	compact = false,
	placeholder = 'Select Date',
	loading = false
}) => {
	const containerNode = useRef(null);
	const [isActive, setIsActive] = useState(false);
	const [month, setMonth] = useState(date().get('month'));
	const [year, setYear] = useState(date().get('year'));

	/**
	 * Calendar Data
	 */

	const calendarData = useMemo(() => {
		return calendarjs().detailed(year, month).calendar;
	}, [month, year]);

	/**
	 * Handle Date Select
	 */

	const handleDateSelect = useCallback(
		(value) => {
			setIsActive(false);
			setMonth(date().get('month'));
			setYear(date().get('year'));
			onChange({
				target: { name, value },
			});
		},
		[name, onChange],
	);

	/**
	 * Handle Click Outside
	 */

	useClickOutside(containerNode.current, () => {
		setIsActive(false);
		setMonth(date().get('month'));
		setYear(date().get('year'));
	});

	/**
	 * Handle Increment Month
	 */

	const handleIncrementMonth = useCallback(
		(delta) => {
			const next = date(`${months[month]} ${year} 15`, 'MMMM YYYY D').add(delta, 'month');

			setMonth(next.get('month'));
			setYear(next.get('year'));
		},
		[month, year],
	);

	return (
		<div
			ref={containerNode}
			className={classNames({
				[fieldStyles.fieldContainer]: true,
				[fieldStyles.readonly]: readonly,
				[fieldStyles.disabled]: disabled,
				[fieldStyles.compact]: compact,
				[styles.fieldContainer]: true,
				[styles.dateFieldCompact]: compact,
				[styles.dateFieldReadonly]: readonly,
				[styles.dateFieldDisabled]: disabled,
				[styles.dateFieldActive]: isActive,
				[className]: !!className,
				[activeClassName]: !!activeClassName && isActive,
			})}>
			{!!label && <label>{label}</label>}
			<div className={styles.positioningContainer}>
				<div className={styles.iconContainer}>
					<CalendarIcon />
				</div>
				<div
					className={classNames({
						[styles.loader]: true,
						[styles.loaderActive]: loading,
					})}>
					<SpinnerIcon fill="#e87124" />
				</div>
				<div
					tabIndex={readonly || disabled ? '-1' : '0'}
					onClick={() => setIsActive(!isActive)}
					className={classNames({
						[fieldStyles.fieldInput]: true,
						[styles.dateFieldDisabled]: true,
						[styles.dateFieldPlaceholder]: !value,
						[inputClassName]: !!inputClassName,
					})}
					disabled={disabled}
					readOnly={readonly}>
					<p>{value ? date(value).format('M/D/YY') : placeholder}</p>
				</div>
				<div
					className={classNames({
						[styles.dateFieldCalendarContainer]: true,
						[calendarClassName]: !!calendarClassName,
					})}>
					<header
						className={classNames({
							[styles.dateFieldCalendarHeader]: true,
							[headerClassName]: !!headerClassName,
						})}>
						<div>
							<ChevronIcon
								onClick={() => handleIncrementMonth(-1)}
							/>
							<span>
								{months[month]} {year}
							</span>
							<ChevronIcon
								onClick={() => handleIncrementMonth(1)}
							/>
						</div>
						<ul className={styles.dateFieldCalendarWeekdayLabels}>
							<li>Sun</li>
							<li>Mon</li>
							<li>Tue</li>
							<li>Wed</li>
							<li>Thu</li>
							<li>Fri</li>
							<li>Sat</li>
						</ul>
					</header>
					<div className={styles.dateFieldCalendar}>
						{calendarData.map((week, w) => (
							<ul key={w} className={styles.dateFieldCalendarRow}>
								{week.map((day, d) => (
									<li
										key={d}
										onClick={() =>
											handleDateSelect(
												date(day.date).format(
													'YYYY-MM-DD HH:mm:ss',
												),
											)
										}
										className={classNames({
											[styles.dateFieldCalendarDay]: true,
											[styles.dateFieldCalendarDayToday]:
												date(day.date).isToday(),
											[cellClassName]: !!cellClassName,
										})}>
										<div>
											<span>{day.day}</span>
										</div>
									</li>
								))}
							</ul>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DateField;
