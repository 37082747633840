import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import fieldStyles from '../Field/Fields.module.scss';

const TextField = ({
	label = null,
	value = '',
	name = '',
	type = 'text',
	onChange = () => {},
	onFocus = () => {},
	onBlur = () => {},
	inputProps = {},
	className = null,
	inputClassName = null,
	readonly = false,
	disabled = false,
	compact = false,
	placeholder = null,
	adornment = null,
	adornmentClassName = null,
	error = null,
	min = null,
	max = null,
	style= {},
}) => {

	const [autoUpdate, setAutoUpdate] = useState(false);

	const handleChange = (e) => {
		if(!e.target.value){
			onChange(e);
			return;
		}

		if (type === 'number') {
			e.target.value = e.target.value.replace(/[^0-9.]/g, '');
			if(min){
				e.target.value = Math.max(Number(e.target.value), min);
			}
			if(max){
				e.target.value = Math.min(Number(e.target.value), max);
			}
		}
		onChange(e);
	};

	useEffect(() => {
		if(value !== null) {
			if (typeof parseInt(max) === 'number' || typeof parseInt(min) === 'number') {
				let updatedValue = value;
				if (type === 'number') {
					if(typeof value === 'number') {
						updatedValue = updatedValue.toString();
					}
					updatedValue = updatedValue.replace(/[^0-9.]/g, '');

					if (min) {
						updatedValue = Math.max(Number(updatedValue), min);
					}
					if (max) {
						updatedValue = Math.min(Number(updatedValue), max);
					}
				} else {
					if (value.length > parseInt(max)) {
						updatedValue = value.substring(0, max);
					}
				}

				onChange({target: {name, value: updatedValue}});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [max, min, type])

	return (
		<div
			className={classNames({
				[fieldStyles.fieldContainer]: true,
				[fieldStyles.readonly]: readonly,
				[fieldStyles.disabled]: disabled,
				[fieldStyles.compact]: compact,
				[className]: !!className,
			})}
		style={style}>
			{!!label && <label>{label}</label>}
			{!!adornment && (
				<div
					className={classNames({
						[fieldStyles.adornmentContainer]: true,
						[adornmentClassName]: !!adornmentClassName,
					})}>
					{adornment}
				</div>
			)}
			<input
				name={name}
				type={type}
				min={min}
				max={max}
				value={value}
				onChange={handleChange}
				onFocus={onFocus}
				onBlur={onBlur}
				className={classNames({
					[fieldStyles.fieldInput]: true,
					[inputClassName]: !!inputClassName,
				})}
				placeholder={placeholder}
				readOnly={readonly}
				disabled={disabled}
				tabIndex={readonly || disabled ? '-1' : '0'}
				spellCheck={false}
				{...inputProps}
			/>
			{!!error && <span className={fieldStyles.fieldError}>{error}</span>}
		</div>
	);
};

export default TextField;
