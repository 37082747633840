import React, { useCallback } from 'react';
import ChevronIcon from '@components/Icons/ChevronIcon';
import SelectField from '@components/SelectField/SelectField';
import styles from './Filter.module.scss';

const Filter = ({
	filterOptions = [],
	filterField = '',
	filterPlaceholder = 'Filter',
	onFilterChange = () => {},
	isFiltering = false,
}) => {
	/**
	 * Handle Filter Change
	 */

	const handleChange = useCallback(
		async (e) => {
			onFilterChange(e.target.value);
		},
		[onFilterChange],
	);

	return (
		<div className={styles.filterContainer}>
			<SelectField
				value={filterField}
				options={filterOptions}
				onChange={handleChange}
				className={styles.filterField}
				inputClassName={styles.filterFieldInput}
				menuClassName={styles.filterFieldMenu}
				placeholder={filterPlaceholder}
				loading={isFiltering}
				allowNull={true}
				indicator={<ChevronIcon fill="rgba(34, 34, 34, 0.5)" />}
			/>
		</div>
	);
};

export default Filter;
