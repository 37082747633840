import * as React from 'react';

const SvgComponent = (props) => (
	<svg
		width={33}
		height={27}
		viewBox="0 0 33 37"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path d="m29.25 7-13 8.125L3.25 7V3.75l13 8.125 13-8.125zm0-6.5h-26C1.462.5.016 1.962.016 3.75L0 23.25a3.26 3.26 0 0 0 3.25 3.25h26a3.26 3.26 0 0 0 3.25-3.25V3.75A3.26 3.26 0 0 0 29.25.5z" />
	</svg>
);

export default SvgComponent;
