import React, { useMemo } from 'react';
import CheckmarkIcon from '@components/Icons/CheckmarkIcon';
import ExclamationIcon from '@components/Icons/ExclamationIcon';
import styles from './QuoteStatusIndicator.module.scss';
import classNames from 'classnames';

const QuoteStatusIndicator = ({
	quote = {},
	onClick = null,
	children = null,
	className = null,
}) => {
	const icon = useMemo(() => {
		switch (quote.quote_status) {
			case 'approved': {
				return <CheckmarkIcon fill="#fff" />;
			}
			case 'rejected': {
				return <ExclamationIcon fill="#FFF" />;
			}
		}
	}, [quote]);

	return (
		<div
			onClick={() => onClick && onClick()}
			className={classNames({
				[styles.indicator]: true,
				[styles.indicatorClickable]: !!onClick,
				[styles.indicatorApproved]: quote.quote_status === 'approved',
				[styles.indicatorRejected]: quote.quote_status === 'rejected',
				[className]: !!className,
			})}>
			<i>{icon}</i>
			{children}
		</div>
	);
};

export default QuoteStatusIndicator;
