import React, { useCallback, useEffect, useRef, useState } from 'react';
import Http from '@utilities/Http';
import { API_ROOT, SKETCH_API_TOKEN } from '@config';
import ConfiguratorPreview from '@components/ConfiguratorPreview/ConfiguratorPreview';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styles from './Sketch.module.scss';

const Sketch = ({ match, params }) => {
	const history = useHistory();
	const [quote, setQuote] = useState(null);
	const [siblingQuote, setSiblingQuote] = useState(null);
	const [isFetching, setIsFetching] = useState(true);
	const token = useQuery().get('x');
	const [imageOrientation, setImageOrientation] = useState('front');

	function toggleImageOrientation(orientation = null) {
		if(orientation) {
			setImageOrientation(orientation);
		} else {
			if (imageOrientation === 'front') {
				setImageOrientation('back');
			} else {
				setImageOrientation('front');
			}
		}
	}

	function useQuery() {
		const { search } = useLocation();

		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	/**
	 * Fetch Quote
	 */

	useEffect(() => {
		setIsFetching(true);
		Http()
			.get(`${API_ROOT}/build/${match.params?.quoteId}`, {
				params: {
					getSiblingQuote: 1,
					x: token,
				},
			})
			.then((quoteResponse) => {
				setQuote(quoteResponse.data.quote);
				if (quoteResponse.data.siblingQuote)
					setSiblingQuote(quoteResponse.data.siblingQuote);
			})
			.finally(() => {
				setIsFetching(false);
			});
	}, [match.params]);

	return (
		// <Screen loading={isFetching}>
		<div className="sketch-preview-page configurator-preview-container">
			{!isFetching && quote && (
				<ConfiguratorPreview
					quote={quote}
					selectedProductOptionValues={quote.productOptionValues}
					siblingQuote={siblingQuote ?? null}
					siblingSelectedProductOptionValues={
						siblingQuote ? siblingQuote.productOptionValues : null
					}
					imageOrientation={imageOrientation}
					toggleImageOrientation={toggleImageOrientation}
					isSummary={true}
				/>
			)}
		</div>
		// </Screen>
	);
};

export default Sketch;
