import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '@components/Card/Card';
import Product from './components/Product/Product';
import Http from '@utilities/Http';
import ProductSelectModal from '@components/ProductSelectModal/ProductSelectModal';
import CreateQuoteFromProductModal from '@components/CreateQuoteFromProductModal/CreateQuoteFromProductModal';
import styles from './ProductLines.module.scss';
import { API_ROOT } from '@config';

const ProductLines = ({ setAvailableCategories = () => {} }) => {
	const user = useSelector((state) => state.auth.user);
	const [isMounted, setIsMounted] = useState(false);

	// product line categories
	const categories = useSelector(
		(state) => state.staticData.productCategories,
	);

	// categorized product lines
	const [categorizedProductLines, setCategorizedProductLines] = useState([]);

	// selected values
	const [selectedProductLine, setSelectedProductLine] = useState(null);
	const [selectedProductIds, setSelectedProductIds] = useState([]);

	// modals
	const [productSelectModalActive, setProductSelectModalActive] =
		useState(false);
	const [createQuoteModalActive, setCreateQuoteModalActive] = useState(false);

	/**
	 * Fetch Product Lines
	 */

	const fetchProductLines = useCallback(async () => {
		const endpoint = user?.organization_id
			? `organizations/product-lines/${user.organization_id}`
			: 'product-lines/all';

		const { data } = await Http().get(`${API_ROOT}/${endpoint}`);

		return data.productLines;
	}, [user]);

	/**
	 * Organize Product Lines By Category
	 */

	useEffect(() => {
		if (!isMounted) {
			return;
		}

		fetchProductLines().then((productLines) => {
			// chunk by category
			const categorized = productLines.reduce((result, product_line) => {
				if (!result[product_line.product_line_category_id]) {
					result[product_line.product_line_category_id] = [];
				}

				result[product_line.product_line_category_id].push(
					product_line,
				);

				return result;
			}, {});

			if (isMounted) {
				setCategorizedProductLines(categorized);
				setAvailableCategories(
					Object.keys(categorized).reduce((result, key) => {
						if (categorized[key].length) {
							result.push(key);
						}

						return result;
					}, []),
				);
			}
		});
	}, [fetchProductLines, isMounted, setAvailableCategories]);

	/**
	 * Handle Product Select
	 */

	const handleProductSelect = useCallback((productIds) => {
		setSelectedProductIds(productIds);
		setProductSelectModalActive(false);
		setTimeout(() => {
			setCreateQuoteModalActive(true);
		}, 150);
	}, []);

	useEffect(() => {
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	function getSelectedProductLine() {
		if(!selectedProductLine) {
			return null;
		}

		let productLines = [];

		for (let productLinesArrayKey in categorizedProductLines) {
			productLines = productLines.concat(categorizedProductLines[productLinesArrayKey]);
		}

		return productLines.find((productLine) => {
			return productLine.product_line_id === selectedProductLine;
		});
	}

	return (
		<>
			{categories.map((category) => {
				if (
					!categorizedProductLines[category.product_line_category_id]
						?.length
				) {
					return null;
				}

				return (
					<Card
						key={category.product_line_category_id}
						title={category.product_line_category_title || ''}
						cardId={`product-line-category-${category.product_line_category_id}`}>
						<ul className={styles.list}>
							{categorizedProductLines[
								category.product_line_category_id
							]?.map((productLine) => (
								<li
									key={
										productLine.product_line_id
											? productLine.product_line_id +
											  '-pl'
											: productLine.product_id + '-p'
									}>
									<Product
										productLine={productLine}
										onCustomize={() => {
											if (productLine.product_line_id) {
												setSelectedProductLine(
													productLine.product_line_id,
												);
												setProductSelectModalActive(
													true,
												);
											} else {
												setSelectedProductIds([productLine.product_id]);
												setCreateQuoteModalActive(true);
											}
										}}
									/>
								</li>
							))}
						</ul>
					</Card>
				);
			})}
			<ProductSelectModal
				productLine={getSelectedProductLine()}
				active={productSelectModalActive}
				onClose={() => {
					setProductSelectModalActive(false)
					setSelectedProductLine(null);
				}}
				onSelect={handleProductSelect}
			/>
			<CreateQuoteFromProductModal
				active={createQuoteModalActive}
				onClose={() => {
					setCreateQuoteModalActive(false)
					setSelectedProductLine(null);
				}}
				productLineId={selectedProductLine}
				productIds={selectedProductIds}
			/>
		</>
	);
};

export default ProductLines;
