import React, { useEffect } from 'react';
import { useMobileMenuState } from '@hooks/useMobileMenuState';
import ScreenLoader from '@components/ScreenLoader/ScreenLoader';
import AuthenticatedNavigation from '../Navigation/AuthenticatedNavigation/AuthenticatedNavigation';
import PublicNavigation from '../Navigation/PublicNavigation/PublicNavigation';
import Footer from '../Footer';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
const Screen = ({
	children = null,
	headerContent = null,
	footerContent = null,
	hideNavigation = false,
	hideSidebar = false,
	hideFooter = false,
	loading = false,
	pageContentClassName = '',
	wrapperClassName = '',
}) => {
	const user = useSelector((state) => state.auth.user);
	const [, setMobileMenuOpen] = useMobileMenuState();

	// close mobile menu on mount
	useEffect(() => {
		setMobileMenuOpen(false);
	}, [setMobileMenuOpen]);

	return (
		<div
			className={classNames({
				'page-layout': true,
				'hide-navigation': hideNavigation,
				'hide-sidebar': hideSidebar,
				[wrapperClassName]: !!wrapperClassName,
			})}>
			{!hideNavigation &&
				(user && window.location.hash !== '#contact-form' ? (
					<AuthenticatedNavigation />
				) : (
					<PublicNavigation />
				))}
			{headerContent}
			<div
				className={classNames({
					'page-content': true,
					[pageContentClassName]: !!pageContentClassName,
				})}>
				{!loading && (
					<>
						{children}
						{!hideFooter && <Footer />}
					</>
				)}
				{loading && <ScreenLoader />}
			</div>
			{footerContent}
			<ErrorMessage />
		</div>
	);
};

export default Screen;
