import React, {useState, useEffect, createRef, useCallback, useRef, useReducer, useMemo} from 'react';
import ReactDOM from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';
import classNames from 'classnames/bind';
import Axios from 'axios';
import Collapsible from 'react-collapsible';
import {API_ROOT, OUTER_SHELL_OPTION_ID, THERMAL_LINER_OPTION_ID, MOISTURE_BARRIER_OPTION_ID} from '@config';
import Http from '@utilities/Http';
import Header from '@components/Header';
import FieldSet from '@components/FieldSet';
import {range, getProductOptionValueLabel, getQuoteDisplayNumber} from '@utilities/methods';
import ScreenOverlay from '@components/ScreenOverlay';
import trashcan from '@assets/images/trashcan.svg';
import trashcanWhite from '@assets/images/trashcan-white.svg';
import QuoteHeaderInformation from '@components/QuoteHeaderInformation';
import Button from '@components/Button/Button';
import ConfiguratorPreview from '@components/ConfiguratorPreview/ConfiguratorPreview';
import ScreenLoader from "@components/ScreenLoader/ScreenLoader";
import {setScreen, resetConfigurator, updateConfiguratorOptionsQuoteId} from "@store/reducers/configurator";
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';
import QuoteSaveNotificationModal from '@components/QuoteSaveNotificationModal/QuoteSaveNotificationModal';
import CreateAccountPromptModal from '@components/CreateAccountPromptModal/CreateAccountPromptModal';
import WhyCreateAccountModal from '@components/WhyCreateAccountModal/WhyCreateAccountModal';
import CreateAccountModal from '@components/CreateAccountModal/CreateAccountModal';
import SaveToSwitchQuoteModal from "@components/SaveToSwitchQuoteModal";
import LetteringSummary from '@components/LetteringSummary/LetteringSummary';
import VariableProductImage from "@components/VariableProductImage";
import {isQuoteApproved, isSubmitted} from "@utilities/orders";
import {letteringReducer} from "@components/LetteringModal/reducer";
import CollapseCustom from "@components/CollapseCustom/CollapseCustom";
import Messenger from "@components/Messenger/Messenger";
import TextField from '@components/TextField/TextField';
import { setIsNewQuote } from '@store/reducers/build';
import {useErrorAlert} from "@hooks/useErrorAlert";
import ConfiguratorBuildInput from "./components/ConfiguratorBuildInput";

export const custom_x_default = "101"
export const custom_y_default = "-1"

function optionIsTextArea(productOption) {
    return productOption?.product_option_type_id === '6';
}

// map[categoryId] : map[optionId] : map(optionvalueId : optionvalue)
let allProductOptions = new Map();
// map[optionId : categoryId]
let allOptionCategoryIds = new Map();
// map[optionValueId : optionValue]
let allOptionValues = new Map();

function ConfiguratorBuild({productLineId = null, productId = null, quoteId = null, highlightErrors = false, setHighlightErrors = () => {}}) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const buildToken = useSelector(state => state.build.token);
    const [buildIsNewQuote, setBuildIsNewQuote] = useState(useSelector(state => state.build.isNewQuote));
    const {productOptionSections, letteringOptions, positionOptions, letteringCategoryId, specialRequestCategoryId, staticDataSettled} = useSelector(
        (state) => state.staticData
    );

    const productLines = useSelector((state) => state.staticData.productLines);

    const defaultLetteringPlacements = useSelector(
        (state) => state.staticData.letteringOptions.placements,
    );
    const [letteringPlacements,setLetteringPlacements] = useState(defaultLetteringPlacements);

    const [letteringData, letteringDispatch] = useReducer(letteringReducer, []);
    const [letteringPlacement, setLetteringPlacement] = useState('');

    const [hoverImage,setHoverImage] = useState(null);

    const [customPlacementsValid, setCustomPlacementsValid] = useState(true);
    const [refreshToken, setRefreshToken] = useState(0);
    const [checkForUpdates, setCheckForUpdates] = useState(true);
    const fetchUpdatesTimeOut = useRef(null);

    const [imageOrientation, setImageOrientation] = useState('front');

    const {headerHeight} = useSelector((state) => state.dimensions);

    const [state, setState] = useState({
        productLineId: productLineId ? productLineId : null,
        productId: productId ? productId : null,
        productRetrieved: false,
        quoteId: null,
        quote: {productImages: []},
        product: {outlineImages: []},
        activeSectionIndex: 0,
        activeCategoryIndex: null,
        quoteProductOptionValues: [],
        validProductOptionValues: [],
        productOptionSections: [],
        productOptions: [],
        requiredOptions: [],
        autoScroll: false,
        configurableOptionPositions: [
            {
                product_option_value_position_id: '1',
                product_option_value_position_title: 'Left',
            },
            {
                product_option_value_position_id: '2',
                product_option_value_position_title: 'Right',
            },
            {
                product_option_value_position_id: '3',
                product_option_value_position_title: 'Customize',
            },
        ],
        configurableOptionPositionSides: [
            {
                'label': 'Front',
                'value': 'front',
            },
            {
                'label': 'Back',
                'value': 'back',
            },
        ],
        optionValueConfigurationTemp: {
            product_option_value_id: null,
            product_option_value_position_id: null,
            product_option_value_configuration_quantity: null,
        },
        currentlyConfiguringOptionValueIndex: null,
        configuringCategoryId: null,
        configuringProductOptionIndex: null,
        configuringProductOption: null,
        savingQuote: false,
        optionsRetrieved: false,
        validOptionsRetrieved: false,
        productOptionValuesAutomaticUpdate: false,
        firstInitialized: false,
        positioningCategoryId: null,
        positioningProductOptionIndex: null,
        positioningProductOption: null,
        currentlyPositioningOptionValueIndex: null
    });

    const [validatedOptions,setValidatedOptions] = useState(new Map());

    const [isPristine, setIsPristine] = useState(true);
    const [confirmModalActive, setConfirmModalActive] = useState(false);
    const [quoteSaveModalActive, setQuoteSaveModalActive] = useState(false);
    const [createAccountPromptModalActive, setCreateAccountPromptModalActive] = useState(false);
    const [whyCreateAccountModalActive, setWhyCreateAccountModalActive] = useState(false);
    const [createAccountModalActive, setCreateAccountModalActive] = useState(false);
    const [saveToSwitchQuoteModalActive, setSaveToSwitchQuoteModalActive] = useState(false);
    const [switchToID, setSwitchToID] = useState(null);
    const [isEditable, setIsEditable] = useState(true);
    const isCSRUser = user?.permissions?.includes('csr_access');
    const isRSMUser = user?.permissions?.includes('rsm_access');
    const isComplete = useRef(false);
    const savingToSwitchQuote = useRef(false);
    const [showLetteringPlacements,setShowLetteringPlacements] = useState(false);
    const [reloadOptions, setReloadOptions] = useState(false);
    const [, setErrorAlert] = useErrorAlert();

    const [composite, setComposite] = useState(null);

    const [activeSubCategoryIds, setActiveSubCategoryIds] = useState([]);

    let configuringSelectedOptionValueIndex;
    let configuringSelectedOptionValue;

    const isLetteringAvailable = useMemo(()=>{
        const isAvailable =  getActiveOptionSections().filter(section=>{
            return getActiveOptionCategoriesBySectionId(section.product_option_section_id).filter(productOptionCategory=>{
                return productOptionCategory.product_option_category_id === letteringCategoryId;
            }).length>0;
        }).length>0
        return isAvailable;
    },[getActiveOptionSections, getActiveOptionCategoriesBySectionId, letteringCategoryId])


    if (optionValueConfiguratorActive()) {
        if (state.optionValueConfigurationTemp.product_option_value_id) {
            configuringSelectedOptionValueIndex =
                state.configuringProductOption.values.findIndex((value) =>
                    value.product_option_value_id === state.optionValueConfigurationTemp.product_option_value_id,
                );
            configuringSelectedOptionValue = state.configuringProductOption.values[configuringSelectedOptionValueIndex];
        }
    }

    const compositeOptionValueIds = {
        outerShellId: getProductOptionQuoteProductOptionValues(OUTER_SHELL_OPTION_ID)[0]?.product_option_value_id,
        thermalLinerId: getProductOptionQuoteProductOptionValues(THERMAL_LINER_OPTION_ID)[0]?.product_option_value_id,
        moistureBarrierId: getProductOptionQuoteProductOptionValues(MOISTURE_BARRIER_OPTION_ID)[0]?.product_option_value_id,
    }

    const quoteNumbers = state.quote.quote_id ? [getQuoteDisplayNumber(state.quote)] : [];

    if (state.quote.siblingQuote) {
        quoteNumbers.push(getQuoteDisplayNumber(state.quote.siblingQuote))
    }

    let quoteNumberDisplay = quoteNumbers.sort()[0];
    if(quoteNumbers.sort()[0]) {
        quoteNumberDisplay = quoteNumbers.sort()[0].toString().padStart(4, '0');
    }

    //Check if section is active
    function sectionIsActive(sectionIndex) {
        return state.activeSectionIndex === sectionIndex;
    }

    //Check if category is active
    function categoryIsActive(sectionIndex, categoryIndex) {
        return (
            sectionIsActive(sectionIndex) &&
            state.activeCategoryIndex === categoryIndex
        );
    }

    //Check if option value selection for option is valid
    function optionSelectionIsValid(productOptionId) {
       if (!validatedOptions){
        return true;
       }
        return validatedOptions.has(productOptionId) && validatedOptions.get(productOptionId).validated;
    }

    function categoryOptionSelectionsAreValid(categoryId) {
        let isValid = true;
        for (let option of getCategoryOptions(categoryId)) {
            // const isRequired = optionIsRequired(option); //todo: should validate all sections now.

            if (!optionSelectionIsValid(option.product_option_id)) {
                isValid = false;
            }
        }

        return isValid;
    }

    function categoryHasRequiredOption(categoryId) {

        for (let option of getCategoryOptions(categoryId)) {
            let foundOption = state.requiredOptions.find(requiredOption => option.product_option_id === requiredOption.product_option_id);
            if (foundOption) {
                return true;
            }
        }

        return false;
    }

    //Check if option value is selected
    function optionValueIsSelected(optionValueId) {

        return (
            state.quoteProductOptionValues.findIndex((quoteProductOptionValue) => {
                    return (
                        quoteProductOptionValue.product_option_value_id === optionValueId
                    );
                },
            ) >= 0
        );
    }

    //Check if option value is available/valid
    const optionValueIsAvailable = (optionId, optionValueId) => {
        if(!validatedOptions.has(optionId)){
            return false;
        }
        const option = validatedOptions.get(optionId);
        return option.valid.includes(optionValueId.toString());
    };

    //Set category active
    function setCategoryActive(sectionIndex, categoryIndex, isLettering = false) {
        if (!categoryIsActive(sectionIndex, categoryIndex)) {
            //set active category to 0 when active section updated
            setState((prevState) => ({
                ...prevState,
                activeSectionIndex: sectionIndex,
                activeCategoryIndex: categoryIndex,
            }));
            setShowLetteringPlacements(isLettering);
        }
        setRefreshToken(Math.random());
    }

    //Set category inactive
    function setCategoryInactive(sectionIndex, categoryIndex, isLettering = false) {
        if(categoryIsActive(sectionIndex, categoryIndex)){
            setState((prevState) => ({
                ...prevState,
                activeSectionIndex: null,
                activeCategoryIndex: null,
            }));
            if(isLettering) {
                setShowLetteringPlacements(false);
            }
        }
        setRefreshToken(Math.random());
    }


    function deselectOptionValue(productOptionValueId) {
        setIsPristine(false);
        let newSelections = Object.fromEntries(validatedOptions);
        const productOptionValue = getProductOptionValueById(productOptionValueId);
        const dependencies = newSelections[productOptionValue.product_option_id].dependencies[productOptionValueId];
        const idsToRemove = typeof dependencies === 'undefined' ? [productOptionValueId] : [productOptionValueId, ...Object.values(dependencies)];
        //remove other optionValues if they were added as a 'require all' rule, values may be in different options
        for (const optionId of Object.keys(newSelections)) {
            for(let i = 0; i<idsToRemove.length; i++){
                const existingIndex = newSelections[optionId]?.selected?.indexOf(idsToRemove[i].toString());
                if (existingIndex >= 0) {
                    newSelections[optionId].selected.splice(existingIndex, 1);
                }
            }
        }
        let selectedIds = extractOptionSelections(newSelections);

        updateValidatedSelections(selectedIds);

        setRefreshToken(Math.random());
    }


    function deselectOptionValues(productOptionValueIds) {
        setIsPristine(false);
        let newSelections = Object.fromEntries(validatedOptions);
        let idsToRemove = [];

        for (let productOptionValueId of productOptionValueIds) {
            const productOptionValue = getProductOptionValueById(productOptionValueId);
            const dependencies = newSelections[productOptionValue.product_option_id].dependencies[productOptionValueId];
            idsToRemove = typeof dependencies === 'undefined' ? [...idsToRemove, productOptionValueId] : [...idsToRemove, productOptionValueId, ...dependencies];
        }
        //remove other optionValues if they were added as a 'require all' rule, values may be in different options
        for (const optionId of Object.keys(newSelections)) {
            for(let i = 0; i<idsToRemove.length; i++){
                const existingIndex = newSelections[optionId]?.selected?.indexOf(idsToRemove[i].toString());
                if (existingIndex >= 0) {
                    newSelections[optionId].selected.splice(existingIndex, 1);
                }
            }
        }
        let selectedIds = extractOptionSelections(newSelections);

        updateValidatedSelections(selectedIds);

        setRefreshToken(Math.random());

    }




    function getOptionValueText(productOption) {
        if (!optionIsTextArea(productOption)) {
            return '';
        }
        const existingIndex = state.quoteProductOptionValues.findIndex((quoteProductOptionValue) => {
            return (
                quoteProductOptionValue.product_option_value_id ===
                productOption.values[0].product_option_value_id
            );
        },
    );
        return existingIndex >=0? state?.quoteProductOptionValues[existingIndex]?.quote_product_option_value_message || '' : '';
    }

    function setOptionValueText(categoryId, categoryOptionIndex, productOptionValue, newText){
        setIsPristine(false);

        const categoryOptions = getCategoryOptions(categoryId);
        const productOption = categoryOptions[categoryOptionIndex];
        if(!optionIsTextArea(productOption)){
            return;
        }

        productOptionValue.quote_product_option_value_message = newText;
        setState((prevState) => {
            let quoteProductOptionValues = [...prevState.quoteProductOptionValues];

            for (let optionValue of getCategoryOptions(categoryId)[categoryOptionIndex].values) {
                const existingIndex = quoteProductOptionValues.findIndex((quoteProductOptionValue) => {
                        return (
                            quoteProductOptionValue.product_option_value_id ===
                            optionValue.product_option_value_id
                        );
                    },
                );
                if (existingIndex >= 0) {
                    quoteProductOptionValues.splice(existingIndex, 1);
                    break;
                }
            }

            quoteProductOptionValues.push(productOptionValue);

            let activeSectionIndex = prevState.activeSectionIndex;
            let activeCategoryIndex = prevState.activeCategoryIndex;
            let autoScroll = false;

            return {
                ...prevState,
                quoteProductOptionValues,
                activeCategoryIndex,
                activeSectionIndex,
                autoScroll,
            };
        });
    }

    const textStateFunctions = {setter: setOptionValueText, getter: getOptionValueText};



    //Set option value selected
    function setOptionValueSelected(categoryId, categoryOptionIndex, productOptionValue, isAutomatic = false) {
        setIsPristine(false);
        const categoryOptions = getCategoryDirectOptions(categoryId);
        const productOption = categoryOptions[categoryOptionIndex];
        if (!optionValueIsAvailable(productOption.product_option_id, productOptionValue.product_option_value_id) || !validateProductOptionDependentProductOptionsSet(productOption)) {
            return;
        }
        const optionId = productOption.product_option_id;
        let newSelections = Object.fromEntries(validatedOptions);
        if(typeof newSelections[optionId] === 'undefined'){
            newSelections[optionId] = {selected: []};
        }

        let action = 'add';
        if (optionValueIsSelected(productOptionValue.product_option_value_id)) {
            action = 'remove';
        }

        if (!productOptionValue.product_option_value_id) {
            action = 'remove-multiple';

        }
        if(action === 'remove-multiple' || action === 'remove'){
            const dependencies = validatedOptions.get(productOption.product_option_id).dependencies[productOptionValue.product_option_value_id];
            const idsToRemove = typeof dependencies === 'undefined' ? [productOptionValue.product_option_value_id] : [productOptionValue.product_option_value_id, ...Object.values(dependencies)];
            //remove other optionValues if they were added as a 'require all' rule, values may be in different options
            for (const optionId of Object.keys(newSelections)) {

                for(let i = 0; i<idsToRemove.length; i++){
                    const existingIndex = newSelections[optionId]?.selected?.indexOf(idsToRemove[i].toString());
                    if (existingIndex >= 0) {
                        newSelections[optionId].selected.splice(existingIndex, 1);
                    }
                }
            }

            const tempSelectedIds = extractOptionSelections(newSelections);
            for (const optionId of Object.keys(newSelections)) {

                const catId = allOptionCategoryIds.get(optionId)
                const option = allProductOptions.get(catId)?.get(optionId);
                if(!validateProductOptionDependentProductOptionsSet(option, tempSelectedIds)){
                     newSelections[optionId].selected = [];
                }
             }


            if(action == 'remove-multiple'){

                newSelections[optionId].selected = [];

            }
         } else if(action == 'add'){

            if (!optionIsMultiselect(productOption)) {
                //Deselect other values
                newSelections[optionId].selected = [];

                if (productOption.product_option_id === '3') {
                    let productLine = productLines.find(productLine => productLine.product_line_id === state.productLineId);
                    if (productLine.product_line_limit_patch_color == 1) {
                        setState((prevState) => {
                            let quoteProductOptionValues = [...prevState.quoteProductOptionValues].map((quoteProductOptionValue) => {
                                if (quoteProductOptionValue.product_option_value_id_patch_color) {
                                    quoteProductOptionValue.product_option_value_id_patch_color = productOptionValue.product_option_value_id;
                                }
                                return quoteProductOptionValue;
                            });
                            return {
                                ...prevState,
                                quoteProductOptionValues,
                            };
                        });
                    }
                }
            }

            newSelections[optionId].selected.push(productOptionValue.product_option_value_id);

        }

        const selectedIds = extractOptionSelections(newSelections);
        updateValidatedSelections(selectedIds);

    }

    function getCategory(categoryId) {
        return state.productOptionSections.reduce((categories, section) => {
            return categories.concat(section.productOptionCategories);
        }, []).find(category => category.product_option_category_id === categoryId);
    }


    //get category options
    function getCategoryOptions(categoryId) {
        if(validatedOptions?.size === 0 || !allProductOptions?.has(categoryId)){
            return [];
        }
        let activeOptions = [];
        for(const [productOptionId, productOption] of allProductOptions.get(categoryId).entries()){
            if(validatedOptions?.has(productOptionId) && validatedOptions.get(productOptionId).valid.length > 0){
                activeOptions.push(productOption);
            }
        }

        return activeOptions;
    }


    function getCategoryDirectOptions(categoryId) {
        return state.productOptions.filter((productOption) => {
            return productOption.product_option_category_id === categoryId && productOption.values.length >= 1;
        });

    }

    function getActiveOptionCategoriesBySectionIndex(optionSectionIndex) {
        return state.productOptionSections[optionSectionIndex]?.productOptionCategories?.filter((optionCategory) => {

            return (getCategoryOptions(optionCategory.product_option_category_id).length >= 1);
        });
    }


    function getActiveOptionCategoriesBySectionId(optionSectionId) {
        return state.productOptionSections.find(section => section.product_option_section_id === optionSectionId).productOptionCategories.filter((optionCategory) => {

            return (getCategoryOptions(optionCategory.product_option_category_id).length >= 1);
        });
    }

    function getActiveOptionSections() {
        return state.productOptionSections.filter((section, index) => {
            return getActiveOptionCategoriesBySectionIndex(index).length >= 1;
        });
    }

    function optionIsMultiselect(productOption) {
        return productOption.product_option_type_id === '2';
    }

    function getProductOptionQuoteProductOptionValues(productOptionId) {
        return state.quoteProductOptionValues.filter(
            (quoteProductOptionValue) => quoteProductOptionValue.product_option_id === productOptionId
        );
    }

    function optionValueConfiguratorActive() {
        return (
            state.configuringCategoryId !== null &&
            state.configuringProductOptionIndex !== null &&
            state.configuringProductOption !== null
        );
    }

    function getPositionById(positionId) {
        const positionIndex = state.configurableOptionPositions.findIndex((position) =>
            position.product_option_value_position_id === positionId
        );

        if (positionIndex >= 0) {
            return state.configurableOptionPositions[positionIndex];
        }

        return null;
    }

    function getProductOptionValueById(productOptionValueId) {
       if(!allOptionValues.has(productOptionValueId)){
           return null;
       }
       return allOptionValues.get(productOptionValueId);
    }


    function getProductOptionSelectedValues(productOption) {
        let selectedValues = [];

        // If quote is not editable, ignore current options and select the quotes options
        if (!isEditable) {
            state.quoteProductOptionValues.every(productOptionValue => {
                if (productOptionValue.product_option_id === productOption.product_option_id) {
                    selectedValues.push(productOptionValue);
                }
                return true;
            })
        } else {
            productOption.values.every(productOptionValue => {
                if(optionValueIsSelected(productOptionValue.product_option_value_id)){
                    selectedValues.push(productOptionValue);
                }
                return true;
            });
        }

        return selectedValues;
    }

    const validateProductOptionDependentProductOptionsSet = (productOption, selectedIdsOverride = null) => {

        if(!productOption?.product_option_id || !validatedOptions?.has(productOption.product_option_id)){
            return false;
        }
        return validatedOptions.get(productOption.product_option_id)?.valid.length > 0;

    };

    function saveQuoteOptionsAndGoToSummary(comments) {
        isComplete.current = true;
        saveQuoteOptions(comments);
    }

    function checkCustomText(){
        if(state.quoteProductOptionValues.length === 0){
            return;
        }
        let missingFromCategory = false;

        state.quoteProductOptionValues.every((optValue) => {
            if((optValue.position_option_id == 3
                || optValue.product_option_value_position_id == 3
                || (optValue.quote_product_option_value_position_token?.includes('custom') && (optValue.quote_product_option_value_custom_position_top || optValue.quote_product_option_value_custom_position_left))
                )
                && !optValue.quote_product_option_value_message
                && optValue.product_option_id != 131 //not available for lettering patches
            ){
                missingFromCategory = optValue.product_option_description;
                return false;
            }
            return true;
        });
        return missingFromCategory;

    }

    async function saveQuoteOptions(comments, clearTemporaryQuote = true, ignoreServerUpdates = false) {
        if(isEditable) {
            if (!state.quoteId) {
                return;
            }

            if (clearTemporaryQuote) {
                if (state.quote.quote_is_temporary == 1) {
                    let [quoteOverwrite, quoteProductOptionValuesOverwrite] = await removeQuoteFromAccessory(state.quote.quote_id);
                    state.quoteId = quoteOverwrite.quote_id;
                    state.quote = quoteOverwrite;
                    state.quoteProductOptionValues = quoteProductOptionValuesOverwrite;
                    dispatch(updateConfiguratorOptionsQuoteId(quoteOverwrite.quote_id));
                }
            }

            if (!customPlacementsValid) {
                setErrorAlert({
                    errorTitle: 'Warning',
                    errorMessage: 'Custom placements outside of the product area are not allowed.'
                })
                return;
            }

            const missingCustomTextFrom = checkCustomText();
            if (missingCustomTextFrom) {
                setErrorAlert({
                    errorTitle: 'Error',
                    errorMessage: missingCustomTextFrom + " Custom Placement instructions are required."
                })
                return;
            }
            if (!state.quote.quote_product_quantity || state.quote.quote_product_quantity == 0) {
                setErrorAlert({errorTitle: 'Error', errorMessage: 'At least one product must be added to the quote.'})
                return;
            }
            if (!quoteHasRequiredOptions(state.quote) && state.quote.order_id) {
                setErrorAlert({
                    errorTitle: 'Error',
                    errorMessage: 'Quotes assigned to orders must have all required options.'
                })
                return;
            }

            setState((prevState) => ({...prevState, savingQuote: true}));

            if (user && !isPristine && state.quote.shared_user_count && comments === undefined) {
                setQuoteSaveModalActive(true);
                return true;
            }

            if (!user) {
                setCreateAccountPromptModalActive(true);
            }

            clearTimeout(fetchUpdatesTimeOut.current);

            return Http().post(`${API_ROOT}/build/${state.quoteId}?token=${buildToken}`, {
                quote: {
                    ...state.quote,
                    quoteProductOptionValues: state.quoteProductOptionValues
                },
                comments,
                ignoreServerUpdates: ignoreServerUpdates,
            }).then(({data}) => {
                const {quote: result, validationMessage: validationMessage} = data;

                if (result.allSiblingQuotes) {
                    result.allSiblingQuotes.push({
                        quote_id: result.quote_id,
                        quote_options: result.quote_options,
                        is_valid: result.is_valid,
                        sibling_quote_id: result.sibling_quote_id,
                        quote_product_quantity: result.quote_product_quantity,
                        quote_is_temporary: result.quote_is_temporary,
                        quote_is_confirmed: result.quote_is_confirmed
                    });
                    result.allSiblingQuotes.sort((siblingA, siblingB) => {
                        return siblingA.quote_id - siblingB.quote_id;
                    })
                }
                if (clearTemporaryQuote && result.allSiblingQuotes) {
                    result.allSiblingQuotes = result.allSiblingQuotes.filter(
                        (siblingQuote) => {
                            return siblingQuote.quote_is_temporary != 1;
                        })
                }
                setState((prevState) => ({
                    ...prevState,
                    quote: result,
                    quoteProductOptionValues: result.productOptionValues,
                    savingQuote: false,
                }));

                setIsPristine(true);
                if(validationMessage && !buildIsNewQuote && !isComplete.current) {
                    setTimeout(function() { setErrorAlert({errorTitle: 'Error', errorMessage: validationMessage})}, 1);
                }

                if (user && isComplete.current) {
                    //Switch to sibling quote for summary screen
                    if (state.quote.sibling_quote_id) {
                        dispatch(updateConfiguratorOptionsQuoteId(state.quote.sibling_quote_id));
                    }
                    dispatch(setScreen('summary'));
                } else if (user && savingToSwitchQuote.current) {
                    dispatch(updateConfiguratorOptionsQuoteId(state.quote.siblingQuote.quote_id));
                    setSaveToSwitchQuoteModalActive(false);
                }
                // Returning to result to add new accessories to check siblings validity
                return result;

            }).finally(() => {
                isComplete.current = false;
                savingToSwitchQuote.current = false;
            }).catch((e) => {
                const { response } = e;

                if(response?.data?.newUpdates) {
                    setErrorAlert({
                        errorTitle: 'Warning',
                        errorMessage: 'You are saving an outdated version of this quote, would you like to save anyway?',
                        errorType: 'confirm',
                        errorIgnoreAction: () => {
                            setState((prevState) => ({...prevState, savingQuote: false}));
                        },
                        errorAction: () => {
                            saveQuoteOptions(comments, clearTemporaryQuote, true);
                        }
                    });
                } else {
                    console.log(e);
                }
            });
        } else {
            if (user && isComplete.current) {
                //Switch to sibling quote for summary screen
                if (state.quote.sibling_quote_id) {
                    dispatch(updateConfiguratorOptionsQuoteId(state.quote.sibling_quote_id));
                }
                dispatch(setScreen('summary'));
                isComplete.current = false;
                savingToSwitchQuote.current = false;
            } else if (user && savingToSwitchQuote.current) {
                dispatch(updateConfiguratorOptionsQuoteId(state.quote.siblingQuote.quote_id));
                setSaveToSwitchQuoteModalActive(false);
                isComplete.current = false;
                savingToSwitchQuote.current = false;
            }
        }
    }


    function clearCategoryOptions(categoryId) {
        let newSelections = Object.fromEntries(validatedOptions);
        for (let productOption of getCategoryOptions(categoryId)) {
            for (let selectedProductOptionValue of getProductOptionSelectedValues(productOption)) {
                const productOptionValueId = selectedProductOptionValue.product_option_value_id;
                const productOptionValue = getProductOptionValueById(productOptionValueId);
                const dependencies = newSelections[productOptionValue.product_option_id].dependencies[productOptionValueId];
                let idsToRemove = [];
                if (typeof dependencies === 'undefined') {
                    idsToRemove = [productOptionValueId];
                } else if(typeof dependencies === 'object') {
                    idsToRemove = [productOptionValueId, ...Object.values(dependencies)];
                } else {
                    idsToRemove = [productOptionValueId, ...dependencies];
                }
                for (const optionId of Object.keys(newSelections)) {
                    for(let i = 0; i<idsToRemove.length; i++){
                        const existingIndex = newSelections[optionId]?.selected?.indexOf(idsToRemove[i].toString());
                        if (existingIndex >= 0) {
                            newSelections[optionId].selected.splice(existingIndex, 1);
                        }
                    }
                }
            }
        }

        let selectedIds = extractOptionSelections(newSelections);

        updateValidatedSelections(selectedIds);

        setRefreshToken(Math.random());
    }

    function makePair(selectedCategoryId, pairCategoryId) {
        setIsPristine(false);
        let selectedOptions = [];
        for (let productOption of getCategoryDirectOptions(selectedCategoryId)) {
            for (let selectedProductOptionValue of getProductOptionSelectedValues(productOption)) {
                //option codes () have different values for left and right, need to remove those to match by label
                const label = selectedProductOptionValue.product_option_value_label.replace(/ *\([^)]*\) */, "").trim();
                selectedOptions.push(label);
            }
        }

        for(let childCategory of getCategory(selectedCategoryId).product_option_category_children) {
            for (let productOption of getCategoryDirectOptions(childCategory.product_option_category_id)) {
                for (let selectedProductOptionValue of getProductOptionSelectedValues(productOption)) {
                    //option codes () have different values for left and right, need to remove those to match by label
                    const label = selectedProductOptionValue.product_option_value_label.replace(/ *\([^)]*\) */, "").trim();
                    selectedOptions.push(label);
                }
            }
        }

        let optionsToDeselect = [];
        let optionIndex = 0;
        let newQuoteOptionValues = [];
        let newSubCategoryIds = [];
        let newSelections = Object.fromEntries(validatedOptions);
        for (let productOption of getCategoryDirectOptions(pairCategoryId)) {
            for(let productOptionValue of productOption.values) {
                if(optionValueIsSelected(productOptionValue.product_option_value_id)){
                    optionsToDeselect.push(productOptionValue);
                }
            }
            for (let productOptionValue of productOption.values.filter(productOptionValue => {
                const label = productOptionValue.product_option_value_label.replace(/ *\([^)]*\) */, "").trim();
                return selectedOptions.includes(label);
            } )) {
                if (!productOptionValue.product_option_value_id) {
                    let selectedProductOptionValues = getProductOptionSelectedValues(getCategoryDirectOptions(pairCategoryId)[optionIndex]);
                    for (let selectedProductOptionValue of selectedProductOptionValues) {
                        optionsToDeselect.push(selectedProductOptionValue);
                    }
                    continue;
                }
                newQuoteOptionValues.push(productOptionValue);
                newSelections[productOptionValue.product_option_id].selected.push(productOptionValue.product_option_value_id);
            }
            optionIndex += 1;
        }

        for(let childCategory of getCategory(pairCategoryId).product_option_category_children) {
            optionIndex = 0;
            for (let productOption of getCategoryDirectOptions(childCategory.product_option_category_id)) {
                for(let productOptionValue of productOption.values) {
                    if(optionValueIsSelected(productOptionValue.product_option_value_id)){
                        optionsToDeselect.push(productOptionValue);
                    }
                }
                for (let productOptionValue of productOption.values.filter(productOptionValue => {
                    const label = productOptionValue.product_option_value_label.replace(/ *\([^)]*\) */, "").trim();
                    return selectedOptions.includes(label);
                } )) {
                    if (!productOptionValue.product_option_value_id) {
                        let selectedProductOptionValues = getProductOptionSelectedValues(getCategoryDirectOptions(childCategory.product_option_category_id)[optionIndex]);
                        for (let selectedProductOptionValue of selectedProductOptionValues) {
                            optionsToDeselect.push(selectedProductOptionValue);
                        }
                        continue;
                    }
                    if(productOptionValue.product_option_category_parent_id === pairCategoryId) {
                        newSubCategoryIds.push(productOptionValue.product_option_category_id);
                    }
                    newQuoteOptionValues.push(productOptionValue);
                    newSelections[productOptionValue.product_option_id].selected.push(productOptionValue.product_option_value_id);
                }
                optionIndex += 1;
            }
        }

        if(newQuoteOptionValues.length === 0 || !newQuoteOptionValues.map(option=>option.product_option_value_label.replace(/ *\([^)]*\) */, "").trim()).includes(selectedOptions[0])) {
            setErrorAlert({errorTitle: 'Error', errorMessage: 'Selected option not available for pairing.'})
            return;
        }

        for (let optionToDeselect of optionsToDeselect) {
            const optionToDeselectIndex = newSelections[optionToDeselect.product_option_id].selected.indexOf(optionToDeselect.product_option_value_id);
            if(optionToDeselectIndex >= 0) {
                newSelections[optionToDeselect.product_option_id].selected.splice(optionToDeselectIndex, 1);
            }
        }

        const selectedIds = extractOptionSelections(newSelections);
        updateValidatedSelections(selectedIds);

        if(newQuoteOptionValues.length > 1 && newSubCategoryIds.length > 0){
            setActiveSubCategoryIds((previousIds) => {
                return [...previousIds, ...newSubCategoryIds];

            });
        }
    }


    function setQuoteQuantity(quantity) {
        setState(prevState => {
            let quote = {...prevState.quote};

            quote.quote_product_quantity = quantity;

            return {
                ...prevState,
                quote
            }
        });
    }


    function subCategoryIsActive(categoryId){
        return activeSubCategoryIds.indexOf(categoryId) >= 0 ;
    }


    const addNewAccessory = useCallback(async () => {
        if(isEditable) {
            //TODO: stop page from reloading when this is called
            if (!user) {
                setCreateAccountPromptModalActive(true);
            }
            const response = await saveQuoteOptions('', false);
            let quoteIdToSwitchTo;
            if (response?.allSiblingQuotes) {
                for (let siblingQuote of response.allSiblingQuotes) {
                    if (siblingQuote.is_valid != 1 && state.quote.quote_id == siblingQuote.quote_id) {
                        setErrorAlert({
                            errorTitle: 'Warning',
                            errorMessage: 'Please finish your current item before adding another accessory.'
                        })
                        return;
                    } else if (siblingQuote.is_valid != 1) {
                        quoteIdToSwitchTo = siblingQuote.quote_id;
                    }
                }
            } else if (response?.is_valid != 1) {
                setErrorAlert({
                    errorTitle: 'Warning',
                    errorMessage: 'Please finish your current item before adding another accessory.'
                })
                return;
            }

            if (quoteIdToSwitchTo) {
                setState((state) => {
                    return {
                        ...state,
                        quoteId: quoteIdToSwitchTo,
                        quote: response,
                        quoteProductOptionValues: response.productOptionValues
                    };
                });
                dispatch(updateConfiguratorOptionsQuoteId(quoteIdToSwitchTo));
                setReloadOptions(true);

            } else {
                const {data} = await Http().post(
                    `${API_ROOT}/build/add-sibling-to-quote/${state.quoteId}`
                );


                setState((state) => {
                    return {
                        ...state,
                        quote: data.quote,
                        quoteProductOptionValues: data.quote.productOptionValues
                    };
                });
                dispatch(updateConfiguratorOptionsQuoteId(data.newQuoteId));
                setReloadOptions(true);
            }
        }
    }, [state.quoteId, buildToken, user, state.quote, state.quoteProductOptionValues, isEditable]);


    const removeQuoteFromAccessory = useCallback(async (quoteToDelete) => {
        if (isEditable) {
            const {data} = await Http().post(
                `${API_ROOT}/build/remove-accessory-quote/${quoteToDelete}`
            );

            if (data.quote.allSiblingQuotes) {
                let previousIdIndex =
                    data.quote.allSiblingQuotes.findIndex(
                        (siblingQuote) => {
                            return (
                                Number(siblingQuote.quote_id) - 1 === Number(data.quote.quote_id)
                            );
                        },
                    );
                if (previousIdIndex === -1) {
                    data.quote.allSiblingQuotes.push({
                        quote_id: data.quote.quote_id,
                        quote_options: data.quote.quote_options,
                        is_valid: data.quote.is_valid,
                        sibling_quote_id: data.quote.sibling_quote_id,
                        quote_product_quantity: data.quote.quote_product_quantity
                    });
                } else {
                    data.quote.allSiblingQuotes.splice(previousIdIndex, 0, {
                        quote_id: data.quote.quote_id,
                        quote_options: data.quote.quote_options,
                        is_valid: data.quote.is_valid,
                        sibling_quote_id: data.quote.sibling_quote_id,
                        quote_product_quantity: data.quote.quote_product_quantity
                    });
                }
            }


            setState((state) => {
                return {
                    ...state,
                    quoteId: data.quote.quote_id,
                    quote: data.quote,
                    quoteProductOptionValues: data.quote.productOptionValues
                };
            });

            return [data.quote, data.quote.productOptionValues]
        }
    }, [state.quoteId, buildToken, user, isEditable]);

    const optionIsRequired = useCallback(option => {
        const requiredOption = state.requiredOptions.find(requiredOption =>
            requiredOption.product_option_id === option.product_option_id
        );

        if (!requiredOption) {
            return false;
        }

        const isSelected = !!state.quoteProductOptionValues.find(option =>
            option.product_option_id === requiredOption.product_option_id
        );

        return !isSelected;
    }, [
        state.requiredOptions,
        state.quoteProductOptionValues,
    ]);

    const quoteHasRequiredOptions = useCallback((quote) => {
        let value = true;

        if (quote.quote_id === state.quoteId) {
            const missingOptions = [];

            value = state.requiredOptions
                .filter(requiredOption => requiredOption.product_id === quote.product_id)
                .every((requiredOption) => {
                    const isSelected = !!state.quoteProductOptionValues.find(option =>
                        option.product_option_id === requiredOption.product_option_id
                    );

                    if (!isSelected) {
                        missingOptions.push(requiredOption);
                    }

                    return isSelected;
                });

            // console.log('missing option', missingOptions);
        }

        return value;

    }, [
        state.requiredOptions,
        state.quoteId,
        state.quoteProductOptionValues,
    ]);

    const  handleSavePosition=(optionValueIndex,top, left) => {

        setState(prevState => {
            const quoteProductOptionValues = [...prevState.quoteProductOptionValues];
            quoteProductOptionValues.splice(optionValueIndex, 1, {
                ...quoteProductOptionValues[optionValueIndex],
                quote_product_option_value_custom_position_top: top,
                quote_product_option_value_custom_position_left: left,
            });

            return {
                ...prevState,
                quoteProductOptionValues,
                positioningCategoryId: null,
                positioningProductOptionIndex: null,
                positioningProductOption: null,
                currentlyPositioningOptionValueIndex: null,
            };

        });
    }


    useEffect(() => {
        setState(prevState => {
            return {
                ...prevState,
                quoteId: quoteId,
                optionsRetrieved: false,
                validOptionsRetrieved: false,
                productRetrieved: prevState.product.product_id ? !['1','2','3'].includes(prevState.product.product_id) : false
            }
        });
    }, [quoteId])

    //get quote
    const fetchQuote = useCallback( () => {
        Http()
            .get(`${API_ROOT}/build/${state.quoteId}?token=${buildToken}&validateRules`)
            .then(async (quoteResponse) => {
                const quote = quoteResponse.data.quote;
                if(!quote) {
                    throw new Error('Quote not found');
                }

                await fetchOptions(quote.product_id, quote.product_line_id)

                let editable = true;
                if(isCSRUser) {
                    editable = isCSRUser && (quote?.user_id == user?.user_id || quote.shared_to_user === '1') && !isSubmitted(quote) && !isQuoteApproved(quote) && quote.quote_exactly_as_original !== '1';
                } else if(isRSMUser) {
                    editable = isRSMUser && (quote?.user_id == user?.user_id || quote.shared_to_user === '1') && !isSubmitted(quote) && !isQuoteApproved(quote) && quote.quote_exactly_as_original !== '1';
                } else {
                    editable = !isSubmitted(quote) && !isQuoteApproved(quote) && quote.quote_exactly_as_original !== '1';
                }
                setIsEditable(editable);

                if(quote.allSiblingQuotes) {
                    let previousIdIndex =
                        quote.allSiblingQuotes.findIndex(
                            (siblingQuote) => {
                                return (
                                    Number(siblingQuote.quote_id) - 1 === Number(quote.quote_id)
                                );
                            },
                        );
                    if(previousIdIndex === -1) {
                        quote.allSiblingQuotes.push({quote_id: quote.quote_id, quote_options: quote.quote_options, is_valid: quote.is_valid, sibling_quote_id: quote.sibling_quote_id, quote_product_quantity: quote.quote_product_quantity, quote_is_temporary: quote.quote_is_temporary, quote_is_confirmed: quote.quote_is_confirmed});
                    } else {
                        quote.allSiblingQuotes.splice(previousIdIndex, 0, {quote_id: quote.quote_id, quote_options: quote.quote_options, is_valid: quote.is_valid, sibling_quote_id: quote.sibling_quote_id, quote_product_quantity: quote.quote_product_quantity, quote_is_temporary: quote.quote_is_temporary, quote_is_confirmed: quote.quote_is_confirmed});
                    }
                }
                const productId = quote.product_id;
                const productLineId = quote.product_line_id;
                updateValidatedSelections(quote.productOptionValues.map(productOptionValue => { return productOptionValue.product_option_value_id}), productId, productLineId, quote, quoteResponse.data.requiredOptions, true, editable);

                if(quoteResponse.data.validationMessage && !buildIsNewQuote) {
                    setTimeout(function() { setErrorAlert({errorTitle: 'Error', errorMessage: quoteResponse.data.validationMessage})}, 1);
                }
                dispatch(setIsNewQuote(false));
            })
            .catch((error) => {
                console.log('error: ', error);
            });
    }, [state.quoteId, buildToken]);

    async function fetchOptions(productId, productLineId) {
        setState(prevState => {
            return {
                ...prevState,
                optionsRetrieved: false,
                validOptionsRetrieved: false,
                quoteProductOptionValues: []
            };
        });
        const cancelToken = Axios.CancelToken.source();
        await Http().get(`${API_ROOT}/product-options`,
            {
                params: {
                    productId: productId,
                    productLineId: productLineId,
                }
            },
            {
                cancelToken: cancelToken.token
            }
        ).then((response) => {
            if(response?.data?.productOptions){
                allProductOptions = new Map();
                allOptionCategoryIds = new Map();
                allOptionValues = new Map();
                response.data.productOptions.forEach((option) => {

                    if(!allProductOptions.has(option.product_option_category_id)){
                        allProductOptions.set(option.product_option_category_id, new Map());
                    }
                    if(!allProductOptions.get(option.product_option_category_id).has(option.product_option_id)){
                        allProductOptions.get(option.product_option_category_id).set(option.product_option_id, option);
                    }
                    if(!allOptionCategoryIds.has(option.product_option_id)){
                        allOptionCategoryIds.set(option.product_option_id, option.product_option_category_id);
                    }

                    option.values.forEach((optionValue) => {
                        allOptionValues.set(optionValue.product_option_value_id, optionValue);
                    });

                });

            }

            setState(prevState => {
                return {
                    ...prevState,
                    optionsRetrieved: true,
                    productOptions: response.data.productOptions,
                }
            });


        }).catch((error) => {
            console.log(error);
        })

        return () => {
            cancelToken.cancel();
        }
    }

    function extractOptionSelections(options){
        let productOptionValueIds = [];
        if(options instanceof Map){
            for(const [key, option] of options){
                if(option.selected && Array.isArray(option.selected)){
                    productOptionValueIds = [...productOptionValueIds, ...option.selected];
                }
            }
        }else{
            Object.entries(options).forEach(([key, option]) => {
                if(option.selected && Array.isArray(option.selected)){
                    productOptionValueIds = [...productOptionValueIds, ...option.selected];
                }
            });
        }
        return productOptionValueIds
    }

    const debounceTimeout = useRef(null);
    function updateValidatedSelections(productOptionValueIds, newProductId = null, newProductLineId = null, newQuote = null, newRequiredOptions = null, withLoadingCatcher = true, editable = null){
        clearTimeout(debounceTimeout.current);
        const loadingCatcher = document.createElement('div');
        if(withLoadingCatcher) {
            loadingCatcher.style.position = 'fixed';
            loadingCatcher.style.top = '0';
            loadingCatcher.style.left = '0';
            loadingCatcher.style.width = '100%';
            loadingCatcher.style.height = '100%';
            loadingCatcher.style.cursor = 'progress';
            loadingCatcher.style.zIndex = '9999';
            document.body.appendChild(loadingCatcher);
        }



        newProductId = newProductId || state.productId;
        newProductLineId = newProductLineId || state.productLineId;

        const validateUrl = new URL(`${API_ROOT}/product-option-values/validate`);
        if(newProductId){
            validateUrl.searchParams.append('productId', newProductId);
        }
        if(newProductLineId){
            validateUrl.searchParams.append('productLineId', newProductLineId);
        }
        validateUrl.searchParams.append('id_values', productOptionValueIds.join());

        if ( (editable === null && isEditable === false)
            || editable === false) {
            validateUrl.searchParams.append('isEditable', '0');
        }

        Http().get(validateUrl.href).then((response) => {
            if(response.data.options) {
                const newValidatedOptions = new Map(Object.entries(response.data.options));

                setValidatedOptions(newValidatedOptions);

                let selectedOptionValueIds = [];
                let validOptionValueIds = [];
                newValidatedOptions.forEach((option, key) => {
                    if(option.selected && Array.isArray(option.selected)){
                        selectedOptionValueIds = [...selectedOptionValueIds, ...option.selected];
                    }
                    if(option.valid && Array.isArray(option.valid)){
                        validOptionValueIds = [...validOptionValueIds, ...option.valid];
                    }
                });

                setState(prevState => {
                    let currentOptionValues = newQuote ? [...newQuote.productOptionValues] : [...prevState.quoteProductOptionValues];
                    let currentOptionValueIds = currentOptionValues.map((optionValue) => {
                        return optionValue.product_option_value_id;
                    });
                    let selectedOptions = currentOptionValues.filter((optionValue, index) => {
                        // Don't remove invalid options for quotes that are not editable (i.e. completed quotes)
                        if ( (editable === null && isEditable === false)
                            || editable === false) {
                            return true;
                        }
                        if (!['2', '3', '5'].includes(optionValue.product_option_type_id)
                            && currentOptionValueIds.indexOf(optionValue.product_option_value_id) !== index
                            && optionValue.product_option_category_id !== '28') {
                            return false;
                        }
                        return selectedOptionValueIds.includes(optionValue.product_option_value_id) && validOptionValueIds.includes(optionValue.product_option_value_id);
                    });
                    const curSelectedIds = selectedOptions.map((optionValue) => {
                        return optionValue.product_option_value_id;
                    });
                    const validProductOptionValues = [];
                    validOptionValueIds.forEach((optionValueId) => {
                        const value = allOptionValues.get(optionValueId);
                        if(value){
                            validProductOptionValues.push(value);
                            if(selectedOptionValueIds.includes(optionValueId) && !curSelectedIds.includes(optionValueId)){
                                // Do not automatically select options for quotes that are not editable
                                if ( (editable === null && isEditable === false)
                                    || editable === false) {
                                    return;
                                }
                                selectedOptions.push(value);
                            }
                        }
                    });

                    //Set default position
                    for(let [index, selectedOption] of selectedOptions.entries()) {
                        if(
                            selectedOption.position_option_id
                            && selectedOption.position_option_id != 1
                            && !selectedOptions[index].product_option_value_position_id
                        ) {
                            const availablePositions = getAvailablePositions(selectedOption.position_option_id);
                            if(availablePositions[0].product_option_value_position_id === '3' && selectedOption.imageStyles?.length > 0) {
                                selectedOptions[index].quote_product_option_value_custom_position_left = selectedOption.imageStyles[0].product_option_image_style_left;
                                selectedOptions[index].quote_product_option_value_custom_position_top = selectedOption.imageStyles[0].product_option_image_style_top;
                            }
                            selectedOptions[index].product_option_value_position_id = availablePositions[0].product_option_value_position_id;
                        }
                    }

                     return {
                         ...prevState,
                         productLineId: newProductLineId,
                         productId: newProductId,
                         quoteProductOptionValues: selectedOptions,
                         // quoteProductOptionValues: newQuote.productOptionValues,
                         quote: newQuote ?
                             {
                                 ...newQuote,
                                 productOptionValues: selectedOptions,
                             }
                             :
                             {
                             ...prevState.quote,
                             productOptionValues: selectedOptions,
                         },
                         validProductOptionValues,
                         validOptionsRetrieved: true,
                         firstInitialized: true,
                         requiredOptions: newRequiredOptions ? newRequiredOptions : prevState.requiredOptions
                     }

                });
                setRefreshToken(Math.random());

            }
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            if(withLoadingCatcher) {
                loadingCatcher.remove();
            }
        });

    }

    const queryForUpdates = useCallback(() =>  {
        if(state.quoteId) {
            Http().get(`${API_ROOT}/build/update-check/${state.quoteId}`, {
                params: {
                    modifiedTimestamp: state.quote.quote_modified_timestamp,
                    auditLogTimestamp: state.quote.latest_audit_log_timestamp,
                }
            }).then((response) => {
                if (response.data.newUpdates && !state.savingQuote) {
                    // If the quote has been changed
                    if (!isPristine) {
                        setErrorAlert({
                            errorTitle: 'Warning',
                            errorMessage: 'You are working on an outdated version of this quote, would you like to refresh with the new options and overwrite your current changes?',
                            errorType: 'confirm',
                            errorIgnoreAction: () => {
                                clearTimeout(fetchUpdatesTimeOut.current);
                                setCheckForUpdates(false);
                            },
                            errorAction: () => {
                                clearTimeout(fetchUpdatesTimeOut.current);
                                setBuildIsNewQuote(false);
                                fetchQuote();
                            }
                        });
                        // If the quote has not been changed, but the saved quote has different options
                    } else if (state.quoteProductOptionValues.length !== response.data.quote.productOptionValues.length) {
                        setErrorAlert({
                            errorTitle: 'Warning',
                            errorMessage: 'You are working on an outdated version of this quote, would you like to refresh with the new options and overwrite your current changes?',
                            errorType: 'confirm',
                            errorIgnoreAction: () => {
                                clearTimeout(fetchUpdatesTimeOut.current);
                                setCheckForUpdates(false);
                            },
                            errorAction: () => {
                                clearTimeout(fetchUpdatesTimeOut.current);
                                setBuildIsNewQuote(false);
                                fetchQuote();
                            }
                        });
                        // If the quote has not been changed, but the quote values do not match
                    } else if (state.quote.contract_id !== response.data.quote.contract_id
                        || state.quote.quote_discount !== response.data.quote.quote_discount
                        || state.quote.quote_status !== response.data.quote.quote_status
                        || state.quote.fire_department_id !== response.data.quote.fire_department_id
                        || state.quote.quote_stock_quote_input !== response.data.quote.quote_stock_quote_input
                        || state.quote.quote_stock_flag !== response.data.quote.quote_stock_flag
                        || state.quote.quote_promised_date !== response.data.quote.quote_promised_date
                        || state.quote.quote_product_quantity !== response.data.quote.quote_product_quantity
                        || state.quote.quote_net_price_adjustment !== response.data.quote.quote_net_price_adjustment
                    ) {
                        setErrorAlert({
                            errorTitle: 'Warning',
                            errorMessage: 'You are working on an outdated version of this quote, would you like to refresh with the new options and overwrite your current changes?',
                            errorType: 'confirm',
                            errorIgnoreAction: () => {
                                clearTimeout(fetchUpdatesTimeOut.current);
                                setCheckForUpdates(false);
                            },
                            errorAction: () => {
                                clearTimeout(fetchUpdatesTimeOut.current);
                                setBuildIsNewQuote(false);
                                fetchQuote();
                            }
                        });
                    }
                }
                if(checkForUpdates) {
                    clearTimeout(fetchUpdatesTimeOut.current);
                    fetchUpdatesTimeOut.current = setTimeout(queryForUpdates, 30000);
                }
            }).catch((e) => {
                clearTimeout(fetchUpdatesTimeOut.current);
                fetchUpdatesTimeOut.current = setTimeout(queryForUpdates, 30000);
            });
        }
    }, [state.quoteId, state.quote.quote_modified_timestamp, state.quote.latest_audit_log_timestamp, state.quote.contract_id, state.quote.quote_discount, state.quote.quote_status, state.quote.fire_department_id, state.quote.quote_stock_quote_input, state.quote.quote_stock_flag, state.quote.quote_promised_date, state.quote.quote_product_quantity, state.quote.quote_net_price_adjustment, state.savingQuote, state.quoteProductOptionValues.length, checkForUpdates, isPristine, setErrorAlert, fetchQuote]);


    useEffect(() => {
        if(checkForUpdates && state.quoteId && state.validOptionsRetrieved) {
            queryForUpdates();
        }
        return () => {
            clearTimeout(fetchUpdatesTimeOut.current);
        }
    }, [checkForUpdates, state.quoteId, state.quote.quote_modified_timestamp, state.quote.latest_audit_log_timestamp, state.quote, state.validOptionsRetrieved]);

    useEffect(() => {
        // If new quote, save auto selected options (if not accessory)
        if(state.quote.product_configurable === '1' && state.validOptionsRetrieved
            && buildIsNewQuote && state.quoteProductOptionValues) {
            setTimeout(() => {
                saveQuoteOptions();
                dispatch(setIsNewQuote(false));
            }, 1000);
        }
    }, [state.validOptionsRetrieved]);

    useEffect(() => {
        if (state.quoteId) {
            fetchQuote();
        }
    }, [fetchQuote, state.quoteId]);

    //Get product
    useEffect(() => {
        if (staticDataSettled && state.productId) {

            Http().get(`${API_ROOT}/products/get/${state.productId}`, {
                params: {
                    productLineId: state.productLineId
                }
            }).then((productResponse) => {
                setState((prevState) => {

                    const sections = productOptionSections.map((section) => {
                            return {
                                ...section,
                                productOptionCategories: section.productOptionCategories
                                    .map((category) => ({...category, ref: createRef()})),
                            };
                        },
                    );

                    return {
                        ...prevState,
                        activeSectionIndex: 0,
                        activeCategoryIndex: 0,
                        productOptionSections: sections,
                        product: productResponse.data.product,
                        productRetrieved: true,
                    };
                });
            }).catch((error) => {
                console.log('error: ', error);
            });
            setRefreshToken(Math.random());
        }
    }, [state.productLineId, state.productId, staticDataSettled, productOptionSections]);

    useEffect(() => {
        if(!state.productLineId) {
            return;
        }
        Http()
            .post(
                `${API_ROOT}/product-lines/lettering-placements/${state.productLineId}`,
            )
            .then(({ data }) => {
                setLetteringPlacements(data?.lettering_placements || defaultLetteringPlacements);
            });
    }, [state.productLineId, defaultLetteringPlacements]);

    // //Scroll to category section
    useEffect(() => {
        if (state.activeCategoryIndex !== null && state.autoScroll) {
            let category = getActiveOptionCategoriesBySectionIndex(state.activeSectionIndex)[state.activeCategoryIndex];

            setTimeout(() => {
                const isScrollable = function (ele) {
                    const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

                    const overflowYStyle = window.getComputedStyle(ele).overflowY;
                    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

                    return hasScrollableContent && !isOverflowHidden;
                };

                const getScrollableParent = function (ele) {
                    return !ele || ele === document.body
                        ? document.body
                        : isScrollable(ele)
                            ? ele
                            : getScrollableParent(ele.parentNode);
                };

                const y = category.ref.current.offsetTop - headerHeight;
                getScrollableParent(category.ref.current).scrollTo({top: y, behavior: 'smooth'});
            }, 500);

            setState((prevState) => ({...prevState, autoScroll: false}));
        }
    }, [state.activeCategoryIndex, state.autoScroll]);

    useEffect(() => {
        Http().get(`${API_ROOT}/composites/get-composite-by-values`, {
            params: {
                outerShellId: compositeOptionValueIds.outerShellId,
                thermalLinerId: compositeOptionValueIds.thermalLinerId,
                moistureBarrierId: compositeOptionValueIds.moistureBarrierId
            }
        }).then(response => {
            setComposite(response.data.composite);
        })
    }, [compositeOptionValueIds.outerShellId, compositeOptionValueIds.thermalLinerId, compositeOptionValueIds.moistureBarrierId, setComposite])

    const updateQuote = useCallback(async (quote) => {
        if (isEditable) {
            if (!user) {
                setCreateAccountPromptModalActive(true);
            }

            if (!quoteHasRequiredOptions(quote) && quote.order_id) {
                setErrorAlert({
                    errorTitle: 'Error',
                    errorMessage: 'Quotes assigned to orders must have all required options.'
                })
                return;
            }

            clearTimeout(fetchUpdatesTimeOut.current);

            const {data} = await Http().post(
                `${API_ROOT}/build/${state.quoteId}?token=${buildToken}`,
                {
                    quote: {
                        ...quote,
                    }
                },
            );

            const {quote: result} = data;
            if (result) {
                setState((state) => {
                    return {
                        ...state,
                        quote: result,
                        quoteProductOptionValues: result.productOptionValues
                    };
                });
            }
        }
    }, [state.quoteId, buildToken, user, isEditable]);


    function getProductOptionValuePhoto(productOptionValue) {
        //Find photo for specific product, if found return it
        const productPhotos = productOptionValue?.photos.filter(
            (photo) => photo.product_id === state.productId,
        );
        if (productPhotos?.length) {
            return productPhotos[0];
        }

        //else return the first photo in the list, if any exist
        if (productOptionValue?.photos.length) {
            return productOptionValue.photos[0];
        }

        return null;
    }

    const getPositionOptionById = useCallback((positionId) =>{

        if(!positionId || !positionOptions?.length){
            return null;
        }

        const positionIndex = positionOptions.findIndex((position) =>
            position.position_option_id == positionId
        );
        if (positionIndex >= 0) {
            return positionOptions[positionIndex];
        }

        return null;
    },[positionOptions]);

    const getPositionOptionByToken = useCallback((positionToken) =>{

        if(!positionToken || !positionOptions?.length){
            return null;
        }

        const positionIndex = positionOptions.findIndex((position) =>
            position.position_option_token == positionToken
        );
        if (positionIndex >= 0) {
            return positionOptions[positionIndex];
        }

        return null;
    },[positionOptions]);

    const handleSelectHover = (e) =>{

        if(!e?.target?.id.includes('react-select-')){
            setHoverImage(null);
            return;
        }
        let index = Math.max(parseInt(e.target.id.split('-')[4]), 0);
        let hoverOption = filterOverlayOptions(state.configuringProductOption.values.filter(productOptionValue => optionValueIsAvailable(state.configuringProductOption.product_option_id, productOptionValue.product_option_value_id)))[index];
        if (!hoverOption) {
            setHoverImage(null);
            return;
        }
        let photo = getProductOptionValuePhoto(hoverOption);
        if (!photo) {
            setHoverImage(null);
            return;
        }
        const rect = e.target.parentNode?.parentNode?.getBoundingClientRect();
        const innerRect = e.target.getBoundingClientRect();
        const y = e.clientY - rect.top;
        const innerY = e.clientY - innerRect.top;
        photo.topOffset = (y-innerRect.height/2) + (innerY<5?innerRect.height/2:-innerRect.height/2);
        setHoverImage(photo);
    }

    const  filterOverlayOptions = (options) =>{
        //TODO: handle multiple of the same option value being selected with new validate
        return options.filter(option => {
            if((option.product_option_value_max_quantity !== null && state.quoteProductOptionValues.filter((productOptionValue) => productOptionValue.product_option_value_id === option.product_option_value_id).length >= option.product_option_value_max_quantity)
                && state?.quoteProductOptionValues[state.currentlyConfiguringOptionValueIndex]?.product_option_value_id !== option.product_option_value_id) {
                return false;
            }
            const positionId = option.position_option_id;
            if(!positionId){
                return true;
            }
            const availablePositions = getAvailablePositions(positionId);
            if(availablePositions.length === 0){
                return true; //static
            }
            for(let i = 0; i < availablePositions.length; i++){
                if(!isPositionDisabled(availablePositions[i], option)){
                    return true;
                }
            }
            return false;
        });

    }


    const getAvailablePositions = useCallback((positionOptionId = null) => {
        if(!positionOptionId && !state.optionValueConfigurationTemp?.position_option_id){
                return state.configurableOptionPositions;
        }
        if(!positionOptionId){
            positionOptionId = state.optionValueConfigurationTemp?.position_option_id;
        }
        switch(positionOptionId.toString()){
            case '1':
                return [];
            case '2':
                return [state.configurableOptionPositions[0], state.configurableOptionPositions[1]];
            case '3':
                return [state.configurableOptionPositions[2]]
            case '5':
                return [state.configurableOptionPositions[0]]
            case '6':
                return [state.configurableOptionPositions[0],state.configurableOptionPositions[2] ]
            case '7':
                return [state.configurableOptionPositions[1]]
            case '8':
                return [state.configurableOptionPositions[1], state.configurableOptionPositions[2]]
            default:
                return state.configurableOptionPositions;
        }

    }, [state.optionValueConfigurationTemp, state.configurableOptionPositions]);

    function isPositionDisabled(position, configurationOverride = null) {
        const optionToCheck = configurationOverride || state.optionValueConfigurationTemp;

        let otherSelectedPositions = state.quoteProductOptionValues.filter(quoteProductOptionValue => {
            // If chest pockets are selected then flashlight holders should not be available for that side
            if (quoteProductOptionValue.product_option_category_id === '17'
                || quoteProductOptionValue.product_option_category_id === '18') {
                return optionToCheck.product_option_id === '61';
            }

            return quoteProductOptionValue.product_option_id === optionToCheck.product_option_id;
        }).map(optionValue => optionValue.product_option_value_position_id);

        if (position.product_option_value_position_id === '1' || position.product_option_value_position_id == '2') {
            if (otherSelectedPositions.includes(position.product_option_value_position_id)) {
                return true;
            }
        }

        const positionOptionId = optionToCheck.position_option_id || state.configuringProductOption.position_option_id;

        if (position.product_option_value_position_id === '3') {
            //If custom position, check that only left-right, left-only, or right-only isn't the productOption.position_option and that images and styles are available
            return (positionOptionId === '2' || positionOptionId === '5' || positionOptionId === '7') || (optionToCheck.images?.length <= 0 || optionToCheck.imageStyles?.length <= 0);
        } else if (position.product_option_value_position_id === '1') {
            //Check that custom only, right-only, or right-custom isn't selected for left options
            return positionOptionId === '3' || positionOptionId === '7' || positionOptionId === '8';
        } else if (position.product_option_value_position_id === '2') {
            //Check that custom only, left-only, or left-custom isn't selected for right options
            return positionOptionId === '3' || positionOptionId === '5' || positionOptionId === '6';
        }
    }



    function toggleImageOrientation(orientation = null) {
        if(orientation) {
            setImageOrientation(orientation);
        } else {
            if (imageOrientation === 'front') {
                setImageOrientation('back');
            } else {
                setImageOrientation('front');
            }
        }
    }

    return (
        <>
            <Header
                actions={isEditable ? [
                            {
                                onClick: () => saveQuoteOptions(),
                                label: 'Save',
                                actionInProcess: state.savingQuote,
                            },
                            {
                                onClick: async () => {
                                    if (!isPristine) {
                                        setConfirmModalActive(true);
                                        return;
                                    }
                                    if(state.quote.quote_is_temporary == 1){
                                        await removeQuoteFromAccessory(state.quote.quote_id);
                                     }
                                    return dispatch(resetConfigurator());
                                },
                                label: 'Close',
                                className: 'text-button'
                            }
                        ]
                     :
                        [
                            {
                                onClick: () => {
                                    if (!isPristine) {
                                        setConfirmModalActive(true);
                                        return;
                                    }

                                    return dispatch(resetConfigurator());
                                },
                                label: 'Close',
                                className: 'text-button'
                            }
                        ]
                   }
                lowerContent={
                    (state.quote.quote_id ? (
                        <QuoteHeaderInformation
                            productLineTitle={state.quote.product_line_description}
                            productTitle={state.quote.siblingQuote && state.quote.product_configurable === '1' ? 'Full Set' : state.quote.product_title}
                            quoteNumber={quoteNumberDisplay}
                            quoteName={state.quote.quote_name}
                            quoteId={state.quote.quote_id}
                            orderStatus={state.quote.order_status}
                            composite={composite}
                            updateQuote={updateQuote}
                            forMobile={true}
                            isShared={state.quote.is_shared}
                        />
                    ) : null)
                }
            >
                {state.quote.quote_id ? (
                    <QuoteHeaderInformation
                        productLineTitle={state.quote.product_line_description}
                        productTitle={state.quote.siblingQuote && state.quote.product_configurable === '1' ? 'Full Set' : state.quote.product_title}
                        quoteNumber={quoteNumberDisplay}
                        quoteName={state.quote.quote_name}
                        quoteId={state.quote.quote_id}
                        orderStatus={state.quote.order_status}
                        composite={composite}
                        updateQuote={updateQuote}
                        isShared={state.quote.is_shared}
                    />
                ) : null}
            </Header>
            <ConfirmationModal
                active={confirmModalActive}
                title="Unsaved Changes"
                message="Do you want to save your changes to this quote?"
                onClose={async () => {
                    setConfirmModalActive(false);
                    dispatch(resetConfigurator());
                }}
                onConfirm={async () => {
                    setConfirmModalActive(false);
                    const isSharedModal = await saveQuoteOptions();
                    if (isSharedModal !== true) {
                        dispatch(resetConfigurator());
                    }
                }}
                mountOnEnter={false}
                unmountOnExit={false}
            />
            <QuoteSaveNotificationModal
                active={quoteSaveModalActive}
                onClose={() => {
                    setQuoteSaveModalActive(false)
                    setState((prevState) => ({...prevState, savingQuote: false}));
                    // saveQuoteOptions(null);
                }}
                onConfirm={(comments) => {
                    setQuoteSaveModalActive(false)
                    saveQuoteOptions(comments);
                }}
            />
            <SaveToSwitchQuoteModal
                active={saveToSwitchQuoteModalActive}
                onClose={() => {
                    setSaveToSwitchQuoteModalActive(false);
                }}
                onReject={() => {
                    setSaveToSwitchQuoteModalActive(false);
                    setIsPristine(true);
                    dispatch(updateConfiguratorOptionsQuoteId(switchToID));
                }}
                onConfirm={() => {
                    setSaveToSwitchQuoteModalActive(false);
                    savingToSwitchQuote.current = true;
                    saveQuoteOptions();
                }}
                fromProductTitle={state.product.product_title}
                toProductTitle={state.quote?.siblingQuote?.product_title}
            />
            <CreateAccountPromptModal active={createAccountPromptModalActive} onClose={() => {
                setCreateAccountPromptModalActive(false);
            }}
                                      onCta={() => {
                                          setCreateAccountPromptModalActive(false);
                                          setTimeout(() => {
                                              setCreateAccountModalActive(true);
                                          }, 150);
                                      }}/>
            <WhyCreateAccountModal active={whyCreateAccountModalActive} onClose={() => {
                setWhyCreateAccountModalActive(false)
            }}
                                   onCta={() => {
                                       setWhyCreateAccountModalActive(false);
                                       setTimeout(() => {
                                           setCreateAccountModalActive(true);
                                       }, 150);
                                   }}/>
            <CreateAccountModal active={createAccountModalActive} onClose={() => {
                setCreateAccountModalActive(false)
            }}/>
            { state.quote.quote_id &&
                <Messenger
                    quoteId={state.quote.sibling_quote_id ? state.quote.sibling_quote_id : state.quote.quote_id}
                    user={user}
                    functional={false}
                    forLeft={true}
                    openLabel="View Quote Log"
                    title="Quote Log"
                />
            }
            {
                state.optionsRetrieved && state.validOptionsRetrieved && state.productRetrieved ?
                    <>
                        <section
                            className="flex-row-wrap min-app-height-container"
                            style={{marginTop: headerHeight}}>
                            <div className="sixty-width configurator-preview-container">
                                {state.product.product_configurable === '1' ?
                                    <ConfiguratorPreview
                                        quote={state.quote}
                                        selectedProductOptionValues={state.quoteProductOptionValues}
                                        showLetteringPlacements={showLetteringPlacements}
                                        letteringData={letteringData}
                                        letteringDispatch={letteringDispatch}
                                        letteringPlacement={letteringPlacement}
                                        allLetteringPlacements={letteringPlacements}
                                        setLetteringPlacement={setLetteringPlacement}
                                        hideLettering={!isLetteringAvailable}
                                        handleSavePosition={handleSavePosition}
                                        setCustomPlacementsValid={setCustomPlacementsValid}
                                        imageOrientation={imageOrientation}
                                        toggleImageOrientation={toggleImageOrientation}
                                    />
                                    :
                                    <VariableProductImage
                                        images={state.quote.productImages}
                                        selectedProductOptionValues={state.quoteProductOptionValues}
                                        defaultImage={state.quote.product_image_file_url}
                                    />
                                }
                            </div>
                            <div className="forty-width options-column">
                                <div className="configurator-tabs">
                                    {
                                        !state.quote.siblingQuote || state.product.product_configurable !== '1' ?
                                            <div className={classNames("configurator-tab", "active")} onClick={() => {setShowLetteringPlacements(false);dispatch(setScreen('build'))}}>
                                                Configure
                                                {quoteHasRequiredOptions(state.quote)
                                                    ? <img src="/images/cog.png" style={{width: 24, height: 'auto', marginLeft: 10}}/>
                                                    : <img src="/images/invalid-light.png" style={{width: 24, height: 'auto', marginLeft: 10}}/>
                                                }
                                            </div>
                                            :
                                            <>
                                                {
                                                    [state.quote, state.quote.siblingQuote].sort((quote1, quote2) => {
                                                        return quote1.product_id - quote2.product_id;
                                                    }).map((quote) => {
                                                        return (
                                                            <div
                                                                key={quote.quote_id}
                                                                className={classNames("configurator-tab", {active: state.quoteId === quote.quote_id}, {
                                                                    'configurator-tab-red': highlightErrors && !quoteHasRequiredOptions(quote) && state.quoteId !== quote.quote_id,
                                                                })}
                                                                onClick={() => {
                                                                    setShowLetteringPlacements(false);
                                                                    if (state.quoteId !== quote.quote_id) {
                                                                        if (!isPristine) {
                                                                            setSwitchToID(quote.quote_id);
                                                                            setSaveToSwitchQuoteModalActive(true);
                                                                        } else {
                                                                            dispatch(updateConfiguratorOptionsQuoteId(quote.quote_id));
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                {quote.product_title}
                                                                {
                                                                    quote.product_id === '1' ?
                                                                        (<img src="/images/coat-small.png" style={{width: 19, height: 'auto', marginLeft: 10}}/>)
                                                                        : (<img src="/images/pants-small.png" style={{width: 13, height: 'auto', marginLeft: 10}}/>)
                                                                }
                                                                {!quoteHasRequiredOptions(quote) && (
                                                                    <img src="/images/invalid-light.png" style={{width: 24, height: 'auto', marginLeft: 10}}/>
                                                                )}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </>
                                    }
                                    <div className={classNames("configurator-tab")} onClick={() => saveQuoteOptionsAndGoToSummary()}>
                                        Summary <img src="/images/lines.png" style={{width: 24, height: 'auto', marginLeft: 10}}/>
                                    </div>
                                </div>
                                {getActiveOptionSections().map(
                                    (productOptionSection, sectionIndex) => {
                                        // console.log('section:', productOptionSection)

                                        const sectionActive = sectionIsActive(sectionIndex);

                                        return (
                                            <div
                                                key={productOptionSection.product_option_section_id + "" + refreshToken}
                                                className={classNames(
                                                    'option-section-container',
                                                    {'active': sectionActive},
                                                )}>
                                                    {getActiveOptionCategoriesBySectionId(productOptionSection.product_option_section_id).map((productOptionCategory, categoryIndex) => {

                                                            const categoryActive = categoryIsActive(sectionIndex, categoryIndex);
                                                            const categoryValid = categoryOptionSelectionsAreValid(productOptionCategory.product_option_category_id);
                                                            const categoryOptions = getCategoryDirectOptions(productOptionCategory.product_option_category_id);
                                                            const isLettering = productOptionCategory.product_option_category_id === letteringCategoryId;


                                                            const categoryOptionsValid = categoryOptions.map((productOption) => {
                                                                return validateProductOptionDependentProductOptionsSet(productOption);
                                                            });
                                                            const inValidSection = arr => arr.every(v => v === false);

                                                            if(inValidSection(categoryOptionsValid)){
                                                                return null;
                                                            }

                                                            return (
                                                                <div
                                                                    key={productOptionCategory.product_option_category_id}
                                                                    ref={productOptionCategory.ref}
                                                                    className={classNames(
                                                                        'option-container',
                                                                        {
                                                                            'active':
                                                                            categoryActive,
                                                                        },
                                                                        {
                                                                            'option-container-invalid':
                                                                            highlightErrors && !categoryValid,
                                                                        },
                                                                    )}>
                                                                    <Collapsible

                                                                        open={categoryActive}
                                                                        easing="ease-out"
                                                                        transitionTime={250}
                                                                        onOpening={() => setCategoryActive(sectionIndex, categoryIndex,isLettering)}
                                                                        onClosing={() => setCategoryInactive(sectionIndex, categoryIndex,isLettering)}
                                                                        contentInnerClassName="option-container-inner"
                                                                        trigger={
                                                                            <div
                                                                                className="option-heading-wrapper"
                                                                                onClick={() => {
                                                                                    setCategoryActive(sectionIndex, categoryIndex);
                                                                                }}>
                                                                                <div className="option-heading">
                                                                                    <div className="option-avatar-container">
                                                                                        {
                                                                                            state.productId == 1 && (
                                                                                                <img
                                                                                                    className="option-avatar coat"
                                                                                                    src={categoryActive ? highlightErrors && !categoryValid ? '/images/coat-icon-gray.png' : '/images/coat-icon-orange.png' : '/images/coat-icon-gray.png'}
                                                                                                />)
                                                                                        }
                                                                                        {
                                                                                            (state.productId == 2 || state.productId == 3) && (
                                                                                                <img
                                                                                                    className="option-avatar pants"
                                                                                                    src={categoryActive ? highlightErrors && !categoryValid ? '/images/pants-icon-gray.png' : '/images/pants-icon-orange.png' : '/images/pants-icon-gray.png'}
                                                                                                />)
                                                                                        }
                                                                                        {
                                                                                            (state.productId != 1 && state.productId != 2 && state.productId != 3) && (
                                                                                                <img
                                                                                                    className="option-avatar coat"
                                                                                                    src={categoryActive ? (state.product.product_icon_active_file ? `${API_ROOT}${state.product.product_icon_active_file_path}` : '/images/default-icon-orange.png') : (state.product.product_icon_inactive_file ? `${API_ROOT}${state.product.product_icon_inactive_file_path}` :'/images/default-icon-gray.png')}
                                                                                                />
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                    <span className="option-title">
																		            {productOptionCategory.product_option_category_title}
																	            </span>
                                                                                </div>
                                                                                {categoryHasRequiredOption(productOptionCategory.product_option_category_id) ?
                                                                                    <img
                                                                                        className="validation-indicator"
                                                                                        src={categoryValid ? '/images/valid.png' : '/images/invalid.png'}
                                                                                    />
                                                                                    : null
                                                                                }
                                                                            </div>
                                                                        }>
                                                                        {isLettering && (
                                                                            <>
                                                                            <LetteringSummary
                                                                                values={state.quoteProductOptionValues.filter(value => value.product_option_category_id === letteringCategoryId)}
                                                                                productOptions={state.productOptions}
                                                                                productOptionValues={state.quoteProductOptionValues}
                                                                                productId={state.productId}
                                                                                data={letteringData}
                                                                                dispatch={letteringDispatch}
                                                                                placement={letteringPlacement}
                                                                                setPlacement={setLetteringPlacement}
                                                                                placements={letteringPlacements}
                                                                                allPlacements={defaultLetteringPlacements}
                                                                                onSave={(data, action) => {
                                                                                    if(isEditable) {
                                                                                        setIsPristine(false);
                                                                                        if(action === 'add') {
                                                                                            let selectedIds = extractOptionSelections(validatedOptions);
                                                                                            const values = [
                                                                                                ...state.quoteProductOptionValues.filter(value => {
                                                                                                        return !value.lettering_placement_id
                                                                                                    }
                                                                                                ),
                                                                                                ...data
                                                                                            ];
                                                                                            setState(prevState => {
                                                                                                return {
                                                                                                    ...prevState,
                                                                                                    quoteProductOptionValues: values
                                                                                                }
                                                                                            });
                                                                                            let newIds = values.map(value => value.product_option_value_id).filter(
                                                                                                    productOptionValueId => {
                                                                                                        return selectedIds.indexOf(productOptionValueId) === -1;
                                                                                                    }
                                                                                                );
                                                                                            selectedIds = selectedIds.concat(newIds);
                                                                                            updateValidatedSelections(selectedIds);
                                                                                        } else if (action === 'remove') {
                                                                                            const values = [
                                                                                                ...state.quoteProductOptionValues.filter(value => {
                                                                                                        return !data.map(item => item.lettering_placement_id).includes(value.lettering_placement_id)
                                                                                                    }
                                                                                                )
                                                                                            ];
                                                                                            setState(prevState => {
                                                                                                return {
                                                                                                    ...prevState,
                                                                                                    quoteProductOptionValues: values
                                                                                                }
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                productLineId={state.productLineId}
                                                                                optionIsAvailable={optionValueIsAvailable}
                                                                                isEditable={isEditable}
                                                                            />
                                                                            </>
                                                                        )}
                                                                        {
                                                                            !isLettering && categoryOptions.map((productOption, optionIndex) => {
                                                                                    return (
                                                                                        <ConfiguratorBuildInput
                                                                                            key={productOption.product_option_id}
                                                                                            productOption={productOption}
                                                                                            validateProductOptionDependentProductOptionsSet={validateProductOptionDependentProductOptionsSet}
                                                                                            isEditable={isEditable}
                                                                                            productOptionCategory={productOptionCategory}
                                                                                            optionIndex={optionIndex}
                                                                                            state={state}
                                                                                            setState={setState}
                                                                                            optionIsMultiselect={optionIsMultiselect}
                                                                                            getProductOptionSelectedValues={getProductOptionSelectedValues}
                                                                                            optionValueIsAvailable={optionValueIsAvailable}
                                                                                            optionValueIsSelected={optionValueIsSelected}
                                                                                            deselectOptionValue={deselectOptionValue}
                                                                                            deselectOptionValues={deselectOptionValues}
                                                                                            setOptionValueSelected={setOptionValueSelected}
                                                                                            categoryOptions={categoryOptions}
                                                                                            categoryActive={categoryActive}
                                                                                            setIsPristine={setIsPristine}
                                                                                            getCategoryOptions={getCategoryOptions}
                                                                                            textStateFunctions={textStateFunctions}
                                                                                            positionOptions={positionOptions}
                                                                                            getAvailablePositions={getAvailablePositions}
                                                                                            getPositionOptionById={getPositionOptionById}
                                                                                            getPositionOptionByToken={getPositionOptionByToken}
                                                                                            filterOverlayOptions={filterOverlayOptions}
                                                                                            setImageOrientation={setImageOrientation}
                                                                                        />
                                                                                    );
                                                                                },
                                                                            )
                                                                        }

                                                                        {
                                                                            !isLettering && productOptionCategory.product_option_category_children.map(productOptionCategory => {
                                                                                const childCategoryOptions = getCategoryDirectOptions(productOptionCategory.product_option_category_id);

                                                                                if(childCategoryOptions.filter((childCategory) => {
                                                                                    return childCategory.values.filter((value) => optionValueIsAvailable(childCategory.product_option_id, value.product_option_value_id)).length > 0;
                                                                                }).length === 0)
                                                                                    return null;


                                                                                return (
                                                                                    <div key={productOptionCategory.product_option_category_id} style={{width: '100%'}}>
                                                                                        <CollapseCustom
                                                                                            open={subCategoryIsActive(productOptionCategory.product_option_category_id)}
                                                                                        >
                                                                                            {
                                                                                                childCategoryOptions.map((productOption, optionIndex) => {

                                                                                                    return (
                                                                                                        <ConfiguratorBuildInput
                                                                                                            key={productOption.product_option_id}
                                                                                                            productOption={productOption}
                                                                                                            validateProductOptionDependentProductOptionsSet={validateProductOptionDependentProductOptionsSet}
                                                                                                            isEditable={isEditable}
                                                                                                            productOptionCategory={productOptionCategory}
                                                                                                            optionIndex={optionIndex}
                                                                                                            state={state}
                                                                                                            setState={setState}
                                                                                                            optionIsMultiselect={optionIsMultiselect}
                                                                                                            getProductOptionSelectedValues={getProductOptionSelectedValues}
                                                                                                            optionValueIsAvailable={optionValueIsAvailable}
                                                                                                            optionValueIsSelected={optionValueIsSelected}
                                                                                                            deselectOptionValue={deselectOptionValue}
                                                                                                            deselectOptionValues={deselectOptionValues}
                                                                                                            setOptionValueSelected={setOptionValueSelected}
                                                                                                            categoryOptions={categoryOptions}
                                                                                                            categoryActive={categoryActive}
                                                                                                            setIsPristine={setIsPristine}
                                                                                                            getCategoryOptions={getCategoryOptions}
                                                                                                            textStateFunctions={textStateFunctions}
                                                                                                            positionOptions={positionOptions}
                                                                                                            getAvailablePositions={getAvailablePositions}
                                                                                                            getPositionOptionById={getPositionOptionById}
                                                                                                            getPositionOptionByToken={getPositionOptionByToken}
                                                                                                            filterOverlayOptions={filterOverlayOptions}
                                                                                                            setImageOrientation={setImageOrientation}
                                                                                                        />
                                                                                                    );
                                                                                                })
                                                                                            }
                                                                                        </CollapseCustom>
                                                                                        <Button
                                                                                            style={{marginTop: 10}}
                                                                                            onClick={() => {
                                                                                            setActiveSubCategoryIds(prevSubCategoryIds => {
                                                                                                const subCategoryIds = [...prevSubCategoryIds];
                                                                                                if(subCategoryIds.indexOf(productOptionCategory.product_option_category_id) >= 0) {
                                                                                                    subCategoryIds.pop(productOptionCategory.product_option_category_id);
                                                                                                } else {
                                                                                                    subCategoryIds.push(productOptionCategory.product_option_category_id);
                                                                                                }


                                                                                                return subCategoryIds;
                                                                                            })
                                                                                        }}>
                                                                                            {subCategoryIsActive(productOptionCategory.product_option_category_id) ? 'Hide' : 'See'} {productOptionCategory.product_option_category_title}
                                                                                        </Button>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }

                                                                        {!isLettering && isEditable &&
                                                                            <div
                                                                                style={{width: '100%'}}
                                                                            >
                                                                                <Button onClick={() => {
                                                                                    clearCategoryOptions(productOptionCategory.product_option_category_id)
                                                                                }}
                                                                                        style={{margin: '20px auto 0'}}
                                                                                >
                                                                                    Clear All
                                                                                </Button>
                                                                            </div>
                                                                        }
                                                                        {productOptionCategory.product_option_category_pair_id && (
                                                                            <div
                                                                                style={{width: '100%'}}
                                                                            >
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        makePair(productOptionCategory.product_option_category_id, productOptionCategory.product_option_category_pair_id)
                                                                                    }}
                                                                                    style={{margin: '20px auto 0'}}
                                                                                >
                                                                                    Make A Pair
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </Collapsible>
                                                                </div>
                                                            );

                                                        },
                                                    )}
                                            </div>

                                        );
                                    },
                                )}
                                {state.quote.quote_id &&
                                staticDataSettled ? (
                                    <>
                                        {
                                            state.product.product_configurable !== '1' ?
                                                <div className="option-select-container" style={{padding: 60, boxSizing: 'border-box'}}>
                                                    <span>
                                                        Set Quantity
                                                    </span>
                                                    <TextField disabled={!isEditable} type="number" min={1} max={9999} value={state.quote.quote_product_quantity} onChange={(e) => {
                                                        setQuoteQuantity(e.target.value);
                                                    }}/>


                                                    {
                                                        isEditable ?
                                                            <div>
                                                                <Button
                                                                    style={{marginTop: 50, width: '100%'}}
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        if(!isEditable) {
                                                                            return;
                                                                        }
                                                                        addNewAccessory();
                                                                    }}
                                                                    loading={false}>
                                                                    Add
                                                                </Button>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        state.quote.allSiblingQuotes?.map(
                                                            (siblingQuote) =>  {
                                                                if(siblingQuote.quote_is_confirmed == 0){

                                                                    return null;
                                                                }
                                                                return (
                                                                    <div key={siblingQuote.quote_id}
                                                                         onClick={async () => {
                                                                             if (state.quoteId !== siblingQuote.quote_id) {
                                                                                 if (!isPristine) {
                                                                                     saveQuoteOptions();
                                                                                 } else if(state.quote.quote_is_temporary == 1){
                                                                                     await removeQuoteFromAccessory(state.quote.quote_id);
                                                                                 }
                                                                                //TODO: stop page from refreshing when this is called
                                                                                 dispatch(updateConfiguratorOptionsQuoteId(siblingQuote.quote_id));
                                                                                 setReloadOptions(true);
                                                                             }
                                                                         }}
                                                                         className={classNames("accessory-box", {'invalid': siblingQuote.is_valid != 1 && highlightErrors}, {'current': siblingQuote.quote_id === state.quote.quote_id})}>
                                                                        {siblingQuote.quote_options} x {siblingQuote.quote_product_quantity}
                                                                        {state.quote.allSiblingQuotes.length > 1 && isEditable &&
                                                                            <img
                                                                                src={siblingQuote.is_valid != 1 && highlightErrors ? trashcanWhite : trashcan}
                                                                                className="delete-action"
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    removeQuoteFromAccessory(
                                                                                        siblingQuote.quote_id
                                                                                    );
                                                                                }}
                                                                            />
                                                                        }
                                                                    </div>);
                                                            }
                                                        )
                                                    }
                                                </div>
                                                : null
                                        }
                                        <div className="quote-completion-button-container">
                                            {
                                                state.quote.siblingQuote && state.product.product_configurable == '1' && state.quote.quote_is_temporary != 1 && state.quote.quote_is_confirmed == 1 ?
                                                        <Button
                                                            variant="outlined"
                                                            style={{marginBottom: 10}}
                                                            type="submit"
                                                            onClick={() => {
                                                                if (!isPristine) {
                                                                    setSwitchToID(state.quote.siblingQuote.quote_id);
                                                                    setSaveToSwitchQuoteModalActive(true);
                                                                } else {
                                                                    dispatch(updateConfiguratorOptionsQuoteId(state.quote.siblingQuote.quote_id));
                                                                }
                                                            }}
                                                            loading={false}>
                                                            Configure {state.quote.siblingQuote.product_title}
                                                        </Button>
                                                    :
                                                    null
                                            }
                                            {(isEditable &&  (!state.quote.allSiblingQuotes || state.quote.allSiblingQuotes.filter(siblingQuote => siblingQuote.quote_is_temporary != 1 && siblingQuote.quote_is_confirmed == 1).length > 0)) && (
                                                <Button
                                                    type="submit"
                                                    onClick={() => saveQuoteOptionsAndGoToSummary()}
                                                    loading={false}>
                                                    Complete Quote
                                                </Button>
                                            )}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </section>
                        {optionValueConfiguratorActive()
                            ? ReactDOM.createPortal(
                                <ScreenOverlay>
                                    <div className="overlay-content">
                                        {hoverImage && (
                                            <div style={{top:(hoverImage.topOffset||0)+'px'}} className="hover-image-container inset">
                                                <img src={`${API_ROOT}${hoverImage.product_option_value_photo_file_url}`} />
                                            </div>
                                        )}
                                        <FieldSet
                                            fields={[
                                                {
                                                    key: 'product_option_value_id',
                                                    type: 'select',
                                                    title: state.configuringProductOption.product_option_description,
                                                    fieldProps: {
                                                        options: filterOverlayOptions(state.configuringProductOption.values.filter(productOptionValue => optionValueIsAvailable(productOptionValue.product_option_id, productOptionValue.product_option_value_id))),
                                                        isOptionSelected: (productOptionValue) => state.optionValueConfigurationTemp.product_option_value_id === productOptionValue.product_option_value_id,
                                                        getOptionLabel: (productOptionValue) => getProductOptionValueLabel(productOptionValue),
                                                        getOptionValue: (productOptionValue) => productOptionValue.product_option_value_id,
                                                        isOptionDisabled: (productOptionValue) => !optionValueIsAvailable(state.configuringProductOption.product_option_id, productOptionValue.product_option_value_id),
                                                        onChange: (productOptionValue, details) => {
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                optionValueConfigurationTemp:
                                                                    {
                                                                        ...prevState.optionValueConfigurationTemp,
                                                                        product_option_value_id: productOptionValue.product_option_value_id,
                                                                        ...productOptionValue
                                                                    },
                                                            }));
                                                            setHoverImage(null);
                                                        },
                                                        value: getProductOptionValueById(state.optionValueConfigurationTemp.product_option_value_id),
                                                    },
                                                    fieldWrapperProps: {
                                                        onMouseOver: (e)=>{handleSelectHover(e)},
                                                        onMouseLeave: (e)=>{setHoverImage(null)}
                                                    }
                                                },
                                                ...(
                                                    (state.optionValueConfigurationTemp.product_option_value_id &&
                                                    state.configuringProductOption.position_option_id !== '1' && getAvailablePositions().length > 0)
                                                    ?
                                                    [{
                                                        key: 'product_option_value_position_id',
                                                        type: 'select',
                                                        title: 'Position',
                                                        fieldProps: {
                                                            options: getAvailablePositions(),
                                                            isOptionSelected: (position) => state.optionValueConfigurationTemp.product_option_value_position_id === position.product_option_value_position_id,
                                                            isOptionDisabled: (position) => isPositionDisabled(position),
                                                            getOptionLabel: (position) => position.product_option_value_position_title,
                                                            getOptionValue: (position) => position.product_option_value_position_id,
                                                            onChange: (position, details) => {
                                                                {
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        optionValueConfigurationTemp:
                                                                            {
                                                                                ...prevState.optionValueConfigurationTemp,
                                                                                product_option_value_position_id:
                                                                                position.product_option_value_position_id,
                                                                                ...(position.product_option_value_position_id !== '3' ? {product_option_value_custom_position_side: null} : {product_option_value_custom_position_side: 'front'})
                                                                            },
                                                                    }));

                                                                    if(position.product_option_value_position_id === '3') {
                                                                        setImageOrientation('front');
                                                                    }
                                                                }
                                                            },
                                                            value: getPositionById(state.optionValueConfigurationTemp.product_option_value_position_id)
                                                        },
                                                }]
                                                    : []),
                                                ...(
                                                    (state.optionValueConfigurationTemp.product_option_value_position_id &&
                                                    state.optionValueConfigurationTemp.product_option_value_position_id === '3' && getAvailablePositions().length > 0)
                                                    ?
                                                    [{
                                                        key: 'product_option_value_custom_position_side',
                                                        type: 'select',
                                                        title: 'Side to Customize',
                                                        fieldProps: {
                                                            options: state.configurableOptionPositionSides,
                                                            isOptionSelected: (side) => {
                                                                return state.optionValueConfigurationTemp.product_option_value_custom_position_side === side.value
                                                            },
                                                            getOptionLabel: (side) => side.label,
                                                            getOptionValue: (side) => side.value,
                                                            onChange: (side, details) => {
                                                                setState((prevState) => ({
                                                                    ...prevState,
                                                                    optionValueConfigurationTemp:
                                                                        {
                                                                            ...prevState.optionValueConfigurationTemp,
                                                                            product_option_value_custom_position_side: side.value,
                                                                        },
                                                                }));
                                                                setImageOrientation(side.value);
                                                            },
                                                            value: state.optionValueConfigurationTemp.product_option_value_custom_position_side
                                                                ? state.configurableOptionPositionSides.find(side => side.value === state.optionValueConfigurationTemp.product_option_value_custom_position_side)
                                                                : null
                                                        },
                                                }]
                                                    : []),
                                                ...(
                                                    state.optionValueConfigurationTemp.product_option_value_id &&
                                                    configuringSelectedOptionValue.product_option_value_max_quantity &&
                                                    configuringSelectedOptionValue.product_option_value_min_quantity &&
                                                    (configuringSelectedOptionValue.product_option_value_max_quantity !== configuringSelectedOptionValue.product_option_value_min_quantity) &&
                                                    configuringSelectedOptionValue.product_option_type_id === '5'
                                                    ? [
                                                        {
                                                            key: 'quote_product_option_value_quantity',
                                                            type: 'select',
                                                            title: 'Quantity',
                                                            fieldProps: {
                                                                options: range(
                                                                    parseInt(configuringSelectedOptionValue.product_option_value_max_quantity) -
                                                                    parseInt(configuringSelectedOptionValue.product_option_value_min_quantity) + 1,
                                                                    parseInt(configuringSelectedOptionValue.product_option_value_min_quantity)
                                                                ).map((quantity) => ({
                                                                    label: quantity.toString(),
                                                                    value: quantity
                                                                })),
                                                                isOptionSelected: (quantity) => state.optionValueConfigurationTemp.quote_product_option_value_quantity === quantity.value,
                                                                getOptionLabel: (quantity) => quantity.label,
                                                                getOptionValue: (quantity) => quantity.value,
                                                                onChange: (quantity, details) =>
                                                                    setState((prevState,) => ({
                                                                            ...prevState,
                                                                            optionValueConfigurationTemp: {
                                                                                ...prevState.optionValueConfigurationTemp,
                                                                                quote_product_option_value_quantity:
                                                                                quantity.value,
                                                                            },
                                                                        }),
                                                                    ),
                                                                value: {
                                                                    label: state.optionValueConfigurationTemp.quote_product_option_value_quantity?.toString(),
                                                                    value: state.optionValueConfigurationTemp.quote_product_option_value_quantity,
                                                                },
                                                            },
                                                        },
                                                    ]
                                                    : []),
                                            ]}

                                            actions={[
                                                {
                                                    label: state.currentlyConfiguringOptionValueIndex !== null? 'Save':'Add',
                                                    type: 'submit',
                                                    disabled: true,
                                                    onClick: () => {
                                                        setIsPristine(false);
                                                        let categoryId = state.configuringCategoryId;
                                                        if(
                                                            (
                                                                configuringSelectedOptionValue && configuringSelectedOptionValue.product_option_type_id === '5' &&
                                                                (
                                                                    !state.optionValueConfigurationTemp.quote_product_option_value_quantity &&
                                                                    configuringSelectedOptionValue.product_option_value_max_quantity !== configuringSelectedOptionValue.product_option_value_min_quantity &&
                                                                    configuringSelectedOptionValue.product_option_value_max_quantity &&
                                                                    configuringSelectedOptionValue.product_option_value_min_quantity
                                                                )
                                                            )
                                                            || (configuringSelectedOptionValue && configuringSelectedOptionValue.product_option_type_id == 3 && (!state.optionValueConfigurationTemp.product_option_value_position_id && state.optionValueConfigurationTemp.position_option_id != 1))
                                                        ) {
                                                            setErrorAlert({errorTitle: 'Warning', errorMessage: 'Please select all required options.'})
                                                        }else {
                                                            let overrideQuantity = null;
                                                            if(state.optionValueConfigurationTemp.product_option_value_max_quantity) {
                                                                let totalQuantity = 0;
                                                                state.quoteProductOptionValues.forEach((optionValue, index) => {
                                                                    if (optionValue.product_option_value_id === state.optionValueConfigurationTemp.product_option_value_id && index !== state.currentlyConfiguringOptionValueIndex) {
                                                                        totalQuantity += parseInt(optionValue.quote_product_option_value_quantity) || 1;
                                                                    }
                                                                })
                                                                if(totalQuantity>0) {
                                                                    let requestedQuantity = Math.min((state.optionValueConfigurationTemp.quote_product_option_value_quantity || 1),(parseInt(state.optionValueConfigurationTemp.product_option_value_max_quantity) - totalQuantity));
                                                                    if (requestedQuantity <= 0) {
                                                                        setErrorAlert({errorTitle: 'Error', errorMessage: 'Total quantity of option exceeds max quantity of ' + state.optionValueConfigurationTemp.product_option_value_max_quantity})
                                                                        return;
                                                                    }
                                                                    overrideQuantity = requestedQuantity;
                                                                }

                                                            }
                                                            const positionObj = getPositionOptionById(state.optionValueConfigurationTemp.position_option_id);
                                                            let selectedIds = extractOptionSelections(validatedOptions);
                                                            selectedIds.push(state.optionValueConfigurationTemp.product_option_value_id);

                                                            setState((prevState) => {
                                                                const quoteProductOptionValues = [...prevState.quoteProductOptionValues];
                                                                if(overrideQuantity){
                                                                    prevState.optionValueConfigurationTemp.quote_product_option_value_quantity = overrideQuantity;
                                                                }
                                                                const optionValueConfiguration = {
                                                                    ...prevState.optionValueConfigurationTemp,
                                                                    product_option_id: state.configuringProductOption.product_option_id,
                                                                };

                                                                if(optionValueConfiguration.product_option_value_position_id === '3' && (prevState.currentlyConfiguringOptionValueIndex === null || quoteProductOptionValues[prevState.currentlyConfiguringOptionValueIndex].product_option_value_position_id !== '3')) {
                                                                    optionValueConfiguration.quote_product_option_value_custom_position_top = custom_y_default,
                                                                    optionValueConfiguration.quote_product_option_value_custom_position_left = custom_x_default,
                                                                    optionValueConfiguration.position_option_token = positionObj?positionObj.position_option_token:'custom',
                                                                    optionValueConfiguration.quote_product_option_value_position_token = positionObj?positionObj.position_option_token:'custom',
                                                                    optionValueConfiguration.newCustomize = true
                                                                }else if(optionValueConfiguration.product_option_value_position_id !== '3'){
                                                                    optionValueConfiguration.quote_product_option_value_custom_position_top = null,
                                                                    optionValueConfiguration.quote_product_option_value_custom_position_left = null,
                                                                    optionValueConfiguration.position_option_token = positionObj?positionObj.position_option_token:null,
                                                                    optionValueConfiguration.quote_product_option_value_position_token = positionObj?positionObj.position_option_token:null,
                                                                    optionValueConfiguration.newCustomize = false,
                                                                    optionValueConfiguration.quote_product_option_value_message = null
                                                                }

                                                                if (prevState.currentlyConfiguringOptionValueIndex !== null) {
                                                                    quoteProductOptionValues.splice(prevState.currentlyConfiguringOptionValueIndex, 1, optionValueConfiguration);
                                                                } else {
                                                                    quoteProductOptionValues.push(optionValueConfiguration);
                                                                }

                                                                return {
                                                                    ...prevState,
                                                                    quoteProductOptionValues,
                                                                    optionValueConfigurationTemp:
                                                                        {
                                                                            product_option_value_id: null,
                                                                            product_option_value_position_id: null,
                                                                            product_option_value_configuration_quantity: null,
                                                                        },
                                                                    configuringCategoryId: null,
                                                                    configuringProductOptionIndex: null,
                                                                    configuringProductOption: null,
                                                                    currentlyConfiguringOptionValueIndex: null,
                                                                };
                                                            });

                                                            updateValidatedSelections(selectedIds);
                                                        }
                                                    },
                                                },
                                                {
                                                    label: 'Close',
                                                    onClick: () => {
                                                        setState((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                optionValueConfigurationTemp:
                                                                    {
                                                                        product_option_value_id: null,
                                                                        product_option_value_position_id: null,
                                                                        product_option_value_configuration_quantity: null,
                                                                    },
                                                                configuringCategoryId: null,
                                                                configuringProductOptionIndex: null,
                                                                configuringProductOption: null,
                                                            };
                                                        });
                                                    },
                                                },
                                            ]}
                                        />

                                    </div>
                                </ScreenOverlay>,
                                document.getElementById('overlay-portal'),
                            )
                            : null}
                    </>
                    :
                    <ScreenLoader className="margin-auto"/>
            }
        </>
    );
}

export default ConfiguratorBuild;
