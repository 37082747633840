import { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from '@components/Icons/CloseIcon';
import { CSSTransition } from 'react-transition-group';
import styles from './ErrorModal.module.scss';
import classNames from 'classnames';

const ErrorModal = ({
	               active = false,
	               obscured = false,
	               onClose = () => {},
	               children = null,
	               mountOnEnter = true,
	               unmountOnExit = true,
	               className = null,
	               style = null
               }) => {
	const containerNode = useRef(document.getElementById('overlay-portal-error'));

	/**
	 * Handle Close
	 */

	const handleClose = useCallback(() => {
		onClose();
	}, [onClose]);

	/**
	 * Trap Modal Clicks
	 */

	const handleModalClick = useCallback((e) => {
		e.stopPropagation();
	}, []);

	/**
	 * Prevent Body Scroll
	 */

	useEffect(() => {
		document.body.style.overflowY = active ? 'hidden' : 'scroll';
	}, [active]);

	/**
	 * Re-enable scroll on unmount
	 */
	useEffect(() => {
		return () => {
			document.body.style.overflowY = 'scroll';
		};
	}, [])

	/**
	 * Modal
	 */

	const modal = (
		<CSSTransition
			in={active}
			nodeRef={containerNode}
			classNames={{
				enter: styles.modalEnter,
				enterActive: styles.modalEnterActive,
				enterDone: styles.modalEnterDone,
				exit: styles.modalExit,
				exitActive: styles.modalExitActive,
				exitDone: styles.modalExitDone,
			}}
			mountOnEnter={mountOnEnter}
			unmountOnExit={unmountOnExit}
			timeout={150}>
			<div className={styles.backdrop} onClick={handleClose}>
				<div
					className={classNames({
						[styles.modal]: true,
						[styles.obscured]: !!obscured,
						[className]: !!className,
					})}
					style={style}
					onClick={handleModalClick}>
					<button
						onClick={handleClose}
						className={styles.modalCloseButton}>
						<CloseIcon />
					</button>
					{children}
				</div>
			</div>
		</CSSTransition>
	);

	// return ReactDOM.createPortal(active ? modal : null, containerNode.current);
	return ReactDOM.createPortal(modal, containerNode.current);
};

export default ErrorModal;
