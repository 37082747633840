import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={38}
			height={38}
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
			display="block"
			{...props}>
			<rect x={45.5} y={8} rx={3} ry={3} width={9} height={12}>
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.9166666666666666s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(30 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.8333333333333334s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(60 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.75s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(90 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.6666666666666666s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(120 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.5833333333333334s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(150 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.5s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(180 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.4166666666666667s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(210 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.3333333333333333s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(240 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.25s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(270 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.16666666666666666s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(300 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="-0.08333333333333333s"
					repeatCount="indefinite"
				/>
			</rect>
			<rect
				x={45.5}
				y={8}
				rx={3}
				ry={3}
				width={9}
				height={12}
				fill="inherit"
				transform="rotate(330 50 50)">
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur="1s"
					begin="0s"
					repeatCount="indefinite"
				/>
			</rect>
		</svg>
	);
}

export default SvgComponent;
