import { useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useMobileMenuState } from '@hooks/useMobileMenuState';
import NavigationItem from '../NavigationItem/NavigationItem';
import ChevronIcon from '../../Icons/ChevronIcon';
import styles from './PublicNavigation.module.scss';
import { useSelector } from 'react-redux';
import { API_ROOT } from '@config';

const PublicNavigation = () => {
	const location = useLocation();
	const [mobileMenuOpen, setMobileMenuOpen] = useMobileMenuState();
	const content = useSelector((state) => state.staticData.content);
	const logo = content?.logos?.indexed['logo-light'];

	const navItems = useMemo(() => {
		const items = [];

		if (location.pathname !== '/') {
			items.push({
				path: '/',
				title: 'Learn More',
			});
		}

		if (location.pathname !== '/login') {
			items.push({
				path: '/login',
				title: 'Log In',
			});
		}

		items.push({
			path: '/create-account',
			title: 'Create Account',
		});

		return items;
	}, [location]);

	return (
		<section className={styles.container}>
			<div
				className={classNames({
					[styles.appNavigation]: true,
					[styles.appNavigationActive]: mobileMenuOpen,
				})}>
				<Link to="/" className={styles.logo}>
					{!!logo && (
						<img
							src={`${API_ROOT}/uploaded/content/${logo?.content_item_image}`}
						/>
					)}
				</Link>
				<button
					onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
					className={styles.mobileNavButton}>
					<ChevronIcon />
				</button>
				<nav>
					<ul>
						{navItems.map((item, i) => (
							<NavigationItem key={i} item={item} />
						))}
					</ul>
				</nav>
			</div>
		</section>
	);
};

export default PublicNavigation;
