import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			width={16}
			height={16}
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<g>
				<path d="M13.6 2.4h-8V4h8zm-3.2 6.4H5.6v1.6h4.8zm3.2-3.2h-8v1.6h8zm2.4-4v9.6c0 .88-.72 1.6-1.6 1.6H4.8c-.88 0-1.6-.72-1.6-1.6V1.6C3.2.72 3.92 0 4.8 0h9.6c.88 0 1.6.72 1.6 1.6zM1.6 14.4h11.2V16H1.6C.72 16 0 15.28 0 14.4V3.2h1.6z" />
			</g>
		</svg>
	);
}

export default SvgComponent;
