import { store } from '@store';

/**
 * Get the total price of a quote entity
 */

export const getQuoteTotalPrice = (
	quote = null,
	siblingQuote = null,
	ignoreSiblings = false,
	ignoreDiscount = false,
	ignoreQuantity = false,
	ignoreNetPriceAdjustment = false,
	onlyNetOverridePrice = false,
	ignoreExpedite = false,
) => {
	let quotes = [];
	quotes.push(quote);

	let sizing_quantity = quote.product_line_id ? quote.sizing_quantity : quote.quote_product_quantity;

	if (!quote.allSiblingQuotes && siblingQuote) {
		quotes.push(siblingQuote);
	}

	if(!ignoreSiblings && quote.allSiblingQuotes) {
		for(let allSiblingQuote of quote.allSiblingQuotes) {
			quotes.push(allSiblingQuote);
		}
	}

	const expeditePercentage = parseFloat(
		store.getState().staticData.orderSettings.custom_expedite_percentage,
	);

	let price = 0;
	for (let theQuote of quotes) {
		let quotePrice = 0;
		if (!theQuote || !Array.isArray(theQuote.prices)) {
			break;
		}

		if(onlyNetOverridePrice && !theQuote.quote_net_price_adjustment) {
			break;
		}

		if(theQuote.quote_net_price_adjustment && !ignoreNetPriceAdjustment) {
			quotePrice = theQuote.quote_net_price_adjustment;
		} else {
			quotePrice = theQuote.prices.reduce((result, price) => {
				return (result += parseFloat(price.price || 0));
			}, 0);


			if(!ignoreDiscount) {
				// add discount
				if (quote.quote_discount) {
					quotePrice -= quotePrice * parseFloat(quote.quote_discount || 0);

					// Apply price format before quantity to prevent incorrect total price
					quotePrice = Math.round(quotePrice * 100) / 100;
				}
			}


		}

		let quantity;
		if(!ignoreQuantity) {
			if (theQuote.product_line_id) {
				// multiply by sizing quantity
				if (theQuote.product_id === "1") {
					quantity = sizing_quantity > 0 ? theQuote.sizing_quantity_coat : 1;
				} else if (theQuote.product_id === "2") {
					quantity = sizing_quantity > 0 ? theQuote.sizing_quantity_pants : 1;
				} else if (theQuote.product_id === "3") {
					quantity = sizing_quantity > 0 ? theQuote.sizing_quantity_coveralls : 1;
				}
			} else {
				if (theQuote.quote_product_quantity) {
					quantity = sizing_quantity > 0 ? theQuote.quote_product_quantity : 1;
				}
			}
		} else {
			quantity = 1;
		}

		price += Math.max(quotePrice * quantity, 0);
	}

	// add expedite percentage
	if (!ignoreExpedite && quote.quote_expedite) {
		price += price * (expeditePercentage / 100);
	}

	return price;
};

/**
 * Get the total price of an order entity
 */

export const getOrderTotalPrice = (order = null) => {
	if (!order || !Array.isArray(order.quotes)) {
		return 0;
	}

	return order.quotes.reduce((result, quote) => {
		return (result += getQuoteTotalPrice(quote, null, true));
	}, 0);
};

/**
 *  Split quote prices and group by price
 *  Ex:)
 *      $335 x 85
 *      $398 x 85
 */

export const splitQuotePrices = (
	quote = null,
	siblingQuote = null
) => {
	let quotes = [];
	quotes.push(quote);

	let sizing_quantity = quote.product_line_id ? quote.sizing_quantity : quote.quote_product_quantity;

	if (!quote.allSiblingQuotes && siblingQuote) {
		quotes.push(siblingQuote);
	}

	if(quote.allSiblingQuotes) {
		for(let allSiblingQuote of quote.allSiblingQuotes) {
			quotes.push(allSiblingQuote);
		}
	}

	let prices = [];
	for (let theQuote of quotes) {
		let listPrice = getQuoteTotalPrice(
			theQuote,
			null,
			true,
			true,
			true,
			true
		);
		let netPrice = getQuoteTotalPrice(
			theQuote,
			null,
			true,
			false,
			true,
			false
		);

		let quantity;
		if (theQuote.product_line_id) {
			// multiply by sizing quantity
			if (theQuote.product_id === "1") {
				quantity = sizing_quantity > 0 ? theQuote.sizing_quantity_coat : 1;
			} else if (theQuote.product_id === "2") {
				quantity = sizing_quantity > 0 ? theQuote.sizing_quantity_pants : 1;
			} else if (theQuote.product_id === "3") {
				quantity = sizing_quantity > 0 ? theQuote.sizing_quantity_coveralls : 1;
			}
		} else {
			if (theQuote.quote_product_quantity) {
				quantity = sizing_quantity > 0 ? theQuote.quote_product_quantity : 1;
			}
		}
		quantity = parseInt(quantity);

		let index = prices.findIndex((price) => price.price === listPrice);
		if (index !== -1) {
			prices[index].quantity += quantity;
		} else {
			prices.push({key: `key-${prices.length}`,price: listPrice, net: netPrice, quantity: quantity});
		}
	}

	return prices;
};
