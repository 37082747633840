import classNames from 'classnames/bind';
import styles from './BottomBanner.module.scss';

const BottomBanner= ({message}) => {
    return (
        <div className={classNames({
            [styles.bottomBanner]: true
        })}>
            {message}
        </div>
    );
};

export default BottomBanner;
