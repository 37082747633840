import { useLayoutEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import Card from '@components/Card/Card';
import Sort from '@components/Collection/Sort/Sort';
import Filter from '@components/Collection/Filter/Filter';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import NoItems from './NoItems/NoItems';
import LoadingMore from './LoadingMore/LoadingMore';
import styles from './Collection.module.scss';

const Collection = ({
	title = '',
	items = [],
	itemIdField = null,
	siblingItemField = null,
	component: ItemComponent = null,
	sortOptions = [],
	sortField = '',
	sortDirection = 'ASC',
	sortPlaceholder = 'Sort',
	onSortChange = () => {},
	filterOptions = [],
	filterField = '',
	filterPlaceholder = 'Filter',
	onFilterChange = () => {},
	page = 0,
	onPageChange = () => {},
	isLastPage = false,
	onAddItem = null,
	isFetching = false,
	isRemoving = false,
	noResultsAction = null,
	onRemove = null,
	onEdit = null,
	collectionCardClassName = null,
	collectionCardHeaderClassName = null,
	collectionListContainerClassName = null,
	copyQuote = null,
	setSelectingOrderToAssign = null,
	handleReorder = null,
    onCancelOrder = null,
}) => {
	const node = useRef(null);

	/**
	 * Create Intersection Observer
	 */

	const observer = useMemo(() => {
		return new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && !isFetching && !isLastPage) {
					onPageChange((page) => page + 1);
				}
			},
			{ root: null, threshold: 1.0 },
		);
	}, [isLastPage, isFetching, onPageChange]);

	/**
	 * Observe Sentry Element
	 */

	useLayoutEffect(() => {
		const el = node.current;
		observer.observe(el);

		return () => {
			observer.unobserve(el);
		};
	}, [observer]);

	return (
		<Card
			title={title}
			headerContent={
				<>
					{!!sortOptions.length && (
						<Sort
							sortOptions={sortOptions}
							sortField={sortField}
							sortDirection={sortDirection}
							sortPlaceholder={sortPlaceholder}
							onSortChange={onSortChange}
							onAddItem={onAddItem}
							isSorting={isFetching}
						/>
					)}
					{!!filterOptions.length && (
						<Filter
							filterOptions={filterOptions}
							filterField={filterField}
							filterPlaceholder={filterPlaceholder}
							onFilterChange={onFilterChange}
							isFiltering={isFetching}
						/>
					)}
				</>
			}
			className={classNames({
				[styles.collectionCard]: true,
				[collectionCardClassName]: !!collectionCardClassName,
			})}
			headerClassName={classNames({
				[styles.collectionCardHeader]: true,
				[collectionCardHeaderClassName]:
					!!collectionCardHeaderClassName,
			})}>
			<div
				className={classNames({
					[styles.collectionListContainer]: true,
					[collectionListContainerClassName]:
						!!collectionListContainerClassName,
					[styles.collectionListContainerLoading]:
						(page === 0 && isFetching) || isRemoving,
				})}>
				{!!items.length && (
					<ul>
						{items
							.filter((item) => {
								if (siblingItemField) {
									return item[siblingItemField] === null;
								}

								return true;
							})
							.map((item, i) => (
								<li
									className={styles.collectionItemContainer}
									key={i}>
									<ItemComponent
										item={item}
										siblingItem={
											siblingItemField && itemIdField
												? items.find(
														(itemObj) =>
															itemObj[
																siblingItemField
															] ===
															item[itemIdField],
												  )
												: null
										}
										onRemove={onRemove}
										onCancelOrder={onCancelOrder}
										onEdit={onEdit}
										copyQuote={copyQuote}
										setSelectingOrderToAssign={
											setSelectingOrderToAssign
										}
										handleReorder={handleReorder}
									/>
								</li>
							))}
					</ul>
				)}
				{!items.length && <NoItems>{noResultsAction}</NoItems>}
			</div>
			{page === 0 && isFetching && (
				<SpinnerIcon
					fill="#e87124"
					className={styles.collectionLoader}
				/>
			)}
			<div ref={node} className={styles.scrollSentry} />
			{page > 0 && isFetching && !isRemoving && <LoadingMore />}
		</Card>
	);
};

export default Collection;
