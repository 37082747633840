import Button from '@components/Button/Button';
import styles from './ProductLineCategories.module.scss';
import { API_ROOT } from '@config';
import classNames from 'classnames';
import PublicBanner from '@components/PublicBanner/PublicBanner';
import { useSelector } from 'react-redux';

const ProductLineCategories = ({
	availableCategories = [],
	children = null,
	onSelect = () => {},
	containerStyle = null,
}) => {
	const categories = useSelector(
		(state) => state.staticData.productCategories,
	);
	const content = useSelector((state) => state.staticData.content);
	const bannerImage =
		content['products-page']?.indexed['banner']['content_item_image'];

	return (
		<PublicBanner
			className={classNames({
				[containerStyle]: !!containerStyle,
			})}
			backgroundImage={
				bannerImage
					? `${API_ROOT}/uploaded/content/${bannerImage}`
					: null
			}>
			{children}
			<div className={styles.nav}>
				<div>
					{categories
						.filter((category) =>
							availableCategories.includes(
								category.product_line_category_id,
							),
						)
						.map((category, c) => {
							return (
								<Button
									key={c}
									variant="transparent-light"
									onClick={() =>
										onSelect(
											category.product_line_category_id,
										)
									}>
									{category.product_line_category_title}
								</Button>
							);
						})}
				</div>
			</div>
		</PublicBanner>
	);
};

export default ProductLineCategories;
