import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import styles from './CustomerAlertModal.module.scss';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import TextAreaField from '@components/TextAreaField/TextAreaField';

const CustomerAlertModal = ({
	order = {},
	active = false,
	onSubmit = async () => {},
	onClose = () => {},
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [customerAlert, setCustomerAlert] = useState(
		order.order_customer_alert || '',
	);
	const [customerAlertError, setCustomerAlertError] = useState(null);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(async () => {
		setIsSubmitting(true);

		await onSubmit({
			order_id: order.order_id,
			order_status: 'Requires Edits',
			order_customer_alert: customerAlert || '',
		});

		setIsSubmitting(false);
		onClose();
	}, [onSubmit, onClose, order, customerAlert]);

	const handleClose = useCallback(async () => {
		setIsSaving(true);

		await onSubmit({
			order_id: order.order_id,
			order_customer_alert: customerAlert || '',
		});
		setIsSaving(false);
		onClose();
	}, [onSubmit, onClose, order, customerAlert]);

	useEffect(() => {
		setCustomerAlert(order.order_customer_alert || '');
	}, [order, active]);

	return (
		<Modal active={active} onClose={handleClose}>
			<h4 className={modalStyles.modalTitle}>Customer Alert</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<TextAreaField
					value={customerAlert}
					onChange={(e) => {
						setCustomerAlertError(null);
						setCustomerAlert(e.target.value);
					}}
					className={styles.customerAlert}
					inputClassName={styles.customerAlertInput}
					error={customerAlertError}
				/>
			</div>
			<div className={modalStyles.modalActions}>
				<Button
					variant="outlined"
					onClick={handleClose}
					loading={isSaving}>
					Save And Close
				</Button>
				<Button
					variant="solid"
					onClick={handleSubmit}
					loading={isSubmitting}>
					Submit
				</Button>
			</div>
		</Modal>
	);
};

export default CustomerAlertModal;
