import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { API_ROOT } from '@config';
import http from '@utilities/Http';
import CloseIcon from '@components/Icons/CloseIcon';
import SearchIcon from '@components/Icons/SearchIcon';
import ChevronIcon from '@components/Icons/ChevronIcon';
import TextField from '@components/TextField/TextField';
import SuggestField from '@components/SuggestField/SuggestField';
import filterStyles from '@components/Collection/CollectionFilters.module.scss';
import styles from './CSRFilters.module.scss';
import SelectField from '@components/SelectField/SelectField';
import FieldSet from '@components/FieldSet';
import Http from '@utilities/Http';
import { resetConfigurator } from '@store/reducers/configurator';

const CSRFilters = ({ filters, setFilters = () => {} }) => {
	const user = useSelector((state) => state.auth.user);
	const debounceTimeout = useRef(null);
	const [values, setValues] = useState({ ...filters });
	const [selectedFireDepartment, setSelectedFireDepartment] = useState(null);

	const { orderStatuses } = useSelector((state) => ({
		orderStatuses: state.staticData.orderStatuses,
	}));

	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedCSRUser, setSelectedCSRUser] = useState(null);
	const [selectedOrganization, setSelectedOrganization] = useState(null);

	const [users, setUsers] = useState([]);
	const [csrUsers, setCSRUsers] = useState([]);

	const [isFetchingUsers, setIsFetchingUsers] = useState(false);

	const [isFetchingCSRUsers, setIsFetchingCSRUsers] = useState(false);
	const { productLines } = useSelector((state) => state.staticData);
	const [getProductLines, setGetProductLines] = useState([]);

	const { alertCodes } = useSelector((state) => ({
		alertCodes: state.staticData.alertCodes,
	}));

	/**
	 * Handle Remove Filter
	 */

	const handleRemove = useCallback(
		(name) => {
			setFilters((filters) => ({ ...filters, [name]: '' }));
			setValues((values) => ({ ...values, [name]: '' }));
		},
		[setFilters],
	);

	/**
	 * Handle Filter Change
	 */

	const handleChange = useCallback(
		(e) => {
			setValues((values) => ({
				...values,
				[e.target.name]: e.target.value,
			}));
		},
		[setValues],
	);

	/**
	 * Debounce Updates
	 */

	useEffect(() => {
		clearTimeout(debounceTimeout.current);

		const isTextFieldDiff = [
			'order_name',
			'order_firewriter_number',
			'order_po_number',
		].some((key) => filters[key] !== values[key]);

		if (JSON.stringify(filters) !== JSON.stringify(values)) {
			debounceTimeout.current = setTimeout(
				() => {
					setFilters({ ...values });
				},
				isTextFieldDiff ? 350 : 0,
			);
		}
	}, [setFilters, filters, values]);

	/**
	 * Sync Local 'Values' with Parent 'Filters'
	 */

	useEffect(() => {
		setValues({ ...filters });
	}, [filters]);

	/**
	 * Tags
	 */

	const tags = useMemo(() => {
		const tags = [];

		// order name
		if (filters?.order_name) {
			tags.push(
				<li key="order_name" onClick={() => handleRemove('order_name')}>
					{filters?.order_name}
					<CloseIcon />
				</li>,
			);
		}

		// firewriter order number
		if (filters?.order_firewriter_number) {
			tags.push(
				<li
					key="order_firewriter_number"
					onClick={() => handleRemove('order_firewriter_number')}>
					{filters?.order_firewriter_number}
					<CloseIcon />
				</li>,
			);
		}

		// firedex order number
		if (filters?.order_fire_dex_number) {
			tags.push(
				<li
					key="order_fire_dex_number"
					onClick={() => handleRemove('order_fire_dex_number')}>
					{filters?.order_fire_dex_number}
					<CloseIcon />
				</li>,
			);
		}

		// po number
		if (filters?.order_po_number) {
			tags.push(
				<li
					key="order_po_number"
					onClick={() => handleRemove('order_po_number')}>
					PO#: {filters?.order_po_number}
					<CloseIcon />
				</li>,
			);
		}

		// fire department
		if (filters?.fire_department_id && selectedFireDepartment) {
			tags.push(
				<li
					key="fire_department_id"
					onClick={() => {
						setSelectedFireDepartment(null);
						handleRemove('fire_department_id');
					}}>
					{selectedFireDepartment.fire_department_name}
					<CloseIcon />
				</li>,
			);
		}

		// order status
		if (filters?.order_status) {
			tags.push(
				<li
					key="order_status"
					onClick={() => handleRemove('order_status')}>
					Status: {filters?.order_status}
					<CloseIcon />
				</li>,
			);
		}

		// distributor
		if (filters?.organization_id) {
			tags.push(
				<li
					key="organization_id"
					onClick={() => handleRemove('organization_id')}>
					Distributor: {selectedOrganization?.organization_name}
					<CloseIcon />
				</li>,
			);
		}

		// quote product line
		if (filters?.product_line_description) {
			tags.push(
				<li
					key="product_line_description"
					onClick={() => handleRemove('product_line_description')}>
					{filters?.product_line_description}
					<CloseIcon />
				</li>,
			);
		}

		// csr user
		if (filters?.csr_user_id && selectedCSRUser) {
			tags.push(
				<li
					key="csr_user_id"
					onClick={() => {
						setSelectedCSRUser(null);
						handleRemove('csr_user_id');
					}}>
					CSR: {selectedCSRUser.user_display_name}
					<CloseIcon />
				</li>,
			);
		}

		// user (created by)
		if (filters?.user_id && selectedUser) {
			tags.push(
				<li
					key="user_id"
					onClick={() => {
						setSelectedUser(null);
						handleRemove('user_id');
					}}>
					Created By: {selectedUser.user_display_name}
					<CloseIcon />
				</li>,
			);
		}
		// order alert code
		if (filters?.order_alert_code) {
			const alertCode = alertCodes.find( (code) => code.order_alert_code_id == filters?.order_alert_code);
			tags.push(
				<li
					key="order_alert_code"
					onClick={() => handleRemove('order_alert_code')}>
					Order Alert Code: {alertCode?.order_alert_code_value}
					<CloseIcon />
				</li>,
			);
		}

		return tags.map((tag, t) => tag);
	}, [
		filters,
		selectedFireDepartment,
		selectedUser,
		selectedCSRUser,
		selectedOrganization,
		handleRemove,
	]);

	/**
	 * Fetch Users
	 */

	useEffect(() => {
		setIsFetchingUsers(true);
		http()
			.get(`${API_ROOT}/users`)
			.then(({ data }) => {
				setUsers(data.users);
			})
			.finally(() => {
				setIsFetchingUsers(false);
			});
	}, [user]);

	useEffect(() => {
		if (productLines) {
			let copy = [...productLines];
			setGetProductLines(
				copy.sort((a, b) =>
					a.product_line_description.toLowerCase() >
					b.product_line_description.toLowerCase()
						? 1
						: -1,
				),
			);
		}
	}, [productLines]);

	/**
	 * Fetch CSR Users
	 */

	useEffect(() => {
		setIsFetchingCSRUsers(true);
		http()
			.get(`${API_ROOT}/users/csr`)
			.then(({ data }) => {
				setCSRUsers(data.users);

				setSelectedCSRUser(
					data.users.find(
						(csrUser) => csrUser.user_id === user.user_id,
					),
				);
			})
			.finally(() => {
				setIsFetchingCSRUsers(false);
			});
	}, [user]);

	return (
		<div className={filterStyles.filtersContainer}>
			<div className={filterStyles.filtersContent}>
				<div
					className={classNames({
						[filterStyles.fieldsList]: true,
						[styles.fieldsList]: true,
					})}>
					<TextField
						className={classNames({
							[filterStyles.filter]: true,
							[styles.nameFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						name="order_name"
						value={values.order_name}
						onChange={handleChange}
						placeholder="Order Name"
						adornment={<SearchIcon fill="#FFF" />}
					/>
					<SuggestField
						placeholder="Fire Department"
						name="fire_department_id"
						value={values.fire_department_id}
						valueField="fire_department_id"
						labelField="fire_department_name"
						paramName="fire_department_name"
						url={`${API_ROOT}/fire-departments`}
						getResults={(data) => data.fireDepartments}
						onChange={handleChange}
						onSelect={setSelectedFireDepartment}
						className={classNames({
							[filterStyles.filter]: true,
							[styles.departmentFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						menuClassName={filterStyles.filterInputMenu}
						adornment={<SearchIcon fill="#FFF" />}
					/>
					<TextField
						className={classNames({
							[filterStyles.filter]: true,
							[styles.firewriterNumberFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						name="order_firewriter_number"
						value={values.order_firewriter_number}
						onChange={handleChange}
						placeholder="FireWriter Order #"
						adornment={<SearchIcon fill="#FFF" />}
					/>
					<TextField
						className={classNames({
							[filterStyles.filter]: true,
							[styles.firewriterNumberFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						name="order_fire_dex_number"
						value={values.order_fire_dex_number}
						onChange={handleChange}
						placeholder="FireDex Order #"
						adornment={<SearchIcon fill="#FFF" />}
					/>
					<TextField
						className={classNames({
							[filterStyles.filter]: true,
							[styles.poNumberFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						name="order_po_number"
						value={values.order_po_number}
						onChange={handleChange}
						placeholder="PO #"
						adornment={<SearchIcon fill="#FFF" />}
					/>
					<SelectField
						className={classNames({
							[filterStyles.filter]: true,
							[styles.statusFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						menuClassName={filterStyles.filterInputMenu}
						name="order_status"
						value={values.order_status}
						onChange={handleChange}
						options={orderStatuses.map((orderStatus) => ({
							label: orderStatus,
							value: orderStatus,
						}))}
						placeholder="Status"
						indicator={<ChevronIcon fill="rgba(255,255,255,0.5)" />}
						allowNull={false}
					/>
					<FieldSet
						onChange={handleChange}
						containerClassName={styles.csrUserFilterContainer}
						inputContainerClassName={styles.innerFilterContainer}
						loading={isFetchingUsers}
						className={classNames({
							[filterStyles.filter]: true,
							[styles.csrUserFilter]: true,
						})}
						fields={[
							{
								key: 'csr_user_id',
								type: 'select',
								fieldProps: {
									className: 'new-filter-container',
									classNamePrefix: 'new-filter',
									class: 'new-filter',
									inputClassName: filterStyles.filterInput,
									menuClassName: filterStyles.filterInputMenu,
									options: [
										{
											user_display_name: 'CSR',
											user_id: '',
										},
										...csrUsers,
									],
									styles: classNames({
										[filterStyles.filter]: true,
										[styles.ownerFilter]: true,
									}),
									name: 'csr_user_id',
									getOptionLabel: (csr_user) =>
										csr_user.user_display_name,
									getOptionValue: (csr_user) =>
										csr_user.user_id,
									onChange: (csr_user) => {
										setSelectedCSRUser(csr_user);
										setValues((values) => ({
											...values,
											['csr_user_id']: csr_user.user_id,
										}));
									},
									value: csrUsers.find((option) => {
										return (
											option.user_id ===
											values.csr_user_id
										);
									}),
									placeholder: 'CSR',
									indicator: (
										<ChevronIcon fill="rgba(255,255,255,0.5)" />
									),
								},
							},
						]}
					/>
					<SuggestField
						placeholder="Distributor"
						name="organization_id"
						value={values.organization_id}
						valueField="organization_id"
						labelField="organization_name"
						paramName="organization_name"
						url={`${API_ROOT}/distributors`}
						getResults={(data) => data.distributors}
						onChange={handleChange}
						onSelect={(organization)=>{setSelectedOrganization(organization);}}
						className={classNames({
							[filterStyles.filter]: true,
							[styles.departmentFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						menuClassName={filterStyles.filterInputMenu}
						adornment={<SearchIcon fill="#FFF" />}
						additionalParams={'used_by_organization_quotes'}
					/>
					
					<SelectField
						className={classNames({
							[filterStyles.filter]: true,
							[styles.productFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						menuClassName={filterStyles.filterInputMenu}
						name="product_line_description"
						value={values.product_line_description}
						onChange={handleChange}
						placeholder="Product"
						options={[
							{
								product_line_description: 'Accessories',
							},
							...getProductLines,
						]}
						labelField="product_line_description"
						valueField="product_line_description"
						indicator={<ChevronIcon fill="rgba(255,255,255,0.5)" />}
						allowNull={false}
					/>
					<FieldSet
						onChange={handleChange}
						containerClassName={styles.filterContainer}
						inputContainerClassName={styles.innerFilterContainer}
						loading={isFetchingUsers}
						className={classNames({
							[filterStyles.filter]: true,
							[styles.ownerFilter]: true,
							[styles.searchFilter]: true,
						})}
						fields={[
							{
								key: 'user_id',
								type: 'select',
								fieldProps: {
									className: 'new-filter-container',
									classNamePrefix: 'new-filter',
									class: 'new-filter',
									inputClassName: filterStyles.filterInput,
									menuClassName: filterStyles.filterInputMenu,
									options: [
										{
											user_display_name: 'Created By',
											user_id: '',
										},
										...users,
									],
									styles: classNames({
										[filterStyles.filter]: true,
										[styles.ownerFilter]: true,
									}),
									name: 'user_id',
									getOptionLabel: (user) =>
										user.user_display_name,
									getOptionValue: (user) => user.user_id,
									onChange: (user) => {
										setSelectedUser(user);
										setValues((values) => ({
											...values,
											['user_id']: user.user_id,
										}));
									},
									placeholder: 'Created By',
									indicator: (
										<ChevronIcon fill="rgba(255,255,255,0.5)" />
									),
								},
							},
						]}
					/>
					<SelectField
						className={classNames({
							[filterStyles.filter]: true,
							[styles.productFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						menuClassName={filterStyles.filterInputMenu}
						name="order_alert_code"
						value={values.order_alert_code}
						onChange={handleChange}
						placeholder="Order Alert Code"
						options={alertCodes}
						labelField="order_alert_code_title"
						valueField="order_alert_code_id"
						indicator={<ChevronIcon fill="rgba(255,255,255,0.5)" />}
						allowNull={false}
					/>
				</div>
				{!!tags.length && <ul className={filterStyles.tags}>{tags}</ul>}
			</div>
		</div>
	);
};

export default CSRFilters;
