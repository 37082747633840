import classNames from 'classnames';
import React from 'react';
import CheckmarkIcon from '@components/Icons/CheckmarkIcon';
import fieldStyles from '../Field/Fields.module.scss';
import styles from './CheckboxField.module.scss';

const CheckboxField = ({
	label = null,
	value = 0,
	name = '',
	onChange = () => {},
	inputProps = {},
	className = null,
	readonly = false,
	disabled = false,
	compact = false,
	children = null,
	tabIndex = null,
	containerStyle = {}
}) => {
	const uniq = Math.random().toString(36);

	return (
		<div
			className={classNames(
				fieldStyles.fieldContainer,
				[styles.checkboxFieldContainer],
				{
					[fieldStyles.readonly]: readonly,
					[fieldStyles.disabled]: disabled,
					[fieldStyles.compact]: compact,
					[className]: !!className,
				},
			)}
			style={containerStyle}
		>
			<input
				id={`form-input-${uniq}`}
				type="checkbox"
				name={name}
				checked={value}
				disabled={readonly || disabled}
				onChange={(e) => {
					onChange({
						...e,
						target: { ...e.target, name, value: +e.target.checked },
					});
				}}
				tabIndex={tabIndex}
				{...inputProps}
			/>
			<div className={styles.box}>
				<CheckmarkIcon fill="#FFF" />
			</div>
			<div className={styles.contentContainer}>
				{!!label && (
					<label htmlFor={`form-input-${uniq}`}>{label}</label>
				)}
				{children}
			</div>
		</div>
	);
};

export default CheckboxField;
