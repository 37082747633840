import { useMemo } from 'react';
import { API_ROOT } from '@config';
import Card from '@components/Card/Card';
import Carousel from '@components/Carousel/Carousel';
import styles from './ProductCarousel.module.scss';
import { useSelector } from 'react-redux';

const ProductCarousel = () => {
	const contentItems = useSelector(
		(state) => state.staticData.content['dashboard-carousel']?.items || [],
	);
	const user = useSelector((state) => state.auth.user);

	/**
	 * Carousel Items
	 */

	const carouselItems = useMemo(() => {
		return contentItems
			.filter((item) => {
				if (item['roles']) {
					for (var i = 0, len = item['roles'].length; i < len; i++) {
						if (
							user?.roles?.find(
								(role) =>
									role.role_token ===
									item['roles'][i].role_token,
							)
						)
							return true;
					}
					return false;
				}
				return true;
			})
			.map((item) => ({
				image: `${API_ROOT}/uploaded/content/${item.content_item_image}`,
				caption: item.content_item_description,
				url: item.content_item_url,
				urlText: item.content_item_url_text,
			}));
	}, [contentItems, user]);

	return (
		<Card className={styles.carouselCard}>
			{carouselItems?.length && <Carousel items={carouselItems} />}
		</Card>
	);
};

export default ProductCarousel;
