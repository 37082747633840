import React, { useCallback, useEffect } from 'react';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';
import { useLocation } from 'react-router-dom';
import {download, getQuoteExportSketch} from "@utilities/export";

const SketchDownload = ({ match }) => {
	const token = useQuery().get('x');

	function useQuery() {
		const { search } = useLocation();

		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	/**
	 * Fetch Quote
	 */

	useEffect(() => {
		if(match.params) {
			Http()
				.get(`${API_ROOT}/build/${match.params?.quoteId}`, {
					params: {
						getSiblingQuote: 1,
						x: token,
					},
				})
				.then((quoteResponse) => {
					let quote = quoteResponse.data.quote;
					let siblingQuote = undefined;
					if (quoteResponse.data.siblingQuote)
						siblingQuote = quoteResponse.data.siblingQuote;

					if (quote.product_configurable === '1') {
						handleQuoteExport(quote.quote_id, 'front');
						handleQuoteExport(quote.quote_id, 'back');
						if (siblingQuote !== undefined) {
							handleQuoteExport(siblingQuote.quote_id, 'front');
							handleQuoteExport(siblingQuote.quote_id, 'back');
						}
					}
				});
		}
	}, []);

	const handleQuoteExport = useCallback(
		async (quoteId, side = undefined) => {
			try {
				const url = await getQuoteExportSketch(quoteId, side);
				download(
					url,
					quoteId + `_${side}.png`,
				);
			} catch (error) {
				console.log(error);
			}
		},
		[],
	);

	return null;
};

export default SketchDownload;
