export const schema = {
	fire_department_name_manual: {
		initialValue: null,
		validate: (value, formData) => {
			return value?.length ? null : 'Please enter your Fire Department';
		},
	},
	fire_department_address: {
		initialValue: null,
		validate: (value, formData) => {
			return value?.length
				? null
				: 'Please enter your Fire Department address';
		},
	},
	fire_department_city: {
		initialValue: null,
		validate: (value, formData) => {
			return value?.length
				? null
				: 'Please enter your Fire Department city';
		},
	},
	fire_department_state: {
		initialValue: null,
		validate: (value, formData) => {
			return value?.length
				? null
				: 'Please enter your Fire Department State / Province / Region';
		},
	},
	fire_department_zip_manual: {
		initialValue: null,
		validate: (value, formData) => {
			return value?.length
				? null
				: 'Please enter your Fire Department Postal Code';
		},
	},
	fire_department_country: {
		initialValue: null,
		validate: (value, formData) => {
			return value?.length
				? null
				: 'Please enter your Fire Department country';
		},
	},
	fire_department_county: {
		initialValue: null,
		validate: (value, formData) => {
			return value?.length
				? null
				: 'Please enter your Fire Department county';
		},
	},
};
