import classNames from 'classnames/bind';
import styles from './CollapseCustom.module.scss';

function CollapseCustom({open, children}) {

    return (
        <div className={classNames(styles.contentParent,  {[styles.open]:open})}>
            <div>
            {children}
            </div>
        </div>
    );
}

export default CollapseCustom;
