import React, { useCallback, useState } from 'react';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import TextField from '@components/TextField/TextField';
import FireDepartmentField from '@components/FireDepartmentField/FireDepartmentField';
import Button from '@components/Button/Button';
import styles from './NewOrderModal.module.scss';
import classNames from 'classnames/bind';
import CheckboxField from '@components/CheckboxField/CheckboxField';
import Tooltip from '@components/Tooltip/Tooltip';
import FieldRow from '@components/Field/FieldRow';
import { useForm } from '@hooks/useForm';
import { schema } from './form';
import SelectField from "@components/SelectField/SelectField";
import {useSelector} from "react-redux";

const NewOrderModal = ({ active = false, onClose = () => {} }) => {
	const [orderName, setOrderName] = useState('');
	const [departmentId, setDepartmentId] = useState('');
	const [orderNameError, setOrderNameError] = useState(null);
	const [departmentError, setDepartmentError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [stockOrderActive, setStockOrderActive] = useState(false);
	const [stockOrderInputField, setStockOrderInputField] = useState(null);
	const [stockOrderInputFieldError, setStockOrderInputFieldError] =
		useState(null);
	const [manualFireDepartment, setManualFireDepartment] = useState(false);
	const countries = useSelector((state) => state.staticData.countries);
	const fireDepartmentCountries = useSelector((state) => state.staticData.fireDepartmentCountries);
	const isCSRUser = useSelector((state) =>
		state.auth.user?.permissions?.includes('csr_access'),
	);
	/**
	 * Form data, validation, errors, etc
	 */

	const { formData, formErrors, getValidation, handleChange, setFormData } =
		useForm(schema);

	const [tooltipVisible, setTooltipVisible] = useState(false);

	/**
	 * Handle Modal Close
	 */

	const handleClose = useCallback(
		(order = null) => {
			// clear fields
			setOrderName('');
			setDepartmentId(null);
			setOrderNameError(null);
			setDepartmentError(null);
			setStockOrderInputFieldError(null);
			setStockOrderActive(false);
			setStockOrderInputField(null);
			onClose(order);
			setFormData([null]);
		},
		[onClose],
	);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (e) => {
			if (!orderName) {
				setOrderNameError('Please enter a name for this order');
			}

			if (!departmentId && !stockOrderActive) {
				setDepartmentError('Please select a fire department');
			}

			if (stockOrderActive && !stockOrderInputField) {
				setStockOrderInputFieldError('Please enter your gear use');
			}

			if (manualFireDepartment) {
				const formErrors = await getValidation();
				if (
					!Object.values(formErrors).every((error) => error === null)
				) {
					return;
				}
			}

			if (
				!orderName ||
				(!departmentId && !stockOrderActive && !manualFireDepartment) ||
				(stockOrderActive && !stockOrderInputField)
			) {
				return;
			}

			setIsSubmitting(true);

			http()
				.post(`${API_ROOT}/orders`, {
					order: {
						order_name: orderName,
						fire_department_id:
							departmentId == '' ? null : departmentId,
						order_stock_flag: stockOrderActive ? 1 : 0,
						order_stock_order_input: stockOrderInputField,
						order_manual_department_flag: manualFireDepartment
							? 1
							: 0,
					},
					manualDepartment: formData,
				})
				.then(({ data }) => {
					setIsSubmitting(false);
					handleClose(data.order);
				})
				.catch(() => {
					setIsSubmitting(false);
				});
		},
		[
			orderName,
			departmentId,
			stockOrderActive,
			stockOrderInputField,
			handleClose,
			getValidation,
			formData,
		],
	);

	return (
		<Modal active={active} onClose={() => handleClose()}>
			<h4 className={modalStyles.modalTitle}>New Order</h4>
			<div className={modalStyles.modalBody}>
				<TextField
					label="Order Name"
					value={orderName}
					onChange={(e) => {
						setOrderNameError(null);
						setOrderName(e.target.value);
					}}
					error={orderNameError}
				/>
				{stockOrderActive && !manualFireDepartment && (
					<TextField
						label={
							<div className={styles.stockOrderToggle}>
								Gear Use
								<span
									className={styles.noFindLink}
									onClick={(e) => {
										e.preventDefault();
										setManualFireDepartment(
											!manualFireDepartment,
										);
									}}>
									Can&apos;t Find Department?
								</span>
								<div
									className={styles.stockOrderSwitch}
									onMouseEnter={() => setTooltipVisible(true)}
									onMouseLeave={() =>
										setTooltipVisible(false)
									}>
									<CheckboxField
										label="Not For Fire Department"
										value={stockOrderActive}
										name="order_stock_flag"
										onChange={() => {
											setStockOrderActive(
												!stockOrderActive,
											);
											setManualFireDepartment(false);
										}}
										className={styles.stockOrderSwitch}
									/>
									<Tooltip
										active={tooltipVisible}
										className={styles.stockOrderTooltip}>
										Use text field to enter gear use
									</Tooltip>
								</div>
							</div>
						}
						name="order_stock_order_input"
						value={stockOrderInputField || ''}
						error={stockOrderInputFieldError}
						className={classNames({
							[styles.fireDepartmentField]: true,
						})}
						onChange={(e) => {
							setStockOrderInputFieldError(null);
							setStockOrderInputField(e.target.value);
						}}
					/>
				)}
				{!stockOrderActive && !manualFireDepartment && (
					<FireDepartmentField
						label={
							<div className={styles.stockOrderToggle}>
								Fire Department Zip / Name
								<div style={{ pointerEvents: 'none' }}>
									<span
										className={styles.noFindLink}
										onClick={(e) => {
											e.preventDefault();
											setManualFireDepartment(
												!manualFireDepartment,
											);
											setStockOrderActive(false);
										}}>
										Can&apos;t Find Department?
									</span>
								</div>
								<div>
									<div
										className={styles.stockOrderSwitch}
										onMouseEnter={() =>
											setTooltipVisible(true)
										}
										onMouseLeave={() =>
											setTooltipVisible(false)
										}>
										<CheckboxField
											label="Not For Fire Department"
											value={stockOrderActive}
											name="order_stock_flag"
											onChange={() => {
												setStockOrderActive(
													!stockOrderActive,
												);
											}}
											className={styles.stockOrderSwitch}
										/>
										<Tooltip
											active={tooltipVisible}
											className={
												styles.stockOrderTooltip
											}>
											Use text field to enter gear use
										</Tooltip>
									</div>
								</div>
							</div>
						}
						value={departmentId}
						error={departmentError}
						onChange={(e) => {
							setDepartmentError(null);
							setDepartmentId(e.target.value);
						}}
						className={classNames({
							[styles.fireDepartmentField]: true,
							[styles.hideField]: stockOrderActive,
						})}
						countries={fireDepartmentCountries}
						isCSRUser={isCSRUser}
					/>
				)}
				{manualFireDepartment && (
					<>
						<FieldRow
							className={classNames({
								[styles.fireDepartmentField]: true,
								[styles.hideField]: stockOrderActive,
							})}>
							<TextField
								label={
									<div className={styles.stockOrderToggle}>
										Fire Department Name*
										<span
											className={styles.noFindLink}
											onClick={(e) => {
												e.preventDefault();

												setManualFireDepartment(
													!manualFireDepartment,
												);
											}}>
											Can&apos;t Find Department?
										</span>
										<div>
											<div
												className={
													styles.stockOrderSwitch
												}
												onMouseEnter={() =>
													setTooltipVisible(true)
												}
												onMouseLeave={() =>
													setTooltipVisible(false)
												}>
												<CheckboxField
													label="Not For Fire Department"
													value={stockOrderActive}
													name="order_stock_flag"
													onChange={() => {
														setStockOrderActive(
															!stockOrderActive,
														);
													}}
													className={
														styles.stockOrderSwitch
													}
												/>
												<Tooltip
													active={tooltipVisible}
													className={
														styles.stockOrderTooltip
													}>
													Use text field to enter gear
													use
												</Tooltip>
											</div>
										</div>
									</div>
								}
								name="fire_department_name_manual"
								value={
									formData.fire_department_name_manual || ''
								}
								error={formErrors.fire_department_name_manual}
								onChange={handleChange}
							/>
						</FieldRow>
						<FieldRow
							className={classNames({
								[styles.fireDepartmentField]: true,
							})}>
							<TextField
								label={'Fire Department Address'}
								name="fire_department_address"
								value={formData.fire_department_address || ''}
								error={formErrors.fire_department_address}
								onChange={handleChange}
							/>
							<TextField
								label={'Fire Department City'}
								name="fire_department_city"
								value={formData.fire_department_city || ''}
								error={formErrors.fire_department_city}
								onChange={handleChange}
							/>
						</FieldRow>
						<FieldRow
							className={classNames({
								[styles.fireDepartmentField]: true,
							})}>
							<TextField
								label={'Fire Department State / Province / Region'}
								name="fire_department_state"
								value={formData.fire_department_state || ''}
								error={formErrors.fire_department_state}
								onChange={handleChange}
							/>
							<TextField
								label={'Fire Department Zip / Postal Code'}
								name="fire_department_zip_manual"
								value={
									formData.fire_department_zip_manual || ''
								}
								error={formErrors.fire_department_zip_manual}
								onChange={handleChange}
							/>
						</FieldRow>
						<FieldRow
							className={classNames({
								[styles.fireDepartmentField]: true,
							})}>
							<TextField
								label={'Fire Department County'}
								name="fire_department_county"
								value={formData.fire_department_county || ''}
								error={formErrors.fire_department_county}
								onChange={handleChange}
							/>
							<SelectField
                                    label="Fire Department Country"
                                        placeholder="Country"
                                        value={formData.fire_department_country || ''}
                                        name="fire_department_country"
                                        onChange={handleChange}
                                        options={countries}
                                        labelField="country_name"
                                        valueField="country_code"
                                        className={classNames({ [styles.countryField]: true })}
                                        inputClassName={classNames({[styles.fullHeight]: true})}
                                        menuClassName={classNames({[styles.selectMenu]: true})}
                                    />
						</FieldRow>
					</>
				)}
			</div>
			<div className={modalStyles.modalActions}>
				<Button
					variant="solid"
					onClick={handleSubmit}
					loading={isSubmitting}>
					Add Order
				</Button>
				<Button variant="plain" onClick={() => handleClose()}>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export default NewOrderModal;
