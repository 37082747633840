import AddressList from '@components/AddressList/AddressList';
import Button from '@components/Button/Button';
import { API_ROOT } from '@config';
import { setUser } from '@store/reducers/auth';
import Http from '@utilities/Http';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddresses as setAddressesAction } from '@store/reducers/addresses';
import ShippingAccounts from '@components/ShippingAccounts/ShippingAccounts';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import styles from './OrganizationShippingForm.module.scss';

const OrganizationShippingForm = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const isCSRUser = user?.permissions?.includes('csr_access');
	const [isFetching, setIsFetching] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { addresses: currentAddresses } = useSelector(
		(state) => state.addresses,
	);

	const [addresses, setAddresses] = useState([...currentAddresses]);
	const [shippingAccounts, setShippingAccounts] = useState([]);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			try {
				setIsSubmitting(true);

				// submit addresses
				const { data } = await Http().put(
					`${API_ROOT}/users/addresses`,
					{
						addresses: addresses.map((address) => ({
							...address,
							user_address_is_default: parseInt(
								address.user_address_is_default || 0,
							),
						})),
					},
				);

				dispatch(setAddressesAction(data.addresses));
				dispatch(
					setUser({
						...user,
						...data.user,
					}),
				);

				// submit shipping accounts
				if (user?.permissions?.includes('manage_organization')) {
					const { data } = await Http().put(
						`${API_ROOT}/organizations/shipping-accounts`,
						{
							organization_shipping_accounts:
								shippingAccounts.filter(
									(account) => account.shipping_option_id,
								),
						},
					);

					setShippingAccounts(data.organization_shipping_accounts);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setIsSubmitting(false);
			}
		},
		[user, addresses, shippingAccounts, dispatch],
	);

	/**
	 * Fetch Shipping Accounts
	 */

	const fetchShippingAccounts = useCallback(async () => {
		setIsFetching(true);

		try {
			const { data } = await Http().get(
				`${API_ROOT}/organizations/shipping-accounts`,
			);

			setShippingAccounts(data.organization_shipping_accounts);
		} catch (error) {
			console.log(error);
		} finally {
			setIsFetching(false);
		}
	}, []);

	useEffect(() => {
		fetchShippingAccounts();
	}, [fetchShippingAccounts]);

	return (
		<div className={styles.container}>
			{!!addresses.length && (
				<section className={styles.section}>
					<h6>Address Book</h6>
					<AddressList
						addresses={addresses}
						onChange={setAddresses}
					/>
				</section>
			)}
			{user?.permissions?.includes('manage_organization') && !isCSRUser && (
				<section className={styles.section}>
					<h6>Shipping Accounts</h6>
					{isFetching && (
						<div className={styles.shippingAccountsLoader}>
							<SpinnerIcon />
						</div>
					)}
					<ShippingAccounts
						shippingAccounts={shippingAccounts}
						setShippingAccounts={setShippingAccounts}
					/>
				</section>
			)}
			<div className={styles.actions}>
				<Button
					variant="solid"
					onClick={handleSubmit}
					loading={isSubmitting}
					className={styles.submitButton}>
					Save Changes
				</Button>
			</div>
		</div>
	);
};

export default OrganizationShippingForm;
