/**
 * Normalize Data for Form Submit
 */

export const normalize = (data = [], schema = {}) => {
	return [...data].reduce((result, _item) => {
		const item = { ..._item };

		Object.keys(schema).forEach((key) => {
			const field = schema[key];

			switch (field.sizing_field_type) {
				case 'boolean': {
					// convert boolean to int
					item[key] = +item[key];
					break;
				}
				case 'integer':
				case 'integer_inches':
				case 'float': {
					// remove ″ suffix
					if (/\s″$/.test(item[key])) {
						item[key] = item[key]?.replace(/\s″$/, '');
					}
					break;
				}
				case 'integer_even': {
					// remove ″ suffix
					if (/\s″$/.test(item[key])) {
						item[key] = item[key]?.replace(/\s″$/, '');
					}

					if (item[key] % 2 !== 0) {
						item[key] = parseInt(item[key]) + 1;
					}

					break;
				}
				case 'text':
				case 'longtext':
			}
		});

		Object.keys(item).forEach((key) => {
			// convert '' to null
			if (item[key] === '') {
				item[key] = null;
			}
		});

		return [...result, item];
	}, []);
};
