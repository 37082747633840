import { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import http from '@utilities/Http';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import MailIcon from '@components/Icons/MailIcon';
import styles from './ShareResourceModal.module.scss';
import { API_ROOT } from '@config';
import SuggestField from '@components/SuggestField/SuggestField';
import TextField from "@components/TextField/TextField";
import Http from "@utilities/Http";

const ShareResourceModal = ({selectedProductDownloadIds = [], active = false, onClose = () => {} }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [email, setEmail] = useState('');
	const [copyError, setCopyError] = useState(null);
	const [fetchingShareUrl, setFetchingShareUrl] = useState(true);
	const [shareUrl, setShareUrl] = useState('');
	const [copyButtonText, setCopyButtonText] = useState('Copy');

	/**
	 * Handle Close
	 */

	const handleClose = useCallback(
		() => {
			setCopyError('');
			setEmail('');
			onClose();
			setFetchingShareUrl(true);
			setShareUrl('');
		},
		[onClose],
	);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async () => {

			if (!email) {
				setCopyError(
					"Please enter the email address you'd like to share this quote with",
				);
				return;
			}

			if (!/\S+@\S+\.\S+/.test(email)) {
				setCopyError('Please enter a valid email address');
				return;
			}
			setIsSubmitting(true);

			try {
				await http().post(`${API_ROOT}/product-downloads/share/`, {
					email,
					productDownloadIds: selectedProductDownloadIds,
				});
				handleClose(true);
			} catch (err) {
				if (err.response?.status === 404) {
					setCopyError('We were unable to find that email address');
					return;
				}

				if (err.response?.status === 401) {
					setCopyError(
						err.response?.data?.message || 'Something went wrong',
					);
					return;
				}

				setCopyError('Something went wrong');
			} finally {
				setIsSubmitting(false);
			}

			setIsSubmitting(false);
		},
		[email, handleClose, selectedProductDownloadIds],
	);

	useEffect(() => {
		if(selectedProductDownloadIds.length) {
			Http().post(`${API_ROOT}/product-downloads-get-share-url/`, {
				productDownloadIds: selectedProductDownloadIds
			}).then(({data}) => {
				setShareUrl(data.shareUrl);
				setFetchingShareUrl(false);
			})
		}
	}, [selectedProductDownloadIds]);

	return (
		<Modal active={active} onClose={handleClose} style={{maxWidth: '800px'}}>
			<div className={styles.modalTitleContainer}>
				<MailIcon />
				<h4 className={modalStyles.modalTitle}>
					SHARE OPTIONS
				</h4>
			</div>
			<div className={styles.modalSubtitleContainer}>
				<p className={styles.modalSubtitle}>The link can be viewed only by distributors who are granted access to the FireWriter resource</p>
			</div>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<div className={classNames(styles.field)}>
					<label>
						Enter an <b>email address</b> to share assets.
					</label>
					<div>
						<SuggestField
							placeholder="name@example.com"
							value={email}
							valueField="user_email"
							labelExtractor={(user) =>
								`${user.user_display_name} (${user.user_email})`
							}
							url={`${API_ROOT}/organizations/users`}
							getResults={(data) => data.users}
							onTextChange={(e) => {
								setCopyError('');
								setEmail(e.target.value);
							}}
							onSelect={(user) => {
								setCopyError('');
								setEmail(user.user_email);
							}}
							className={classNames({
								[styles.emailField]: true,
							})}
							inputClassName={styles.emailInput}
							error={copyError}
							noResultsMessage="No Results"
						/>
						{/*<p>*/}
						{/*	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a augue velit.*/}
						{/*</p>*/}
						<Button
							variant="solid"
							onClick={() => handleSubmit()}
							loading={isSubmitting}>
							Share
						</Button>
					</div>
					{/*<p>*/}
					{/*	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a augue velit.*/}
					{/*</p>*/}
				</div>
				<p className={styles.or}>OR</p>
				<div className={classNames(styles.field)}>
					<label>
						<b>Copy the link</b> to share assets.
					</label>
					<div>
						<TextField
							className={classNames({
								[styles.emailField]: true,
							})}
							inputClassName={styles.emailInput}
							value={shareUrl}
							disabled={true}
						/>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a augue velit.
						</p>

						<Button
							variant="solid"
							onClick={() => {
								navigator.clipboard.writeText(shareUrl)
								setCopyButtonText('Copied!');
							}}
							loading={fetchingShareUrl}
							className={styles.collaborateButton}>
							{copyButtonText}
						</Button>
					</div>
					{/*<p>*/}
					{/*	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a augue velit.*/}
					{/*</p>*/}
				</div>
			</div>
			<div
				className={classNames(
					modalStyles.modalActions,
					styles.modalActions,
				)}>
				<p onClick={() => handleClose(false)}>Close</p>
			</div>
		</Modal>
	);
};

export default ShareResourceModal;
