import { useClickOutside } from '@hooks/useClickOutside';
import classNames from 'classnames';
import { useRef } from 'react';
import styles from './Tooltip.module.scss';

const Tooltip = ({
	active = false,
	children = null,
	className = '',
	onClose = () => {},
	useCapture = false,
}) => {
	const node = useRef();
	useClickOutside(node.current, onClose, useCapture);

	return (
		<div
			ref={node}
			className={classNames({
				[styles.tooltip]: true,
				[styles.tooltipActive]: active,
				[className]: !!className,
			})}>
			{children}
		</div>
	);
};

export default Tooltip;
