import React, { useCallback, useState } from 'react';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import TextField from '@components/TextField/TextField';
import Button from '@components/Button/Button';
import fieldStyles from '@components/Field/Fields.module.scss';
import styles from './CreateQuoteFromProductModal.module.scss';
import classNames from 'classnames';
import { generateToken } from '@utilities/token';
import { useDispatch, useSelector } from 'react-redux';
import { setConfiguratorOptions } from '@store/reducers/configurator';
import { setBuildToken, setIsNewQuote } from '@store/reducers/build';
import FireDepartmentField from "@components/FireDepartmentField/FireDepartmentField";
import CheckboxField from "@components/CheckboxField/CheckboxField";
import Tooltip from "@components/Tooltip/Tooltip";

const CreateQuoteFromProductModal = ({
	active = false,
	onClose = () => {},
	productLineId,
	productIds,
	className = null,
}) => {
	const dispatch = useDispatch();
	const [quoteName, setQuoteName] = useState('');
	const [fireDepartmentId, setFireDepartmentId] = useState(null);
	const [stockFlag, setStockFlag] = useState(0);
	const [stockQuoteInput, setStockQuoteInput] = useState('');
	const [error, setError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const buildToken = useSelector((state) => state.build.token);
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const countries = useSelector((state) => state.staticData.countries);
	const fireDepartmentCountries = useSelector((state) => state.staticData.fireDepartmentCountries);
	const isCSRUser = useSelector((state) =>
		state.auth.user?.permissions?.includes('csr_access'),
	);

	/**
	 * Handle Modal Close
	 */

	const handleClose = useCallback(() => {

		// clear fields
		setQuoteName('');
		setFireDepartmentId(null);
		setStockFlag(0);
		setStockQuoteInput('');
		setError(null);
		onClose();
		setIsSubmitting(false);
	}, [onClose]);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			if (!quoteName) {
				setError('Please enter a name for this quote');
				return;
			}

			if (!fireDepartmentId) {
				if (stockFlag && !stockQuoteInput) {
					setError('Please provide gear use');
					return;
				}
			}

			setIsSubmitting(true);

			const token = buildToken || generateToken(64);
			http()
				.post(`${API_ROOT}/build`, {
					quotes: productIds.map(productId => {
						if(productLineId === null){
							return {
								quote_name: quoteName,
								product_id: productId,
								quote_token: token,
								fire_department_id: fireDepartmentId,
								quote_stock_flag: stockFlag,
								quote_stock_quote_input: stockQuoteInput,
							};
						}
						return {
							quote_name: quoteName,
							product_id: productId,
							product_line_id: productLineId,
							quote_token: token,
							fire_department_id: fireDepartmentId,
							quote_stock_flag: stockFlag,
							quote_stock_quote_input: stockQuoteInput,
						};
					}),
				})
				.then(({ data }) => {
					const quote = data.quote;
					handleClose();
					dispatch(setBuildToken(token));
					dispatch(setIsNewQuote(true));
					dispatch(
						setConfiguratorOptions({ quoteId: quote.quote_id }),
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		[
			dispatch,
			quoteName,
			productLineId,
			productIds,
			buildToken,
			handleClose,
			stockFlag,
			stockQuoteInput,
			fireDepartmentId,
		],
	);

	return (
		<Modal
			active={active}
			onClose={() => handleClose()}
			className={classNames({ [className]: !!className })}>
			<h4 className={modalStyles.modalTitle}>Create Quote</h4>
			<form onSubmit={handleSubmit}>
				<div
					className={classNames({
						[modalStyles.modalBody]: true,
						[styles.form]: true,
					})}>
					<TextField
						label="Quote Name"
						value={quoteName}
						onChange={(e) => {
							setQuoteName(e.target.value);
							setError(null);
						}}
						className={styles.firstInput}
					/>
					{stockFlag === 1 && (
						<TextField
							label={
								<div className={styles.stockOrderToggle}>
									Gear Use
									<div
										className={styles.stockOrderSwitch}
										onMouseEnter={() => setTooltipVisible(true)}
										onMouseLeave={() =>
											setTooltipVisible(false)
										}>
										<CheckboxField
											label="Not For Fire Department"
											value={stockFlag}
											name="quote_stock_flag"
											onChange={(e) => {
												setStockFlag(e.target.value);
											}}
											className={styles.stockOrderSwitch}
										/>
										<Tooltip
											active={tooltipVisible}
											className={styles.stockOrderTooltip}>
											Use text field to enter gear use
										</Tooltip>
									</div>
								</div>
							}
							name="quote_stock_quote_input"
							value={stockQuoteInput || ''}
							onChange={(e) => {
								setStockQuoteInput(e.target.value);
							}}
						/>
					)}
					{stockFlag !== 1 && (
						<FireDepartmentField
							label={
								<div className={styles.stockOrderToggle}>
									Fire Department Zip / Name
									<div
										className={styles.stockOrderSwitch}
										onMouseEnter={() => setTooltipVisible(true)}
										onMouseLeave={() =>
											setTooltipVisible(false)
										}>
										<CheckboxField
											label="Not For Fire Department"
											value={stockFlag}
											name="quote_stock_flag"
											onChange={(e) => {
												setStockFlag(e.target.value);
											}}
											className={styles.stockOrderSwitch}
										/>
										<Tooltip
											active={tooltipVisible}
											className={styles.stockOrderTooltip}>
											Use text field to enter gear use
										</Tooltip>
									</div>
								</div>
							}
							value={fireDepartmentId}
							name="fire_department_id"
							onChange={(e) => {
								setFireDepartmentId(e.target.value);
							}}
							className={classNames({
								[styles.fireDepartmentField]: true,
								[styles.hideField]: stockFlag,
							})}
							countries={fireDepartmentCountries}
							isCSRUser={isCSRUser}
						/>
					)}
				</div>
				{!!error && (
					<div className={styles.formErrorContainer}>
						<span className={fieldStyles.fieldError}>{error}</span>
					</div>
				)}
				<div className={modalStyles.modalActions}>
					<Button
						type="submit"
						variant="solid"
						loading={isSubmitting}>
						Create Quote
					</Button>
					<Button variant="plain" onClick={() => handleClose()}>
						Close
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default CreateQuoteFromProductModal;
