import { useCallback, useState } from 'react';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import TextField from '@components/TextField/TextField';
import Button from '@components/Button/Button';
import SelectField from '@components/SelectField/SelectField';
import { useSelector } from 'react-redux';
import FieldRow from '@components/Field/FieldRow';
import styles from './InviteUserModal.module.scss';

const InviteUserModal = ({ active = false, onClose = () => {} }) => {
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [role, setRole] = useState('');
	const [pricingAccess, setPricingAccess] = useState('1');

	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [roleError, setRoleError] = useState('');

	const [isSubmitting, setIsSubmitting] = useState(false);

	const systemRoles = useSelector(
		(state) => state.staticData.organizationSystemRoles,
	);

	/**
	 * Handle Modal Close
	 */

	const handleClose = useCallback(
		(invitedUser = null) => {
			// clear fields
			setFirstNameError('');
			setLastNameError('');
			setEmailError('');
			setRoleError('');
			onClose(invitedUser);
		},
		[onClose],
	);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(() => {
		if (!firstName) {
			setFirstNameError('Please enter a first name');
		}

		if (!lastName) {
			setLastNameError('Please enter a last name');
		}

		if (!/\S+@\S+\.\S+/.test(email)) {
			setEmailError('Please enter a valid email address');
		}

		if (!role) {
			setRoleError('Please select a role for this user');
		}

		setIsSubmitting(true);

		http()
			.post(`${API_ROOT}/users/invite`, {
				firstName,
				lastName,
				email,
				role,
				pricingAccess,
			})
			.then(({ data }) => {
				setIsSubmitting(false);
				handleClose(data.user);
			})
			.catch(() => {
				setIsSubmitting(false);
			});
	}, [firstName, lastName, email, role, handleClose, pricingAccess]);

	return (
		<Modal active={active} onClose={() => handleClose()}>
			<h4 className={modalStyles.modalTitle}>Invite User</h4>
			<div className={modalStyles.modalBody}>
				<FieldRow className={styles.fieldRow}>
					<TextField
						label="First Name"
						value={firstName}
						onChange={(e) => {
							setFirstNameError(null);
							setFirstName(e.target.value);
						}}
						error={firstNameError}
					/>
				</FieldRow>
				<FieldRow className={styles.fieldRow}>
					<TextField
						label="Last Name"
						value={lastName}
						onChange={(e) => {
							setLastNameError(null);
							setLastName(e.target.value);
						}}
						error={lastNameError}
					/>
				</FieldRow>
				<FieldRow className={styles.fieldRow}>
					<TextField
						label="Email Address"
						value={email}
						onChange={(e) => {
							setEmailError(null);
							setEmail(e.target.value);
						}}
						error={emailError}
					/>
				</FieldRow>

				<FieldRow className={styles.fieldRow}>
					<SelectField
						label="User Role"
						value={role}
						error={roleError}
						options={systemRoles.map((role) => ({
							...role,
							role_title: role.role_title.replace(
								'Distributor ',
								'',
							),
						}))}
						labelField="role_title"
						valueField="role_id"
						onChange={(e) => setRole(e.target.value)}
					/>
				</FieldRow>

				<FieldRow className={styles.fieldRow}>
					<SelectField
						label="Pricing Access"
						value={pricingAccess}
						options={[
							{
								value: '1',
								label: 'Yes',
							},
							{
								value: '0',
								label: 'No',
							},
						]}
						labelField="label"
						valueField="value"
						onChange={(e) => setPricingAccess(e.target.value)}
					/>
				</FieldRow>
			</div>
			<div className={modalStyles.modalActions}>
				<Button
					variant="solid"
					onClick={handleSubmit}
					loading={isSubmitting}>
					Send Invitation
				</Button>
			</div>
		</Modal>
	);
};

export default InviteUserModal;
