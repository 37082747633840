import { useMemo } from 'react';

export const useQueryParams = (location) => {
	const params = useMemo(() => {
		if (!location.search) {
			return {};
		}

		const parsed = new URLSearchParams(location.search);
		return Object.fromEntries(parsed);
	}, [location]);

	return params;
};
