import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	configuratorOptions: {
		productId: null,
		productLineId: null,
		quoteId: null,
		onClose() {},
	},
	screen: 'build', //build or summary
};

const configuratorSlice = createSlice({
	name: 'configurator',
	initialState,
	reducers: {
		setConfiguratorOptions(state, action) {
			return {
				...state,
				configuratorOptions: action.payload,
			};
		},
		setScreen(state, action) {
			return {
				...state,
				screen: action.payload,
			};
		},
		resetConfigurator() {
			return initialState;
		},
		updateConfiguratorOptionsQuoteId(state, action) {
			return {
				...state,
				configuratorOptions: {
					...state.configuratorOptions,
					quoteId: action.payload
				}
			}
		}
	},
});

export const { setConfiguratorOptions, setScreen, resetConfigurator, updateConfiguratorOptionsQuoteId } =
	configuratorSlice.actions;
export default configuratorSlice.reducer;
