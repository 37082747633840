import React, {useEffect, useState} from 'react';
import {API_ROOT} from '@config';
import Card from '@components/Card/Card';
import Screen from '@components/Screen/Screen';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';
import http from '@utilities/Http';
import styles from './LegacyQuote.module.scss';
import classNames from "classnames";
import {formatCurrency} from "@utilities/currency";
import LegacyQuoteOptionCategories from "./components/LegacyQuoteOptionCategories";
import LegacyQuoteListItem from "@components/LegacyQuoteListItem/LegacyQuoteListItem";

const LegacyQuote = ({match}) => {
    const [quote, setQuote] = useState(null);
    const [isFetching, setIsFetching] = useState(true);

    const getDisplayOptionCodeText = (text) => {
        return text ? ('(' + text + ') ') : '';
    };

    const getSideText = (text) => {
        return text ? (' - ' + text) : '';
    };

    const coatOptionCategories = [
        {
            title: 'Coat Materials',
            subTotalTitle: 'Materials',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Coat Length',
                    getLabels: (quote) => {
                        return quote.legacy_quote_coat_length.Description;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Outer Shell',
                    getLabels: (quote) => {
                        return `${getDisplayOptionCodeText(quote.legacy_quote_outer_shell.DisplayOptionCode)}${quote.legacy_quote_outer_shell.Description}, ${getDisplayOptionCodeText(quote.legacy_quote_outer_shell_color.DisplayOptionCode)}${quote.legacy_quote_outer_shell_color.Description}`;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Thermal Liner',
                    getLabels: (quote) => {
                        return `(${quote.legacy_quote_thermal_liner.FabricCode}) ${quote.legacy_quote_thermal_liner.Fabric}`;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Moisture Barrier',
                    getLabels: (quote) => {
                        return `(${quote.legacy_quote_moisture_barrier.FabricCode}) ${quote.legacy_quote_moisture_barrier.Fabric}`;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Closure',
            subTotalTitle: 'Closure',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Closure',
                    getLabels: (quote) => {
                        return getDisplayOptionCodeText(quote.legacy_quote_coat_closure.DisplayOptionCode) + `Inner ${quote.legacy_quote_coat_closure.InnerDescription} | Outer ${quote.legacy_quote_coat_closure.OuterDescription}`;
                    },
                    getPrice: (quote) => {
                        return formatCurrency(quote.legacy_quote_coat_closure_option.MSRP);
                    }
                },
                {
                    title: 'Closure Option',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousTypeId === '6' && miscOption.miscellaneousOption.MiscellaneousLocationId === '1';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return formatCurrency(quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousTypeId === '6' && miscOption.miscellaneousOption.MiscellaneousLocationId === '1';
                        }).map(miscOption => miscOption.miscellaneousOption.MSRP)
                            .reduce((a, b) => a + b, 0));
                    }
                },
            ]
        },
        {
            title: 'Coat Trim Style',
            subTotalTitle: 'Trim Style',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Coat Trim',
                    getLabels: (quote) => {
                        return getDisplayOptionCodeText(quote.legacy_quote_coat_trim_option_style.DisplayOptionCode) + `${quote.legacy_quote_coat_trim_option_style.Description}, ${quote.legacy_quote_coat_trim_option_color.Description}`;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Trim Additions',
                    getLabels: (quote) => {
                        return getDisplayOptionCodeText(quote.legacy_quote_coat_trim_addition_option_coat_trim_addition.DisplayOptionCode) + `${quote.legacy_quote_coat_trim_addition_option_coat_trim_addition?.Description}, ${getDisplayOptionCodeText(quote.legacy_quote_coat_trim_addition_option_style.DisplayOptionCode)}${quote.legacy_quote_coat_trim_addition_option_style.Description}`;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                // {
                //     title: 'Trim Addition Color',
                //     getLabels: (quote) => {
                //         return 'test';
                //     },
                //     getPrice: (quote) => {
                //         return '$1337';
                //     }
                // },
            ]
        },
        {
            title: 'Coat Knit Wrist',
            subTotalTitle: 'Knit Wrist',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Knit Wrist',
                    getLabels: (quote) => {
                        return quote.legacy_quote_reinforcements.filter(reinforcement => {
                            return reinforcement.quoteReinforcement.ReinforcementTypeId === '6' && reinforcement.quoteReinforcement.ReinforcementLocationId === '1';
                        }).map(reinforcement => {
                            return [getDisplayOptionCodeText(reinforcement.reinforcement.DisplayOptionCode) + reinforcement.reinforcement.Description, ...reinforcement.quoteReinforcementAdditions.map(reinforcementAddition => getDisplayOptionCodeText(reinforcementAddition.reinforcementAddition.DisplayOptionCode) + reinforcementAddition.reinforcementAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Cuff',
            subTotalTitle: 'Cuff',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Cuff',
                    getLabels: (quote) => {
                        return quote.legacy_quote_reinforcements.filter(reinforcement => {
                            return reinforcement.quoteReinforcement.ReinforcementTypeId === '4' && reinforcement.quoteReinforcement.ReinforcementLocationId === '1';
                        }).map(reinforcement => {
                            return [getDisplayOptionCodeText(reinforcement.reinforcement.DisplayOptionCode) + reinforcement.reinforcement.Description, ...reinforcement.quoteReinforcementAdditions.map(reinforcementAddition => getDisplayOptionCodeText(reinforcementAddition.reinforcementAddition.DisplayOptionCode) + reinforcementAddition.reinforcementAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Reinforcements',
            subTotalTitle: 'Reinforcements',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Shoulder Reinforcement',
                    getLabels: (quote) => {
                        return quote.legacy_quote_reinforcements.filter(reinforcement => {
                            return reinforcement.quoteReinforcement.ReinforcementTypeId === '1' && reinforcement.quoteReinforcement.ReinforcementLocationId === '1';
                        }).map(reinforcement => {
                            return [getDisplayOptionCodeText(reinforcement.reinforcement.DisplayOptionCode) + reinforcement.reinforcement.Description, ...reinforcement.quoteReinforcementAdditions.map(reinforcementAddition => getDisplayOptionCodeText(reinforcementAddition.reinforcementAddition.DisplayOptionCode) + reinforcementAddition.reinforcementAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Elbow Reinforcement',
                    getLabels: (quote) => {
                        return quote.legacy_quote_reinforcements.filter(reinforcement => {
                            return reinforcement.quoteReinforcement.ReinforcementTypeId === '2' && reinforcement.quoteReinforcement.ReinforcementLocationId === '1';
                        }).map(reinforcement => {
                            return [getDisplayOptionCodeText(reinforcement.reinforcement.DisplayOptionCode) + reinforcement.reinforcement.Description, ...reinforcement.quoteReinforcementAdditions.map(reinforcementAddition => getDisplayOptionCodeText(reinforcementAddition.reinforcementAddition.DisplayOptionCode) + reinforcementAddition.reinforcementAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Pattern Options',
            subTotalTitle: 'Pattern Options',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Pattern Options',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousTypeId === '2' && miscOption.miscellaneousOption.MiscellaneousLocationId === '1';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Liner Options',
            subTotalTitle: 'Liner Options',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Liner Options',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousTypeId === '3' && miscOption.miscellaneousOption.MiscellaneousLocationId === '1';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Flash Light Holders',
            subTotalTitle: 'Flash Light Holders',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Flash Light Holders',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '10' && miscOption.miscellaneousOption.MiscellaneousLocationId === '1';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Hooks, Clips, & Straps',
            subTotalTitle: 'Hooks, Clips, & Straps',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Mic Clips',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '6' && miscOption.miscellaneousOption.MiscellaneousLocationId === '1';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Fabric Straps',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '7' && miscOption.miscellaneousOption.MiscellaneousLocationId === '1';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Hooks, D-Rings, & Glove Straps',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return (miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '9' || miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '16') && miscOption.miscellaneousOption.MiscellaneousLocationId === '1';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Takeup Straps',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '8' && miscOption.miscellaneousOption.MiscellaneousLocationId === '1';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Chest Left Pocket',
            subTotalTitle: 'Chest Left Pocket',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Chest Left Pocket',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '1' && pocket.quotePocket.SideId === '1';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Chest Right Pocket',
            subTotalTitle: 'Chest Right Pocket',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Chest Right Pocket',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '1' && pocket.quotePocket.SideId === '2';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Hand Left Pocket',
            subTotalTitle: 'Hand Left Pocket',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Hand Left Pocket',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '2' && pocket.quotePocket.SideId === '1';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Hand Right Pocket',
            subTotalTitle: 'Hand Right Pocket',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Hand Right Pocket',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '2' && pocket.quotePocket.SideId === '2';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Sleeve Left Pockets',
            subTotalTitle: 'Sleeve Left Pockets',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Sleeve Left',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '3' && pocket.quotePocket.SideId === '1';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Sleeve Right Pocket',
            subTotalTitle: 'Sleeve Right Pocket',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Sleeve Right Pocket',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '3' && pocket.quotePocket.SideId === '2';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Lettering',
            subTotalTitle: 'Lettering',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Lettering',
                    getLabels: (quote) => {
                        return quote.legacy_quote_lettering.map(lettering => {
                            return [
                                `"${lettering.quoteLettering.CharPayload}"`,
                                'Position '+ lettering.letteringPlacement.LetteringPlacementId,
                                lettering.lettering.Description,
                                getDisplayOptionCodeText(lettering.coatPatchOptionPatch.DisplayOptionCode) + lettering.coatPatchOptionPatch.Description,
                                ...lettering.childrenLettering.map(childLettering => {
                                    return [
                                        `"${childLettering.quoteLettering.CharPayload}"`,
                                        'Position '+ childLettering.letteringPlacement.LetteringPlacementId,
                                        childLettering.lettering.Description,
                                        getDisplayOptionCodeText(childLettering.coatPatchOptionPatch.DisplayOptionCode) + childLettering.coatPatchOptionPatch.Description,].join(', ');
                                })].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Flags & ID Tags',
            subTotalTitle: 'Flags & ID Tags',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Patches & Labels',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousTypeId === '7' && miscOption.miscellaneousOption.MiscellaneousLocationId === '1';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Custom Options',
            subTotalTitle: 'Custom Options',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Custom Options',
                    getLabels: (quote) => {
                        return quote.legacy_quote_custom_options.filter(customOption => {
                            return customOption.IsCoat === '1';
                        }).map(customOption => customOption.Description)
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Coat Special Requests',
            subTotalTitle: 'Special Requests',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Special Requests',
                    getLabels: (quote) => {
                        return quote.legacy_quote_quote.CoatSpecialRequest;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
    ];

    const pantOptionCategories = [
        {
            title: 'Pant Rise',
            subTotalTitle: 'Pant Rise',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Pant Rise',
                    getLabels: (quote) => {
                        return getDisplayOptionCodeText(quote.legacy_quote_pant_rise.DisplayOptionCode) + quote.legacy_quote_pant_rise.Description
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Rear Panel',
                    getLabels: (quote) => {
                        return getDisplayOptionCodeText(quote.legacy_quote_rear_panel.DisplayOptionCode) + quote.legacy_quote_rear_panel.Description
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Panel Length',
                    getLabels: (quote) => {
                        return getDisplayOptionCodeText(quote.legacy_quote_rear_panel_length.DisplayOptionCode) + quote.legacy_quote_rear_panel_length.Description
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Materials',
            subTotalTitle: 'Materials',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Outer Shell',
                    getLabels: (quote) => {
                        return `${getDisplayOptionCodeText(quote.legacy_quote_outer_shell.DisplayOptionCode)}${quote.legacy_quote_outer_shell.Description}, ${getDisplayOptionCodeText(quote.legacy_quote_outer_shell_color.DisplayOptionCode)}${quote.legacy_quote_outer_shell_color.Description}`;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Thermal Liner',
                    getLabels: (quote) => {
                        return `(${quote.legacy_quote_thermal_liner.FabricCode}) ${quote.legacy_quote_thermal_liner.Fabric}`;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Moisture Barrier',
                    getLabels: (quote) => {
                        return `(${quote.legacy_quote_moisture_barrier.FabricCode}) ${quote.legacy_quote_moisture_barrier.Fabric}`;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Closure',
            subTotalTitle: 'Closure',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Closure',
                    getLabels: (quote) => {
                        return getDisplayOptionCodeText(quote.legacy_quote_pant_closure.DisplayOptionCode) + `Inner ${quote.legacy_quote_pant_closure.InnerDescription} | Outer ${quote.legacy_quote_pant_closure.OuterDescription}`;
                    },
                    getPrice: (quote) => {
                        return formatCurrency(quote.legacy_quote_pant_closure_option.MSRP);
                    }
                },
                {
                    title: 'Closure Option',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousTypeId === '6' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return formatCurrency(quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousTypeId === '6' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => miscOption.miscellaneousOption.MSRP)
                            .reduce((a, b) => a + b, 0));
                    }
                },
            ]
        },
        {
            title: 'Pant Trim Style',
            subTotalTitle: 'Trim Style',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Pant Trim',
                    getLabels: (quote) => {
                        return `${getDisplayOptionCodeText(quote.legacy_quote_pant_trim_style.DisplayOptionCode)}${quote.legacy_quote_pant_trim_style.Description}, ${getDisplayOptionCodeText(quote.legacy_quote_pant_trim_color.DisplayOptionCode)}${quote.legacy_quote_pant_trim_color.Description}`;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Trim Additions',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pant_trim_additions.map(pantTrimAddition => {
                            return `${getDisplayOptionCodeText(pantTrimAddition.pantTrimStyle.DisplayOptionCode)}${pantTrimAddition.pantTrimStyle.Description}, ${getDisplayOptionCodeText(pantTrimAddition.pantTrimAddition.Description)}${pantTrimAddition.pantTrimAddition.Description}`;
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Suspenders',
            subTotalTitle: 'Suspenders',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Suspender Attachment Type',
                    getLabels: (quote) => {
                        return getDisplayOptionCodeText(quote.legacy_quote_pant_suspender_option_suspender_attachment_type.DisplayOptionCode) + quote.legacy_quote_pant_suspender_option_suspender_attachment_type.Description
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Suspender Style',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pant_suspender_option_suspender_type.FiredexCode + ' - ' + getDisplayOptionCodeText(quote.legacy_quote_pant_suspender_option_suspender_type.DisplayOptionCode) + quote.legacy_quote_pant_suspender_option_suspender_type.Description
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Harness',
            subTotalTitle: 'Suspenders',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Harness Installment',
                    getLabels: (quote) => {
                        return quote.legacy_quote_reinforcements.filter(reinforcement => {
                            return reinforcement.quoteReinforcement.ReinforcementTypeId === '7' && reinforcement.quoteReinforcement.ReinforcementLocationId === '2';
                        }).map(reinforcement => {
                            return [getDisplayOptionCodeText(reinforcement.reinforcement.DisplayOptionCode) + reinforcement.reinforcement.Description, ...reinforcement.quoteReinforcementAdditions.map(reinforcementAddition => getDisplayOptionCodeText(reinforcementAddition.reinforcementAddition.DisplayOptionCode) + reinforcementAddition.reinforcementAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Harness & Straps',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '4' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Egress Pocket & Descent System Packages',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '3' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Egress Pocket',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '6';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'RIT AL2 Descent Systems',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '5' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Cuff',
            subTotalTitle: 'Cuff',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Cuff',
                    getLabels: (quote) => {
                        return quote.legacy_quote_reinforcements.filter(reinforcement => {
                            return reinforcement.quoteReinforcement.ReinforcementTypeId === '4' && reinforcement.quoteReinforcement.ReinforcementLocationId === '2';
                        }).map(reinforcement => {
                            return [getDisplayOptionCodeText(reinforcement.reinforcement.DisplayOptionCode) + reinforcement.reinforcement.Description, ...reinforcement.quoteReinforcementAdditions.map(reinforcementAddition => getDisplayOptionCodeText(reinforcementAddition.reinforcementAddition.DisplayOptionCode) + reinforcementAddition.reinforcementAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Reinforcements',
            subTotalTitle: 'Reinforcements',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Knee Reinforcement',
                    getLabels: (quote) => {
                        return quote.legacy_quote_reinforcements.filter(reinforcement => {
                            return reinforcement.quoteReinforcement.ReinforcementTypeId === '3' && reinforcement.quoteReinforcement.ReinforcementLocationId === '2';
                        }).map(reinforcement => {
                            return [getDisplayOptionCodeText(reinforcement.reinforcement.DisplayOptionCode) + reinforcement.reinforcement.Description, ...reinforcement.quoteReinforcementAdditions.map(reinforcementAddition => getDisplayOptionCodeText(reinforcementAddition.reinforcementAddition.DisplayOptionCode) + reinforcementAddition.reinforcementAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Crotch Cuff Yoke Reinforcement',
                    getLabels: (quote) => {
                        return quote.legacy_quote_reinforcements.filter(reinforcement => {
                            return reinforcement.quoteReinforcement.ReinforcementTypeId === '5' && reinforcement.quoteReinforcement.ReinforcementLocationId === '2';
                        }).map(reinforcement => {
                            return [getDisplayOptionCodeText(reinforcement.reinforcement.DisplayOptionCode) + reinforcement.reinforcement.Description, ...reinforcement.quoteReinforcementAdditions.map(reinforcementAddition => getDisplayOptionCodeText(reinforcementAddition.reinforcementAddition.DisplayOptionCode) + reinforcementAddition.reinforcementAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Belts',
            subTotalTitle: 'Belts',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Belts',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '11' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).reduce((acc, miscOption, index) => {
                            const existingIndex = acc.findIndex((element) => miscOption.miscellaneousOption.MiscellaneousOptionId === element.miscellaneousOption.MiscellaneousOptionId && miscOption.quoteMiscellaneous.Side === element.quoteMiscellaneous.Side);
                            if(existingIndex >= 0) {
                                acc[existingIndex].quantity = acc[existingIndex].quantity + 1;
                            } else {
                                acc[index] = {...miscOption, quantity: 1};
                            }

                            return acc;
                        }, []).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes) + (miscOption.quantity > 1 ? (' x' + miscOption.quantity) : ''))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Pattern Options',
            subTotalTitle: 'Pattern Options',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Pattern Options',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousTypeId === '2' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Liner Options',
            subTotalTitle: 'Liner Options',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Liner Options',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousTypeId === '3' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Hooks, Clips, & Straps',
            subTotalTitle: 'Hooks, Clips, & Straps',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Mic Clips',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '6' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Fabric Straps',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '7' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Hooks, D-Rings, & Glove Straps',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return (miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '9' || miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '16') && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
                {
                    title: 'Takeup Straps',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousSubTypeId === '8' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Front Left Pocket',
            subTotalTitle: 'Pant Front Left Pocket',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Pant Front Left Pocket',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '4' && pocket.quotePocket.SideId === '1';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Front Right Pocket',
            subTotalTitle: 'Pant Front Right Pocket',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Pant Front Right Pocket',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '4' && pocket.quotePocket.SideId === '2';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Back Left Pocket',
            subTotalTitle: 'Pant Back Left Pocket',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Pant Back Left Pocket',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '5' && pocket.quotePocket.SideId === '1';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Back Right Pocket',
            subTotalTitle: 'Pant Back Right Pocket',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Pant Back Right Pocket',
                    getLabels: (quote) => {
                        return quote.legacy_quote_pockets.filter(pocket => {
                            return pocket.quotePocket.PocketLocationId === '5' && pocket.quotePocket.SideId === '2';
                        }).map(pocket => {
                            return [getDisplayOptionCodeText(pocket.pocket.DisplayOptionCode) + pocket.pocket.Description, ...pocket.quotePocketAdditions.map(pocketAddition => getDisplayOptionCodeText(pocketAddition.pocketAddition.DisplayOptionCode) + pocketAddition.pocketAddition.Description)].join(', ')
                        }).join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Flags & ID Tags',
            subTotalTitle: 'Pant & ID Tags',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Patches & Labels',
                    getLabels: (quote) => {
                        return quote.legacy_quote_miscellaneous_options.filter(miscOption => {
                            return miscOption.miscellaneousOptionMiscellaneous.MiscellaneousTypeId === '7' && miscOption.miscellaneousOption.MiscellaneousLocationId === '2';
                        }).map(miscOption => getDisplayOptionCodeText(miscOption.miscellaneousOptionMiscellaneous.DisplayOptionCode) + miscOption.miscellaneousOptionMiscellaneous.Description  + getSideText(miscOption.quoteMiscellaneous.Side)  + getSideText(miscOption.quoteMiscellaneous.PlacementNotes))
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Custom Options',
            subTotalTitle: 'Custom Options',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Custom Options',
                    getLabels: (quote) => {
                        return quote.legacy_quote_custom_options.filter(customOption => {
                            return customOption.IsPant === '1';
                        }).map(customOption => customOption.Description)
                            .join(', ');
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
        {
            title: 'Pant Special Requests',
            subTotalTitle: 'Special Requests',
            getSubTotal: (quote) => {
                return '$2448';
            },
            options: [
                {
                    title: 'Special Requests',
                    getLabels: (quote) => {
                        return quote.legacy_quote_quote.PantSpecialRequest;
                    },
                    getPrice: (quote) => {
                        return '$1337';
                    }
                },
            ]
        },
    ];

    const categoryFilter = optionCategory => {
        for(let option of optionCategory.options) {
            const labels = option.getLabels(quote);
            if(labels && !labels.includes('undefined')) {
                return true;
            }
        }

        return false;
    };

    /**
     * Fetch Legacy Quote
     */

    useEffect(() => {
        setIsFetching(true);
        http().get(`${API_ROOT}/legacy-quotes/${match.params?.legacyQuoteId}`)
            .then(({data}) => {
                setQuote(data.legacyQuote);
                setIsFetching(false);
            });
    }, [match.params]);

    return (
        <Screen
            headerContent={<WelcomeHeader/>}
            loading={isFetching}
        >
            {!isFetching && quote && (
                <Card title='Legacy Quotes'>
                    <LegacyQuoteListItem item={quote} link={false}/>
                    <div className={classNames({
                        [styles.legacyQuoteDetailsContainer]: true
                    })}>
                        {quote.legacy_quote_quote.IsCoatQuote === '1' &&
                        coatOptionCategories.filter(categoryFilter).map(optionCategory => {
                            return (
                                <LegacyQuoteOptionCategories key={optionCategory.title} optionCategory={optionCategory} quote={quote}/>
                            );
                        })}
                        {quote.legacy_quote_quote.IsPantQuote === '1' &&
                        pantOptionCategories.filter(categoryFilter).map(optionCategory => {
                            return (
                                <LegacyQuoteOptionCategories key={optionCategory.title} optionCategory={optionCategory} quote={quote}/>
                            );
                        })}
                    </div>
                </Card>
            )}

        </Screen>
    );
};

export default LegacyQuote;
