import Login from '@screens/Auth/Login';
import Forgot from '@screens/Auth/Forgot';
import Reset from '@screens/Auth/Reset';
import Invitation from '@screens/Auth/Invitation';
import VerificationSent from '@screens/Auth/VerificationSent';
import Activate from '@screens/Auth/Activate';
import Dashboard from '@screens/Dashboard/Dashboard';
import Notifications from '@screens/Notifications/Notifications';
import Products from '@screens/Products/Products';
import Resources from '@screens/Resources/Resources';
import Quotes from '@screens/Quotes/Quotes';
import Orders from '@screens/Orders/Orders';
import Order from '@screens/Order/Order';
import Quote from '@screens/Quote/Quote';
import CreateAccount from '@screens/CreateAccount/CreateAccount';
import MyAccount from '@screens/MyAccount/MyAccount';
import Home from '@screens/Home/Home';
import RequestQuote from '@screens/RequestQuote/RequestQuote';
import RequestQuoteConfirmation from '@screens/RequestQuoteConfirmation/RequestQuoteConfirmation';
import RequestQuoteFrame from '@screens/RequestQuoteFrame/RequestQuoteFrame';
import SketchConfigurator from '@screens/SketchConfigurator/SketchConfigurator';
import CSROrders from '@screens/CSROrders/CSROrders';
import Sketch from '@screens/Sketch/Sketch';
import SketchDownload from '@screens/SketchDownload/SketchDownload';
import LegacyQuotes from '@screens/LegacyQuotes/LegacyQuotes';
import LegacyQuote from '@screens/LegacyQuote/LegacyQuote';
import OrderTerms from "@screens/OrderTerms/OrderTerms";

/**
 * Authentication Routes (Login, Forgot Password, etc.)
 */

export const authRoutes = [
	{
		path: '/login',
		component: Login,
		exact: true,
	},
	{
		path: '/forgot',
		component: Forgot,
		exact: true,
	},
	{
		path: '/reset',
		component: Reset,
		exact: true,
	},
	{
		path: '/verify',
		component: VerificationSent,
	},
	{
		path: '/activate',
		component: Activate,
	},
	{
		path: '/invitation',
		component: Invitation,
	},
];

/**
 * Public Routes
 */

export const publicRoutes = [
	{
		path: '/',
		component: Home,
		exact: true,
	},
	{
		path: '/create-account',
		component: CreateAccount,
	},
	{
		path: '/products/all',
		component: Products,
	},
	{
		path: '/request-quote/success',
		component: RequestQuoteConfirmation,
	},
	{
		path: '/request-quote',
		component: RequestQuote,
	},
	{
		path: '/request-quote-frame',
		component: RequestQuoteFrame,
	},
	{
		path: '/sketch-configurator/product-option-image-style/:productOptionImageStyleId',
		component: SketchConfigurator,
	},
	{
		path: '/sketch-configurator/lettering-placement-style/:letteringPlacementStyleId',
		component: SketchConfigurator,
	},
	{
		path: '/sketch/:quoteId',
		component: Sketch,
	},
	{
		path: '/terms-and-conditions',
		component: OrderTerms,
	},
	{
		path: '/sketch-download/:quoteId',
		component: SketchDownload,
	}
];

/**
 * Standard/Distributor User Routes
 */

export const userRoutes = [
	{
		path: '/dashboard',
		component: Dashboard,
	},
	{
		path: '/notifications',
		component: Notifications,
	},
	{
		path: '/products',
		component: Products,
		exact: true,
	},
	{
		path: '/resources',
		component: Resources,
	},
	{
		path: '/favorites',
		component: Resources,
	},
	{
		path: '/quotes',
		component: Quotes,
	},
	{
		path: '/orders',
		component: Orders,
		exact: true,
	},
	{
		path: '/orders/:orderId',
		component: Order,
	},
	{
		path: '/quote/:quoteId',
		component: Quote,
		exact: true,
	},
	{
		path: '/my-account',
		component: MyAccount,
	},
	{
		path: '/legacy-quotes/:legacyQuoteId',
		component: LegacyQuote,
	},
	{
		path: '/legacy-quotes',
		component: LegacyQuotes,
	},
];

/**
 * CSR User Routes
 */

export const csrUserRoutes = [
	{
		path: '/csr-dashboard',
		component: CSROrders,
	},
	{
		path: '/notifications',
		component: Notifications,
	},
	{
		path: '/products',
		component: Products,
		exact: true,
	},
	{
		path: '/resources',
		component: Resources,
	},
	{
		path: '/favorites',
		component: Resources,
	},
	{
		path: '/quotes',
		component: Quotes,
	},
	{
		path: '/orders',
		component: Orders,
		exact: true,
	},
	{
		path: '/orders/:orderId',
		component: Order,
	},
	{
		path: '/quote/:quoteId',
		component: Quote,
		exact: true,
	},
	{
		path: '/my-account',
		component: MyAccount,
	},
	{
		path: '/dashboard',
		component: Dashboard,
	},
	{
		path: '/legacy-quotes/:legacyQuoteId',
		component: LegacyQuote,
	},
	{
		path: '/legacy-quotes',
		component: LegacyQuotes,
	},
];

/**
 * Initial Route - Redirect after login
 */

export const initialRoute = '/dashboard';
