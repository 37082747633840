export const range = function (size, startAt = 0) {
	return [...Array(size).keys()].map((i) => i + startAt);
};

export function getProductOptionValueLabel(productOptionValue) {
	return productOptionValue.product_option_value_label;
}

export function getProductOptionDisplayCode(productOptionValue) {
	return productOptionValue.product_option_value_display_option_code;
}

export const getOrderStatusText = function (status) {
	if (status === 'Cancelled') {
		return 'Approved';
	}

	return status;
};

export const getQuoteDisplayNumber = function (quote) {
	if(quote.quote_exactly_as_original == 1) {
		return quote.quote_original_id;
	}

	return quote.quote_id;
};

export const capitalize = function (string) {
	return string[0].toUpperCase() + string.slice(1).toLowerCase();
}
