import styles from "@screens/Quotes/components/QuotesListItem/QuotesListItem.module.scss";
import {API_ROOT} from "@config";
import React from "react";

function VariableProductImage({images, selectedProductOptionValues, defaultImage}) {
    let variableImage;

    //Search for most specific image that matches
    for(let image of images.sort((imageA, imageB) => (imageB.productOptionValues.length - imageA.productOptionValues.length))) {
        let allProductOptionValuesFound = true;
        for(let productOptionValue of image.productOptionValues) {
            //If option for product image not found, skip to the next one
            if(selectedProductOptionValues.findIndex(selectedProductOptionValue => selectedProductOptionValue.product_option_value_id === productOptionValue.product_option_value_id) < 0) {
                allProductOptionValuesFound = false;
                break;
            }
        }

        //Take first image where all options are found. May be the first image with no options, which would be the default.
        if(allProductOptionValuesFound) {
            variableImage = image;
            break;
        }
    }

    if(!variableImage) {
        variableImage = {product_image_file_url: defaultImage}
    }

    return (

        <div
            className={styles.image}
            style={{
                backgroundImage: `url("${API_ROOT}${variableImage.product_image_file_url}")`,
            }}
        />
    );
}

export default VariableProductImage;
