import React from "react";
import styles from "../LegacyQuote.module.scss";

const LegacyQuoteOptionCategories = ({optionCategory, quote}) => {

    return (
        <section className={styles.legacyQuoteDetailsSection}>
            <h5>{optionCategory.title}</h5>
            <ul>
                {
                    optionCategory.options.filter(option => {
                        const labels = option.getLabels(quote);
                        return labels && !labels.includes('undefined');
                    }).map(option => {

                        return (
                            <li key={option.title}>
                                <div>
                                    <span className={styles.optionTitle}>
                                        {option.title}
                                    </span>
                                    <span className={styles.optionValue}>
                                        {option.getLabels(quote)}
                                    </span>
                                </div>
                                {/*<span className={styles.optionPrice}>*/}
                                {/*    {option.getPrice(quote)}*/}
                                {/*</span>*/}
                            </li>
                        );
                    })
                }
                {/*<li>*/}
                {/*    <div>*/}
                {/*        <span className={styles.optionTitle}>*/}
                {/*            {optionCategory.subTotalTitle} Subtotal*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <span className={styles.optionPrice}>*/}
                {/*        {optionCategory.getSubTotal(quote)}*/}
                {/*    </span>*/}
                {/*</li>*/}
            </ul>
        </section>
    );
}

export default LegacyQuoteOptionCategories;
