import { useCallback, useEffect, useRef, useState } from 'react';
import Logo from '@components/Logo/Logo';
import Button from '@components/Button/Button';
import { API_ROOT } from '@config';
import Http from '@utilities/Http';
import { useSelector } from 'react-redux';
import styles from './OrganizationLogoForm.module.scss';

const OrganizationLogoForm = () => {
	const { user } = useSelector((state) => state.auth);

	const [data, setData] = useState({
		organization_logo: user?.organization_logo,
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const fileInputNode = useRef(null);
	const [isUploading, setIsUploading] = useState(false);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(async () => {
		setIsSubmitting(true);

		try {
			await Http().post(`${API_ROOT}/organizations/update`, {
				organization: {
					organization_id: user.organization_id,
					organization_logo: data.organization_logo,
				},
			});
		} finally {
			setIsSubmitting(false);
		}
	}, [data, user]);

	/**
	 * Handle File Select
	 */

	const handleFileSelect = useCallback(async (e) => {
		setIsUploading(true);

		try {
			const { files } = e.target;

			const formData = new FormData();

			Array.from(files).forEach((file, f) => {
				formData.append(`files[${f}]`, file);
			});

			const { data } = await Http().post(
				`${API_ROOT}/organizations/upload-logo`,
				formData,
			);

			setData((current) => ({
				...current,
				organization_logo: data.organization_logo[0],
			}));
		} catch (error) {
			console.log(error);
		} finally {
			setIsUploading(false);
		}
	}, []);

	/**
	 * Initialize File Input Node
	 */

	useEffect(() => {
		const node = document.createElement('input');
		node.type = 'file';
		node.accept = 'image/*';
		node.onchange = handleFileSelect;

		fileInputNode.current = node;
	}, [handleFileSelect]);

	useEffect(() => {
		setData({ organization_logo: user.organization_logo });
	}, [user]);

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				{!!data.organization_logo && (
					<div className={styles.logoContainer}>
						<Logo user={data} className={styles.logo} />
					</div>
				)}
				<div className={styles.actions}>
					<Button
						variant="plain"
						onClick={() => fileInputNode.current?.click()}
						loading={isUploading}>
						Upload New Logo
					</Button>
					{!!data.organization_logo && (
						<Button
							variant="plain"
							onClick={() =>
								setData((data) => ({
									...data,
									organization_logo: null,
								}))
							}>
							Remove Logo
						</Button>
					)}
				</div>
			</div>
			<Button
				variant="solid"
				onClick={handleSubmit}
				loading={isSubmitting}
				className={styles.submitButton}>
				Save Changes
			</Button>
		</div>
	);
};

export default OrganizationLogoForm;
