import { useEffect } from 'react';
import { fetchUser } from '@store/thunks/fetch-user';
import Card from '@components/Card/Card';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsListItem from '@components/NotificationsListItem/NotificationsListItem';
import cardStyles from '@components/Card/Card.module.scss';
import styles from './News.module.scss';

const News = () => {
	const dispatch = useDispatch();
	const notifications = useSelector(
		(state) => state.notifications?.summary || [],
	);
	const isRefreshing = useSelector((state) => state.auth.isFetchingUser);

	// refresh notifications summary
	useEffect(() => {
		dispatch(fetchUser());
	}, [dispatch]);

	return (
		<Card
			title="News & Notifications"
			headerContent={
				<>
					{isRefreshing && <SpinnerIcon className={styles.loader} />}
					<Link
						className={cardStyles.cardHeaderLink}
						to="/notifications">
						View All
					</Link>
				</>
			}>
			<ul className={styles.newsList}>
				{notifications.map((notification, n) => (
					<NotificationsListItem
						key={n}
						item={notification}
						hideMenu={true}
						className="compact"
						actionsClassName={styles.dashboardNotificationActions}
					/>
				))}
			</ul>
		</Card>
	);
};

export default News;
