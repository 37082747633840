import { createSlice } from '@reduxjs/toolkit';

const raqSlice = createSlice({
	name: 'raq',
	initialState: {
		formData: null,
	},
	reducers: {
		setRAQFormData(state, action) {
			return {
				...state,
				formData: action.payload,
			};
		},
	},
});

export const { setRAQFormData } = raqSlice.actions;
export default raqSlice.reducer;
