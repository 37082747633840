import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			viewBox="0 0 1792 1792"
			width={13}
			height={13}
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path xmlns="http://www.w3.org/2000/svg" d="M912 1696q0-16-16-16-59 0-101.5-42.5T752 1536q0-16-16-16t-16 16q0 73 51.5 124.5T896 1712q16 0 16-16zm816-288q0 52-38 90t-90 38h-448q0 106-75 181t-181 75-181-75-75-181H192q-52 0-90-38t-38-90q50-42 91-88t85-119.5 74.5-158.5 50-206T384 576q0-152 117-282.5T808 135q-8-19-8-39 0-40 28-68t68-28 68 28 28 68q0 20-8 39 190 28 307 158.5T1408 576q0 139 19.5 260t50 206 74.5 158.5 85 119.5 91 88z"/>
		</svg>
	);
}

export default SvgComponent;
