import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			width={9}
			height={10}
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path d="M.775 4.52L0 3.914 4.28.585l4.281 3.33-.78.604-3.5 2.726zm3.5 3.933l3.51-2.73.776.604-4.28 3.329L0 6.326l.77-.599 3.506 2.726z" />
		</svg>
	);
}

export default SvgComponent;
