import {useCallback} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { setError } from '@store/reducers/error';

export const useErrorAlert = () =>  {
	const dispatch = useDispatch();
	const error = useSelector((state) => state.error.error);
	const setErrorAlert = useCallback(
		(value) => {
			dispatch(setError(value));
		},
		[dispatch],
	);

	return [error, setErrorAlert];
};