import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Route } from 'react-router';
import PrivateRoute from '@components/PrivateRoute';
import AuthRoute from '@components/Auth/AuthRoute';
import {
	authRoutes,
	userRoutes,
	csrUserRoutes,
	publicRoutes,
} from '@config/routes';
import { useSelector } from 'react-redux';
import SizerStandalone from '@screens/SizerStandalone/SizerStandalone';

const Router = () => {
	const { user } = useSelector((state) => state.auth);
	const isCSRUser = !!user?.permissions?.includes('csr_access');

	return (
		<BrowserRouter>
			<div className="App">
				<Switch>
					<Route path="/sizer_invite"
						component={SizerStandalone}
					/>
					{authRoutes.map((route, r) => (
						<AuthRoute
							key={r}
							path={route.path}
							component={route.component}
							exact
						/>
					))}
					{!isCSRUser &&
						userRoutes.map((route, r) => (
							<PrivateRoute
								key={r}
								path={route.path}
								component={route.component}
								exact={!!route.exact}
							/>
						))}
					{isCSRUser &&
						csrUserRoutes.map((route, r) => (
							<PrivateRoute
								key={r}
								path={route.path}
								component={route.component}
								exact={!!route.exact}
							/>
						))}
					{publicRoutes.map((route, r) => (
						<Route
							key={r}
							path={route.path}
							component={route.component}
							exact={!!route.exact}
						/>
					))}
				</Switch>
			</div>
		</BrowserRouter>
	);
};

export default Router;
