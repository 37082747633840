import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setDemoModeActive } from '@store/reducers/demo-mode';
import Switch from '@components/Switch/Switch';
import PlusIcon from '@components/Icons/PlusIcon';
import Button from '@components/Button/Button';
import NewOrderModal from '@components/NewOrderModal/NewOrderModal';
import styles from './WelcomeHeader.module.scss';
import NewQuoteModal from '@components/NewQuoteModal/NewQuoteModal';

const WelcomeHeader = ({
	showQuote = true
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector((state) => state.auth.user);
	const [demoModeActive, demoModeVisible] = useSelector((state) => [
		state.demoMode.active,
		state.demoMode.visible,
	]);
	const [modalActive, setModalActive] = useState(false);
	const [newQuoteModalActive, setNewQuoteModalActive] = useState(false);
	const headerTitle = useSelector(
        (state) => state.staticData.orderSettings['app_welcome_header_title']|| "",
    );
	/**
	 * Handle 'New Order' Modal Close
	 */

	const handleModalClose = useCallback(
		(order) => {
			if (order) {
				history.push(`/orders/${order.order_id}`);
			}

			setModalActive(false);
		},
		[history],
	);

	/**
	 * Handle 'New Order' Modal Close
	 */

	const handleNewQuoteModalClose = useCallback(
		(order) => {
			if (order) {
				history.push(`/orders/${order.order_id}`);
			}

			setNewQuoteModalActive(false);
		},
		[history],
	);

	return (
		<header className={styles.welcomeHeader}>
			<div className={styles.welcomeHeaderContent}>
				<div className={styles.welcomeHeaderComponent}>
					<h5>
						<b>{headerTitle}</b>
					</h5>
					{demoModeVisible && (
						<div className={styles.demoModeToggle}>
							<Switch
								label="Demo Mode"
								value={demoModeActive}
								onChange={(value) => {
									dispatch(setDemoModeActive(value));
								}}
							/>
						</div>
					)}
				</div>
				<div className={styles.welcomeHeaderComponent}>
					{user?.permissions?.includes('manage_orders') && (
						<Button
							variant="outlined-alt"
							className={styles.ctaButton}
							adornment={<PlusIcon />}
							onClick={() => {
								setModalActive(true);
							}}>
							New Order
						</Button>
					)}
					{showQuote == true &&
					<Button
						variant="outlined-alt"
						className={styles.ctaButton}
						adornment={<PlusIcon />}
						onClick={() => {
							setNewQuoteModalActive(true);
						}}>
						New Quote
					</Button>}
				</div>
			</div>
			<NewOrderModal active={modalActive} onClose={handleModalClose} />
			<NewQuoteModal
				active={newQuoteModalActive}
				onClose={handleNewQuoteModalClose}
			/>
		</header>
	);
};

export default WelcomeHeader;
