import * as React from 'react';

function SvgComponent(props) {
	const fill = props.fill || '#FFF';
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 10.624 8.099"
			{...props}>
			<path
				data-name="Path 1179"
				d="M3.376 6.39L.858 3.871 0 4.723l3.376 3.376L10.624.851l-.852-.852z"
				fill={fill}
			/>
		</svg>
	);
}

export default SvgComponent;
