import React from 'react';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import styles from './RootLoader.module.scss';

const RootLoader = () => {
	const authSettled = useSelector((state) => state.auth.authSettled);

	return (
		<div
			className={classNames({
				[styles.container]: true,
				[styles.active]: !authSettled,
			})}>
			<SpinnerIcon fill="#e87124" />
		</div>
	);
};

export default RootLoader;
