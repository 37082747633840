import React, { useEffect,useState } from 'react';

import styles from './SizerStandalone.module.scss';
import { useQueryParams } from '@hooks/useQueryParams';
import VirtualSizerForm from '@components/VirtualSizer/VirtualSizerFom';
import Screen from '@components/Screen/Screen';
import { API_ROOT } from '@config';
import Http from '@utilities/Http';
import AuthHeader from '@components/Auth/AuthHeader';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

export default function SizerStandalone({location}) {
	const content = useSelector((state) => state.staticData.orderSettings);
	const {invite} = useQueryParams(location);
	const [quote,setQuote] = useState(null);
	const [submitted,setIsSubmitted] = useState(false);
	useEffect(() => {
		if (invite) {
			Http().get(API_ROOT + '/sizing/get-sizer-invite?invite_token='+invite)
				.then(response => {
					if(response.data?.invite){
						setQuote(response.data.invite);
					}
				})
				.catch(error => {
					console.log(error);
				}
			);
		}
	}, [invite]);

	const submitHandler = (data) => {
		setIsSubmitted(true);
		data['sizer_invite_token'] = invite;
		Http().post(API_ROOT + '/sizing/submit-sizer-invite-response/'+data['quote_id'], data)
			.then(response => {
				alert(response?.data?.message||'Your Sizing info has been submitted successfully');
			})
			.catch(error => {
				setIsSubmitted(false);
				console.log(error);
			}
		);
	};

	return (
		<Screen
				hideNavigation={true}
				hideFooter={true}
				wrapperClassName={styles.whiteBg}
				headerContent={<AuthHeader />}>
				<div className={classNames({[styles.standaloneHeader]:true})} >
					<div>
						<h1>{content.virtual_sizer_title}</h1>
						{content?.virtual_sizer_description && 
						<p dangerouslySetInnerHTML={{
							__html: content.virtual_sizer_description,
						}}>	
						</p>
						}
				
					</div>
				</div>
			<VirtualSizerForm closeHander={submitHandler} quote={quote} submitted={submitted} />
		</Screen>
	);
}