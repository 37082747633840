import { useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import FieldLabel from '../FieldLabel/FieldLabel';
import FieldValue from '../FieldValue/FieldValue';
import { formatters } from '../formatters';
import { useWindowSize } from '@hooks/useWindowSize';
import styles from './MobileLayout.module.scss';

const ColumnForm = ({
	index,
	schema = {},
	ranges = {},
	data = {},
	handleChange = () => {},
	collapsed = true,
	highlightIncomplete = false,
	incomplete = [],
	invalid = [],
	isEditable = true,
	isSizeZero = {},
}) => {
	const dimensions = useWindowSize();
	const node = useRef(null);
	const [contentHeight, setContentHeight] = useState(0);

	/**
	 * Update content height when window dimensions change
	 */

	useLayoutEffect(() => {
		setContentHeight(node.current?.clientHeight);
	}, [dimensions]);

	return (
		<div
			onClick={(e) => e.stopPropagation()}
			className={classNames({
				[styles.columnFormContainer]: true,
				[styles.collapsed]: collapsed,
			})}
			style={{ maxHeight: `${contentHeight}px` }}>
			<div
				ref={node}
				className={classNames({ [styles.columnForm]: true })}>
				{Object.keys(schema).map((key, i) => (
					<div className={styles.columnFormField} key={i}>
						<FieldLabel label={schema[key].sizing_field_title} />
						<FieldValue
							field={schema[key]}
							value={data[key] || ''}
							onChange={(value) => handleChange({ [key]: value })}
							formatter={
								formatters[schema[key].sizing_field_type]
							}
							highlight={
								highlightIncomplete &&
								(incomplete[index]?.includes(key) ||
									invalid[index].includes(key))
							}
							formData={data}
							invalid={invalid[index].includes(key)}
							range={ranges[key]}
							isEditable={isEditable}
							isSizeZero={isSizeZero}
							rowIndex={index}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default ColumnForm;
