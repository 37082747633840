import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import printJS from 'print-js';
import Card from '@components/Card/Card';
import CheckboxField from '@components/CheckboxField/CheckboxField';
import SelectField from '@components/SelectField/SelectField';
import DateField from '@components/DateField/DateField';
import Tooltip from '@components/Tooltip/Tooltip';
import QuotePricing from '@components/QuotePricing/QuotePricing';
import OrderItemPreview from '../OrderItemPreview/OrderItemPreview';
import OrderItemDetails from '../OrderItemDetails/OrderItemDetails';
import Menu from '@components/Menu/Menu';
import CopyToOrderModal from '@components/CopyToOrderModal/CopyToOrderModal';
import { setConfiguratorOptions, setScreen } from '@store/reducers/configurator';
import {getQuoteTotalPrice, splitQuotePrices} from '@utilities/pricing';
import modalStyles from '@components/Modal/Modal.module.scss';
import styles from './OrderItems.module.scss';
import OrderItemActions from '@components/OrderItemActions/OrderItemActions';
import ExportModal from '@components/ExportModal/ExportModal';
import SizingModal from '@components/SizingModal/SizingModal';
import {download, getQuoteExport} from '@utilities/export';
import DialogModal from '@components/DialogModal/DialogModal';
import {isFinalized, isQuoteApproved, isSubmitted} from '@utilities/orders';
import UpdateQuotesPromiseDateModal from '@components/UpdateQuotesPromiseDateModal';
import TextAreaField from '@components/TextAreaField/TextAreaField';
import LoadingMore from '@components/Collection/LoadingMore/LoadingMore';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import {getQuoteDisplayNumber} from "@utilities/methods";
import {store} from "@store";

const OrderItem = ({
	quote,
	siblingQuote = null,
	allSiblings = null,
	updateQuote = async () => {},
	removeQuote = async () => {},
	copyQuote = async () => {},
	isEditable = false,
	orderQuotes = [],
    organizationContracts = [],
	isApproving = false,
	setIsApproving = () => {},
	isUpdating = false,
	setIsUpdating = () => {},
	isSubmittingTCM = false
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useSelector((state) => state.auth.user);
	const isCSRUser = user.permissions.includes('csr_access');

	const demoModeActive = useSelector((state) => state.demoMode.active);
	const { orderSettings } = useSelector(
		(state) => state.staticData,
	);

	const [isMounted, setIsMounted] = useState(true);
	const [copyModalActive, setCopyModalActive] = useState(false);
	const [exportModalActive, setExportModalActive] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [sizingQuoteId, setSizingQuoteId] = useState(null);
	const [showDetails, setShowDetails] = useState(false);
	const [expedite, setExpedite] = useState(false);

	// loaders
	const [, setIsRemoving] = useState(false);
	const [isCopying, setIsCopying] = useState(false);

	// custom expedite tooltip
	const tooltipTimeout = useRef();
	const [tooltipVisible, setTooltipVisible] = useState(false);

	//Quote to set promise date on other quotes
	const [promiseDateQuote, setPromiseDateQuote] = useState(null);

	const [quoteValidationWarningActive, setQuoteValidationWarningActive] = useState(false);

	/**
	 * Pricing
	 */

	const pricing = useMemo(() => {
		let quantity = quote.product_line_id
			? quote.sizing_quantity
			: quote.quote_product_quantity;
		let splitPrices = null;
		let isAccessory = false;
		if(quote.product_configurable !== '1' && allSiblings) {
			quantity = Number(quantity) + allSiblings.reduce((total, current) => total = total + Number(current.quote_product_quantity), 0);
			quote.allSiblingQuotes = allSiblings;
			splitPrices = splitQuotePrices(quote);
            isAccessory = true;
		}

		return {
			coatPrice: getQuoteTotalPrice(
				quote,
				null,
				null,
				true,
				false,
				true
			),
			pantsPrice: siblingQuote ? getQuoteTotalPrice(
				siblingQuote,
				null,
				false,
				true,
				false,
				true
			) : null,
			totalPrice: getQuoteTotalPrice(
				{ ...quote, quote_discount: 0 },
				siblingQuote ? siblingQuote : null,
				false,
				true,
				false,
				true
			),
			netPrice: getQuoteTotalPrice(
				{ ...quote, quote_discount: 0 },
				siblingQuote ? { ...siblingQuote, quote_discount: 0 } : null,
				false,
				false,
				false,
				false,
				true
			),
			netPriceCoat: getQuoteTotalPrice(
				{ ...quote, quote_discount: 0 },
				null,
				false,
				false,
				false,
				false,
				true
			),
			netPricePants: siblingQuote ? getQuoteTotalPrice(
				{ ...siblingQuote, quote_discount: 0 },
				null,
				false,
				false,
				false,
				false,
				true
			) : null,
			discountPrice: getQuoteTotalPrice(quote, siblingQuote),
			discountAmount: quote.quote_discount_percentage > 0 ? quote.quote_discount_percentage : null,
			quantity: quantity,
			quantityCoat: quote.product_id === '1' ? (quantity > 0 ? quote.sizing_quantity_coat : 1) : quote.sizing_quantity_coat,
			quantityPants: quote.product_id === '1' && siblingQuote ? (quantity > 0 ? siblingQuote.sizing_quantity_pants : 1) : (quote.product_id === '2' ? (quantity > 0 ? quote.sizing_quantity_pants : 1) : quote.sizing_quantity_pants),
			quantityCoveralls: quote.product_id === '3' ? (quantity > 0 ? quote.sizing_quantity_coveralls : 1) : quote.sizing_quantity_coveralls,
			quoteDiscount: parseFloat(quote.quote_discount || 0),
			splitPrices: splitPrices,
            isAccessory: isAccessory,
		};
	}, [quote, siblingQuote, allSiblings]);

	/**
	 * 'Custom Expedite' checkbox value
	 */

	useEffect(() => {
		setExpedite(!!quote.quote_expedite);
	}, [quote.quote_expedite]);

	/**
	 * Handle Expedite Checkbox Change
	 */

	const handleExpediteChange = useCallback(
		async (e) => {
			setExpedite(e.target.value);

			if (e.target.value) {
				// show tooltip
				setTooltipVisible(true);
				// hide tooltip after 3s
				clearTimeout(tooltipTimeout.current);
				tooltipTimeout.current = setTimeout(() => {
					setTooltipVisible(false);
				}, 3000);
			}

			setIsUpdating(true);


			await updateQuote({ ...quote, quote_expedite: e.target.value });
		},
		[quote, updateQuote, setIsUpdating],
	);

	/**
	 * Handle 'Remove from Order'
	 */

	const handleRemove = useCallback(async () => {
		setIsRemoving(true);

		try {
			await removeQuote(quote);
		} finally {
			isMounted && setIsRemoving(false);
		}
	}, [quote, removeQuote, isMounted]);

	/**
	 * Handle Contract Pricing change
	 */

	const handleContractPricingChange = useCallback(
		async (contractId) => {
			setIsUpdating(true);


			await updateQuote({ ...quote, contract_id: contractId });
		},
		[quote, updateQuote, setIsUpdating],
	);

	/**
	 * Handle Discount Percentage Change
	 */

	const handleDiscountPercentageChange = useCallback(
		async (percentage) => {
			setIsUpdating(true);

			quote.quote_discount_percentage = percentage;
			if(siblingQuote) {
				siblingQuote.quote_discount = percentage / 100;
				siblingQuote.quote_net_price_adjustment = null
			}
			if(allSiblings) {
				allSiblings = allSiblings.map((sibling) => {
					sibling.quote_discount = percentage / 100;
					sibling.quote_net_price_adjustment = null;
					return sibling;
				});
			}
			await updateQuote({
				...quote,
				quote_discount: percentage / 100,
				quote_net_price_adjustment: null
			});
		},
		[quote, updateQuote, siblingQuote, allSiblings, setIsUpdating],
	);

	/**
	 * Handle 'Copy to Order' Modal Close
	 */

	const handleCopyModalClose = useCallback(
		(order) => {
			if (order) {
				history.push(`/orders/${order.order_id}`);
			}

			setCopyModalActive(false);
		},
		[history],
	);

	/**
	 * Handle Quote Export
	 */

	const handleQuoteExport = useCallback(
		async (type = 'pdf') => {
			const url = await getQuoteExport(quote.quote_id, type, demoModeActive);
			download(
				url,
				`${getQuoteDisplayNumber(quote).padStart(4, '0')}.${
					type === 'pdf' ? 'pdf' : 'doc'
				}`,
			);
		},
		[quote, demoModeActive],
	);

	/**
	 * Handle PDF Print
	 */

	const handlePrint = useCallback(async () => {
		try {
			const url = await getQuoteExport(quote.quote_id, 'pdf', demoModeActive);

			if (!url) {
				return;
			}

			// print document
			printJS(url);
		} catch (error) {
			const response = error.response;
			const data = response && (await response.data.text());
			const message = data && JSON.parse(data).meta.message;
			setErrorModalMessage(message);
		}
	}, [quote, demoModeActive]);

	const validateQuoteRulesForCopy = useCallback(async () => {
		Http()
			.get(`${API_ROOT}/quotes/validate-quote-rules/${quote.quote_id}`)
			.then((response) => {
				if (response.data.quoteRulesValidated === 1) {
					setCopyModalActive(true);
				} else {
					setQuoteValidationWarningActive(true);
				}
			});
	}, [quote]);

	const handleCopyQuote = useCallback(async (withOrder = true) => {
		setIsCopying(true);

		try {
			await copyQuote(quote, withOrder);
		} finally {
			isMounted && setIsCopying(false);
		}
	}, [quote, copyQuote, isMounted]);

	/**
	 * Menu Actions
	 */

	const menuActions = useMemo(() => {
		const actions = [
			{
				label: 'Export Summary',
				action: () => {
					return setExportModalActive(true);
				},
			},
			{
				label: 'Print Summary',
				action: handlePrint,
			},
			{
				label: 'Sizing Sheet',
				action: () => {
					setSizingQuoteId(quote.quote_id);
				},
			},
			{
				label: 'Copy Quote',
				action: () => {
					return handleCopyQuote(false);
				},
			},
		];

		if (
			isEditable &&
			((isCSRUser && !isFinalized) ||
				!isSubmitted({ order_status: quote.order_status }))
		) {
			actions.unshift({
				label: 'Remove from Order',
				action: handleRemove,
			});
		}

		return actions;
	}, [handleRemove, handlePrint, quote, isCSRUser]);

	/**
	 * Mounted check
	 */

	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		};
	}, []);

	return (
		<>
			<Card
				className={styles.orderItem}
				contentClassName={styles.orderItemContent}>
				<Menu actions={menuActions} className={styles.orderItemMenu} />
				<div className={styles.orderItemInfoContainer}>
					<div
						className={styles.orderItemPreviewContainer}
						onClick={() => {
							if (quote.quote_status === 'approved') {
								return;
							}
							dispatch(
								setConfiguratorOptions({
									quoteId: quote.quote_id,
								}),
							);
						}}>
						{quote.product_line_id ? (
							<OrderItemPreview
								quote={quote}
								orientation="front"
							/>
						) : (
							<div
								className={styles.image}
								style={{
									backgroundImage: `url("${API_ROOT}${quote.product_image_file_url}")`,
								}}
							/>
						)}
					</div>
					<div className={styles.orderItemTitleContainer}>
						<div>
							<p className={styles.orderItemTitle}>
								{quote.quote_name}
							</p>
							<p className={styles.orderItemTitle}>
								{(quote.product_line_id
									? quote.product_line_description + ' '
									: '') +
									(quote.siblingQuote &&
									quote.product_configurable === '1'
										? 'Full Set'
										: quote.product_title)}
							</p>
							<p className={styles.orderItemSubTitle}>
								#{getQuoteDisplayNumber(quote).padStart(4, '0')}
							</p>
						</div>
						<ul className={styles.orderItemLinks}>
							<li onClick={() => setShowDetails(!showDetails)}>
								{showDetails ? 'Hide' : 'Show'} Details
							</li>
							<li
								onClick={() => {
									dispatch(
										setConfiguratorOptions({
											quoteId: quote.quote_id,
										}),
									);
								}}>
								{(isCSRUser || quote.quote_exactly_as_original === '1' || isSubmitted(quote) || isQuoteApproved(quote))
									? 'View Buildout'
									: 'Edit Buildout'}
							</li>
							{
								user.user_id == 1 ?
									<li
										onClick={() => {
											dispatch(
												setConfiguratorOptions({
													quoteId: quote.quote_id,
												}),
											);
											dispatch(
												setScreen('summary'),
											);
										}}>
										Show Summary
									</li>
									: null
							}
							{ isFinalized({ order_status: quote.order_status })
								&& quote.quote_not_for_reorder !== '1' &&  (
									<li onClick={() => validateQuoteRulesForCopy()}>
										Reorder Exact Quote
									</li>
								)}
							{isEditable && (
								<li onClick={() => handleCopyQuote()}>
									{isCopying ? (
										<SpinnerIcon
											fill="#e87124"
											className={styles.copyLoader}
										/>
									) : (
										'Copy Quote'
									)}
								</li>
							)}
						</ul>
					</div>
					{(isEditable || isCSRUser) && (
						<>
							<div className={styles.orderItemActionsContainer}>
								<div>
									{!demoModeActive && (
										<SelectField
											compact
											label="Contract Pricing"
											placeholder="Select Pricing"
											value={quote.contract_id}
											options={organizationContracts}
											name="contract_id"
											onChange={(e) =>
												handleContractPricingChange(
													e.target.value,
												)
											}
											labelField="contract_title"
											valueField="contract_id"
											loading={isUpdating}
											className={styles.orderItemContract}
											disabled={!isEditable}
										/>
									)}
									{isCSRUser && (
										<div>
											<DateField
												compact
												label="Promise Date"
												placeholder="Select Promise Date"
												loading={isUpdating}
												value={
													quote.quote_promised_date
														? quote.quote_promised_date +
														  '12:00:00'
														: ''
												}
												name="quote_promised_date"
												onChange={(e) => {
													setIsUpdating(true);
													const date =
														e.target.value.split(
															' ',
														)[0];
													updateQuote({
														...quote,
														quote_promised_date:
															date,
													});

													for (let orderQuote of orderQuotes.filter(
														(orderQuote) =>
															quote.quote_id !==
															orderQuote.quote_id
															&& orderQuote.sibling_quote_id === null,
													)) {
														if (
															!orderQuote.quote_promised_date
														) {
															updateQuote({
																...orderQuote,
																quote_promised_date:
																	date,
															});
														}
													}
												}}
												disabled={isSubmittingTCM}
											/>
											{orderQuotes.filter(
												(quote) =>
													quote.sibling_quote_id ===
													null,
											).length > 1 &&
												quote.quote_promised_date && (
													<p
														className={
															styles.orderItemInputAction
														}
														onClick={() =>
															setPromiseDateQuote(
																quote,
															)
														}>
														Assign to all?
													</p>
												)}
										</div>
									)}
									{orderSettings.custom_expedite_enabled ===
										'1' &&
										isEditable && (
											<CheckboxField
												value={expedite}
												label="Custom Expedite"
												onChange={handleExpediteChange}
												className={
													styles.orderItemExpediteFlag
												}>
												<Tooltip
													active={
														!!expedite &&
														tooltipVisible
													}
													className={
														styles.expediteTooltip
													}>
													<span>
														{
															orderSettings.custom_expedite_percentage
														}
														% addition will be added
														to this item
													</span>
												</Tooltip>
											</CheckboxField>
										)}
								</div>
							</div>
							{/* {isCSRUser && (
								<div
									className={styles.orderItemActionsContainer}
									style={{
										gridRow: '3/3',
										gridColumn: '1/-1',
									}}>
									<TextAreaField
										label="TCM Line Item Notes"
										name="quote_notes"
										value={quote.quote_notes || ''}
										onChange={(e) =>
											updateQuote({
												...quote,
												quote_notes: e.target.value,
											})
										}
										className={styles.textAreaContainer}
										inputClassName={styles.textarea}
										disabled={isFinalized(quote)}
									/>
								</div>
							)} */}
						</>
					)}
					{!demoModeActive && (
						<QuotePricing
							price={pricing.totalPrice}
							priceCoat={pricing.coatPrice}
							pricePants={pricing.pantsPrice}
							discountPrice={pricing.discountPrice}
							discountAmount={pricing.discountAmount}
							handleDiscountChange={
								handleDiscountPercentageChange
							}
							className={styles.orderItemPricing}
							discountModalClassName={modalStyles.withSidebar}
							isEditable={isEditable}
							quantity={pricing.quantity}
							quantityCoat={pricing.quantityCoat}
							quantityPants={pricing.quantityPants}
							quantityCoveralls={pricing.quantityCoveralls}
							hasSibling={siblingQuote !== null}
							quoteDiscount={pricing.quoteDiscount}
							netPrice={pricing.netPrice}
							netPriceCoat={pricing.netPriceCoat}
							netPricePants={pricing.netPricePants}
							splitPrices={pricing.splitPrices}
                            isAccessory={pricing.isAccessory}
						/>
					)}
				</div>
				{showDetails && (
					<OrderItemDetails
						quote={quote}
						siblingQuote={siblingQuote}
						allSiblings={allSiblings}
					/>
				)}
				<CopyToOrderModal
					active={copyModalActive}
					onClose={handleCopyModalClose}
					quoteId={quote.quote_id}
					className={modalStyles.withSidebar}
				/>
				<ExportModal
					active={exportModalActive}
					onClose={setExportModalActive}
					onPdf={() => handleQuoteExport('pdf')}
					onWord={() => handleQuoteExport('word')}
				/>
				<SizingModal
					quoteId={sizingQuoteId}
					productLineId={quote.product_line_id}
					productIds={[
						quote.product_id,
						siblingQuote?.product_id,
					]}
					active={!!sizingQuoteId}
					onClose={async (refresh = false, quantity_coat = null, quantity_pants = null, quantity_coveralls = null) => {
						if (refresh) {
							if (quote.product_configurable) {
								// Update values of quote in state immediately to update price
								if (quote.product_id === '1') {
									quote.sizing_quantity_coat = quantity_coat;
									if (siblingQuote) {
										siblingQuote.sizing_quantity_pants = quantity_pants;
									}
								} else if (quote.product_id === '2') {
									quote.sizing_quantity_pants = quantity_pants;
								} else {
									quote.sizing_quantity_coveralls = quantity_coveralls;
								}
							}
						}
						await updateQuote({...quote});
						setSizingQuoteId(null);
					}}
					hideBackButton={true}
					isEditable={isEditable && !isCSRUser}
				/>
				<DialogModal
					active={!!errorModalMessage}
					title="Export Failed"
					onConfirm={() => setErrorModalMessage('')}
					message={errorModalMessage}
					labels={['Got It']}
				/>
			</Card>
			{
				(quote.order_status !== 'Not Submitted' &&
					!isFinalized({ order_status: quote.order_status })
					|| isCSRUser) && (
					<OrderItemActions quote={quote} updateQuote={updateQuote} isApproving={isApproving} setIsApproving={setIsApproving} isUpdating={isUpdating} />
				)}
			<UpdateQuotesPromiseDateModal
				active={!!promiseDateQuote}
				onClose={() => setPromiseDateQuote(null)}
				promiseDate={promiseDateQuote?.quote_promised_date}
				quotesToUpdate={orderQuotes.filter(
					(orderQuote) =>
						promiseDateQuote?.quote_id !== orderQuote.quote_id,
				)}
				updateQuote={updateQuote}
			/>
			<DialogModal
				active={quoteValidationWarningActive}
				title="Quote Invalid"
				message="This quote has disabled or invalid option selections. Would you still like to continue?"
				onConfirm={() => {
					setCopyModalActive(true);
					setQuoteValidationWarningActive(false);
				}}
				onClose={() => setQuoteValidationWarningActive(false)}
				labels={['Continue', 'Cancel']}
			/>
		</>
	);
};

export default OrderItem;
