import AuthHeader from '@components/Auth/AuthHeader';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import { useQueryParams } from '@hooks/useQueryParams';

function VerificationSent({ location }) {
	const params = useQueryParams(location);
	return (
		<>
			<AuthHeader />
			<Hero heroTitle="WELCOME TO<br />FIREDEX!" />
			<section className="content-wrapper">
				<div className="content-container flex-center-content">
					<form
						className="form-container"
						onSubmit={(e) => e.preventDefault}>
						<p className="form-text">
							A verification link has been sent to{' '}
							<b>{params.email}</b>.<br />
							<br />
							Please click the link to finish setting up your
							account.
						</p>
					</form>
				</div>
			</section>
			<Footer className="auth-footer" />
		</>
	);
}

export default VerificationSent;
