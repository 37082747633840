import classNames from 'classnames';
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {API_ROOT} from "@config";

const LetteringTextArched = ({
	placementId,
	setPlacement = null,
	item = {},
	getLetteringText = () => {},
	showShape = false,
	charLimit=0,
	placementStyle = null,
}) => {
	const isArched = item.product_option_value_lettering_is_arched === '1';
	const isInverted = item.product_option_value_lettering_is_inverted === '1';
	const viewBox = useCallback(()=>{
		if(item?.product_option_value_lettering_num_rows == '2'){
			return '0 0 200 150';

		}else{
			return '0 0 200 50';
		}
	},[item])
	const pathD = useCallback(()=>{
		if(item?.product_option_value_lettering_num_rows == '2'){
			if (isInverted) {
				return 'M -40 30 A 100 20 0 1 0 240 30';
			} else {
				return 'M -40 100 A 100 20 0 1 1 240 100'
			}

		}else {
			if (isInverted) {
				return 'M -60 15 A 100 15 0 1 0 260 15';
			} else {
				return 'M -60 50 A 100 15 0 1 1 260 50'
			}
		}
	},[isInverted, item]);

	const [fontScalar, setFontScalar] = useState()

	const shapeRef = useRef(null);

	const updateScalar = useCallback(() =>{

		const baseWidth = (parseFloat(placementStyle?.lettering_placement_style_width) / 100) * 450;
		setFontScalar((shapeRef?.current?.clientWidth * 1.4) / baseWidth)
	},[placementStyle])

	const getBaseFontSize = useMemo(()=>{
		const cl = charLimit||getLetteringText(placementId)?.length||8;
		const baseWidth = (parseFloat(placementStyle?.lettering_placement_style_width) / 100) * 450;
		const baseFontSize =  Math.ceil((baseWidth*(1.6/cl)));
		// return baseFontSize;
		return baseFontSize > 75 ? 75 : baseFontSize;

	},[charLimit, getLetteringText, placementId, placementStyle?.lettering_placement_style_width])

	useEffect(() => {
		updateScalar();
		window.addEventListener('resize',updateScalar);

		return ()=>{
			window.removeEventListener('resize',updateScalar);
		}
	},[updateScalar])

	const renderShape = () =>{
		if(!placementStyle){
			return null;
		}
		let image = null;
		if(placementStyle.lettering_placement_style_image){
			image = (<img style={{visibility:(showShape && !getLetteringText(placementId))?'visible':'hidden'}} src={`${API_ROOT}/uploaded/lettering/placement-placeholders/`+placementStyle.lettering_placement_style_image} />)
		}
		return (
			<div ref={shapeRef} className={classNames({
				['no-img']:!placementStyle.lettering_placement_style_image
			})}
				 style={{ width:'100%'}}
			>
				{image}
			</div>
		)
	}

	return (
		<div
			onClick={() =>
				typeof setPlacement === 'function' &&
				(!item.lettering_row_number || item.lettering_row_number == '0') &&
				setPlacement(item)
			}
			className={classNames({
				[`placement-`]: true,
			})}
			style={{
				height:'auto',
				position:'absolute',
				top:placementStyle.lettering_placement_style_top+'%',
				left:placementStyle.lettering_placement_style_left+'%',
				width:placementStyle.lettering_placement_style_width+'%',

			}}
		>
			{renderShape()}
			<svg
				viewBox={viewBox()}
				style={{
					color: item.product_option_value_lettering_color || '#222',
					height:(getLetteringText(placementId) && item?.product_option_value_lettering_num_rows == '2')?'200%':'100%',
					width:'100%',
					position:'absolute',
					top:'15%',
					overflow: 'visible'
				}}>
				<path
					id={"curve" + placementId}
					d={pathD()}
					fill="none"
					onClick={() =>
						typeof setPlacement === 'function' &&
						item.lettering_row_number == '0' &&
						setPlacement(item)
					}
				/>
				<text
					textAnchor="middle"
					fontWeight="bold"
					fontSize={getBaseFontSize + 'px'}
					fontFamily="monospace"
					fill="currentColor"
					className="text">
					<textPath startOffset="50%" xlinkHref={"#curve" + placementId}>
						{getLetteringText(placementId) || placementId}
					</textPath>
				</text>
			</svg>
		</div>
	);
};

export default LetteringTextArched;
