import * as React from 'react';

function SvgComponent(props) {
	const fill = props.fill || '#FFF';
	return (
		<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M9.346 16.887c-4.122 0-7.477-3.355-7.477-7.477 0-4.121 3.355-7.476 7.477-7.476 4.121 0 7.476 3.355 7.476 7.476 0 4.122-3.355 7.477-7.476 7.477zm0-16.823C4.186.064 0 4.251 0 9.41c0 5.16 4.187 9.346 9.346 9.346s9.346-4.187 9.346-9.346S14.505.064 9.346.064z"
				fill={fill}
			/>
			<g>
				<path
					d="M14.024 6.526a.522.522 0 000-.74l-1.227-1.225a.522.522 0 00-.738 0l-.96.959 1.966 1.965zm-9.28 5.35v1.965h1.964l5.796-5.796-1.965-1.965z"
					fill={fill}
				/>
			</g>
		</svg>
	);
}

export default SvgComponent;
