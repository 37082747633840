import Button from '@components/Button/Button';
import styles from './SubmitButton.module.scss';

const SubmitButton = (props) => {
	return (
		<Button variant="outlined" className={styles.submitButton} {...props}>
			Save Changes
		</Button>
	);
};

export default SubmitButton;
