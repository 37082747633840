import React from 'react';
import { useSelector } from 'react-redux';
import styles from './DashboardHeader.module.scss';

const DashboardHeader = () => {
	const headerTitle = useSelector(
        (state) => state.staticData.orderSettings['app_dashboard_header_title']|| "",
    );
	return (
		<header className={styles.dashboardHeader}>
			<div className={styles.dashboardHeaderContent}>
				<h5>
					<b>{headerTitle}</b>
				</h5>
			</div>
		</header>
	);
};

export default DashboardHeader;
