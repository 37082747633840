import { useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@components/Tooltip/Tooltip';
import ShareIcon from '@components/Icons/ShareIcon';
import CopyIcon from '@components/Icons/CopyIcon';
import styles from './QuoteIndicator.module.scss';
import classNames from 'classnames';

const QuoteIndicator = ({ quote }) => {
	const user = useSelector((state) => state.auth.user);
	const [tooltipVisible, setTooltipVisible] = useState(false);

	if (quote.quote_original_id && quote.quote_exactly_as_original !== '1') {
		const recipient =
			quote.user_id === user.user_id ? 'you' : quote.user_display_name;

		return (
			<div
				className={classNames({
					[styles.quoteIndicator]: true,
					[styles.quoteIndicatorCopy]: true,
				})}
				onMouseEnter={() => setTooltipVisible(true)}
				onMouseLeave={() => setTooltipVisible(false)}>
				<CopyIcon fill="#FFF" />
				<Tooltip active={tooltipVisible} className={styles.tooltip}>
					{quote.quote_original_user_display_name} sent a copy of this
					quote to {recipient}
				</Tooltip>
			</div>
		);
	}

	if (quote.shared_user_count > 0) {
		const recipient =
			quote.user_id !== user.user_id
				? 'you'
				: quote.shared_user_count + ' others';

		const sentTo =
			quote.user_id === user.user_id
				? 'You are'
				: quote.user_display_name + ' is';

		return (
			<div
				className={classNames({
					[styles.quoteIndicator]: true,
					[styles.quoteIndicatorCollaborate]: true,
				})}
				onMouseEnter={() => setTooltipVisible(true)}
				onMouseLeave={() => setTooltipVisible(false)}>
				<ShareIcon fill="#FFF" />
				<Tooltip active={tooltipVisible} className={styles.tooltip}>
					{sentTo} collaborating on this quote with {recipient}
				</Tooltip>
			</div>
		);
	}

	return null;
};

export default QuoteIndicator;
