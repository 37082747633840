import { useMemo } from 'react';
import { API_ROOT } from '@config';
import { useSelector } from 'react-redux';
import styles from './WhyCreateAccountContent.module.scss';
import Button from '@components/Button/Button';
import { useHistory } from 'react-router-dom';

const WhyCreateAccountContent = () => {
	const history = useHistory();
	const contentItems = useSelector(
		(state) => state.staticData.content['why-create-account']?.items || [],
	);

	/**
	 * Content Items
	 */

	const items = useMemo(() => {
		return contentItems.map((item) => ({
			title: item.content_item_label,
			description: item.content_item_description,
			image: `${API_ROOT}/uploaded/content/${item.content_item_image}`,
		}));
	}, [contentItems]);

	return (
		<div className={styles.container}>
			<ul>
				{items.map((item, i) => (
					<li key={i}>
						<div className={styles.info}>
							{/* <div className={styles.indicator}>{i + 1}</div> */}
							<h6>{item.title}</h6>
							<p>{item.description}</p>
						</div>
						<div
							className={styles.image}
							style={{
								backgroundImage: `url('${item.image}')`,
							}}
						/>
					</li>
				))}
			</ul>
			<Button
				variant="solid"
				onClick={() => {
					history.push('/products/all');
				}}>
				Start Building
			</Button>
		</div>
	);
};

export default WhyCreateAccountContent;
