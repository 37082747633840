import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {API_ROOT, TCM_ROOT} from '@config';
import axios, {CancelToken} from 'axios';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import http from '@utilities/Http';
import Card from '@components/Card/Card';
import Screen from '@components/Screen/Screen';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';
import OrderItems from './components/OrderItems/OrderItems';
import CustomerAlert from './components/CustomerAlert/CustomerAlert';
import Button from '@components/Button/Button';
import PlusIcon from '@components/Icons/PlusIcon';
import AddQuoteToOrderModal from '@components/AddQuoteToOrderModal/AddQuoteToOrderModal';
import {getOrderTotalPrice, getQuoteTotalPrice} from '@utilities/pricing';
import styles from './Order.module.scss';
import ExportModal from '@components/ExportModal/ExportModal';
import {download, getOrderQuoteExportUrl} from '@utilities/export';
import OrderForm from './components/OrderForm/OrderForm';
import DialogModal from '@components/DialogModal/DialogModal';
import {isFinalized, isSubmitted} from '@utilities/orders';
import CustomerAlertModal from '@components/CustomerAlertModal/CustomerAlertModal';
import Messenger from "@components/Messenger/Messenger";
import QuoteCollaborationApproveModal from "@components/QuoteCollaborationApproveModal/QuoteCollaborationApproveModal";
import CheckboxField from "@components/CheckboxField/CheckboxField";
import AddAccessoryToOrderModal from '@components/AddAccessoryToOrderModal/AddAccessoryToOrderModal';
import { setConfiguratorOptions } from '@store/reducers/configurator';
import {useErrorAlert} from "@hooks/useErrorAlert";
import Http from "@utilities/Http";

const shippingFields = {
    order_ship_to_name: 'Please enter the shipping name',
    order_ship_to_street: 'Please enter the shipping street address',
    order_ship_to_city: 'Please enter the shipping city',
    order_ship_to_state: 'Please enter the shipping state',
    order_ship_to_zip: 'Please enter the shipping zip code',
    order_ship_to_country: 'Please enter the shipping country',
};

const Order = ({match}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.auth.user);
    const [isFetching, setIsFetching] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [organizationContracts, setOrganizationContracts] = useState([]);
    // field save debounce timer
    const fieldSaveDebounceTimer = useRef(null);
    // field save request source
    const fieldSaveRequestSource = useRef(null);
    // order data
    const [order, setOrder] = useState(null);
    // validation errors
    const [errors, setErrors] = useState([]);
    const [validationModalActive, setValidationModalActive] = useState(false);
    // add quote to order modal
    const [showAddQuoteModal, setShowAddQuoteModal] = useState(false);
    const [showAccessoryModal, setShowAccessoryModal] = useState(false);
    // customer alert modal
    const [customerAlertModalActive, setCustomerAlertModalActive] =
        useState(false);
    // export all modal
    const [showExportModal, setShowExportModal] = useState(false);
    // order export error message modal
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [approvedModalMessage, setApprovedModalMessage] = useState('');
    // selected saved address
    const [selectedAddress, setSelectedAddress] = useState(null);
    // shipping credentials
    const [shippingCredentials, setShippingCredentials] = useState({});
    const hasCsrAccess = user?.permissions?.includes('csr_access');
    const isCSRUser = hasCsrAccess && order?.user_id != user?.user_id;
    // fetching / submitting flags

    const [isSubmittingTCM, setIsSubmittingTCM] = useState(false);

    const [, setErrorAlert] = useErrorAlert();

    const [collaboratingQuoteApprove, setCollaboratingQuoteApprove] = useState(null);

    const orderSubmissionDisabled = useSelector(
        (state) => state.staticData.orderSettings['disable_order_submission'],
    );
    const isAdmin = user?.permissions?.includes('access_admin');

    const demoModeActive = useSelector((state) => state.demoMode.active);

    const configuratorQuoteId = useSelector(
        (state) => state.configurator.configuratorOptions.quoteId,
    );
    const configuratorProductId = useSelector(
        (state) => state.configurator.configuratorOptions.productId,
    );
    const configuratorProductLineId = useSelector(
        (state) => state.configurator.configuratorOptions.productLineId,
    );

    /**
     * Static Data
     */

    const {shippingOptions} = useSelector((state) => state.staticData);

    const [isSaving, setIsSaving] = useState(false);

    //last updated timestamps
    const [latestUpdateTimestamps, setLatestUpdateTimestamps] = useState({
        modifiedTimestamp: null,
        auditLogTimestamp: null,
    });

    const fetchUpdatesTimeout = useRef(null);
    const fetchUpdatesRequestSource = useRef(null);

    /**
     * Validate form
     */

    const validateForm = useCallback(
        (order) => {
            const errors = {};

            if(!agreedToTerms && !user?.permissions.includes('csr_access')) {
                errors.agreedToTerms = 'Please agree to the Terms and Conditions';
            }
            // order name
            if (!order.order_name) {
                errors.order_name = 'Please enter a name for this order';
            }

            // fire department
            if (!order.fire_department_id && order.order_stock_flag !== 1) {
                // order name
                if (order.order_manual_department_flag) {
                    if (!order.fire_department_name_manual) {
                        errors.fire_department_name_manual =
                            'Please enter a fire department name';
                    }
                    if (!order.fire_department_address) {
                        errors.fire_department_address =
                            'Please enter a fire department address';
                    }
                    if (!order.fire_department_city) {
                        errors.fire_department_city =
                            'Please enter a fire department city';
                    }
                    if (!order.fire_department_state) {
                        errors.fire_department_state =
                            'Please enter a Fire Department State / Province / Region';
                    }
                    if (!order.fire_department_zip_manual) {
                        errors.fire_department_zip_manual =
                            'Please enter a Fire Department Zip / Postal Code';
                    }
                    if (!order.fire_department_county) {
                        errors.fire_department_county =
                            'Please enter a fire department county';
                    }
                    if (!order.fire_department_country) {
                        errors.fire_department_country =
                            'Please enter a fire department country';
                    }
                } else {
                    errors.fire_department_id =
                        'Please select a fire department';
                }
            }

            if((order.fire_department_id || order.order_manual_department_flag == 1) && order.order_stock_flag !== 1 && order.order_id != 7800){ //todo: quick fix to allow order to submit
                if(!order.fire_department_contact_first){
                    errors.fire_department_contact_first = 'Please enter a Fire Department contact first name';
                }
                if(!order.fire_department_contact_last){
                    errors.fire_department_contact_last = 'Please enter a Fire Department contact last name';
                }

                if(!order.fire_department_contact_email || !order.fire_department_contact_email.includes('@')
                    || !order.fire_department_contact_email.includes('.') || !order.fire_department_contact_email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){
                    errors.fire_department_contact_email = 'Please enter a Valid Fire Department contact email';
                }
                if(!order.fire_department_contact_title){
                    errors.fire_department_contact_title = 'Please enter a Fire Department contact title';
                }

            }

            if(order.order_stock_flag && !order.order_stock_order_input) {
                errors.order_stock_order_input = 'Please include a Gear Use';
            }

            // po #
            if (!order.order_po_number) {
                errors.order_po_number = 'Please enter a PO #';
            }

            // po document
            if (
                !order.orderDocuments?.find(
                    (doc) => doc.order_document_type === 'PO',
                )
            ) {
                errors.orderDocuments = 'Please supply a PO Document';
            }

            // shipping options
            const selectedShipping = shippingOptions.find(
                (option) =>
                    option.shipping_option_id === order.shipping_option_id,
            );
            if (!selectedShipping) {
                errors.shipping_option_id = 'Please select a shipping option';
            }

            // shipping option account
            if (
                selectedShipping?.shipping_option_requires_account &&
                !order.order_shipping_account
            ) {
                errors.order_shipping_account =
                    'Shipping type requires an account number';
            }

            if(
                selectedShipping?.shipping_option_tcm_id == 99 &&
                !order.order_shipping_instructions
            ){
                errors.order_shipping_instructions = 'Please enter shipping instructions';
            }
            // shipping address
            Object.keys(shippingFields)
                .filter((key) => key !== 'order_ship_to_attention')
                .reduce((errors, key) => {
                    if (!order[key]) {
                        errors[key] = shippingFields[key];
                    }

                    return errors;
                }, errors);

            return errors;
        },
        [shippingOptions, agreedToTerms],
    );

    const validateAddressLengths = useCallback( (order) => {
            const errors = [];
            const combinedAddress = order['order_ship_to_city'] + ', ' + order['order_ship_to_state'] + ' ' + order['order_ship_to_zip'];
            if(order.order_ship_to_name && order.order_ship_to_name.length > 30) {
                errors.push(<p><br/>Ship to name exceeds 30 characters.<br/>Please shorten name by at least {order.order_ship_to_name.length - 30} characters:<br/><br/>{order.order_ship_to_name}</p>);
            }
            if(order.order_ship_to_attention && order.order_ship_to_attention.length > 30) {
                errors.push(<p><br/>Ship to Attention exceeds 30 characters.<br/>Please shorten attention by at least {order.order_ship_to_attention.length - 30} characters:<br/><br/>{order.order_ship_to_attention}<br/></p>);
            }
            if(order.order_ship_to_street && order.order_ship_to_street.length > 30) {
                errors.push(<p><br/>Address Street exceeds 30 characters.<br/>Please shorten street by at least {order.order_ship_to_street.length - 30} characters:<br/><br/>{order.order_ship_to_street}<br/></p>);
            }
            if(order.order_ship_to_number && order.order_ship_to_number.length > 30) {
                errors.push(<p><br/>Address Street Number exceeds 30 characters.<br/>Please shorten street number by at least {order.order_ship_to_number.length - 30} characters:<br/><br/>{order.order_ship_to_number}<br/></p>);
            }
            if(order.order_ship_to_city && order.order_ship_to_city.length > 30) {
                errors.push(<p><br/>Ship To City exceeds 30 characters.<br/>Please shorten city by at least {order.order_ship_to_city.length - 30} characters:<br/><br/>{order.order_ship_to_city}<br/></p>);
            }
            if(order.order_ship_to_name && order.order_ship_to_name.length > 30) {
                errors.push(<p><br/>Ship to name exceeds 30 characters.<br/>Please shorten name by at least {order.order_ship_to_name.length - 30} characters:<br/><br/>{order.order_ship_to_name}<br/></p>);
            }
            if(order.order_po_number && order.order_po_number.length > 30) {
                errors.push(<p><br/>PO # exceeds 30 characters.<br/>Please shorten PO # by at least {order.order_po_number.length - 30} characters:<br/><br/>{order.order_po_number}<br/></p>);
            }
            if(combinedAddress.length > 30) {
                errors.push(<p><br/>Combined City, State and Zipcode exceed 30 characters.<br/>Please shorten address by at least {combinedAddress.length - 30} characters:<br/><br/>{combinedAddress}</p>);
            }

            return errors;
    },
        []
    );

    /**
     * Disable/Allow editing
     */

    const isEditable = useMemo(() => {
        // if (user?.permissions.includes('csr_access')) {
        // 	return !isFinalized(order);
        // }
        if (
            user?.permissions.includes('rsm_access') &&
            order?.user_id != user?.user_id
        ) {
            return false;
        }

        if(isSubmittingTCM) {
            return false;
        }

        return !isSubmitted(order);
    }, [user, order, isSubmittingTCM]);

    const queryForUpdates = useCallback(() => {
        fetchUpdatesRequestSource.current?.cancel();
        fetchUpdatesRequestSource.current = CancelToken.source();
        clearTimeout(fetchUpdatesTimeout.current);
        if(latestUpdateTimestamps.modifiedTimestamp && latestUpdateTimestamps.auditLogTimestamp) {
            Http().get(
                `${API_ROOT}/orders/update-check/${match.params?.orderId}`,
                {
                    params: {
                        modifiedTimestamp: latestUpdateTimestamps.modifiedTimestamp,
                        auditLogTimestamp: latestUpdateTimestamps.auditLogTimestamp,
                    },
                    cancelToken: fetchUpdatesRequestSource.current?.token
                },
            ).then(({data}) => {
                if (data.newUpdates) {
                    fetchUpdatesRequestSource.current?.cancel();
                    clearTimeout(fetchUpdatesTimeout.current);
                    setOrder(data.order.order);
                    setErrorAlert({
                        errorTitle: 'Alert',
                        errorMessage: 'The state of your order was found to be out of date. It has automatically been updated.',
                        errorIgnoreAction: () => {
                            fetchUpdatesRequestSource.current?.cancel();
                            clearTimeout(fetchUpdatesTimeout.current);
                            setLatestUpdateTimestamps({
                                modifiedTimestamp: data.order.order.order_modified_timestamp,
                                auditLogTimestamp: data.order.order.latest_audit_log_timestamp
                            });
                        },
                        errorAction: () => {
                            fetchUpdatesRequestSource.current?.cancel();
                            clearTimeout(fetchUpdatesTimeout.current);
                            setLatestUpdateTimestamps({
                                modifiedTimestamp: data.order.order.order_modified_timestamp,
                                auditLogTimestamp: data.order.order.latest_audit_log_timestamp
                            });
                        }
                    });
                } else {
                    fetchUpdatesTimeout.current = setTimeout(queryForUpdates, 30000);
                }
            })
        } else {
            fetchUpdatesRequestSource.current?.cancel();
            clearTimeout(fetchUpdatesTimeout.current);
            fetchUpdatesTimeout.current = setTimeout(queryForUpdates, 30000);
        }
    }, [latestUpdateTimestamps.auditLogTimestamp, latestUpdateTimestamps.modifiedTimestamp, match.params?.orderId, setErrorAlert])

    /**
     * Handle Save Order
     */

    const handleSaveOrder = useCallback(
        async (order, ignoreServerUpdates = false) => {
            // cancel any pending request
            fieldSaveRequestSource.current?.cancel();
            // create new cancel token
            fieldSaveRequestSource.current = CancelToken.source();

            clearTimeout(fetchUpdatesTimeout.current);
            fetchUpdatesRequestSource.current?.cancel();

            //We ignore server updates if was already in the process of saving since timestamps will be out of sync
            const forIgnore = isSaving;
            setIsSaving(true);
            return http()
                .post(
                    `${API_ROOT}/orders/${match.params?.orderId}`,
                    {
                        order,
                        modifiedTimestamp: latestUpdateTimestamps.modifiedTimestamp,
                        auditLogTimestamp: latestUpdateTimestamps.auditLogTimestamp,
                        ignoreServerUpdates: ignoreServerUpdates || forIgnore
                    },
                    {cancelToken: fieldSaveRequestSource.current?.token},
                )
                .then(({data}) => {
                    clearTimeout(fetchUpdatesTimeout.current);
                    // Update order alert codes in case METRO has changed
                    if(order) {
                        if(JSON.stringify(order.alert_codes) !== JSON.stringify(data.order.order.alert_codes)) {
                            setOrder(
                                {
                                    ...order,
                                    alert_codes: data.order.order.alert_codes
                                }
                            );
                        }
                    }

                    setLatestUpdateTimestamps({
                        modifiedTimestamp: data.order.order.order_modified_timestamp,
                        auditLogTimestamp: data.order.order.latest_audit_log_timestamp
                    });

                    setIsSaving(false);

                    return data.order;
                })
                .catch((err) => {
                    clearTimeout(fetchUpdatesTimeout.current);
                    if (axios.isCancel(err)) {
                        // silent
                        return;
                    }

                    setIsSaving(false);

                    const { response } = err;

                    if(response?.data?.newUpdates) {
                        setErrorAlert({
                            errorTitle: 'Warning',
                            errorMessage: 'You are saving an outdated version of this order, would you like to save anyway? Canceling will update to the latest version.',
                            errorType: 'confirm',
                            errorIgnoreAction: () => {
                                fetchUpdatesRequestSource.current?.cancel();
                                clearTimeout(fetchUpdatesTimeout.current);
                                setLatestUpdateTimestamps({
                                    modifiedTimestamp: response.data.order.order_modified_timestamp,
                                    auditLogTimestamp: response.data.order.latest_audit_log_timestamp
                                });

                                setOrder(response.data.order);
                            },
                            errorAction: () => {
                                fetchUpdatesRequestSource.current?.cancel();
                                clearTimeout(fetchUpdatesTimeout.current);
                                handleSaveOrder(order, true);
                            }
                        });
                    } else {
                        console.log(err);
                        fetchUpdatesTimeout.current = setTimeout(queryForUpdates, 30000);
                    }
                });
        },
        [isSaving, latestUpdateTimestamps.auditLogTimestamp, latestUpdateTimestamps.modifiedTimestamp, match.params?.orderId, queryForUpdates, setErrorAlert],
    );

    /**
     * Handle Submit Order
     */

    const handleSubmitOrder = useCallback((ignoreCollaboratingCheck = false) => {

        if (orderSubmissionDisabled === '1' && !isAdmin && !hasCsrAccess) {
            return;
        }

        const errors = validateForm(order);
        setErrors(errors);

        if (Object.keys(errors).length) {
            setValidationModalActive(true);
            return;
        }

        const addressLengthErrors = validateAddressLengths(order);
        if (addressLengthErrors.length > 0) {
            setErrorAlert({
                errorTitle: 'Address Error',
                errorMessage: addressLengthErrors,
            });
            return;
        }

        if(ignoreCollaboratingCheck !== true) {
            for(let quote of order.quotes) {
                if(quote.shared_user_count) {
                    setCollaboratingQuoteApprove(true);
                    return;
                }
            }
        }
        setIsSubmitting(true);

        return setTimeout(() => {
            return http()
                .post(`${API_ROOT}/orders/submit/${order.order_id}`)
                .then(() => {
                    setIsSubmitting(false);
                    history.push('/orders');
                })
                .catch(() => {
                    setIsSubmitting(false);
                });
        }, 600);
    }, [orderSubmissionDisabled, isAdmin, hasCsrAccess, validateForm, order, validateAddressLengths, setErrorAlert, history]);

    /**
     * Handle Send Approved Order to TCM
     */

    const handleTCM = useCallback(async () => {
        const errors = validateForm(order);
        setErrors(errors);

        if (Object.keys(errors).length) {
            setValidationModalActive(true);
            return;
        }

        return http()
            .post(`${TCM_ROOT ? TCM_ROOT : API_ROOT}/orders/tcm/${order.order_id}`)
            .then(({data}) => {
                setApprovedModalMessage(
                    <>
                        <p>
                            Fire-Dex Order #{data.order.order_fire_dex_number}{' '}
                            entered in TCM
                        </p>
                        <p>CSR must release to production</p>
                    </>,
                );
            });
    }, [order, validateForm]);

    /**
     * Handle Field Change
     */

    const handleFieldChange = useCallback(
        (e) => {
            const {
                target: {name, value},
            } = e;

            // Save can trigger if values are defaulted to ''
            if((order[name] === null || order[name] === undefined) && value === '') {
                return;
            }
            //Only fire if actual change
            if(order[name] != value) {
                // clear selected address if editing ship-to
                if (Object.keys(shippingFields).includes(name)) {
                    setSelectedAddress(null);
                }
                setOrder((order) => {
                    const updatedOrder = {
                        ...order,
                        [name]: value,
                    };
                    if (name === 'order_stock_flag' && value == 1) {
                        updatedOrder.order_manual_department_flag = 0;
                        updatedOrder.fire_department_id = null;
                    } else if (name === 'order_manual_department_flag' && value == 1) {
                        updatedOrder.order_stock_flag = 0;
                        updatedOrder.fire_department_id = null;
                    } else if (name === 'fire_department_id' && value) {
                        updatedOrder.order_stock_flag = 0;
                        updatedOrder.order_manual_department_flag = 0;
                    }
                    // updatedOrder.order_manual_department_flag = (updatedOrder.fire_department_id || updatedOrder.order_stock_flag)?0:1;
                    // unset field error
                    setErrors((errors) => {
                        delete errors[name];
                        return errors;
                    });

                    // clear save timer
                    clearTimeout(fieldSaveDebounceTimer.current);

                    // start save timer
                    fieldSaveDebounceTimer.current = setTimeout(() => {
                        handleSaveOrder(updatedOrder);
                    }, 500);

                    return updatedOrder;
                });
            }
        },
        [handleSaveOrder, order],
    );

    /**
     * Handle Document Upload
     */

    const handleDocumentUpload = useCallback(
        async (files = [], type = 'Additional') => {
            const formData = new FormData();

            Array.from(files).forEach((file, f) => {
                formData.append(`files[${f}]`, file);
            });

            try {
                const {data} = await http().post(
                    `${API_ROOT}/orders/upload-document`,
                    formData,
                );

                const orderDocuments = [
                    // if PO type, replace existing PO document
                    ...(type === 'PO'
                        ? order.orderDocuments.filter(
                            (doc) => doc.order_document_type !== 'PO',
                        )
                        : order.orderDocuments),
                    // append new files
                    ...data.fileName.map((fileName) => ({
                        order_document_name: fileName,
                        order_document_file: fileName,
                        order_document_type: type,
                    })),
                ];

                const result = await handleSaveOrder({
                    ...order,
                    orderDocuments,
                });

                if (result.order?.orderDocuments) {
                    setOrder({
                        ...order,
                        orderDocuments: result.order.orderDocuments,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        [order, handleSaveOrder],
    );

    /**
     * Handle Set Customer Alert
     */

    const handleCustomerAlert = useCallback(
        async (update) => {
            const result = await handleSaveOrder({
                ...order,
                ...update,
            });

            setOrder({
                ...order,
                ...result.order,
            });
        },
        [order, handleSaveOrder],
    );

    /**
     * Handle Quote Export
     */

    const handleExport = useCallback(
        async (type = 'pdf') => {
            const url = await getOrderQuoteExportUrl(order.order_id, type, demoModeActive);
            download(url, `${order.order_id}.zip`);
        },
        [order, demoModeActive],
    );


    /**
     * Fetch Order
     */

    useEffect(() => {
        fetchUpdatesRequestSource.current?.cancel();
        clearTimeout(fetchUpdatesTimeout.current);
        setIsFetching(true);
        http()
            .get(`${API_ROOT}/orders/${match.params?.orderId}`)
            .then(({data}) => {
                setLatestUpdateTimestamps({
                    modifiedTimestamp: data.order.order_modified_timestamp,
                    auditLogTimestamp: data.order.latest_audit_log_timestamp
                });
                setOrder(data.order);
                setSelectedAddress(null);
                setAgreedToTerms(isSubmitted(data.order));
            })
            .then(() => {
                return http().get(
                    `${API_ROOT}/organizations/shipping-accounts`,
                );
            })
            .then(({data}) => {
                setShippingCredentials(
                    data.organization_shipping_accounts.reduce(
                        (credentials, account) => {
                            credentials[account.shipping_option_id] =
                                account.organization_shipping_account_number;
                            return credentials;
                        },
                        {},
                    ),
                );
            })
            .then(() => {
                return http().get(
                    `${API_ROOT}/organizations/contracts?order_id=${match.params?.orderId}`,
                );
            })
            .then(({data}) => {
                setOrganizationContracts(data.contracts);
            })
            .finally(() => {
                setIsFetching(false);
            });
    }, [match.params, user]);

    useEffect(() => {
        if(!(configuratorQuoteId || (configuratorProductId && configuratorProductLineId))) {
            if (latestUpdateTimestamps.modifiedTimestamp && latestUpdateTimestamps.auditLogTimestamp) {
                queryForUpdates();
            }
        } else {
            fetchUpdatesRequestSource.current?.cancel();
            clearTimeout(fetchUpdatesTimeout.current);
        }
    }, [latestUpdateTimestamps.modifiedTimestamp, latestUpdateTimestamps.auditLogTimestamp, configuratorQuoteId, configuratorProductId, configuratorProductLineId]);

    return (
        <Screen
            headerContent={<WelcomeHeader/>}
            loading={isFetching}
            pageContentClassName={styles.orderPageContent}>
            {!isFetching && order && (
                <>
                    <div className={styles.orderActions}>
                        <>
                            <Button
                                variant="solid"
                                className={styles.exportAllButton}
                                onClick={() => setShowExportModal(true)}>
                                Export Quotes
                            </Button>
                            {isEditable && (
                            <>
                                <Button
                                    variant="solid"
                                    adornment={<PlusIcon fill="#FFF"/>}
                                    className={styles.addQuoteToOrderButton}
                                    onClick={() => setShowAddQuoteModal(true)}>
                                    Add Quote to Order
                                </Button>
                                <Button
                                    variant="solid"
                                    adornment={<PlusIcon fill="#FFF"/>}
                                    className={styles.addQuoteToOrderButton}
                                    onClick={() => setShowAccessoryModal(true)}>
                                    Add Accessory To Order
                                </Button>
                            </>
                            )}
                        </>
                    </div>
                    <Card>
                        <OrderForm
                            order={order}
                            errors={errors}
                            setOrder={setOrder}
                            setErrors={setErrors}
                            handleFieldChange={handleFieldChange}
                            handleDocumentUpload={handleDocumentUpload}
                            validationModalActive={validationModalActive}
                            setValidationModalActive={setValidationModalActive}
                            shippingFields={shippingFields}
                            selectedAddress={selectedAddress}
                            setSelectedAddress={setSelectedAddress}
                            shippingCredentials={shippingCredentials}
                            isEditable={isEditable}
                            isSubmittingTCM={isSubmittingTCM}
                            handleSaveOrder={handleSaveOrder}
                        />
                        <CustomerAlert
                            order={order}
                            isCSRUser={isCSRUser}
                            onCustomerAlert={handleCustomerAlert}
                            className={styles.orderRejectedContainer}>
                            <>
                                {((!isCSRUser && !isFinalized(order)) || (!isCSRUser &&
                                    user.user_organization_pricing === '1' &&
                                    order.order_status === 'Requires Edits' &&
                                    isEditable) || (!isCSRUser &&
                                    user.user_organization_pricing === '1' &&
                                    order.order_status === 'Not Submitted' &&
                                    !!order.quotes.length &&
                                    isEditable)) &&
                                <div className={styles.termsHolder}>
                                    <CheckboxField
                                        value={agreedToTerms}
                                        onChange={() => {
                                            setAgreedToTerms(!agreedToTerms)
                                        }}
                                        label={''}
                                    />
                                    <a href="/terms-and-conditions" target="_blank">I agree to the terms and
                                        conditions</a>
                                </div>
                                }
                                {isCSRUser && !isFinalized(order) && (
                                    <Button
                                        variant="outlined"
                                        onClick={() =>
                                            setCustomerAlertModalActive(true)
                                        }>
                                        {order.order_customer_alert
                                            ? 'Edit Customer Alert'
                                            : 'Customer Alert'}
                                    </Button>
                                )}
                                {!isCSRUser &&
                                user.user_organization_pricing === '1' &&
                                order.order_status === 'Requires Edits' &&
                                !!order.quotes.length &&
                                isEditable && (
                                    <Button
                                        variant="outlined"
                                        onClick={handleSubmitOrder}
                                        loading={isSubmitting}
                                        disabled={orderSubmissionDisabled === '1' && !isAdmin && !hasCsrAccess ? true : false}
                                    >
                                        Resubmit Order
                                    </Button>
                                )}
                                {!isCSRUser &&
                                user.user_organization_pricing === '1' &&
                                order.order_status === 'Not Submitted' &&
                                !!order.quotes.length &&
                                isEditable && (
                                    <Button
                                        variant="outlined"
                                        onClick={handleSubmitOrder}
                                        loading={isSubmitting}
                                        disabled={orderSubmissionDisabled === '1' && !isAdmin && !hasCsrAccess ? true : false}
                                    >
                                        Submit Order
                                    </Button>
                                )}
                            </>
                        </CustomerAlert>
                    </Card>
                    <OrderItems
                        order={order}
                        setOrder={setOrder}
                        totalPrice={getOrderTotalPrice(order)}
                        onSubmit={handleSubmitOrder}
                        onTCM={handleTCM}
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        isSubmittingTCM={isSubmittingTCM}
                        setIsSubmittingTCM={setIsSubmittingTCM}
                        isEditable={isEditable}
                        noItemsActions={
                            <>
                                {isEditable && (
                                    <Button
                                        variant="solid"
                                        adornment={<PlusIcon fill="#FFF"/>}
                                        className={styles.addQuoteToOrderButton}
                                        onClick={() =>
                                            setShowAddQuoteModal(true)
                                        }>
                                        Add Quote to Order
                                    </Button>
                                )}
                                <Link to="/quotes">
                                    <Button variant="outlined">
                                        View All Quotes
                                    </Button>
                                </Link>
                            </>
                        }
                        organizationContracts={organizationContracts}
                    />
                    <AddQuoteToOrderModal
                        active={showAddQuoteModal}
                        onClose={(quote, quotes) => {
                            setShowAddQuoteModal(false);

                            if (quote) {
                                setOrder((order) => ({
                                    ...order,
                                    quotes,
                                }));
                            }
                        }}
                        orderId={match.params?.orderId}
                    />
                    <AddAccessoryToOrderModal
                        active={showAccessoryModal}
                        onClose={(quote) => {
                            setShowAccessoryModal(false);

                            if (quote) {
                                setOrder((order) => ({
                                    ...order,
                                    quotes: [...order.quotes, quote],
                                }));
                                dispatch(
                                    setConfiguratorOptions({
                                        quoteId: quote.quote_id,
                                    }),
                                );
                            }
                        }}
                        orderId={match.params?.orderId}
                    />
                    <ExportModal
                        active={showExportModal}
                        onClose={setShowExportModal}
                        onPdf={() => handleExport('pdf')}
                        onWord={() => handleExport('word')}
                        title="Export Quotes"
                    />
                    <DialogModal
                        active={!!errorModalMessage}
                        title="Export Failed"
                        onConfirm={() => setErrorModalMessage('')}
                        message={errorModalMessage}
                        labels={['Got It']}
                    />
                    <CustomerAlertModal
                        order={order}
                        active={customerAlertModalActive}
                        onClose={() => setCustomerAlertModalActive(false)}
                        onSubmit={handleCustomerAlert}
                    />
                    <DialogModal
                        active={!!approvedModalMessage}
                        title="Order Approved"
                        onConfirm={() => {
                            setApprovedModalMessage('');
                            history.push('/orders');
                        }}
                        message={approvedModalMessage}
                        labels={['Got It']}
                    />
                    <QuoteCollaborationApproveModal
                        active={collaboratingQuoteApprove}
                        title="Confirm Submit"
                        onConfirm={() => {
                            if (orderSubmissionDisabled === '1' && !isAdmin && !hasCsrAccess) {
                                return;
                            }

                            setCollaboratingQuoteApprove(false);
                            handleSubmitOrder(true);
                        }}
                        onClose={() => {
                            setCollaboratingQuoteApprove(false);
                        }}
                    />
                    {
                        (isCSRUser || order.order_status === 'Requires Edits') && (
                            <Messenger
                                orderId={match.params?.orderId}
                                user={user}
                                functional={order.order_status === 'Requires Edits'}
                            />
                        )
                    }
                </>
            )}
        </Screen>
    );
};

export default Order;
