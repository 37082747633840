import { useMemo } from 'react';

export const useLetteringValidation = (data, charLengths = {}) => {
	const errors = useMemo(() => {
		return Object.keys(data).reduce((errors, placementId) => {
			const value = data[placementId];

			// patch option not empty
			if (!value?.product_option_value_id) {
				errors[placementId] = 'product_option_value_id';
				return errors;
			}

			// patch color option not empty
			if (!value?.product_option_value_id_patch_color) {
				errors[placementId] = 'product_option_value_id_patch_color';
			}

			// style option not empty
			if (!value?.product_option_value_id_style) {
				errors[placementId] = 'product_option_value_id_style';
				return errors;
			}

			// type option not empty
			if (!value?.quote_product_option_value_lettering_type) {
				errors[placementId] =
					'quote_product_option_value_lettering_type';
				return errors;
			}

			// if custom text, has length
			if (
				value?.quote_product_option_value_lettering_type === 'custom' &&
				!value?.quote_product_option_value_lettering_custom_text?.length
			) {
				errors[placementId] =
					'quote_product_option_value_lettering_custom_text';
				return errors;
			}

			// if custom text, length doesn't exceed limit
			if (
				value?.quote_product_option_value_lettering_type === 'custom' &&
				value?.quote_product_option_value_lettering_custom_text
					?.length > charLengths[placementId]
			) {
				errors[placementId] =
					'quote_product_option_value_lettering_custom_text';
				return errors;
			}

			return errors;
		}, {});
	}, [data, charLengths]);

	return errors;
};
