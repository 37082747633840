import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

const PrivateRoute = ({ ...rest }) => {
	const { authSettled = false, user } = useSelector((state) => state.auth);

	if (authSettled && !user) {
		return <Redirect to={{ pathname: '/login' }} />;
	}

	return <Route {...rest} />;
};

export default PrivateRoute;
