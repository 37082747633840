import classNames from 'classnames';
import React from 'react';
import styles from './Fields.module.scss';

const FieldGroup = ({ children = null, className = null }) => {
	return (
		<div
			className={classNames({
				[styles.fieldGroup]: true,
				[styles.fieldContainer]: true,
				[className]: !!className,
			})}>
			{children}
		</div>
	);
};

export default FieldGroup;
