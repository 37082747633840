import classNames from 'classnames';
import React from 'react';
import styles from './Fields.module.scss';

const FieldColumn = ({
	children = null,
	className = null,
	justify = false,
	siblingCount = 1,
}) => {
	return (
		<div
			className={classNames({
				[styles.fieldColumn]: true,
				[styles.fieldColumnJustified]: justify,
				[className]: !!className,
			})}
			style={{
				maxWidth: `${100 / siblingCount}%`,
			}}>
			{children}
		</div>
	);
};

export default FieldColumn;
