import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			width={13}
			height={13}
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path d="M9.75 9.75H13V13H9.75zm0-4.875H13v3.25H9.75zM4.875 0h3.25v3.25h-3.25zM13 0v3.25H9.75V0zM4.875 4.875h3.25v3.25h-3.25zM0 4.875h3.25v3.25H0zM0 9.75h3.25V13H0zm4.875 0h3.25V13h-3.25zM0 0h3.25v3.25H0z" />
		</svg>
	);
}

export default SvgComponent;
