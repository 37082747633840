import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@components/Button/Button';
import http from '@utilities/Http';
import styles from './IOActions.module.scss';
import { API_ROOT } from '@config';
import { download } from '@utilities/export';
import SendIcon from "@components/Icons/SendIcon";
import StarIcon from "@components/Icons/StarIcon";
import SizerInviteModal from '@components/SizerInviteModal/SizerInviteModal';
import VirtualSizerModal from '@components/VirtualSizerModal/VirtualSizerModal';
import SizerShareModal from '@components/SizerShareModal/SizerShareModal';
const IOActions = ({
	quoteId,
	handleClose = () => {},
	handleUpload: onUpload = () => {},
	isEditable = true,
	refresh = () => {},
}) => {
	const fileInputNode = useRef(null);
	const [isDownloading, setIsDownloading] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [importErrors, setImportErrors] = useState([]);
	const [showInviteModal, setShowInviteModal] = useState(false);
	const [showSizerModal, setShowSizerModal] = useState(false);

	/**
	 * Handle Template Download
	 */

	const handleTemplateDownload = useCallback(() => {
		setIsDownloading(true);
		http()
			.get(`${API_ROOT}/sizing/template/${quoteId}`, {
				responseType: 'blob',
			})
			.then(({ headers, data }) => {
				const parts = headers['content-disposition']?.split(';');
				const filename =
					(parts?.length > 1 && parts[1].split('=')[1]) ||
					'sizing-template';

				return {
					filename: filename.replace(/(_|")/g, ''),
					url: window.URL.createObjectURL(new Blob([data])),
				};
			})
			.then(({ filename, url }) => {
				download(url, `${filename}`);
			})
			.finally(() => {
				setIsDownloading(false);
			});
	}, [quoteId]);

	/**
	 * Handle CSV Upload
	 */

	const handleUpload = useCallback(
		(e) => {
			setIsUploading(true);
			setImportErrors([]);

			const formData = new FormData();

			formData.append('file', e.target.files[0]);

			http()
				.post(`${API_ROOT}/sizing/import/${quoteId}`, formData)
				.then(({ data }) => {
					return onUpload(data);
				})
				.catch((error) => {
					const data = error.response?.data;
					const errors = [];

					if (data.reason) {
						errors.push(data.reason);
					}

					setImportErrors(errors);
				})
				.finally(() => {
					setIsUploading(false);
					fileInputNode.current.value = null;
				});
		},
		[quoteId, onUpload],
	);

	/**
	 * Initialize File Input Node
	 */

	useEffect(() => {
		const node = document.createElement('input');
		node.type = 'file';
		node.accept = 'text/csv';
		node.onchange = handleUpload;

		fileInputNode.current = node;
	}, [handleUpload]);

	const handleSizerSubmit = () => {
		setShowSizerModal(false);
		refresh();
	};

	return (
		<div className={styles.ioActionsContainer}>
			<SizerShareModal
					active={showSizerModal}
					onClose={() => {setShowSizerModal(false)}}
					quoteId={quoteId}
					refresh={refresh}
				/>
				
			<div className={styles.actions}>
				
				<Button
					tabIndex="-1"
					variant="solid-orange"
					onClick={()=>{setShowSizerModal(true)}}>
					<StarIcon styles={{width:24,height:24}} />
					Virtual Sizer
				</Button>
				{isEditable && (
					<Button
						tabIndex="-1"
						variant="solid"
						loading={isUploading}
						onClick={() => fileInputNode.current?.click()}>
						+ Upload New Sizing Sheet
					</Button>
				)}
				<Button
					tabIndex="-1"
					variant="solid-gold"
					loading={isDownloading}
					onClick={handleTemplateDownload}>
					Download Sizing Template
				</Button>
				<Button
					tabIndex="-1"
					variant="solid-gray"
					onClick={handleClose}
					data-sizing-back-button>
					Back to Summary
				</Button>
			</div>
			{!!importErrors.length && (
				<div className={styles.errors}>
					<p>We weren&apos;t able to import your spreadsheet:</p>
					<ul>
						{importErrors.map((error, e) => (
							<li key={e}>{error}</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default IOActions;
