import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import styles from './DialogModal.module.scss';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

const DialogModal = ({
	active = false,
	title = '',
	message = '',
	onConfirm = () => {},
	onClose = () => {},
	labels = [],
}) => {
	const [isConfirming, setIsConfirming] = useState(false);

	/**
	 * Confirm Button Handler
	 */

	const handleConfirm = useCallback(async () => {
		setIsConfirming(true);
		await onConfirm();
		setIsConfirming(false);
	}, [onConfirm]);

	return (
		<Modal active={active} onClose={() => onClose()}>
			<h4 className={modalStyles.modalTitle}>{title}</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<p>{message}</p>
			</div>
			<div className={modalStyles.modalActions}>
				{!!labels[1] && (
					<Button variant="outlined" onClick={onClose}>
						{labels[1]}
					</Button>
				)}
				{!!labels[0] && (
					<Button
						variant="solid"
						onClick={handleConfirm}
						loading={isConfirming}>
						{labels[0]}
					</Button>
				)}
			</div>
		</Modal>
	);
};

export default DialogModal;
