import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { fetchUser } from '../thunks/fetch-user';

const demoModeSlice = createSlice({
	name: 'demoMode',
	initialState: {
		active: false,
		visible: true,
	},
	reducers: {
		setDemoModeActive(state, action) {
			return {
				...state,
				active: action.payload,
			};
		},
	},
	extraReducers: {
		[fetchUser.fulfilled]: (state, action) => {
			const hasPricing =
				action.payload.user.permissions.includes('view_pricing') &&
				parseInt(action.payload.user.user_organization_pricing) === 1;
			return {
				...state,
				active: !hasPricing,
				visible: hasPricing,
			};
		},
	},
});

export const { setDemoModeActive } = demoModeSlice.actions;
export default persistReducer(
	{ key: 'demo-mode', storage },
	demoModeSlice.reducer,
);
