import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import styles from './WhyCreateAccountModal.module.scss';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import WhyCreateAccountContent from '@components/WhyCreateAccountContent/WhyCreateAccountContent';

const WhyCreateAccountModal = ({
	active = false,
	onClose = () => {},
	onCta = () => {},
}) => {
	const history = useHistory();

	function handleLoginNavigate () {
		onClose();
		if (history) {
			history.push('/login');
		} else {
			window.location = '/login';
		}
	}

	return (
		<Modal
			active={active}
			onClose={() => onClose()}
			className={styles.modal}>
			<h4 className={modalStyles.modalTitle}>Why Create an Account?</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<WhyCreateAccountContent />
			</div>
			<div
				className={classNames([
					modalStyles.modalActions,
					styles.modalActions,
				])}>
				<div>
					<Button variant="solid" onClick={onCta}>
						Create Account
					</Button>
					<Button
						variant="outlined"
						onClick={() => handleLoginNavigate()}>
						Log In
					</Button>
				</div>
				<Button
					variant="plain"
					onClick={onClose}
					className={styles.closeButton}>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export default WhyCreateAccountModal;
