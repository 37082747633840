import { useLayoutEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConfiguratorOptions } from '@store/reducers/configurator';

const Quote = () => {
	const dispatch = useDispatch();
	const params = useParams();

	useLayoutEffect(() => {
		dispatch(
			setConfiguratorOptions({
				quoteId: params.quoteId,
			}),
		);
	}, [dispatch, params.quoteId]);

	return <Redirect to="/quotes" />;
};

export default Quote;
