import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import styles from './QuoteSaveNotificationModal.module.scss';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import TextAreaField from '@components/TextAreaField/TextAreaField';
import {useErrorAlert} from "@hooks/useErrorAlert";

const QuoteSaveNotificationModal = ({
	active = false,
	onConfirm = async () => {},
	onClose = () => {},
}) => {
	const [isConfirming, setIsConfirming] = useState(false);
	const [comments, setComments] = useState('');
	const [, setErrorAlert] = useErrorAlert();

	/**
	 * Confirm Button Handler
	 */

	const handleConfirm = useCallback(async () => {
		if(comments && comments !== '') {
			setIsConfirming(true);
			await onConfirm(comments);
			setIsConfirming(false);
			setComments('');
		} else {
			setErrorAlert({errorTitle: 'Warning', errorMessage: 'Comment required to save!'})
		}
	}, [onConfirm, comments]);

	/**
	 * Close Button Handler
	 */

	const handleClose = useCallback(() => {
		setComments('');
		onClose();
	}, [onClose]);

	return (
		<Modal active={active} onClose={() => handleClose()}>
			<h4 className={modalStyles.modalTitle}>Save Your Changes</h4>
			<div className={classNames(modalStyles.modalBody)}>
				<p>
					Send a notification to other users about
					your changes to this quote.
				</p>
				<TextAreaField
					label="Comments"
					value={comments}
					onChange={(e) => setComments(e.target.value)}
					className={styles.commentsInput}
					inputClassName={styles.commentsInputTextarea}
				/>
			</div>
			<div className={modalStyles.modalActions}>
				<Button variant="outlined" onClick={handleClose}>
					Cancel
				</Button>
				<Button
					variant="solid"
					onClick={handleConfirm}
					loading={isConfirming}>
					Yes
				</Button>
			</div>
		</Modal>
	);
};

export default QuoteSaveNotificationModal;
