import { useCallback, useLayoutEffect } from 'react';

export const useClickOutside = (
	node = null,
	onClickOutside = () => {},
	useCapture = false,
) => {
	const onClick = useCallback(
		(e) => {
			if (node && !node.contains(e.target)) {
				onClickOutside();
			}
		},
		[node, onClickOutside],
	);

	useLayoutEffect(() => {
		document.addEventListener('click', onClick, useCapture);

		return () => {
			document.removeEventListener('click', onClick, useCapture);
		};
	}, [onClick, useCapture]);
};
