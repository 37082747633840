import React, { useState, useEffect } from 'react';
import styles from '../../VirtualSizer.module.scss';
import classNames from 'classnames';



export default function StyledSelect({ options, changeHandler, inputDisabled, field }) {

	const [showOptions, setShowOptions] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [allOptions, setAllOptions] = useState([]);
	const [updated, setUpdated] = useState('');
	const clickHandler = (e) => {
		if (inputDisabled) {
			return;
		}
		if (e.target.hasAttribute('data-index')) {
			let selected = allOptions[parseInt(e.target.getAttribute('data-index'))];
			setSelectedOption(selected);
			if (field) {
				changeHandler(field[0], selected.value);
			} else {
				changeHandler(selected.value);
			}
			setShowOptions(false);
			setUpdated(Math.random());
		} else {
			setShowOptions(!showOptions);
		}
	};
	const closeOptions = () => {
		setShowOptions(false)
	}

	useEffect(() => {
		let selected = null;
		setAllOptions(options.map(option => {
			if (!option) {
				return null;
			}
			if (!Object.prototype.hasOwnProperty.call(option, 'selected')) {
				option.selected = false;
			} else if (option.selected) {
				selected = option;
			}
			return option;
		}));
		if (!selectedOption && selected) {
			setSelectedOption(selected);
			if (field) {
				changeHandler(field[0], selected.value);
			} else {
				changeHandler(selected.value);
			}
		}
		setUpdated(Math.random());
	}, [options]);
	//'styled-selector marked-input ' + (inputDisabled?'disabled ':'') + (field[1].error || !field[1].value?'invalid ':'valid') + (!field[1].value?'empty ':'') 
	return (
		<>

			{!!options &&

				<div className={classNames({
					[styles.styledSelector]: true,
					[styles.markedInput]: true,
					[styles.disabled]: inputDisabled,
					[styles.invalid]: field[1].error || !field[1].value,
					[styles.valid]: !field[1].error && field[1].value,
					[styles.empty]: !field[1].value
				})} onClick={(e) => clickHandler(e)}>
					<span className={classNames({
						[styles.single]: true,
					})} >{selectedOption ? selectedOption.label : field[1].placeholder}</span>
					{showOptions &&
						<div className={classNames({
							[styles.optionList]: true,
							[styles.single]: true
						})}>
							{allOptions.length > 0 && allOptions.map((option, index) => {
								return (
									<span key={option.label + option.value}
										data-index={index}

										className={classNames({
											[styles.option]: true,
											[styles.selected]: selectedOption && option.value === selectedOption.value
										})}
									>
										{option.label}
									</span>
								)
							})}

						</div>
					}
				</div>
			}
			{showOptions && <div onClick={(e) => { clickHandler(e) }} className='click-catcher' />}
		</>
	)
}
