import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import SelectField from '@components/SelectField/SelectField';
import TextField from '@components/TextField/TextField';
import Switch from '@components/Switch/Switch';
import TrashIcon from '@components/Icons/TrashIcon';
import classNames from 'classnames';
import styles from './ShippingAccounts.module.scss';
import FieldRow from '@components/Field/FieldRow';
import Button from '@components/Button/Button';

const ShippingAccounts = ({
	shippingAccounts = [],
	setShippingAccounts = () => {},
}) => {
	const shippingOptions = useSelector(
		(state) => state.staticData.shippingOptions,
	);

	/**
	 * Handle Field Change
	 */

	const handleFieldChange = useCallback(
		(index, key, value) => {
			const data = [...shippingAccounts];

			data.splice(index, 1, {
				...data[index],
				[key]: value,
			});

			setShippingAccounts(data);
		},
		[shippingAccounts, setShippingAccounts],
	);

	/**
	 * Handle Default Change
	 */

	const handleDefaultChange = useCallback(
		(index, value) => {
			value = value ? '1' : '0';
			setShippingAccounts(
				shippingAccounts.map((account, a) => {
					return {
						...account,
						organization_shipping_account_is_default:
							a === index ? value : '0',
					};
				}),
			);
		},
		[shippingAccounts, setShippingAccounts],
	);

	/**
	 * Handle Delete
	 */

	const handleDelete = useCallback(
		(index) => {
			const next = shippingAccounts.filter((_, i) => i !== index);

			if (
				next.length &&
				!next.find(
					(account) =>
						account.organization_shipping_account_is_default ===
						'1',
				)
			) {
				next[0].organization_shipping_account_is_default = '1';
			}

			setShippingAccounts(next);
		},
		[shippingAccounts, setShippingAccounts],
	);

	/**
	 * Handle Add
	 */

	const handleAdd = useCallback(() => {
		setShippingAccounts([
			...shippingAccounts,
			{
				organization_shiping_account_number: '',
				shipping_option_id: '',
				organization_shipping_account_is_default: '0',
			},
		]);
	}, [shippingAccounts, setShippingAccounts]);

	/**
	 * Determine if account # is required for shipping option
	 */

	const shippingOptionRequiresAccount = useCallback(
		(shipping_option_id) => {
			const option = shippingOptions.find(
				(option) => option.shipping_option_id === shipping_option_id,
			);
			return option?.shipping_option_requires_account;
		},
		[shippingOptions],
	);

	/**
	 * Carrier Options
	 */

	const carrierOptions = useMemo(() => {
		return shippingOptions.map((option) => {
			const exists = !!shippingAccounts.find(
				(account) =>
					account.shipping_option_id === option.shipping_option_id,
			);

			return { ...option, disabled: exists };
		});
	}, [shippingOptions, shippingAccounts]);

	return (
		<div className={styles.container}>
			<ul className={styles.list}>
				{shippingAccounts.map((account, a) => (
					<li key={a}>
						<FieldRow className={styles.row}>
							<SelectField
								label="Carrier"
								options={carrierOptions}
								value={account.shipping_option_id}
								labelField="shipping_option_description"
								valueField="shipping_option_id"
								onChange={(e) => {
									handleFieldChange(
										a,
										'shipping_option_id',
										e.target.value,
									);
								}}
								className={styles.carrierField}
							/>
							<TextField
								label="Account Number"
								className={styles.accountNumberField}
								value={
									account.organization_shipping_account_number ||
									''
								}
								onChange={(e) => {
									handleFieldChange(
										a,
										'organization_shipping_account_number',
										e.target.value,
									);
								}}
								disabled={
									!shippingOptionRequiresAccount(
										account.shipping_option_id,
									)
								}
							/>
							<div className={styles.actionContainer}>
								<Switch
									label="Default"
									onChange={(value) => {
										handleDefaultChange(a, value);
									}}
									value={
										account.organization_shipping_account_is_default ===
										'1'
									}
									className={classNames({
										[styles.isDefaultField]: true,
									})}
								/>
								<div className={styles.deleteContainer}>
									<TrashIcon
										onClick={() => {
											handleDelete(a);
										}}
									/>
								</div>
							</div>
						</FieldRow>
					</li>
				))}
				<li>
					<Button
						variant="outlined"
						onClick={handleAdd}
						className={styles.addButton}>
						+ Add Account
					</Button>
				</li>
			</ul>
		</div>
	);
};

export default ShippingAccounts;
