import { useCallback } from 'react';
import classNames from 'classnames';
import fieldStyles from '@components/Field/Fields.module.scss';
import styles from './ProductPicker.module.scss';
import { API_ROOT } from '@config';

const ProductPicker = ({
	name = '',
	label = null,
	options = [],
	value = [],
	onChange = () => {},
	valueField = 'id',
	labelField = 'label',
	imageField = 'image',
	className = null,
	error = null,
}) => {
	/**
	 * Option is selected
	 */

	const isSelected = useCallback(
		(option) => {
			return !!value.find((i) => i[valueField] === option[valueField]);
		},
		[value, valueField],
	);

	/**
	 * Handle Select
	 */

	const handleSelect = useCallback(
		(option) => {
			if (value.find((i) => i[valueField] === option[valueField])) {
				onChange({
					target: {
						name,
						value: value.filter(
							(item) => item[valueField] !== option[valueField],
						),
					},
				});
			} else {
				onChange({
					target: { name, value: [...value, { ...option }] },
				});
			}
		},
		[onChange, name, value, valueField],
	);

	/**
	 * Handle Quantity Change
	 */

	const handleQuantityChange = useCallback(
		(option, quantity) => {
			quantity = quantity.replace(/[a-zA-Z]/g, '');

			const next = [
				...value.filter((i) => i[valueField] !== option[valueField]),
				{ ...option, quantity },
			];

			onChange({
				target: {
					name,
					value: next,
				},
			});
		},
		[name, value, valueField, onChange],
	);

	/**
	 * Get Quantity Value
	 */

	const getQuantityValue = useCallback(
		(option) => {
			const valueItem = value.find(
				(item) => item[valueField] == option[valueField],
			);

			return valueItem?.quantity || '';
		},
		[value, valueField],
	);

	return (
		<div
			className={classNames({
				[fieldStyles.fieldContainer]: true,
				[className]: !!className,
			})}>
			{!!label && <label>{label}</label>}
			<div className={styles.list}>
				{options.map((option, o) => (
					<li
						key={o}
						onClick={() => handleSelect(option)}
						className={classNames({
							[styles.item]: true,
							[styles.selected]: isSelected(option),
						})}>
						<img src={`${API_ROOT}${option[imageField]}`} />
						<label>{option[labelField]}</label>
						<div
							className={styles.quantityField}
							onClick={(e) => e.stopPropagation()}>
							<label>Quantity:</label>
							<input
								type="text"
								value={getQuantityValue(option)}
								onChange={(e) => {
									handleQuantityChange(
										option,
										e.target.value,
									);
								}}
								disabled={!isSelected(option)}
								className={classNames({
									[fieldStyles.fieldInput]: true,
									[styles.quantityInput]: true,
								})}
							/>
						</div>
					</li>
				))}
			</div>
			{!!error && <span className={fieldStyles.fieldError}>{error}</span>}
		</div>
	);
};

export default ProductPicker;
