import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useClickOutside } from '@hooks/useClickOutside';
import ChevronIcon from '../Icons/ChevronIcon';
import Auth, { logout } from '@store/reducers/auth';
import styles from './UserMenu.module.scss';
import Avatar from '@components/Avatar/Avatar';
import AuthService from "@services/AuthService";

const UserMenu = ({ variant = '' }) => {
	const node = useRef(null);
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);

	/**
	 * Menu Items
	 */

	const items = useMemo(() => {
		const items = [
			{
				label: 'Log Out',
				action: () => {
					AuthService.logout()
						.then(() => {
							dispatch(logout());
						})
				},
			},
		];

		if (variant !== 'my-account') {
			items.unshift({
				label: 'My Account',
				action: () => {
					history.push('/my-account');
				},
			});
		}

		return items;
	}, [dispatch, history, variant]);

	/**
	 * Initialize Menu Height
	 */

	useLayoutEffect(() => {
		node.current?.style.setProperty(
			'--itemHeight',
			items.length * 40 + 'px',
		);
	}, [items]);

	/**
	 * Close Menu on Click Outside
	 */

	useClickOutside(node.current, () => {
		setIsOpen(false);
	});

	if (!user) {
		return null;
	}

	const name = [
		user.profile_first_name + ' ',
		user.profile_last_name.charAt(0).toUpperCase(),
		user.profile_last_name ? '.' : '',
	].join('');

	return (
		<div
			ref={node}
			data-item-height={40 * items.length + 'px'}
			className={classNames({
				'user-menu': true,
				[styles.userMenuActive]: isOpen,
				[styles.container]: true,
				[styles.myAccount]: variant === 'my-account',
			})}>
			<button onClick={() => setIsOpen(!isOpen)}>
				<Avatar user={user} />
				<div className={styles.userInfo}>
					<p className={styles.userName}>{name}</p>
					{!!user.organization_id && (
						<p className={styles.organizationName}>
							{user.organization_name}
						</p>
					)}
				</div>
				<ChevronIcon className={styles.chevron} />
			</button>
			<ul className={styles.menu}>
				{items.map((item, i) => (
					<li key={i}>
						<a
							onClick={(e) => {
								e.preventDefault();
								item.action();
							}}>
							{item.label}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default UserMenu;
