import { useCallback, useState } from 'react';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import TextField from '@components/TextField/TextField';
import SuggestField from '@components/SuggestField/SuggestField';
import Button from '@components/Button/Button';
import fieldStyles from '@components/Field/Fields.module.scss';
import styles from './CopyToOrderModal.module.scss';
import classNames from 'classnames';
import CheckboxField from '@components/CheckboxField/CheckboxField';

const CopyToOrderModal = ({
	active = false,
	onClose = () => {},
	quoteId,
	className = null,
}) => {
	const [orderName, setOrderName] = useState('');
	const [fireDepartmentId, setFireDepartmentId] = useState('');
	const [orderId, setOrderId] = useState(null);
	const [error, setError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [copyExact, setCopyExact] = useState(true);

	/**
	 * Handle Modal Close
	 */

	const handleClose = useCallback(
		(order = null) => {
			// clear fields
			setOrderName('');
			setFireDepartmentId('');
			setOrderId(null);
			setError(null);
			onClose(order);
		},
		[onClose],
	);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(async () => {
		if (!orderName && !orderId) {
			setError(
				'Please enter a new order name, or search for an existing order.',
			);
			return;
		}

		let copyToId = orderId;

		try {
			setIsSubmitting(true);

			/**
			 * Create New Order
			 */

			if (!copyToId) {
				copyToId = await http()
					.post(`${API_ROOT}/orders`, {
						order: {
							order_name: orderName,
							fire_department_id: fireDepartmentId || null,
						},
					})
					.then(({ data }) => {
						return data.order.order_id;
					});
			}

			/**
			 * Copy Quote to Order
			 */
			await http()
				.post(`${API_ROOT}/orders/copy-quote-to-order/${copyToId}`, {
					quoteId,
					skipRulesValidation: true,
					copyExact: true,
				})
				.then(({ data }) => {
					handleClose(data.order);
				});
		} catch {
			setIsSubmitting(false);
		}
	}, [orderName, fireDepartmentId, orderId, quoteId, handleClose, copyExact]);

	return (
		<Modal
			active={active}
			onClose={() => handleClose()}
			className={classNames({ [className]: !!className })}>
			<h4 className={modalStyles.modalTitle} style={{marginBottom: 24}}>Reorder Exact Quote</h4>
			<p className={modalStyles.modalDescription}>This will create an order with the same quote number. You will need to add sizing, but will not be able to make changes to the quote options.</p>
			<div
				className={classNames({
					[modalStyles.modalBody]: true,
					[styles.form]: true,
				})}>
				<TextField
					label="New Order Name"
					value={orderName}
					onChange={(e) => {
						setOrderName(e.target.value);
						setError(null);
					}}
				/>
				<p className={styles.separator}>OR</p>
				<SuggestField
					label="Existing Order Name or Number"
					value={orderId}
					valueField="order_id"
					url={`${API_ROOT}/orders`}
					getResults={(data) => data.orders}
					labelExtractor={(order) =>
						`${order.order_name} - ${order.order_id}`
					}
					onChange={(e) => {
						setError(null);
						setOrderId(e.target.value);
					}}
				/>

			</div>
			{!!error && (
				<div className={styles.formErrorContainer}>
					<span className={fieldStyles.fieldError}>{error}</span>
				</div>
			)}
			<div className={modalStyles.modalActions}>
				<Button
					variant="solid"
					onClick={handleSubmit}
					loading={isSubmitting}>
					Reorder
				</Button>
				<Button variant="plain" onClick={() => handleClose()}>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export default CopyToOrderModal;
