import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
			<path d="M4.185 20.853l7.335-7.347 8.096 8.11 1.947-1.95-8.096-8.11 7.285-7.298-1.841-1.844-7.302 7.314L3.51 1.616l-1.947 1.95 8.096 8.11-7.317 7.33z" />
		</svg>
	);
}

export default SvgComponent;
