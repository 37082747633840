import React from 'react';
import { contactPhone, contactEmail } from '@config/contact';
import styles from './PublicFooter.module.scss';
import { useSelector } from 'react-redux';
import { API_ROOT } from '@config';

const Footer = () => {
	const content = useSelector((state) => state.staticData.content);
	const logo = content?.logos?.indexed['footer-logo-light'];

	return (
		<div className={styles.container}>
			{!!logo && (
				<a
					href={logo.content_item_url}
					target="_blank"
					rel="noopener noreferrer">
					<img
						src={`${API_ROOT}/uploaded/content/${logo?.content_item_image}`}
						className={styles.logo}
					/>
				</a>
			)}
			<ul>
				<li>
					{window.location.pathname !== '/' && (
						<a
							href={`mailto:${contactEmail}`}
							target="_blank"
							rel="noopener noreferrer">
							Message Us
						</a>
					)}
				</li>
				<li>
					<a
						href={`tel:${contactPhone}`}
						target="_blank"
						rel="noopener noreferrer">
						Call Us: (330) 723-0000
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Footer;
