import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import styles from './TCMValidationModal.module.scss';
import classNames from 'classnames';

const TCMValidationModal = ({
	active = false,
	title = 'More Information Needed',
	errors = {},
	onClose = () => {},
}) => {
	return (
		<Modal active={active} onClose={() => onClose()}>
			<h4 className={modalStyles.modalTitle}>{title}</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<ul className={styles.errorsList}>
					{Object.keys(errors).map((key) => (
						<li key={key}>{errors[key]}</li>
					))}
				</ul>
			</div>
			<div className={modalStyles.modalActions}>
				<Button variant="solid" onClick={onClose}>
					Back
				</Button>
			</div>
		</Modal>
	);
};

export default TCMValidationModal;
