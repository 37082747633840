import React from 'react';
import styles from './NoItems.module.scss';

const NoItems = ({ children = null }) => {
	return (
		<div className={styles.noItemsContainer}>
			<p>
				Found <b>0</b> Results
			</p>
			<div className={styles.noItemsContent}>{children}</div>
		</div>
	);
};

export default NoItems;
