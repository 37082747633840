import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import classNames from "classnames/bind";
import fieldStyles from "@components/Field/Fields.module.scss";
import styles from "../ConfiguratorBuild.module.scss";
import Select from "react-select";
import plusicon from "@assets/images/plus-icon.svg";
import {getProductOptionValueLabel} from "@utilities/methods";
import {API_ROOT} from "@config";
import trashcanBlack from "@assets/images/trashcan-black.svg";
import * as textAreaStyles from "@components/TextAreaField/TextAreaField.module.scss";


export const custom_x_default = "101"
export const custom_y_default = "-1"


function optionIsTextArea(productOption) {
    return productOption?.product_option_type_id === '6';
}

const ConfiguratorBuildInput = ({
                                    productOption,
                                    validateProductOptionDependentProductOptionsSet,
                                    isEditable,
                                    productOptionCategory,
                                    optionIndex,
                                    state,
                                    setState,
                                    optionIsMultiselect,
                                    getProductOptionSelectedValues,
                                    optionValueIsAvailable,
                                    optionValueIsSelected,
                                    deselectOptionValue,
                                    deselectOptionValues,
                                    setOptionValueSelected,
                                    textStateFunctions,
                                    categoryActive,
                                    getAvailablePositions,
                                    getPositionOptionById,
                                    getPositionOptionByToken,
                                    filterOverlayOptions,
                                    setImageOrientation,
                                }) => {

    let optionValuesOutput;
    let optionWrapperClassNames = [];
    const dependentProductOptionsSet = validateProductOptionDependentProductOptionsSet(productOption);
    const messageRef = useRef(null);
    const [positionMessage, setPositionMessage] = useState(null);
    function optionIsSelect(productOption) {
        return productOption.product_option_type_id === '4';
    }
    const positionInstructions = useSelector(
        (state) => state.staticData.orderSettings['custom_placement_instructions']|| "Enter Custom Positioning Instructions",
    );
    const [hoverImage, setHoverImage] = useState(null);



    function optionIsConfigurable(productOption) {
        return productOption.product_option_type_id === '3' || productOption.product_option_type_id === '5';
    }

    function initializeOptionValueConfigurator(categoryId, categoryOptionIndex, productOption, currentlyConfiguringOptionValueIndex = null) {
        setState((prevState) => {
            return {
                ...prevState,
                configuringCategoryId: categoryId,
                configuringProductOptionIndex: categoryOptionIndex,
                configuringProductOption: productOption,
                currentlyConfiguringOptionValueIndex,
                ...(
                    currentlyConfiguringOptionValueIndex !== null
                        ?
                        {
                            optionValueConfigurationTemp: prevState.quoteProductOptionValues[currentlyConfiguringOptionValueIndex],
                        }
                        : {}
                ),
            };
        });
    }

    const handleSelectHover = (e) =>{
        if(!e?.target?.id.includes('react-select-') || !e?.target?.closest('.select-outer') || !e?.target?.closest('.select-outer').classList.contains('option')){
            setHoverImage(null);
            return;
        }
        let index = Math.max(parseInt(e.target.id.split('-')[4]), 0);
        let hoverOption = productOption.values.filter(productOptionValue => optionValueIsAvailable(productOption.product_option_id, productOptionValue.product_option_value_id))[index];
        if (!hoverOption) {
            setHoverImage(null);
            return;
        }
        let photo = getProductOptionValuePhoto(hoverOption);
        if (!photo) {
            setHoverImage(null);
            return;
        }
        const rect = e.target.closest(".option-select-container").getBoundingClientRect();
        const innerRect = e.target.getBoundingClientRect();
        const y = e.clientY - rect.top;
        const innerY = e.clientY - innerRect.top;
        photo.topOffset = (y-100) + (innerY<5?innerRect.height/2:-innerRect.height/2);
        setHoverImage(photo);
    }

    function removeQuoteProductOptionValueByIndex(quoteProductOptionValueIndex) {
        const quoteProductOptionValue = state.quoteProductOptionValues[quoteProductOptionValueIndex];
        if(!quoteProductOptionValue?.product_option_value_id){
            return;
        }
        const valueCount = state.quoteProductOptionValues.filter(optionValue=>optionValue.product_option_value_id == quoteProductOptionValue.product_option_value_id).length;
        setState(prevState => {
            const quoteProductOptionValues = [...prevState.quoteProductOptionValues,];
            quoteProductOptionValues.splice(quoteProductOptionValueIndex, 1);

            return {
                ...prevState,
                quoteProductOptionValues,
            };
        });
        if(valueCount === 1){
            deselectOptionValue(quoteProductOptionValue.product_option_value_id);
        }
    }

    function getProductOptionValuePhoto(productOptionValue) {
        //Find photo for specific product, if found return it
        const productPhotos = productOptionValue?.photos.filter(
            (photo) => photo.product_id === state.productId,
        );
        if (productPhotos?.length) {
            return productPhotos[0];
        }

        //else return the first photo in the list, if any exist
        if (productOptionValue?.photos.length) {
            return productOptionValue.photos[0];
        }

        return null;
    }


    function getCurrentSelectedPosition(positionId, productOptionValueMainIndex) {

        let positions = getAvailablePositions(positionId)
        if(positions.length === 1) {
            return positions[0];
        }

        let selectedPosition = state.configurableOptionPositions.find(position => state.quoteProductOptionValues[productOptionValueMainIndex]?.product_option_value_position_id === position?.product_option_value_position_id);
        return selectedPosition ? selectedPosition : null;
    }

    function setCustomPlacementInstructions(quoteProductOptionValueIndex, e) {
        setState((prevState) => {
            const updatedQuoteProductOptionValues = prevState.quoteProductOptionValues.map((optValue, index) => {
                if (index === quoteProductOptionValueIndex) {
                    optValue.quote_product_option_value_message = e.target.value;
                }
            });

            return {
                ...prevState,
                updatedQuoteProductOptionValues,
            }
        })
    }


    const getPositioningElements = (productOption) => {
        return (
            <>
                {
                    getProductOptionSelectedValues(productOption)
                        .map(productOptionValue => {
                            const productOptionValueMainIndex = state.quoteProductOptionValues.findIndex(quoteProductOptionValue => {
                                return quoteProductOptionValue.product_option_value_id === productOptionValue.product_option_value_id
                            });
                            if(productOptionValueMainIndex < 0) return null;

                            let positionId = productOptionValue.position_option_id || productOption.position_option_id;
                            let positionObj = getPositionOptionById(positionId);

                            if(!positionObj) return null;

                            if(positionObj.position_option_token === 'static') return null;

                            let positionToken = positionObj.position_option_token;

                            //If customizable position only but no image, don't show positioning option
                            if (positionToken === 'custom') {
                                if ((productOptionValue.images?.length < 1 && productOptionValue.imageStyles?.length < 1)
                                    &&
                                    (productOptionValue.mirrorImages?.length < 1 && productOptionValue.mirrorImageStyles?.length < 1)) {
                                    return null;
                                }
                            }   else if (
                                positionToken === 'left-right-custom' ||
                                positionToken === 'left-custom' ||
                                positionToken === 'right-custom') {
                                //If customize an option but no images, remove customize as option
                                if ((productOptionValue.images?.length < 1 && productOptionValue.imageStyles?.length < 1)
                                    &&
                                    (productOptionValue.mirrorImages?.length < 1 && productOptionValue.mirrorImageStyles?.length < 1)) {
                                    switch (positionToken) {
                                        case 'left-right-custom':
                                            positionToken = 'left-right';
                                            break;
                                        case 'left-custom':
                                            positionToken = 'left-only';
                                            break;
                                        case 'right-custom':
                                            positionToken = 'right-only';
                                            break;
                                    }
                                    positionObj = getPositionOptionByToken(positionToken);
                                    positionId = positionObj.position_option_id;
                                }

                            }

                            productOption.position_option_token = positionObj.position_option_token;
                            productOptionValue.position_option_token = positionObj.position_option_token;
                            if (positionObj.position_option_token === 'custom' && state.productId === '1') {
                                return (
                                    <React.Fragment key={productOptionValue.product_option_value_id}>
                                        <p style={{width: '100%'}}>
                                            {positionInstructions}
                                        </p>
                                        <div className={classNames({
                                            [fieldStyles.fieldContainer]: true,
                                            [styles.fullWidth]: true,
                                        })}>
                                            <textarea
                                                className={classNames({
                                                    [fieldStyles.fieldInput]: true,
                                                    [fieldStyles.disabled]: !isEditable,
                                                })}
                                                style={{minHeight: 80}}
                                                disabled={!isEditable}
                                                defaultValue={state.quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_message}
                                                value={positionMessage}
                                                onChange={(e) => {
                                                    setPositionMessage(e.target.value);
                                                }}
                                                onBlur={e => {
                                                    setState(prevState => {

                                                        const quoteProductOptionValues = [...prevState.quoteProductOptionValues];
                                                        quoteProductOptionValues.splice(productOptionValueMainIndex, 1, {
                                                            ...quoteProductOptionValues[productOptionValueMainIndex],
                                                            quote_product_option_value_message: e.target.value,

                                                        });

                                                        return {
                                                            ...prevState,
                                                            quoteProductOptionValues,
                                                        };
                                                    });
                                                }}
                                            >
                                            </textarea>
                                        </div>

                                    </React.Fragment>
                                );
                            } else {
                                    let availablePositions = getAvailablePositions(positionId);
                                    if(availablePositions.length === 0)
                                        return null;

                                    return (
                                        <div key={productOptionValue.product_option_value_id} style={{width: '100%', marginTop: 10}}>
                                            <div className="select-outer">
                                                <Select
                                                    value={getCurrentSelectedPosition(positionId, productOptionValueMainIndex)}
                                                    options={availablePositions}
                                                    isOptionSelected={(position) => {
                                                        return state.quoteProductOptionValues[productOptionValueMainIndex]?.product_option_value_position_id === position?.product_option_value_position_id;
                                                    }}
                                                    isDisabled={!isEditable}
                                                    getOptionLabel={(position) => position?.product_option_value_position_title}
                                                    getOptionValue={(position) => position?.product_option_value_position_id}
                                                    onChange={(position, details) => {
                                                        setState((prevState) => {
                                                            const quoteProductOptionValues = [...prevState.quoteProductOptionValues];
                                                            quoteProductOptionValues[productOptionValueMainIndex].product_option_value_position_id = position?.product_option_value_position_id;

                                                            if(position?.product_option_value_position_id !== '3') {
                                                                quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_custom_position_top = null;
                                                                quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_custom_position_left = null;
                                                                quoteProductOptionValues[productOptionValueMainIndex].product_option_value_custom_position_side = null;
                                                                quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_message = "";
                                                                setPositionMessage(null);
                                                            }else{
                                                                quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_custom_position_top = quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_custom_position_top || custom_y_default,
                                                                    quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_custom_position_left = quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_custom_position_left || custom_x_default,
                                                                    quoteProductOptionValues[productOptionValueMainIndex].position_option_token = positionObj.position_option_token,
                                                                    quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_position_token = positionObj.position_option_token,
                                                                    quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_message = messageRef?.current?.value || "",
                                                                    quoteProductOptionValues[productOptionValueMainIndex].newCustomize = true
                                                                quoteProductOptionValues[productOptionValueMainIndex].product_option_value_custom_position_side = 'front';
                                                                setImageOrientation('front');
                                                            }

                                                            return {
                                                                ...prevState,
                                                                quoteProductOptionValues
                                                            };
                                                        })
                                                    }}
                                                    isMulti={false}
                                                />
                                                {
                                                    state.quoteProductOptionValues[productOptionValueMainIndex].product_option_value_position_id === '3' && (
                                                        <React.Fragment key={productOptionValue.product_option_value_id}>
                                                            <div style={{marginTop: 10}}>
                                                                <Select
                                                                    value={state.quoteProductOptionValues[productOptionValueMainIndex].product_option_value_custom_position_side
                                                                        ? state.configurableOptionPositionSides.find(side => side.value === state.quoteProductOptionValues[productOptionValueMainIndex].product_option_value_custom_position_side)
                                                                        : null}
                                                                    options={state.configurableOptionPositionSides}
                                                                    isOptionSelected={(side) => {
                                                                        return state.quoteProductOptionValues[productOptionValueMainIndex]?.product_option_value_custom_position_side === side?.value;
                                                                    }}
                                                                    isDisabled={!isEditable}
                                                                    getOptionLabel={(side) => side?.label}
                                                                    getOptionValue={(side) => side?.value}
                                                                    onChange={(side, details) => {
                                                                        setState((prevState) => {
                                                                            const quoteProductOptionValues = [...prevState.quoteProductOptionValues];
                                                                            quoteProductOptionValues[productOptionValueMainIndex].product_option_value_custom_position_side = side?.value;
                                                                            quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_custom_position_top = custom_y_default;
                                                                            quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_custom_position_left = custom_x_default;
                                                                            setImageOrientation(side.value);

                                                                            return {
                                                                                ...prevState,
                                                                                quoteProductOptionValues
                                                                            };
                                                                        })
                                                                    }}
                                                                    isMulti={false}
                                                                />
                                                            </div>
                                                            <p>
                                                                {positionInstructions}
                                                            </p>
                                                            <div className={classNames({
                                                                [fieldStyles.fieldContainer]: true,
                                                                [styles.fullWidth]: true,
                                                            })}>
                                                                <textarea
                                                                    className={classNames({
                                                                        [fieldStyles.fieldInput]: true,
                                                                        [fieldStyles.disabled]: !isEditable,
                                                                    })}
                                                                    disabled={!isEditable}
                                                                    style={{minHeight: 80}}
                                                                    defaultValue={state.quoteProductOptionValues[productOptionValueMainIndex].quote_product_option_value_message}
                                                                    value={positionMessage}
                                                                    onChange={(e) => {
                                                                        setPositionMessage(e.target.value);
                                                                    }}
                                                                    onBlur={e=>{
                                                                        setState(prevState => {
                                                                            const quoteProductOptionValues = [...prevState.quoteProductOptionValues];
                                                                            quoteProductOptionValues.splice(productOptionValueMainIndex, 1, {
                                                                                ...quoteProductOptionValues[productOptionValueMainIndex],
                                                                                quote_product_option_value_message: e.target.value,

                                                                            });

                                                                            return {
                                                                                ...prevState,

                                                                                quoteProductOptionValues,
                                                                            };
                                                                        });
                                                                    }}
                                                                >
                                                                </textarea>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    );

                            }
                        })
                }
            </>
        );
    }
    let availableOptions = productOption.values.filter(productOptionValue => optionValueIsAvailable(productOption.product_option_id, productOptionValue.product_option_value_id));
    if(availableOptions.length === 0) {
        return null;
    }
    if (optionIsConfigurable(productOption)) {

        optionValuesOutput =
            (
                <div>
                    { filterOverlayOptions(productOption.values.filter(productOptionValue => optionValueIsAvailable(productOptionValue.product_option_id, productOptionValue.product_option_value_id))).length > 0 &&
                        <button
                            className={classNames(
                                'option-value',
                                'button-type',
                            )}
                            onClick={() => {
                                if (isEditable)
                                    initializeOptionValueConfigurator(
                                        productOptionCategory.product_option_category_id,
                                        optionIndex,
                                        productOption,
                                    )
                            }
                            }>
                        <span>
                            Add {productOption.product_option_description}
                        </span>
                            <img src={plusicon}/>
                        </button>
                    }
                    {
                        state.quoteProductOptionValues.map((quoteProductOptionValue, index) => {
                                if (quoteProductOptionValue.product_option_id === productOption.product_option_id) {
                                    let selectedIndex = -1;
                                    let defaultIndex = -1;
                                    productOption.values.every((productOptionValue, index) => {
                                        if (productOptionValue.product_option_value_id === quoteProductOptionValue.product_option_value_id) {
                                            selectedIndex = index;
                                            return false;
                                        }
                                        if (productOptionValue.product_option_value_is_default > 0) {
                                            defaultIndex = index;
                                        }
                                        return true;
                                    });

                                    const selectedProductOptionValueIndex = selectedIndex >= 0 ? selectedIndex : defaultIndex;
                                    // Catch options that are no longer available for quotes that are complete
                                    if (selectedProductOptionValueIndex >= 0
                                        || (selectedProductOptionValueIndex === -1 && !isEditable)
                                    ) {

                                        const selectedProductOptionValue = (selectedProductOptionValueIndex === -1 && !isEditable) ? quoteProductOptionValue : productOption.values[selectedProductOptionValueIndex];

                                        const photo = getProductOptionValuePhoto(
                                            selectedProductOptionValue,
                                        );

                                        const selectedPositionIndex = state.configurableOptionPositions
                                            .findIndex((position) =>
                                                position.product_option_value_position_id === quoteProductOptionValue.product_option_value_position_id,
                                            );

                                        const selectedPosition = state.configurableOptionPositions[selectedPositionIndex];

                                        return (
                                            <div key={index}
                                                 className={classNames(
                                                     'configured-wrapper',
                                                 )}
                                                 onClick={() => {
                                                     if (isEditable)
                                                         initializeOptionValueConfigurator(
                                                             productOptionCategory.product_option_category_id,
                                                             optionIndex,
                                                             productOption,
                                                             index,
                                                         )
                                                 }}>
                                                <div
                                                    key={index}
                                                    className={classNames(
                                                        'option-value',
                                                        'configured',
                                                    )}>
                                                    <div className="configured-option-value-info-container">
                                                        <div className="configured-option-value-info">
                                                            {getProductOptionValueLabel(
                                                                selectedProductOptionValue,
                                                            )}
                                                            {
                                                                quoteProductOptionValue.quote_product_option_value_quantity !== null && quoteProductOptionValue.quote_product_option_value_quantity > 1 ?
                                                                    ' x ' + quoteProductOptionValue.quote_product_option_value_quantity
                                                                    : null
                                                            }
                                                            <br/>
                                                            {selectedPosition ? selectedPosition.product_option_value_position_title : null}

                                                        </div>
                                                        {photo ? (
                                                            <img
                                                                className={classNames({
                                                                    "configured-option-value-photo": true,
                                                                    "large": true,
                                                                })}
                                                                src={`${API_ROOT}${photo.product_option_value_photo_file_url}`}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    {isEditable && (
                                                        <img src={trashcanBlack}
                                                             className="delete-action"
                                                             onClick={(e) => {
                                                                 e.stopPropagation();
                                                                 removeQuoteProductOptionValueByIndex(
                                                                     index,
                                                                 );
                                                             }}
                                                        />
                                                    )}


                                                </div>
                                                {photo ? (
                                                    <img
                                                        className={classNames({
                                                            "configured-option-value-photo": true,
                                                            "small": true,
                                                        })}
                                                        src={`${API_ROOT}${photo.product_option_value_photo_file_url}`}
                                                    />
                                                ) : null}
                                                {quoteProductOptionValue.product_option_value_position_id === '3' &&
                                                    <div
                                                        className={classNames({
                                                            [fieldStyles.fieldContainer]: true,
                                                        })}
                                                        style={{padding:20}}
                                                    >
                                                        <p>
                                                            {positionInstructions}
                                                        </p>
                                                        <textarea
                                                            disabled={!isEditable}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                            className={classNames({
                                                                [fieldStyles.fieldInput]: true,
                                                                [fieldStyles.disabled]: !isEditable,
                                                            })}
                                                            style={{ minHeight: 80 }}
                                                            value={quoteProductOptionValue.quote_product_option_value_message}
                                                            onChange={customPlacement => {
                                                                setCustomPlacementInstructions(
                                                                    index,
                                                                    customPlacement
                                                                );
                                                            }}
                                                        >
                                                     </textarea>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    }
                                }
                            },
                        )}
                </div>
            );
    } else if (optionIsSelect(productOption) || optionIsMultiselect(productOption) || productOption.values.length > 6) {
        const selectedProductOptionValues = getProductOptionSelectedValues(productOption);
        const photos = selectedProductOptionValues.map((productOptionValue) => getProductOptionValuePhoto(productOptionValue));
        optionWrapperClassNames.push('select-option-wrapper');

        optionValuesOutput =
            (
                <div className="option-select-container"  onMouseOver={(e)=>{handleSelectHover(e)}} onMouseLeave={e=>{setHoverImage(null)}}>
                    {hoverImage && (
                        <div style={{top:(hoverImage.topOffset||0)+'px'}} className="hover-image-container">
                            <img src={`${API_ROOT}${hoverImage.product_option_value_photo_file_url}`} />
                        </div>
                    )}
                    <Select
                        value={getProductOptionSelectedValues(productOption)}
                        className="select-outer option"
                        options={[
                            ...(
                                !optionIsMultiselect(productOption) ?
                                    [{
                                        product_option_value_id: '',
                                        product_option_value_description: "Select " + productOption.product_option_description
                                    }] :
                                    []
                            ),
                            ...productOption.values.filter(productOptionValue => optionValueIsAvailable(productOption.product_option_id, productOptionValue.product_option_value_id))
                        ].filter(productOptionValue => productOptionValue?.product_option_value_id)}
                        isOptionSelected={(productOptionValue) => optionValueIsSelected(productOptionValue.product_option_value_id)}
                        getOptionLabel={(productOptionValue) => getProductOptionValueLabel(productOptionValue)}
                        getOptionValue={(productOptionValue) => productOptionValue.product_option_value_id}
                        isOptionDisabled={(productOptionValue) => !optionValueIsAvailable(productOption.product_option_id, productOptionValue.product_option_value_id) && productOptionValue.product_option_value_id}
                        isDisabled={!dependentProductOptionsSet || !isEditable}
                        isClearable={isEditable}

                        onChange={(productOptionValue, details) => {
                            console.log('details.action: ', details.action)
                            setHoverImage(null);
                            //If Multiselect
                            if (optionIsMultiselect(productOption)) {
                                if (details.action === 'select-option') {
                                    setOptionValueSelected(
                                        productOptionCategory.product_option_category_id,
                                        optionIndex,
                                        details.option
                                    );
                                } else if (details.action === 'remove-value') {
                                    deselectOptionValue(details.removedValue.product_option_value_id);
                                } else if (details.action === 'clear') {
                                    deselectOptionValues(details.removedValues.map(removedValue => removedValue.product_option_value_id))
                                }
                            } else {
                                if (details.action === 'select-option') {
                                    setOptionValueSelected(
                                        productOptionCategory.product_option_category_id,
                                        optionIndex,
                                        productOptionValue
                                    );
                                } else if (details.action === 'remove-option') {
                                    deselectOptionValue(productOptionValue.product_option_value_id);
                                }else if (details.action === 'clear') {
                                    deselectOptionValues(details.removedValues.map(removedValue => removedValue.product_option_value_id))
                                }
                            }
                        }}
                        isMulti={optionIsMultiselect(productOption)}
                    />
                    {
                        productOption.position_option_token !== 'static' && getPositioningElements(productOption)
                    }
                    {photos.length ? (
                        <div className="select-photos-container">
                            {photos.map((photo, index) => {
                                if (photo) {
                                    return (
                                        <a className="photo-loose"
                                           key={photo.product_option_value_photo_id}
                                           href={`${API_ROOT}${photo.product_option_value_photo_file_url}`} target="_blank"
                                           rel="noreferrer">
                                            <img
                                                key={index}
                                                src={`${API_ROOT}${photo.product_option_value_photo_file_url}`}
                                            />
                                        </a>
                                    );
                                }

                                return null;
                            })}
                        </div>
                    ) : null}
                </div>
            );
    } else if (optionIsTextArea(productOption)){
        optionValuesOutput =
            (
                <div
                    className={classNames(
                        'option-values-container',
                        {active: categoryActive},
                    )}>
                    <p style={{marginBottom:'10px'}}>
                        {productOption.values.length >= 1 ? 'This section can be used with prior approval from your Sales Representative or Customer Service Representatives.' : ''}
                    </p>
                    <div
                        className={classNames({
                            [fieldStyles.fieldContainer]: true,
                            [styles.fullWidth]: true,
                        })}>
                        <div  className={textAreaStyles.inputContainer}>
                    <textarea
                        className={classNames({
                            [fieldStyles.fieldInput]: true,
                            [fieldStyles.disabled]: !isEditable,
                        })}
                        defaultValue={textStateFunctions.getter(productOption)}
                        ref={messageRef}
                        disabled={!isEditable}
                        onChange={e => {
                            textStateFunctions.setter(
                                productOptionCategory.product_option_category_id,
                                optionIndex,
                                productOption.values[0],
                                e.target.value
                                );
                        }}
                    >
                    </textarea>
                        </div>

                    </div>
                </div>
            )

    } else {
        var countDisabled = 0;
        const selectedValues = getProductOptionSelectedValues(productOption);

        // If quote is not editable, add missing values that are no longer available
        // to be displayed in the builder
        let oldValues = [];
        if (!isEditable) {
            state.quoteProductOptionValues.map((quoteProductOptionValue, index) => {
                if (quoteProductOptionValue.product_option_id === productOption.product_option_id) {
                    let optionValueIndex = -1;
                    productOption.values.every((productOptionValue, index) => {
                        if (productOptionValue.product_option_value_id === quoteProductOptionValue.product_option_value_id) {
                            optionValueIndex = index;
                            return false;
                        }
                        return true;
                    });
                    if (optionValueIndex === -1) {
                        oldValues.push(quoteProductOptionValue);
                    }
                }
            });
        }
        optionValuesOutput =
            (
                <div
                    className={classNames(
                        'option-select-container',
                        {active: categoryActive},
                    )}>
                    {

                        [...productOption.values, ...oldValues].map(
                            (productOptionValue) => {
                                const isSelected = selectedValues.find(selectedValue => selectedValue.product_option_value_id === productOptionValue.product_option_value_id);
                                const isDisabled = !optionValueIsAvailable(productOption.product_option_id, productOptionValue.product_option_value_id) || !dependentProductOptionsSet || productOptionValue.product_option_value_hide_option === '1';

                                const photo = getProductOptionValuePhoto(productOptionValue);

                                if(isDisabled)
                                    countDisabled += 1 ;
                                return (
                                    <div
                                        key={productOptionValue.product_option_value_id}
                                        className={classNames(
                                            'option-value-wrapper',
                                            {
                                                active: !!isSelected
                                            },
                                            {
                                                disabled: isEditable && isDisabled,
                                            },
                                        )}
                                        onClick={() => {
                                            if (isEditable)
                                                setOptionValueSelected(
                                                    productOptionCategory.product_option_category_id,
                                                    optionIndex,
                                                    productOptionValue,
                                                )
                                        }
                                        }>
                                        <div className="option-value">
                                            {photo ? (
                                                <div className="option-value-photo-container-outer">
                                                    <div className="option-value-photo-container-inner">
                                                        <img
                                                            className="option-value-photo"
                                                            src={`${API_ROOT}${photo.product_option_value_photo_file_url}`}
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="option-value-label-container">
                                                <span>
                                                    {getProductOptionValueLabel(productOptionValue)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            },
                        )}

                    {
                        productOption.position_option_token !== 'static' && getPositioningElements(productOption)
                    }
                </div>
            );
    }

    return (
        <div
            key={productOption.product_option_id}
            className={classNames(
                'option-wrapper',
                {active: categoryActive,},
                {hidden: !dependentProductOptionsSet || (countDisabled !== undefined && countDisabled === productOption.values.length)},
                optionWrapperClassNames,
            )}>
            <div className="option-title">
                {productOption.product_option_description}
            </div>
            {optionValuesOutput}
        </div>
    );

}

export default ConfiguratorBuildInput
