import {React, useCallback, useState } from 'react';
import classNames from 'classnames';
import http from '@utilities/Http';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import { API_ROOT } from '@config';
import styles from "../ShareQuoteModal/ShareQuoteModal.module.scss";
import fStyles from '../VirtualSizer/VirtualSizer.module.scss';

const SizerInviteModal = ({ quoteId, active = false, onClose = () => {} }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [email, setEmail] = useState('');
	const [error, setError] = useState(null);

	/**
	 * Handle Close
	 */

	const handleClose = useCallback(
		(didChange = false) => {
			setEmail('');
			setError('');
			onClose(didChange);
		},
		[onClose],
	);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async () => {


			if (!email) {
				setError(
					"Please enter the email address you'd like to share this quote with",
				);
				return;
			}

			if (!/\S+@\S+\.\S+/.test(email)) {
				setError('Please enter a valid email address');
				return;
			}

			try {
				await http().post(`${API_ROOT}/sizing/send-sizing-invite/${quoteId}`, {
					email
				});
				handleClose(true);
			} catch (err) {
				setError(err.response?.data?.meta?.message || 'Something went wrong');
			} finally {
				setIsSubmitting(false);
			}

			setIsSubmitting(false);
		},
		[email, quoteId, handleClose],
	);

	return (
		<Modal active={active} onClose={handleClose} domNode={document.body}>
			<div className={styles.modalTitleContainer}>
				<h4 className={modalStyles.modalTitle}>
					Share Sizing Form
				</h4>
			</div>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<div className={classNames({[styles.field]:true,[fStyles.virtualSizer]:true})}>
					<span style={{marginBottom:24,display:'block'}}>
						Enter an <b>email address</b> to invite a user to select their sizing.
					</span>
					<div className={classNames({[fStyles.fieldList]:true,[fStyles.modalForm]:true})}>
					<label className={classNames({
							[fStyles.fullRow]: true,
							[fStyles.singleRow]: true,
					})}>
							<span className={classNames({ [fStyles.title]: true })}>Email </span>
							<div className={classNames({
								[fStyles.valid]: !error,
								[fStyles.invalid]: error,
								[fStyles.empty]: !email
							})}>
								<input name={'invite_email'} className={classNames({
									[fStyles.invalid]: error || !email,
								})}
									type={'text'} value={email} placeholder={'Email'} onChange={(e) => { setEmail(e.target.value) }} />
							</div>
						</label>
						
						<Button
							className={classNames({[fStyles.modalButton]:true})}
							variant="solid"
							onClick={() => handleSubmit()}
							loading={isSubmitting}>
							Send Invite
						</Button>
					</div>
				</div>
			</div>
			<div
				className={classNames(
					modalStyles.modalActions,
					styles.modalActions,
				)}>
				<p onClick={() => handleClose(false)}>Close</p>
			</div>
		</Modal>
	);
};
export default SizerInviteModal;