import Screen from "@components/Screen/Screen";
import {useSelector} from "react-redux";
import styles from "./OrderTerms.module.scss";

export default function OrderTerms(){
    const terms = useSelector(
        (state) => state.staticData.orderSettings['order_terms_and_conditions']|| "",
    );


    return (
        <Screen >
            <div className={styles.tAndC} dangerouslySetInnerHTML={{__html: terms}}  />
        </Screen>
    )
}