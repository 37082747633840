import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M4.993 8.066a3.073 3.073 0 11.004-6.146 3.073 3.073 0 01-.004 6.146zm4.248-.5a4.982 4.982 0 10-1.677 1.676l3.38 3.38 1.679-1.677z" />
		</svg>
	);
}

export default SvgComponent;
