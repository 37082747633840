import Button from '@components/Button/Button';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './CreateAccountCTA.module.scss';

const CreateAccountCTA = () => {
	const history = useHistory();
	const content = useSelector((state) => state.staticData.content);
	const html =
		content['home-page']?.indexed['create-account-cta']?.content_item_html;

	return (
		<div className={styles.container}>
			<div
				dangerouslySetInnerHTML={{
					__html: html,
				}}
			/>
			<Button
				variant="solid"
				onClick={() => {
					history.push('/create-account');
				}}>
				Create New Account
			</Button>
		</div>
	);
};

export default CreateAccountCTA;
