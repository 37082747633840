import { createSlice } from '@reduxjs/toolkit';

const mobileMenuSlice = createSlice({
	name: 'mobileMenu',
	initialState: {
		open: false,
	},
	reducers: {
		setOpen(state, action) {
			return {
				...state,
				open: action.payload,
			};
		},
	},
});

export const { setOpen } = mobileMenuSlice.actions;
export default mobileMenuSlice.reducer;
