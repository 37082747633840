import {useEffect, useState, createRef} from 'react';
import Card from '@components/Card/Card';
import Screen from '@components/Screen/Screen';
import PublicFooter from '../../components/PublicFooter/PublicFooter';
import Http from "@utilities/Http";
import {API_ROOT} from "@config";
import classNames from "classnames/bind";
import InputSlider from "react-input-slider";
import Button from "@components/Button/Button";
import CheckboxField from "@components/CheckboxField/CheckboxField";
import {useErrorAlert} from "@hooks/useErrorAlert";

const SketchConfigurator = ({match}) => {
    const productOptionImageStyleId = match.params?.productOptionImageStyleId;
    const letteringPlacementStyleId = match.params?.letteringPlacementStyleId;

    const [state, setState] = useState({
        productOptionImageStyle: {},
        productOptionValueImage: {},
        outlineImage: {},
        imagePositionLocked: true,
        top: null,
        left: null,
        bottom: null,
        right: null,
        width: null,
        zIndex: null,
        transform: null,
        fromVertical: 'top',
        fromHorizontal: 'left',
        location: 'front',
        initialized: false,
        isSuspender: false,
    });

    const imageRef = createRef(null);
    const imagePreviewRef = createRef(null);

    const [, setErrorAlert] = useErrorAlert();


    function setImagePositionLocked() {
        if (state.initialized) {
            setState(prevState => {
                return {
                    ...prevState,
                    imagePositionLocked: true
                };
            });
        }
    }


    function setImagePositionUnlocked() {
        if (state.initialized) {
            setState(prevState => {
                return {
                    ...prevState,
                    imagePositionLocked: false
                };
            });
        }
    }

    function onMouseMove(e) {
        if (!state.imagePositionLocked) {
            const rect = imagePreviewRef.current.getBoundingClientRect();
            const mouseX = e.clientX - rect.left;
            const mouseY = e.clientY - rect.top;
            const imageWidth = imageRef.current.clientWidth;
            const imageHeight = imageRef.current.clientHeight;
            const imagePreviewWidth = imagePreviewRef.current.clientWidth;
            const imagePreviewHeight = imagePreviewRef.current.clientHeight;

            console.log('mouseX: ', mouseX)
            console.log('mouseY: ', mouseY)

            let top = null;
            let left = null;
            let bottom = null;
            let right = null;

            if (state.fromVertical === 'top') {
                top = (((mouseY - (imageHeight / 2)) / imagePreviewHeight) * 100).toFixed(2);
            } else {
                bottom = (((mouseY + (imageHeight / 2)) / imagePreviewHeight) * 100).toFixed(2);
            }

            if (state.fromHorizontal === 'left') {
                left = (((mouseX - (imageWidth / 2)) / imagePreviewWidth) * 100).toFixed(2);
            } else {
                right = (((mouseX + (imageWidth / 2)) / imagePreviewWidth) * 100).toFixed(2);
            }

            setState(prevState => {
                return {
                    ...prevState,
                    top,
                    left,
                    bottom,
                    right,
                }
            })
        }
    }


    function copyJsonStyleObject() {
        const styles = {
            top: state.top,
            left: state.left,
            width: state.width,
            transform: state.transform
        };

        navigator.clipboard.writeText(JSON.stringify(styles));

        setTimeout(async () => {
                setErrorAlert({errorTitle: 'Styles Copied', errorMessage: 'Styles object copied to clip board. Paste into "Style Configuration Object" field in styles form to update.'});
            }
        )
    }

    useEffect(() => {
        if (productOptionImageStyleId) {
            Http().get(`${API_ROOT}/sketch-configurator/get-product-option-image-style-data/${productOptionImageStyleId}`).then(response => {
                setState(prevState => {
                    return {
                        ...prevState,
                        productOptionImageStyle: response.data.productOptionImageStyle,
                        productOptionValueImage: response.data.productOptionValueImage,
                        top: response.data.productOptionImageStyle.product_option_image_style_top,
                        left: response.data.productOptionImageStyle.product_option_image_style_left,
                        bottom: response.data.productOptionImageStyle.product_option_image_style_bottom,
                        right: response.data.productOptionImageStyle.product_option_image_style_right,
                        width: response.data.productOptionImageStyle.product_option_image_style_width,
                        zIndex: response.data.productOptionImageStyle.product_option_image_style_z_index,
                        transform: response.data.productOptionImageStyle.product_option_image_style_transform,
                        outlineImage: response.data.outlineImage,
                        location: response.data.productOptionImageStyle.product_outline_image_location,
                        initialized: true,
                        isSuspender: response.data.productOptionValueImage.product_option_category_id === '7'
                    }
                })
            });
        }else if(letteringPlacementStyleId){
            Http().get(`${API_ROOT}/sketch-configurator/get-lettering-placement-data/${letteringPlacementStyleId}`).then(response => {
                setState(prevState => {
                    return {
                        ...prevState,
                        letteringPlacementStyle: response.data.letteringPlacementStyle,
                        top: response.data.letteringPlacementStyle.lettering_placement_style_top,
                        left: response.data.letteringPlacementStyle.lettering_placement_style_left,
                        bottom: response.data.letteringPlacementStyle.product_option_image_style_bottom,
                        right: response.data.letteringPlacementStyle.lettering_placement_style_right,
                        width: response.data.letteringPlacementStyle.lettering_placement_style_width,
                        outlineImage: response.data.outlineImage,
                        location: response.data.letteringPlacementStyle.lettering_placement_style_location,

                        initialized: true,
                    }

                })
            });
        }
    }, [productOptionImageStyleId, setState, letteringPlacementStyleId]);

    const top = state.top ? (state.top + '%') : null;
    const left = state.left ? (state.left + '%') : null;
    const bottom = state.bottom ? (state.bottom + '%') : null;
    const right = state.right ? (state.right + '%') : null;
    const width = state.width ? (state.width + '%') : null;
    const zIndex = state.zIndex;
    const transform = state.transform;

    return (
        <Screen
            hideFooter={true}
            footerContent={
                <>
                    <PublicFooter/>
                </>
            }>
            <Card>
                <div>Image Position: {state.imagePositionLocked ? 'Locked' : 'Unlocked'}</div>
                <div className="configurator-preview-container">
                    <div className="configurator-flip-container"
                         onMouseMove={(e) => {
                             onMouseMove(e);
                         }}
                         onMouseDown={() => setImagePositionUnlocked()}
                         onMouseUp={() => setImagePositionLocked()}
                         style={state.isSuspender ? {paddingTop: 600, paddingBottom: 100} : {paddingTop: 100, paddingBottom: 100}}
                    >
                        {state.initialized &&
                        <div ref={imagePreviewRef}
                             className={classNames(
                                 'configurator-preview-wrapper',
                                 'margin-centered',
                                 {coat: state.productOptionImageStyle.product_id === '1'},
                                 {pants: state.productOptionImageStyle.product_id === '2'},
                                 {pants: state.productOptionImageStyle.product_id === '3'},
                             )}
                             style={{border: '2px solid #000000'}}
                        >
                            <img className="base-image" src={`${API_ROOT}${state.outlineImage.product_outline_image_file_url}`} style={{pointerEvents: 'none', userSelect: 'none'}}/>
                            {
                                state.outlineImage.product_outline_image_overlay_guide_file ?
                                    <img className="base-image"
                                         src={`${API_ROOT}${state.outlineImage.product_outline_image_overlay_guide_file_url}`}
                                         style={{
                                             pointerEvents: 'none',
                                             userSelect: 'none',
                                             position: 'absolute',
                                             top: 0,
                                             left: 0,
                                             bottom: 0,
                                             right: 0,
                                             zIndex: -1,
                                         }}
                                    />
                                    : null
                            }
                            {state?.productOptionValueImage?.product_option_value_image_file &&
                                <img
                                    ref={imageRef}
                                    className="image-part"
                                    src={`${API_ROOT}/uploaded/products/product-option-value-images/${state.productOptionValueImage.product_option_value_image_file}`}
                                    style={{
                                        ...(top != null ? {top} : {}),
                                        ...(left != null ? {left} : {}),
                                        ...(bottom != null ? {bottom} : {}),
                                        ...(right != null ? {right} : {}),
                                        ...(width != null ? {width} : {}),
                                        ...(zIndex != null ? {zIndex: parseInt(zIndex * 100)} : {}),
                                        ...(transform != null ? {transform} : {}),
                                        pointerEvents: 'none',
                                        userSelect: 'none'
                                    }}
                                />
                            }
                            {state?.letteringPlacementStyle?.lettering_placement_style_image &&
                                    <img
                                        ref={imageRef}
                                        className="image-part"
                                        src={`${API_ROOT}/uploaded/lettering/placement-placeholders/${state.letteringPlacementStyle.lettering_placement_style_image}`}
                                        style={{
                                            ...(top != null ? {top} : {}),
                                            ...(left != null ? {left} : {}),
                                            ...(bottom != null ? {bottom} : {}),
                                            ...(right != null ? {right} : {}),
                                            ...(width != null ? {width} : {}),
                                            ...(zIndex != null ? {zIndex: parseInt(zIndex * 100)} : {}),
                                            ...(transform != null ? {transform} : {}),
                                            pointerEvents: 'none',
                                            userSelect: 'none'
                                        }}
                                    />

                            }


                        </div>
                        }
                    </div>
                </div>
                <div>width: {state.width}%</div>
                <InputSlider
                    styles={{
                        track: {
                            width: '100%'
                        }
                    }}
                    axis="x"
                    xstep={0.1}
                    xmin={0}
                    xmax={200}
                    x={state.width}
                    onChange={({x}) => setState(prevState => ({...prevState, width: parseFloat(x.toFixed(2))}))}
                />
                <div>top: {top}</div>
                <div>left: {left}</div>
                {state?.productOptionValueImage?.product_option_value_image_file &&
                <CheckboxField
                    label="Reverse Image"
                    value={!!state.transform}
                    onChange={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                transform: prevState.transform ? null : 'scaleX(-1)'
                            }
                        });
                    }}
                />
                }
                <br/>
                <Button onClick={() => copyJsonStyleObject()}>
                    Copy Style Object
                </Button>
            </Card>
        </Screen>
    );
};

export default SketchConfigurator;
