import { useCallback, useEffect, useRef, useState } from 'react';
import axios, { CancelToken } from 'axios';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';

export const useMaxCharLengths = (data) => {
	const [, setIsFetchingCharLengths] = useState(false);

	// max char lengths
	const [charLengths, setCharLengths] = useState({});

	const [availableStyes, setAvailableStyes] = useState({});

	// char length request source
	const fetchRequestSource = useRef(null);

	// char length debounce timer
	const fetchDebounceTimer = useRef(null);

	/**
	 * Fetch max char lengths for selected patch/style
	 */

	const fetchCharLengths = useCallback(() => {
		if(Object.keys(data).length === 0) {
			return;
		}
		setIsFetchingCharLengths(true);

		// cancel any pending request
		fetchRequestSource.current?.cancel();
		// create new cancel token
		fetchRequestSource.current = CancelToken.source();

		Http()
			.post(
				`${API_ROOT}/products/lettering`,
				{
					data,
				},
				{
					cancelToken: fetchRequestSource.current?.token,
				},
			)
			.then(({ data }) => {
				setCharLengths(data.char_lengths);
				setAvailableStyes(data.available_styles);
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					// silent
				}
			})
			.finally(() => {
				setIsFetchingCharLengths(false);
			});
	}, [data]);

	/**
	 * Debounce fetchCharLenghts
	 */

	useEffect(() => {
		clearTimeout(fetchDebounceTimer.current);

		fetchDebounceTimer.current = setTimeout(() => {
			fetchCharLengths();
		}, 500);
	}, [fetchCharLengths]);

	return [charLengths, availableStyes];
};
