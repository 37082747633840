import {useState, React, useMemo, useCallback} from 'react';
import styles from './AddRowButton.module.scss';

const AddRowButton = ({ onClick = () => {}, count = 0 }) => {
	const [rowsToAdd, setRowsToAdd] = useState(1);

	const rowNums = useMemo(() => {
		return `(${count} Rows Total)`;
	}, [count, rowsToAdd]);

	const handleClick = useCallback(() => {
		const n = parseInt(rowsToAdd);
		if(!n || n < 1){
			return;
		}
		onClick(n);
		setRowsToAdd(1);
	}, [rowsToAdd, onClick]);

	return (
		<div className={styles.addRowContainer}>
		<button className={styles.addRowButton} onClick={handleClick}>
			Add
			<input type="text" value={rowsToAdd} onClick={(e)=>{
				e.stopPropagation();
			}} onChange={(e)=>{setRowsToAdd(e.target.value)}} />
			Rows <div>+</div>
		</button>
		<span>{rowNums}</span>
		</div>
	);
};

export default AddRowButton;
