export const letteringTextOptions = [
	{
		label: 'Name',
		value: 'name',
	},
	{
		label: 'Rank',
		value: 'rank',
	},
	{
		label: 'ID Number',
		value: 'ffid',
	},
	{
		label: 'Custom',
		value: 'custom',
	},
];

export const defaultLetteringTemplate = '/images/lettering-preview.gif';


export const defaultPlacementDimensions = (isForSummary)=> {
	if(isForSummary){
		return {width:41.3,height:6}
	}
	return {width:90,height:6.5}
}