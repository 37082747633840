import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import FixedHeader from '@components/FixedHeader';
import { useSelector } from 'react-redux';
import { API_ROOT } from '@config';

const AuthHeader = () => {
	const location = useLocation();
	const content = useSelector((state) => state.staticData.content);
	const logo = content?.logos?.indexed['logo-light'];

	return (
		<FixedHeader className="auth-header">
			<div className="auth-header-content">
				{!!logo && (
					<Link to="/">
						<img
							className="logo"
							src={`${API_ROOT}/uploaded/content/${logo?.content_item_image}`}
						/>
					</Link>
				)}
				<nav>
					<Link to="/">Learn More</Link>
					<Link to="/create-account">Create Account</Link>
					{location.pathname !== '/login' && (
						<Link to="/login">Log In</Link>
					)}
				</nav>
			</div>
		</FixedHeader>
	);
};

export default AuthHeader;
