import classNames from 'classnames';
import React, {useRef, useState, useEffect, useMemo, useCallback} from "react";
import {API_ROOT} from "@config";

const LetteringText = ({
	placementId,
	setPlacement = null,
	item = {},
	getLetteringText = () => {},
	placementStyle = null,
	showShape = false,
	charLimit=0
}) => {
	const [fontScalar, setFontScalar] = useState();
	
	const shapeRef = useRef(null);

	const updateScalar = useCallback(() =>{
		let baseWidth = (parseFloat(placementStyle?.lettering_placement_style_width) / 100) * 450;
		setFontScalar(shapeRef?.current?.clientWidth / baseWidth)
	},[placementStyle])

	const getBaseFontSize = useMemo(()=>{
		if(!charLimit){
			return 21;
		}
		let baseWidth = (parseFloat(placementStyle?.lettering_placement_style_width) / 100) * 450;
		return Math.ceil((baseWidth*(1.2/charLimit)))
	},[placementStyle,charLimit])

	useEffect(() => {
		updateScalar();
		window.addEventListener('resize',updateScalar);

		return ()=>{
			window.removeEventListener('resize',updateScalar);
		}
	},[updateScalar])

	const renderShape = () =>{
		if(!placementStyle){
			return null;
		}
		let image = null;
		if(placementStyle.lettering_placement_style_image){
			image = (<img style={{visibility:showShape?'visible':'hidden'}} src={`${API_ROOT}/uploaded/lettering/placement-placeholders/`+placementStyle.lettering_placement_style_image} />)
		}
		return (
			<div ref={shapeRef} className={classNames({
				['no-img']:!placementStyle.lettering_placement_style_image
			})}
				 style={{ width:'100%'}}
			>
				{image}
			</div>
		)
	}

	return (
		<div
			onClick={() =>
				typeof setPlacement === 'function' &&
				(!item.lettering_row_number || item.lettering_row_number == '0') &&
				setPlacement(item)
			}
			className={classNames({
				[`placement-`]: true,
			})}
			style={{
				height:'auto',
				position:'absolute',
				top:placementStyle.lettering_placement_style_top+'%',
				left:placementStyle.lettering_placement_style_left+'%',
				width:placementStyle.lettering_placement_style_width+'%',

			}}
		>
			{renderShape()}
			<span
				className={classNames({
					[`placement-text-${placementId}`]: true,
				})}
				style={{
					// set pointer for clickable items
					cursor:
						((!item.lettering_row_number || item.lettering_row_number == '0') && showShape) ? 'pointer' : 'default',
					color: item.product_option_value_lettering_color || '#222',
					fontSize:(getBaseFontSize * fontScalar) + 'px'
				}}>
				{getLetteringText(placementId) || (showShape && placementStyle.lettering_placement_index)}
			</span>
		</div>
	);
};

export default LetteringText;
