import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@components/Avatar/Avatar';
import date from '@utilities/date';
import Http from '@utilities/Http';
import axios from 'axios';
import { API_ROOT } from '@config';
import styles from './DistributorAdminListCard.module.scss';
import TextField from '@components/TextField/TextField';

const DistributorAdminListCard = () => {
    const systemRoles = useSelector(
        (state) => state.staticData.organizationSystemRoles,
    );

    const [users, setUsers] = useState([]);


    /**
     * Fetch Organization Users
     */

    useEffect(() => {
        Http()
            .get(`${API_ROOT}/organizations/distributor-users`)
            .then(({ data }) => {
                setUsers(data.users);
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    console.log('canceled request');
                }
            });
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.usersList}>
                <ul>
                    {users.map((user, u) => (
                        <li key={u} style={{ zIndex: users.length - u }}>
                            <Avatar user={user} className={styles.avatar} />
                            <p>{user.user_display_name}</p>
                            {!systemRoles
                                .map((role) => role.role_id)
                                .includes(user?.roles[0]?.role_id) && (
                                <TextField
                                    label="Role"
                                    value={user?.roles[0]?.role_title}
                                    disabled={true}
                                    className={styles.select}
                                />
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DistributorAdminListCard;
