import classNames from 'classnames/bind';
import Select from 'react-select'

import TextField from "@components/TextField/TextField";

function FieldSet({
                      fields,
                      containerClassName = "",
                      inputContainerClassName = "",
                      actions = []
                  }) {
    return (
        <div className={classNames(
            'field-set-container',
            containerClassName
        )}>
            {
                fields.map(field => {
                    return (
                        <div key={field.key}
                             {...field.fieldWrapperProps}
                             className={classNames(
                                 'field-set-input-container',
                                 inputContainerClassName
                             )}>
                            <div className="field-title">{field.title}</div>
                            {
                                {
                                    'select': <Select
                                        {...field.fieldProps}
                                    />,
                                    'text': <TextField
                                        {...field.fieldProps}
                                    />,
                                }[field.type]
                            }
                        </div>
                    );
                })
            }
            {
                actions.map((action, index) => {
                    return (
                        <button key={index}
                                className={classNames(
                                    'button',
                                    {'light': action.type !== 'submit'}
                                )}
                                style={{
                                    display: 'block',
                                    margin: '0 auto 10px',
                                    width: '100%',
                                    maxWidth: '200px',
                                }}
                                onClick={action.onClick}>
                            {action.label}
                        </button>
                    );
                })
            }
        </div>
    );
}

export default FieldSet;
