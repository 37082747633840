import {useCallback, useEffect, useRef, useState} from 'react';
import Card from '@components/Card/Card';
import NoItems from '../NoItems/NoItems';
import OrderActions from '../OrderActions/OrderActions';
import OrderItem from './OrderItem';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import styles from './OrderItems.module.scss';
import axios, { CancelToken } from 'axios';
import {isQuoteApproved} from "@utilities/orders";

const OrderItems = ({
	order = null,
	setOrder = () => {},
	totalPrice = 0,
	onSubmit = () => {},
	onTCM = () => {},
	isSubmitting = false,
	isSubmittingTCM = false,
	setIsSubmittingTCM = () => {},
	isEditable = false,
	noItemsActions = null,
	organizationContracts = [],
}) => {

	const [isApproving, setIsApproving] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);

	/**
	 * Remove Quote from Order
	 */

	const removeQuote = useCallback(
		async (quote) => {
			await http().post(
				`${API_ROOT}/quotes/remove-quote-from-order/${quote.quote_id}`,
			);

			setOrder((order) => {
				return {
					...order,
					quotes: order.quotes.filter(
						(item) => item.quote_id !== quote.quote_id,
					),
				};
			});
		},
		[setOrder],
	);

	/**
	 * Copy Quote
	 */

	const copyQuote = useCallback(
		async (quote, withOrder = true) => {
			await http()
				.post(`${API_ROOT}/quotes/copy/${quote.quote_id}`, {
					orderId: withOrder ? order?.order_id : null,
				})
				.then(({ data }) => {
					if (data && withOrder)
						setOrder((order) => ({
							...order,
							quotes: data?.quotes,
						}));
				});
		},
		[setOrder],
	);

	/**
	 * Update Quote in Order
	 */

	const fieldSaveRequestSource = useRef(null);
	const saveQuote = useCallback(
		async (quote) => {
			// cancel any pending request
			// fieldSaveRequestSource.current?.cancel();
			// create new cancel token
			// fieldSaveRequestSource.current = CancelToken.source();

			return http()
				.post(
					`${API_ROOT}/build/${quote.quote_id}`,
					{
						quote,
					},
					// { cancelToken: fieldSaveRequestSource.current?.token },
				)
				.then((data) => {
					const { quote: result } = data.data;

					const update = {};

					if (result.quote_status === 'rejected') {
						update.order_status = 'Requires Edits';
						update.order_submitted_timestamp = null;
					}

					setOrder((order) => {
						return {
							...order,
							...update,
							quotes: order.quotes.map((quote) => {
								return quote.quote_id === result.quote_id
									? result
									: quote;
							}),
						};
					});

					setIsApproving(false);
					setIsUpdating(false);
				})
				.catch((err) => {
					setIsApproving(false);
					setIsUpdating(false);
					if (axios.isCancel(err)) {
						// silent
					}
				});
		},
		[setOrder, setIsApproving],
	);

	const fieldSaveDebounceTimer = useRef(null);
	const updateQuote = useCallback(
		async (quote) => {
			// clear save timer
			// clearTimeout(fieldSaveDebounceTimer.current);

			// start save timer
			// fieldSaveDebounceTimer.current = setTimeout(() => {
			saveQuote(quote);
			// }, 500);

			// setOrder((order) => {
			// 	return {
			// 		...order,
			// 		...(quote.quote_status === 'rejected'
			// 			? { 'order_status': 'Requires Edits' }
			// 			: {}),
			// 		quotes: order.quotes.map((orderQuote) => {
			// 			return orderQuote.quote_id === quote.quote_id
			// 				? quote
			// 				: orderQuote;
			// 		}),
			// 	};
			// });
		},
		[saveQuote],
	);

	return (
		<div className={styles.orderItemsContainer}>
			<ul>
				{order.quotes
					.filter((quote) => quote.sibling_quote_id === null)
					.map((orderItem, o) => (
						<li className={styles.orderItemContainer} key={o}>
							<OrderItem
								quote={orderItem}
								siblingQuote={order.quotes.find(
									(quote) =>
										quote.sibling_quote_id ===
										orderItem.quote_id,
								)}
								allSiblings={order.quotes.filter(
									(quote) =>
										quote.sibling_quote_id ===
										orderItem.quote_id,
								)}
								updateQuote={updateQuote}
								removeQuote={removeQuote}
								copyQuote={copyQuote}
								isEditable={isEditable && !isQuoteApproved(orderItem)}
								orderQuotes={order.quotes}
								organizationContracts={organizationContracts}
								isApproving={isApproving}
								setIsApproving={setIsApproving}
								isUpdating={isUpdating}
								setIsUpdating={setIsUpdating}
								isSubmittingTCM={isSubmittingTCM}
							/>
						</li>
					))}
				{!!order.quotes.length && (
					<li className={styles.orderItemContainer}>
						<Card>
							<OrderActions
								order={order}
								totalPrice={totalPrice}
								onSubmit={onSubmit}
								onTCM={onTCM}
								isSubmitting={isSubmitting}
								isEditable={isEditable}
								isSubmittingTCM={isSubmittingTCM}
								setIsSubmittingTCM={setIsSubmittingTCM}
							/>
						</Card>
					</li>
				)}
				{!order.quotes.length && (
					<li className={styles.orderItemContainer}>
						<Card>
							<NoItems actions={noItemsActions} />
						</Card>
					</li>
				)}
			</ul>
		</div>
	);
};

export default OrderItems;
