import classNames from 'classnames';
import React, { useCallback } from 'react';
import fieldStyles from '../Field/Fields.module.scss';
import styles from './TextAreaField.module.scss';

const TextAreaField = ({
	label = null,
	value = '',
	name = '',
	type = 'text',
	onChange = () => {},
	onFocus = () => {},
	onBlur = () => {},
	inputProps = {},
	className = null,
	inputClassName = null,
	readonly = false,
	disabled = false,
	compact = false,
	placeholder = null,
	adornment = null,
	adornmentClassName = null,
	error = null,
	limit = null,
}) => {
	/**
	 * Handle Change
	 */

	const handleChange = useCallback(
		(e) => {
			if (limit !== null && e.target.value.length > limit) {
				return;
			}

			onChange(e);
		},
		[limit, onChange],
	);

	return (
		<div
			className={classNames({
				[fieldStyles.fieldContainer]: true,
				[fieldStyles.readonly]: readonly,
				[fieldStyles.disabled]: disabled,
				[fieldStyles.compact]: compact,
				[className]: !!className,
			})}>
			{!!label && <label>{label}</label>}
			{!!adornment && (
				<div
					className={classNames({
						[fieldStyles.adornmentContainer]: true,
						[adornmentClassName]: !!adornmentClassName,
					})}>
					{adornment}
				</div>
			)}
			<div className={styles.inputContainer}>
				<textarea
					name={name}
					type={type}
					value={value}
					onChange={handleChange}
					onFocus={onFocus}
					onBlur={onBlur}
					className={classNames({
						[fieldStyles.fieldInput]: true,
						[inputClassName]: !!inputClassName,
					})}
					placeholder={placeholder}
					readOnly={readonly}
					disabled={disabled}
					tabIndex={readonly || disabled ? '-1' : '0'}
					spellCheck={false}
					{...inputProps}></textarea>
				{limit !== null && (
					<p
						className={classNames({
							[styles.counter]: true,
							[styles.counterLimit]:
								limit !== null && value.length >= limit,
						})}>
						{value.length} / {limit}
					</p>
				)}
			</div>
			{!!error && <span className={fieldStyles.fieldError}>{error}</span>}
		</div>
	);
};

export default TextAreaField;
