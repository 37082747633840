import {useState, useRef} from 'react';
import styles from './ResourceMenu.module.scss';
import classNames from 'classnames';
import {Link, useHistory} from 'react-router-dom';
import DownloadIcon from '@components/Icons/DownloadIcon';
import BellIcon from '@components/Icons/BellIcon';
import CloseIcon from '@components/Icons/CloseIcon';
import HeartIcon from "@components/Icons/HeartIcon";
import EyeIcon from "@components/Icons/EyeIcon";
import date from '@utilities/date';
import {API_ROOT} from "@config";
import {useClickOutside} from "@hooks/useClickOutside";

const updates = [
    {
        image: 'https://api.firedex.dev3.stream9.net/uploaded/products/product-lines/FireDex_FXR_FullBodySide_800x800.png',
        name: '613b7afdce725.jpg',
        date: '02/01/2022'
    },
    {
        image: 'https://api.firedex.dev3.stream9.net/uploaded/products/products/FireDex_1910TraditionalHelmets_800x800.png',
        name: '613b7afdce725.jpg',
        date: '02/01/2022'
    }
]


const ResourceMenu = ({selectedProduct, setSelectedProductIndex, setSelectedFolder, selectedFolder, isFavorite, userDownloadHistory, setProductDownloadIds, productDownloadIds, productDownloadsHistory, getGeneral, setGetGeneral}) => {
    const [downOpen, setDownOpen] = useState(false);
    const [updatesOpen, setUpdatesOpen] = useState(false);
    const history = useHistory();
    const updatesNode = useRef(null);
    const archivesNode = useRef(null);

    function onClickMenu(label) {
        if (label == 'Favorites') {
            history.push('/favorites');
        }

        if (label == 'Downloads') {
            setDownOpen(!downOpen);
        } else {
            setDownOpen(false);
        }

        if (label == 'Recent Updates') {
            setUpdatesOpen(!updatesOpen);
        } else {
            setUpdatesOpen(false);
        }
    }

    useClickOutside(updatesNode.current, () => {
        setUpdatesOpen(false);
    }, true);

    useClickOutside(archivesNode.current, () => {
        setDownOpen(false);
    }, true);

    return (<>
        <div className={styles.resourceMenu}>
            {!isFavorite &&
                <>

                    <div
                        className={classNames({
                            [styles.tab]: true,
                            [styles.activeTab]: !selectedProduct && !productDownloadIds.length && !getGeneral
                        })}
                        onClick={() => {
                            setSelectedProductIndex(null);
                            setSelectedFolder(null);
                            setGetGeneral(false);
                            setProductDownloadIds([]);
                        }}>
                        All Categories
                    </div>

                    {
                        selectedProduct ?
                            <div
                                className={classNames({
                                    [styles.tab]: true,
                                    [styles.activeTab]: !!selectedProduct && !selectedFolder
                                })}
                                onClick={() => {
                                    setSelectedFolder(null);
                                }}
                            >
                                {selectedProduct.product_line_description}
                            </div>
                            : null
                    }

                    {
                        productDownloadIds.length ?
                            <div
                                className={classNames({
                                    [styles.tab]: true,
                                    [styles.activeTab]: !!productDownloadIds.length
                                })}
                            >
                                Shared
                            </div>
                            : null
                    }

                    {
                        getGeneral ?
                            <div
                                className={classNames({
                                    [styles.tab]: true,
                                    [styles.activeTab]: getGeneral
                                })}
                                onClick={() => {
                                    setSelectedFolder(null);
                                }}
                            >
                                General / Misc.
                            </div>
                            : null
                    }

                    {
                        selectedFolder ?
                            <div
                                className={classNames({
                                    [styles.tab]: true,
                                    [styles.activeTab]: !!selectedFolder
                                })}
                            >
                                {selectedFolder}
                            </div>
                            : null
                    }
                </>
            }
            {isFavorite &&
                <Link to='/resources' style={{opacity: 0.5}}>
                    Back to All Categories
                </Link>}
        </div>
        <div className={styles.buttonsGroup}>
            {
                productDownloadsHistory.length > 0 ?
                <div className={styles.container} onClick={() => {
                    onClickMenu('Recent Updates');
                }}>
                    <span style={{color: 'white', backgroundColor: '#696969'}}>{<BellIcon fill="white" width="10" height="10"/>}</span>
                    <span>Recent Updates</span>

                    <div ref={updatesNode}
                         className={classNames({
                             [styles.dropdownContainer]: true,
                             [styles.active]: updatesOpen
                         })}>
                        <ul className={styles.updatesDropdown}>
                            <li>
                                <span>Recent Updates</span>
                                <span><CloseIcon width="13" height="13"/></span>
                            </li>
                            {productDownloadsHistory.map((productDownloadHistory) => {
                                return (
                                    <li key={productDownloadHistory.product_download_history_id}
                                        onClick={() => {
                                            setSelectedProductIndex(null);
                                            setSelectedFolder(null);
                                            setProductDownloadIds([productDownloadHistory.product_download_id]);
                                        }}
                                    >
                                        <span>
                                            <div className={styles.circle}><BellIcon fill="#E87124"/></div>
                                        </span>
                                        <span className={styles.productImage}
                                              style={{backgroundImage: `url("${API_ROOT}${productDownloadHistory.product_download_file_url}")`}}>
                                        </span>
                                        <span>
                                            <div className={styles.fileName}>
                                                <label>File Name</label>
                                                <label>{productDownloadHistory.product_download_file}</label>
                                            </div>
                                        </span>
                                        <span>{date(productDownloadHistory.product_download_updated_timestamp).format('MM/DD/YY hh:mm')}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                    : null
            }

            <div className={styles.container} onClick={() => {
                onClickMenu('Favorites');
            }}>
                <span style={{color: 'white', backgroundColor: '#E87124'}}>{<HeartIcon fill="white" width="10" height="10"/>}</span>
                <span>Favorites</span>
            </div>

            <div className={styles.container} onClick={() => {
                onClickMenu('Downloads');
            }}>
                <span style={{color: '#222222', backgroundColor: '#FFFFFF'}}>{<DownloadIcon fill="#333" width="10" height="10"/>}</span>
                <span>Downloads</span>
                <div
                    ref={archivesNode}
                    className={classNames({
                        [styles.dropdownContainer]: true,
                        [styles.active]: downOpen
                    })}
                >
                    <ul>
                        <li>
                            <span>Downloads</span>
                            <span><CloseIcon width="13" height="13"/></span>
                        </li>
                        {userDownloadHistory.map((userDownload) => {
                            if(userDownload.user_product_download_archive_id) {
                                return (
                                    <li key={userDownload.user_product_download_archive_id}>
                                        <span>
                                            <div className={styles.circle}><DownloadIcon fill="#E87124" width="13" height="13"/></div>
                                        </span>
                                        <span>{userDownload.user_product_download_archive_file}</span>
                                        <span>{date(userDownload.user_product_download_archive_created_timestamp).format('MM/DD/YY hh:mm')}</span>
                                        {
                                            userDownload.user_product_download_archive_status === 'finished' ?
                                                <Link
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                    }}
                                                    to={{pathname: `${API_ROOT}${userDownload.user_product_download_archive_file_uri}`}}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                />
                                                : null
                                        }
                                    </li>
                                );
                            } else {
                                let previewUrl = userDownload.previous_version_file_name ? userDownload.product_history_preview_prepend + userDownload.previous_version_file_name : userDownload.product_download_file_url_preview;
                                let downloadUrl = userDownload.previous_version_file_name ? userDownload.product_history_download_prepend + userDownload.previous_version_file_name : userDownload.product_download_file_url;
                                return (
                                    <li key={userDownload.user_product_download_id}>
                                        <span>
                                            <div className={styles.circle}><DownloadIcon fill="#E87124" width="13" height="13"/></div>
                                        </span>
                                        <span className={styles.productImage}
                                              style={{backgroundImage: `url("${API_ROOT}${previewUrl}")`}}>
                                        </span>
                                        <span>{userDownload.product_download_file}</span>
                                        <span>{date(userDownload.user_product_download_timestamp).format('MM/DD/YY hh:mm')}</span>
                                        <Link
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                            }}
                                            to={{pathname: `${API_ROOT}${downloadUrl}`}}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        />
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            </div>

            {/*<div className={styles.container} onClick={() => {*/}
            {/*    onClickMenu('View All');*/}
            {/*}}>*/}
            {/*    <span style={{color: 'white', backgroundColor: '#EC9B21'}}>{<EyeIcon fill="white" width="10"*/}
            {/*                                                                         height="10"/>}</span>*/}
            {/*    <span>View All</span>*/}
            {/*</div>*/}
        </div>
    </>);
};

export default ResourceMenu;
