import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryParams } from '@hooks/useQueryParams';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';
import AuthHeader from '@components/Auth/AuthHeader';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import TextField from '@components/TextField/TextField';
import Button from '@components/Button/Button';
import FieldRow from '@components/Field/FieldRow';
import classNames from 'classnames';
import { setToken } from '@store/reducers/auth';
import { fetchUser } from '@store/thunks/fetch-user';
import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';

function Invitation({ location }) {
	const params = useQueryParams(location);
	const dispatch = useDispatch();
	const history = useHistory();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [newPasswordError, setNewPasswordError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);

	const [message, setMessage] = useState('');

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			setMessage('');

			if (!newPassword) {
				setNewPasswordError('Please enter a password');
				return;
			}
			if (!confirmPassword) {
				setConfirmPasswordError('Please confirm your password');
				return;
			}
			if (newPassword !== confirmPassword) {
				setConfirmPasswordError('Passwords do not match');
				return;
			}

			setIsSubmitting(true);

			try {
				const { data } = await Http().post(
					`${API_ROOT}/auth/invitation`,
					{
						password: newPassword,
						code: params.code,
					},
				);

				setNewPassword('');
				setConfirmPassword('');

				dispatch(setToken(data.token));
				dispatch(fetchUser());
				history.push('/dashboard');
			} catch (error) {
				console.log(error);
				if (error.response?.data?.meta?.message) {
					setMessage(error.response?.data?.meta?.message);
				}
			} finally {
				setIsSubmitting(false);
			}
		},
		[newPassword, confirmPassword, params, history, dispatch],
	);

	return (
		<>
			<AuthHeader />
			<Hero heroTitle="WELCOME TO<br />FIREDEX!" />
			<section className="content-wrapper">
				<div className="content-container flex-center-content">
					<form className="form-container" onSubmit={handleSubmit}>
						<p
							className={classNames({
								'form-text': true,
								[styles.formText]: true,
							})}>
							Please set a password for your account:
						</p>
						<FieldRow className={styles.fieldRow}>
							<TextField
								type="password"
								label="New Password*"
								value={newPassword}
								onChange={(e) => {
									setNewPasswordError(null);
									setNewPassword(e.target.value);
								}}
								className={styles.field}
								error={newPasswordError}
							/>
						</FieldRow>
						<FieldRow className={styles.fieldRow}>
							<TextField
								type="password"
								label="Confirm Password*"
								value={confirmPassword}
								onChange={(e) => {
									setConfirmPasswordError(null);
									setConfirmPassword(e.target.value);
								}}
								className={styles.field}
								error={confirmPasswordError}
							/>
						</FieldRow>
						{!!message && (
							<p className={styles.message}>{message}</p>
						)}
						<Button
							type="submit"
							variant="solid"
							loading={isSubmitting}
							className={styles.submitButton}>
							Submit
						</Button>
					</form>
				</div>
			</section>
			<Footer className="auth-footer" />
		</>
	);
}

export default Invitation;
