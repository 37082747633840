import React from 'react';
import styles from '../../VirtualSizer.module.scss';
import classNames from 'classnames';
export default function ImageSelect({field,options,changeHandler})
{
	return (
		<>
			<div className={classNames({
				[styles.markedInput]: true,
				[styles.invalid]: field[1].error || !field[1].value,
				[styles.valid]: !field[1].error && field[1].value,
			})}>
				<div className={classNames({
					[styles.markedInput]: true,
					[styles.value]: field[1].value,
					[styles.empty]: !field[1].value,
				})}>
					{field[1].value || field[1].placeholder}
				</div>
			</div>
			<div className={classNames({
				[styles.options]: true,
			})}>
				{
					options && options.map((option, j) => {
						return (
							<div key={j} className={classNames({
								[styles.imageOption]: true,
								[styles.selected]: field[1].value === option.value,
							})}>
								<span>{option.label}</span>
								<img key={field[0] + 'op' + j} src={option.imgSrc} alt={option.value} onClick={() => {
									changeHandler(field[0], option.value);
								}}/>
							</div>
						)
					})
				}
			</div>
		</>
	)
}
