import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { API_ROOT } from '@config';
import http from '@utilities/Http';
import axios, { CancelToken } from 'axios';
import { useClickOutside } from '@hooks/useClickOutside';
import FieldGroup from '@components/Field/FieldGroup';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import SearchIcon from '@components/Icons/SearchIcon';
import fieldStyles from '../Field/Fields.module.scss';
import suggestFieldStyles from '../SuggestField/SuggestField.module.scss';
import styles from './FireDepartmentField.module.scss';
import TextField from '@components/TextField/TextField';
import SelectField from '@components/SelectField/SelectField';
import {useSelector} from "react-redux";
const FireDepartmentField = ({
	children = null,
	label = null,
	value = '',
	defaultValues = [],
	name = '',
	onChange = () => {},
	onSelect = () => {},
	className = null,
	menuClassName = null,
	inputClassName = null,
	activeClassName = null,
	readonly = false,
	disabled = false,
	compact = false,
	error = null,
	countries = [],
	isCSRUser = false,
}) => {
	const inputNode = useRef(null);
	const debounceTimeout = useRef(null);
	const fetchRequestSource = useRef(null);
	const [isActive, setIsActive] = useState(false);
	const [queryZip, setQueryZip] = useState(defaultValues[0] || '');
	const [queryName, setQueryName] = useState(defaultValues[1] || '');
	const [queryCountry, setQueryCountry] = useState(defaultValues[2] || 'US');
	const [results, setResults] = useState([]);
	const [isFetching, setIsFetching] = useState(false);

	/**
	 * Handle Item Select
	 */

	const handleSelect = useCallback(
		(result) => {
			setQueryZip(result.fire_department_zip);
			setQueryName(isCSRUser ? result.fire_department_name : result.fire_department_name_no_address);
			setQueryCountry(result.fire_department_country);
			onChange({
				target: { name, value: result.fire_department_id },
			});

			onSelect(result);
			setIsActive(false);
		},
		[name, onChange, onSelect, setIsActive],
	);

	/**
	 * Handle Click Outside
	 */

	useClickOutside(inputNode.current, () => setIsActive(false));

	/**
	 * Fetch Results
	 */

	useEffect(() => {
		clearTimeout(debounceTimeout.current);

		// cancel any pending request
		fetchRequestSource.current?.cancel();
		// create new cancel token
		fetchRequestSource.current = CancelToken.source();

		if ([queryZip, queryName].every((value) => value.length <= 2)) {
			setResults([]);
			return;
		}

		debounceTimeout.current = setTimeout(() => {
			setIsFetching(true);
			http()
				.get(
					`${API_ROOT}/fire-departments?fire_department_zip=${queryZip}&fire_department_name=${queryName}&fire_department_country=${queryCountry}`,
					{
						cancelToken: fetchRequestSource.current?.token,
					},
				)
				.then(({ data }) => {
					if (!data?.fireDepartments){
						data.fireDepartments = [];
					}
					if(!Array.isArray(data?.fireDepartments)){
						data.fireDepartments = Object.entries(data?.fireDepartments);
					}
					setResults(data?.fireDepartments || []);
				})
				.catch((err) => {
					setResults([]);
					if (axios.isCancel(err)) {
						// silent
					}
				})
				.finally(() => {
					setIsFetching(false);
				});
		}, 300);
	}, [queryZip, queryName, queryCountry]);

	/**
	 * Clear Inputs on Value Reset
	 */

	useEffect(() => {
		if (!value) {
			setQueryZip('');
			setQueryName('');
			setQueryCountry('US');
		}
	}, [value]);

	useEffect(()=>{
		if(!queryCountry && countries.length > 0){
			setQueryCountry('US')
		}
	},[countries, queryCountry])

	return (
		<div
			className={classNames({
				[fieldStyles.fieldContainer]: true,
				[fieldStyles.readonly]: readonly,
				[fieldStyles.disabled]: disabled,
				[fieldStyles.compact]: compact,
				[suggestFieldStyles.fieldContainer]: true,
				[suggestFieldStyles.suggestFieldCompact]: compact,
				[suggestFieldStyles.suggestFieldReadonly]: readonly,
				[suggestFieldStyles.suggestFieldDisabled]: disabled,
				[suggestFieldStyles.suggestFieldLoading]: isFetching,
				[suggestFieldStyles.suggestFieldActive]: isActive,
				[className]: !!className,
				[activeClassName]: !!activeClassName && isActive,
			})}>
			{!!label && <label>{label}</label>}
			<div className={suggestFieldStyles.positioningContainer}>
				<div
					className={classNames({
						[suggestFieldStyles.loader]: true,
						[suggestFieldStyles.loaderActive]: isFetching,
					})}>
					<SpinnerIcon fill="#e87124" />
				</div>
				<div ref={inputNode} className={styles.fieldGroupContainer}>
					<FieldGroup>
					<SelectField
							compact
							placeholder="Country"
							value={queryCountry || ''}
							name="fire_department_country"
							onChange={(e)=>{
								setQueryCountry(e.target.value)
							}}
							options={countries}
							labelField="country_name"
							valueField="country_code"
							className={classNames({ [styles.countryField]: true })}
							inputClassName={classNames({[styles.fullHeight]: true})}
							menuClassName={classNames({[styles.selectMenu]: true})}
						/>
						<TextField
							value={queryZip}
							onChange={(e) => setQueryZip(e.target.value)}
							disabled={disabled || !queryCountry}
							readonly={readonly}
							className={classNames({ [styles.zipField]: true })}
							inputClassName={classNames({
								[suggestFieldStyles.suggestFieldPlaceholder]:
									!value,
								[inputClassName]: !!inputClassName,
							})}
							inputProps={{
								placeholder: 'Zip',
								onFocus: () => setIsActive(true),
								onBlur: () => setIsActive(false),
							}}
							adornment={readonly ? null : <SearchIcon />}
						/>
						<TextField
							value={queryName}
							onChange={(e) => setQueryName(e.target.value)}
							disabled={disabled || !queryCountry}
							readonly={readonly}
							className={classNames({ [styles.nameField]: true })}
							inputClassName={classNames({
								[suggestFieldStyles.suggestFieldPlaceholder]:
									!value,
								[inputClassName]: !!inputClassName,
							})}
							inputProps={{
								placeholder: 'Fire Department Name',
								onFocus: () => setIsActive(true),
								onBlur: () => setIsActive(false),
							}}
							adornment={readonly ? null : <SearchIcon />}
						/>
					</FieldGroup>
				</div>
				{!!error && (
					<span className={fieldStyles.fieldError}>{error}</span>
				)}
				<div
					className={classNames({
						[suggestFieldStyles.suggestFieldMenuContainer]: true,
						[styles.menuContainer]: true,
						[menuClassName]: !!menuClassName,
					})}>
					<ul className={suggestFieldStyles.suggestFieldMenu}>
						{results.map((result, r) => (
							<li
								key={r}
								onMouseDown={() => handleSelect(result)}>
								{isCSRUser ? result.fire_department_name : result.fire_department_name_no_address}
							</li>
						))}
						{!results.length && (
							<li
								className={classNames({
									[suggestFieldStyles.placeholderItem]: true,
								})}>
								No Results
							</li>
						)}
					</ul>
				</div>
			</div>
			{children}
		</div>
	);
};

export default FireDepartmentField;
