import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useClickOutside } from '@hooks/useClickOutside';
import { useKeyboardNavigation } from '@hooks/useKeyboardNavigation';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import fieldStyles from '../Field/Fields.module.scss';
import styles from './SelectField.module.scss';

const SelectField = ({
	label = null,
	value = '',
	name = '',
	options = [],
	labelField = 'label',
	valueField = 'value',
	itemDisabledField = 'disabled',
	onChange = () => {},
	onSelect = () => {},
	className = null,
	inputClassName = null,
	menuClassName = null,
	activeClassName = null,
	loaderClassName = null,
	readonly = false,
	disabled = false,
	compact = false,
	placeholder = null,
	allowNull = true,
	indicator = null,
	loading = false,
	adornment = null,
	error = null,
	preIcon = null,
	theme = 'black',
	style= {}
}) => {
	const inputNode = useRef(null);
	const [isActive, setIsActive] = useState(false);

	/**
	 * Menu Items
	 */

	const menuItems = useMemo(() => {
		const menuItems = [...options];

		if (allowNull) {
			menuItems.unshift({
				[labelField]: placeholder || 'Select',
				[valueField]: null,
			});
		}

		return menuItems;
	}, [options, allowNull, labelField, valueField, placeholder]);

	/**
	 * Handle Item Select
	 */

	const handleSelect = useCallback(
		(item) => {
			if (item[itemDisabledField]) {
				return;
			}

			onSelect(item);
			onChange({
				target: { name, value: item[valueField] },
			});
		},
		[name, onChange, onSelect, valueField, itemDisabledField],
	);

	/**
	 * Handle Keyboard Navigation
	 */

	const { cursor, setCursor } = useKeyboardNavigation(
		inputNode,
		isActive,
		setIsActive,
		menuItems,
		(option) => option[labelField],
		(option) => {
			handleSelect(option);
		},
	);

	/**
	 * Handle Click Outside
	 */

	useClickOutside(inputNode.current, () => setIsActive(false));

	/**
	 * Get Label for Selected Option
	 */

	const getSelectedOptionValue = useCallback(() => {
		let foundOption = options.find((option) => option[valueField] === value)?.[labelField];
		if(value && !foundOption && options.length > 0) {
			handleSelect({
				[labelField]: 'Select',
				[valueField]: null,
			});
		}
		return (
			foundOption || placeholder
		);
	}, [value, options, valueField, labelField, placeholder]);

	return (
		<div
			className={classNames({
				[fieldStyles.fieldContainer]: true,
				[fieldStyles.readonly]: readonly,
				[fieldStyles.disabled]: disabled,
				[fieldStyles.compact]: compact,
				[styles.fieldContainer]: true,
				[styles.selectFieldCompact]: compact,
				[styles.selectFieldReadonly]: readonly,
				[styles.selectFieldDisabled]: disabled,
				[styles.selectFieldLoading]: loading,
				[styles.selectFieldActive]: isActive,
				[className]: !!className,
				[activeClassName]: !!activeClassName && isActive,
				[styles.white]: theme == 'white'
			})}
			style={style}
		>
			{!!label && (
				<label className={styles.selectFieldLabel}>{label}</label>
			)}
			{!!adornment && adornment}
			<div className={styles.positioningContainer}>
				<div
					className={classNames({
						[styles.loader]: true,
						[styles.loaderActive]: loading,
						[loaderClassName]: !!loaderClassName,
					})}>
					<SpinnerIcon fill="#e87124" />
				</div>
				{!!indicator && !disabled && (
					<div className={styles.indicatorContainer}>{indicator}</div>
				)}
				{!!preIcon && (
					<div className={styles.preIconContainer}>{preIcon}</div>
				)}
				<div
					ref={inputNode}
					tabIndex={readonly || disabled ? '-1' : '0'}
					onClick={() => setIsActive(!isActive)}
					className={classNames({
						[fieldStyles.fieldInput]: true,
						[styles.selectFieldInput]: true,
						[styles.selectFieldPlaceholder]: !value,
						[inputClassName]: !!inputClassName,
					})}
					disabled={disabled}
					readOnly={readonly}
					style={preIcon == null ? {} : {paddingLeft: '40px'}}>
					<p>{getSelectedOptionValue()}</p>
				</div>
				{!!error && (
					<span className={fieldStyles.fieldError}>{error}</span>
				)}
				<div
					className={classNames({
						[styles.selectFieldMenuContainer]: true,
						[menuClassName]: !!menuClassName,
					})}>
					<ul
						onMouseLeave={() => setCursor(null)}
						className={styles.selectFieldMenu}>
						{menuItems.map((item, i) => (
							<li
								key={i}
								onMouseDown={() => handleSelect(item)}
								onMouseOver={() => {
									setCursor(i);
								}}
								className={classNames({
									[styles.cursor]: cursor === i,
									[styles.placeholderItem]:
										allowNull && i === 0,
									[styles.disabledItem]:
										item[itemDisabledField],
								})}>
								{item[labelField]}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default SelectField;
