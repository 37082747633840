import { useCallback, useState } from 'react';
import classNames from 'classnames';
import http from '@utilities/Http';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import MailIcon from '@components/Icons/MailIcon';
import styles from './CollaborateQuoteModal.module.scss';
import { API_ROOT } from '@config';
import SuggestField from '@components/SuggestField/SuggestField';
import ShareIcon from "@components/Icons/ShareIcon";

const CollaborateQuoteModal = ({ quoteId, active = false, onClose = () => {} }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [emailForCopy, setEmailForCopy] = useState('');
	const [emailForShare, setEmailForShare] = useState('');
	const [shareError, setShareError] = useState(null);
	const [copyError, setCopyError] = useState(null);

	/**
	 * Handle Close
	 */

	const handleClose = useCallback(
		(didChange = false) => {
			setCopyError('');
			setShareError('');
			setEmailForCopy('');
			setEmailForShare('');
			onClose(didChange);
		},
		[onClose],
	);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (type = 'share') => {
			const value = type === 'share' ? emailForShare : emailForCopy;
			const setError = type === 'share' ? setShareError : setCopyError;

			if (!value) {
				setError(
					"Please enter the email address you'd like to share this quote with",
				);
				return;
			}

			if (!/\S+@\S+\.\S+/.test(value)) {
				setError('Please enter a valid email address');
				return;
			}
			setIsSubmitting(type);

			try {
				await http().post(`${API_ROOT}/quotes/share/${quoteId}`, {
					email: value,
					copy: type !== 'share',
				});
				handleClose(true);
			} catch (err) {
				if (err.response?.status === 404) {
					setError('We were unable to find that email address');
					return;
				}

				if (err.response?.status === 401) {
					setError(
						err.response?.data?.meta?.message || 'Something went wrong',
					);
					return;
				}

				setError('Something went wrong');
			} finally {
				setIsSubmitting(false);
			}

			setIsSubmitting(false);
		},
		[emailForCopy, emailForShare, quoteId, handleClose],
	);

	return (
		<Modal active={active} onClose={handleClose}>
			<div className={styles.modalTitleContainer}>
				<ShareIcon fill="#FFF" />
				<h4 className={modalStyles.modalTitle}>
					Collaborate With Others
				</h4>
			</div>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<div className={classNames(styles.field)}>
					<label>
						Enter a <b>FireWriter account</b> to collaborate with.
					</label>
					<div>
						<SuggestField
							placeholder="Search or Enter Email"
							value={emailForShare}
							valueField="user_email"
							labelExtractor={(user) =>
								`${user.user_display_name} (${user.user_email})`
							}
							url={`${API_ROOT}/organizations/users`}
							getResults={(data) => data.users}
							onTextChange={(e) => {
								setShareError('');
								setEmailForShare(e.target.value);
							}}
							onSelect={(user) => {
								setShareError('');
								setEmailForShare(user.user_email);
							}}
							className={classNames({
								[styles.emailField]: true,
							})}
							inputClassName={styles.emailInput}
							error={shareError}
							noResultsMessage="No Results"
						/>
						<Button
							variant="solid"
							onClick={() => handleSubmit('share')}
							loading={isSubmitting === 'share'}
							className={styles.collaborateButton}>
							Collaborate
						</Button>
					</div>
					<p>
						This action will allow the given user permission to
						collaborate and make changes to your quote.
					</p>
				</div>
			</div>
			<div
				className={classNames(
					modalStyles.modalActions,
					styles.modalActions,
				)}>
				<p onClick={() => handleClose(false)}>Close</p>
			</div>
		</Modal>
	);
};

export default CollaborateQuoteModal;
