export function getParamString(obj = {}) {
	let result = '';
	for (let key in obj) {
		if (obj[key]) {
			result += `&${key}=${encodeURIComponent(obj[key])}`;
		}
	}

	return result;
}
