import { useCallback } from 'react';
import styles from './ResourceHeader.module.scss';
import classNames from 'classnames';
import TextField from '@components/TextField/TextField';
import filterStyles from '@components/Collection/CollectionFilters.module.scss';
import SearchIcon from '@components/Icons/SearchIcon';
import FilterIcon from '@components/Icons/FilterIcon';
import SelectField from '@components/SelectField/SelectField';
import ResourceMenu from '@components/ResourceMenu/ResourceMenu';

const ResourceHeader = ({selectedProduct, setProductDownloadIds, productDownloadIds, setSelectedProductIndex, setSelectedFolder, selectedFolder, search, setSearch, fileType, setFileType, isFavorite, userDownloadHistory, fileTypes, productDownloadsHistory, getGeneral, setGetGeneral}) => {
	return (
		<div className={styles.resourceMenu}>
			<div className="page-content" >
				<div className={styles.header}>
					{isFavorite ? 'Favorites' : 'Asset Manager'}
				</div>
				<div className={classNames({
					[styles.body]: true,
					[styles.gridActive]: fileTypes.length > 0,
				})}>
					<TextField
						className={classNames({
							[filterStyles.filter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						name="search_key"
						value={search}
						onChange={e => {
							setSearch(e.target.value);
						}}
						placeholder="Search keyword"
						adornment={<SearchIcon fill="#FFF" />}
					/>
					{
						fileTypes.length > 0 ?
							<SelectField
								className={classNames({
									[filterStyles.filter]: true,
									[styles.filterContainer]: true,
								})}
								inputClassName={filterStyles.filterInput}
								menuClassName={filterStyles.filterInputMenu}
								name="filter"
								value={fileType}
								onChange={e => {
									setFileType(e.target.value)
								}}
								options={fileTypes.map((fileType) => ({
									label: fileType,
									value: fileType,
								}))}
								placeholder="Filter by File Type"
								preIcon={<FilterIcon/>}
								allowNull={true}
								style={{zIndex: 100}}
							/>
							: null
					}
				</div>
				<div className={styles.footer}>
					<ResourceMenu
						selectedProduct={selectedProduct}
						setSelectedProductIndex={setSelectedProductIndex}
						setSelectedFolder={setSelectedFolder}
						selectedFolder={selectedFolder}
						isFavorite={isFavorite}
						userDownloadHistory={userDownloadHistory}
						setProductDownloadIds={setProductDownloadIds}
						productDownloadIds={productDownloadIds}
						productDownloadsHistory={productDownloadsHistory}
						getGeneral={getGeneral}
						setGetGeneral={setGetGeneral}
					/>
				</div>
			</div>
		</div>
	);
};

export default ResourceHeader;
