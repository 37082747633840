import classNames from 'classnames';
import React from 'react';
import styles from './Fields.module.scss';

const FieldRow = ({ children = null, className = null, wrap = false, ...props }) => {
	return (
		<div
			className={classNames({
				[styles.fieldRow]: true,
				[styles.wrap]: wrap,
				[className]: !!className,
			})}
			{...props}>
			{children}
		</div>
	);
};

export default FieldRow;
