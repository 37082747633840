import { useCallback, useState } from 'react';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import classNames from 'classnames';
import TextField from '@components/TextField/TextField';
import { formatCurrency } from '@utilities/currency';
import {getQuoteTotalPrice} from "@utilities/pricing";
import styles from "@components/NetPriceAdjustmentModal/NetPriceAdjustmentModal.module.scss";

const NetPriceAdjustmentModal = ({
    active = false,
    quote,
    siblingQuote = null,
    onClose,
    onSubmit
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [netPrice1, setNetPrice1] = useState(null);
    const [netPrice2, setNetPrice2] = useState(null);

    let listPrice1;
    let listPrice2;
    let listPrice1Label = '';
    let listPrice2Label = '';
    if(siblingQuote && (quote.product_id == 1 || siblingQuote.product_id == 1)) {
        listPrice1 = getQuoteTotalPrice(quote, null, true, true, true, true);
        listPrice2 = getQuoteTotalPrice(siblingQuote, null, true, true, true, true);
        listPrice1Label = 'Coat';
        listPrice2Label = 'Pants';
    } else {
        listPrice1 = getQuoteTotalPrice(quote, null, true, true, true);
    }

    let totalOriginalPrice = listPrice1;
    if(listPrice2) {
        totalOriginalPrice += listPrice2;
    }

    let newTotalNetPrice = 0;
    if(netPrice1) {
        newTotalNetPrice += netPrice1;
    } else if (listPrice1) {
        newTotalNetPrice += listPrice1;
    }
    if(netPrice2) {
        newTotalNetPrice += netPrice2;
    } else if (listPrice2) {
        newTotalNetPrice += listPrice2;
    }

    /**
     * Handle Modal Close (reset)
     */

    const handleClose = useCallback(() => {
        setNetPrice1(null);
        setNetPrice2(null);
        onClose();
    }, [onClose]);

    /**
     * Handle Submit
     */

    const handleSubmit = useCallback(async () => {
        setIsSubmitting(true);
        await onSubmit(netPrice1, netPrice2);
        setIsSubmitting(false);
        setNetPrice1(null);
        setNetPrice2(null);
        onClose();
    }, [netPrice1, netPrice2, onSubmit, onClose]);

    return (
        <Modal
            active={active}
            onClose={() => handleClose()}
        >
            <h4 className={modalStyles.modalTitle}>Apply Fire-Dex Approved Net Price</h4>
            <div className={classNames(modalStyles.modalBody, styles.modalBody)}>
                <p>Apply a new net price to this item</p>
                <div className={styles.fields}>
                    <TextField
                        label={listPrice1Label + ' List Price'}
                        value={formatCurrency(listPrice1)}
                        disabled={true}
                        style={{marginRight: 24}}
                    />
                    <TextField
                        label={'New Net Price'}
                        value={netPrice1 || ''}
                        onChange={e => setNetPrice1(parseFloat(e.target.value))}
                        type="number"
                    />
                </div>
                {
                    listPrice2Label ?
                        <div className={styles.fields}>
                            <TextField
                                label={listPrice2Label + ' List Price'}
                                value={formatCurrency(listPrice2)}
                                disabled={true}
                                style={{marginRight: 24}}
                            />
                            <TextField
                                label={'New Net Price'}
                                value={netPrice2 || ''}
                                onChange={e => setNetPrice2(parseFloat(e.target.value))}
                                type="number"
                            />
                        </div>
                        : null
                }
                <div className={styles.fields}>
                    <TextField
                        label={'Total List Price'}
                        value={formatCurrency(totalOriginalPrice)}
                        disabled={true}
                    />
                </div>
                <div className={styles.fields}>
                    <TextField
                        label={'New Total Net Price'}
                        value={formatCurrency(newTotalNetPrice)}
                        disabled={true}
                    />
                </div>
            </div>
            <div className={modalStyles.modalActions}>
                <Button variant="outlined" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant="solid"
                    onClick={handleSubmit}
                    loading={isSubmitting}>
                    Apply
                </Button>
            </div>
        </Modal>
    );
};

export default NetPriceAdjustmentModal;
