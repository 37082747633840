import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12" {...props}>
			<path d="M2.566.633l6.464 6.45 6.464-6.45L17.48 2.62l-8.45 8.45L.58 2.62z" />
		</svg>
	);
}

export default SvgComponent;
