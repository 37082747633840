import WarningIcon from '@components/Icons/WarningIcon';
import styles from './CustomerAlert.module.scss';
import classNames from 'classnames';
import {useEffect, useState} from "react";
import date from "@utilities/date";

const CSRCustomerAlert = ({
	order = null,
    isCSRUser = false,
	className = null,
	children = null,
}) => {
	const [activeCustomerAlert, setActiveCustomerAlert] = useState(null);

	useEffect(() => {
		for (const customerAlert of order.order_customer_alerts) {
			if (customerAlert.order_customer_alert_id === order.order_customer_alert_active_id) {
				setActiveCustomerAlert(customerAlert);
			}
		}
	}, [order.order_customer_alert_active_id, order.order_customer_alerts]);

	if (!order) {
		return null;
	}

	return (
		<div
			className={classNames({
				[styles.customerAlertContainer]: true,
				[styles.hasCustomerAlert]: !!order.order_customer_alert,
				[className]: !!className,
			})}>

			<div className={styles.customerAlertContainerContainer}>
				{order.order_status === 'Requires Edits' && (
					<div className={styles.customerAlertContent}>
						<div className={styles.customerAlertTitle}>
							<WarningIcon />
							<b>Order {order.order_status}</b>
						</div>
						{!!activeCustomerAlert && (
							<p><b>{date(activeCustomerAlert.order_customer_alert_created_timestamp).format('MMM D YYYY hh:mm A')}</b> {activeCustomerAlert.order_customer_alert_message}</p>
						)}
					</div>
				)}
				{
					isCSRUser && order.order_customer_alerts?.map((alert, index) => {
						if (alert.order_customer_alert_id === activeCustomerAlert?.order_customer_alert_id) {
							return null;
						}

						return <div className={styles.customerAlertContent} key={'alert-item-' + index}>
							<p><b>{date(alert.order_customer_alert_created_timestamp).format('MMM D YYYY hh:mm A')}</b> {alert.order_customer_alert_message}</p>
						</div>
					})
				}
			</div>

			{children}
		</div>
	);
};

export default CSRCustomerAlert;
