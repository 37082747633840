import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { fetchStaticData } from '@store/thunks/fetch-static-data';
import { fetchUser } from '@store/thunks/fetch-user';
import Router from './Router';
import RootLoader from '@components/RootLoader/RootLoader';
import { persistor } from '@store';
import Configurator from '@components/Configurator';
import {ENVIRONMENT} from "@config";
import BottomBanner from "@components/BottomBanner/BottomBanner";

if (process.env.NODE_ENV === 'development') {
	require('./debug');
}

function SiteWrapper() {
	const dispatch = useDispatch();
	const configuratorQuoteId = useSelector(
		(state) => state.configurator.configuratorOptions.quoteId,
	);
	const configuratorProductId = useSelector(
		(state) => state.configurator.configuratorOptions.productId,
	);
	const configuratorProductLineId = useSelector(
		(state) => state.configurator.configuratorOptions.productLineId,
	);
	const configuratorOnClose = useSelector(
		(state) => state.configurator.configuratorOptions.onClose,
	);
	const [highlightErrors, setHighlightErrors] = useState(false);

	/**
	 * Fetch static data
	 */

	useEffect(() => {
		dispatch(fetchStaticData());
		dispatch(fetchUser());
	}, [dispatch]);

	useEffect(() => {
		if (
			configuratorQuoteId ||
			(configuratorProductId && configuratorProductLineId)
		) {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}
	}, [configuratorQuoteId, configuratorProductId, configuratorProductLineId]);

	return (
		<div className="site-wrapper">
			<PersistGate loading={null} persistor={persistor}>
				<Router />
			</PersistGate>
			<RootLoader />
			<div id="overlay-portal" />
			<div id="overlay-portal-error" />
			{configuratorQuoteId ||
			(configuratorProductId && configuratorProductLineId) ? (
				<Configurator
					quoteId={configuratorQuoteId}
					productId={configuratorProductId}
					productLineId={configuratorProductLineId}
					onClose={() => {
						if (configuratorOnClose) {
							configuratorOnClose();
						}
					}}
					highlightErrors={highlightErrors}
					setHighlightErrors={setHighlightErrors}
				/>
			) : null}
			{
				ENVIRONMENT === 'staging' ?
					<BottomBanner message="This is a test system. Visit the live app at firewriterv3.firedex.com."/>
					: null
			}
		</div>
	);
}

export default SiteWrapper;
