import { useCallback, useEffect, useRef } from 'react';
import Card from '@components/Card/Card';
import Screen from '@components/Screen/Screen';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RequestQuoteConfirmationForm from './components/RequestQuoteConfirmationForm/RequestQuoteConfirmationForm';
import styles from './RequestQuoteConfirmation.module.scss';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';
import Button from '@components/Button/Button';

const RequestQuoteConfirmation = () => {
	const isAuthenticated = useRef(null);
	const { user, authSettled } = useSelector((state) => state.auth);
	const history = useHistory();

	// form data from previous screen
	const raqFormData = useSelector((state) => state.raq.formData);

	const content = useSelector((state) => {
		return {
			confirmationHeader:
				state.staticData.content['request-quote-page']?.indexed[
					'confirmation'
				]['content_item_description'],
			confirmationContent:
				state.staticData.content['request-quote-page']?.indexed[
					'confirmation'
				]['content_item_html'],
		};
	});

	/**
	 * Redirect if logged in and not standard user
	 */

	useEffect(() => {
		if (user && !user?.roles?.find((role) => role.role_token === 'user')) {
			history.push('/dashboard');
		}
	}, [user, history]);

	/**
	 * Handle logout issues (because this page is both public and non-public)
	 */

	useEffect(() => {
		// keep track of whether user was logged in on load
		if (isAuthenticated.current === null && authSettled) {
			isAuthenticated.current = !!user;
		}

		// if logged out, redirect
		if (isAuthenticated.current && !user) {
			history.push('/login');
		}
	}, [user, authSettled, history]);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (formData) => {
			const { data } = await Http().post(
				`${API_ROOT}/request-quote/confirm`,
				{ ...raqFormData, ...formData },
			);

			if (data.user) {
				history.push(`/verify?email=${raqFormData.user_email}`);
				return;
			}
		},
		[history, raqFormData],
	);

	return (
		<Screen
			hideSidebar={!user}
			hideFooter={true}
			pageContentClassName={styles.pageContent}>
			<Helmet>
				<title>Request a Quote - Success</title>
			</Helmet>
			<Card className={styles.contentCard}>
				{!user && (
					<>
						<h3>{content.confirmationHeader}</h3>
						<RequestQuoteConfirmationForm
							onSubmit={handleSubmit}
							raqFormData={raqFormData}>
							<div
								className={styles.formContentContainer}
								dangerouslySetInnerHTML={{
									__html: content.confirmationContent,
								}}
							/>
						</RequestQuoteConfirmationForm>
					</>
				)}
				{!!user && (
					<>
						<p className={styles.authenticatedMessage}>
							Thanks for contacting us! We&apos;ll be in touch
							soon.
						</p>
						<div className={styles.actions}>
							<Button
								variant="solid"
								type="submit"
								onClick={() => {
									history.push('/quotes');
								}}>
								My Quotes
							</Button>
							<Button
								variant="outlined"
								onClick={() => {
									history.push('/products');
								}}>
								View All Products
							</Button>
						</div>
					</>
				)}
			</Card>
		</Screen>
	);
};

export default RequestQuoteConfirmation;
