import { useCallback, useState } from 'react';
import TextField from '@components/TextField/TextField';
import styles from './PasswordForm.module.scss';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';
import Button from '@components/Button/Button';

const PasswordForm = ({ onSubmit = () => {} }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [currentPasswordError, setCurrentPasswordError] = useState(null);
	const [newPasswordError, setNewPasswordError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);

	const [message, setMessage] = useState('');

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			setMessage('');

			if (!currentPassword) {
				setCurrentPasswordError('Please enter your current password');
				return;
			}
			if (!newPassword) {
				setNewPasswordError('Please enter your new password');
				return;
			}
			if (!confirmPassword) {
				setConfirmPasswordError('Please confirm your new password');
				return;
			}
			if (newPassword !== confirmPassword) {
				setConfirmPasswordError('Passwords do not match');
				return;
			}

			setIsSubmitting(true);

			try {
				await Http().post(`${API_ROOT}/users/change-password`, {
					currentPassword,
					newPassword,
					confirmPassword,
				});

				setCurrentPassword('');
				setNewPassword('');
				setConfirmPassword('');

				setMessage('Your password has been updated!');

				onSubmit();
			} catch (error) {
				console.log(error);
			} finally {
				setIsSubmitting(false);
			}
		},
		[currentPassword, newPassword, confirmPassword, onSubmit],
	);

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<TextField
					type="password"
					label="Current Password*"
					value={currentPassword}
					onChange={(e) => {
						setCurrentPasswordError(null);
						setCurrentPassword(e.target.value);
					}}
					className={styles.field}
					error={currentPasswordError}
				/>
				<TextField
					type="password"
					label="New Password*"
					value={newPassword}
					onChange={(e) => {
						setNewPasswordError(null);
						setNewPassword(e.target.value);
					}}
					className={styles.field}
					error={newPasswordError}
				/>
				<TextField
					type="password"
					label="Confirm Password*"
					value={confirmPassword}
					onChange={(e) => {
						setConfirmPasswordError(null);
						setConfirmPassword(e.target.value);
					}}
					className={styles.field}
					error={confirmPasswordError}
				/>
				{!!message && <p className={styles.message}>{message}</p>}
				<Button
					type="submit"
					variant="solid"
					loading={isSubmitting}
					className={styles.submitButton}>
					Save Changes
				</Button>
			</form>
		</div>
	);
};

export default PasswordForm;
