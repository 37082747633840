import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpen } from '../store/reducers/mobile-menu';

export const useMobileMenuState = () => {
	const dispatch = useDispatch();
	const mobileMenuOpen = useSelector((state) => state.mobileMenu.open);
	const setMobileMenuOpen = useCallback(
		(value) => {
			dispatch(setOpen(value));
		},
		[dispatch],
	);

	return [mobileMenuOpen, setMobileMenuOpen];
};
