import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import styles from './CreateAccountPromptModal.module.scss';
import { useSelector } from 'react-redux';

const CreateAccountPromptModal = ({
	active = false,
	onClose = () => {},
	onCta = () => {},
}) => {
	const history = useHistory();
	const content = useSelector((state) => state.staticData.content);
	const ctaText = content['create-account-modal']?.content_html;

	function handleLoginNavigate () {
		onClose();
		if (history) {
			history.push('/login');
		} else {
			window.location = '/login';
		}
	}

	return (
		<Modal
			active={active}
			onClose={() => onClose()}
			className={styles.modal}>
			<h4
				className={classNames(
					modalStyles.modalTitle,
					styles.modalTitle,
				)}>
				Create Account
			</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<div
					className={styles.content}
					dangerouslySetInnerHTML={{ __html: ctaText }}
				/>
			</div>
			<div
				className={classNames([
					modalStyles.modalActions,
					styles.modalActions,
				])}>
				<Button variant="solid" onClick={onCta}>
					Create Account
				</Button>
				<Button
					variant="outlined"
					onClick={() => handleLoginNavigate()}>
					Log In
				</Button>
				<Button
					variant="plain"
					onClick={onClose}
					className={styles.closeButton}>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export default CreateAccountPromptModal;
