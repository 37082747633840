import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			width={14}
			height={18}
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<g>
				<path d="M10.5 7.938C10.5 5.768 9.179 4 7 4 4.821 4 3.5 5.768 3.5 7.938v5.25h7zm3.5 6.125v.874H0v-.874l1.75-1.75V7.936c0-2.695 1.435-4.934 3.938-5.53v-.595A1.31 1.31 0 017 .5a1.31 1.31 0 011.313 1.312v.595c2.51.595 3.937 2.844 3.937 5.53v4.375zm-8.75 1.75h3.5c0 .962-.787 1.75-1.75 1.75s-1.75-.788-1.75-1.75z" />
			</g>
		</svg>
	);
}

export default SvgComponent;
