import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
	persistStore,
	// FLUSH,
	// REHYDRATE,
	// PAUSE,
	// PERSIST,
	// PURGE,
	// REGISTER,
} from 'redux-persist';

import demoModeReducers from './reducers/demo-mode';
import authReducers from './reducers/auth';
import mobileMenuReducers from './reducers/mobile-menu';
import dimensionsReducers from './reducers/dimensions';
import staticDataReducers from './reducers/static-data';
import configuratorReducers from './reducers/configurator';
import notificationsReducers from './reducers/notifications';
import buildReducers from './reducers/build';
import raqReducers from './reducers/raq';
import addressesReducers from './reducers/addresses';
import errorReducers from './reducers/error';

export const store = configureStore({
	reducer: {
		auth: authReducers,
		mobileMenu: mobileMenuReducers,
		dimensions: dimensionsReducers,
		demoMode: demoModeReducers,
		staticData: staticDataReducers,
		configurator: configuratorReducers,
		notifications: notificationsReducers,
		build: buildReducers,
		raq: raqReducers,
		addresses: addressesReducers,
		error: errorReducers,
	},
	middleware: getDefaultMiddleware({
		// serializableCheck: {
		//     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		// },
		serializableCheck: false, //Need onClose action for configurator store
	}),
});

export const persistor = persistStore(store, null);
