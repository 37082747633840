import { useCallback, useLayoutEffect, useState } from 'react';

export const useKeyboardNavigation = (
	ref,
	isActive,
	setIsActive = () => {},
	options = [],
	extractLabel = () => {},
	onSelect = () => {},
) => {
	const [cursor, setCursor] = useState(-1);
	const [query, setQuery] = useState('');
	const [queryResetTimeout, setQueryResetTimeout] = useState(null);

	/**
	 * Scroll item into view
	 */

	const scrollIntoView = useCallback(
		(index) => {
			const inputNode = ref.current;
			const listNode = inputNode?.nextElementSibling?.children[0];
			const itemNode = listNode?.children[index];

			if (!itemNode) {
				return;
			}

			const listRect = listNode.getBoundingClientRect();
			const itemRect = itemNode.getBoundingClientRect();

			if (
				// if bottom of list item is below bottom of list
				itemRect.y + itemRect.height > listRect.y + listRect.height ||
				// if top of list item is above top of list
				itemRect.y < listRect.y
			) {
				itemNode.scrollIntoView({
					block: 'center',
					behavior: 'smooth',
				});
			}
		},
		[ref],
	);

	/**
	 * Blur Handler
	 */

	const onBlur = useCallback(() => {
		setCursor(-1);
		setQuery('');
		setIsActive(false);
	}, [setIsActive]);

	/**
	 * Keydown Handler
	 */

	const onKeydown = useCallback(
		(e) => {
			let delta = 0;

			/**
			 * Prevent default on all keys except Tab / Ctrl / Shift
			 */

			if (!['Tab'].includes(e.key) && !e.ctrlKey && !e.shiftKey) {
				e.preventDefault();
				e.stopPropagation();
			} else {
				return;
			}

			/**
			 * Handle special keys
			 */

			switch (e.key) {
				// toggle menu open/closed and select item at cursor index
				case 'Enter': {
					options[cursor] && onSelect(options[cursor]);
					setIsActive((value) => !value);
					break;
				}
				// open menu if closed
				case ' ': {
					if (!isActive) {
						setIsActive(true);
					}
					break;
				}
				// close menu
				case 'Escape': {
					setIsActive(false);
					break;
				}
				// navigate down in list
				case 'ArrowDown': {
					if (isActive) {
						delta = 1;
					}

					setIsActive(true);
					break;
				}
				// navigate up in list
				case 'ArrowUp': {
					if (isActive) {
						delta = -1;
					}

					setIsActive(true);
					break;
				}
			}

			/**
			 * Handle a-z
			 */

			if (/^[a-zA-Z0-9]$/.test(e.key)) {
				// clear the query timeout
				window.clearTimeout(queryResetTimeout);

				const nextQuery = query + e.key;
				const exp = new RegExp(`^${nextQuery}`, 'i');

				// find item matching query
				const index = options.findIndex((option) => {
					return exp.test(extractLabel(option));
				});

				// set delta to move to found item
				if (index > -1) {
					delta = index - cursor;
					setQuery(nextQuery);
				} else {
					setQuery('');
				}

				// open menu
				if (!isActive) {
					setIsActive(true);
				}

				// clear the query after 1 second idle
				setQueryResetTimeout(
					window.setTimeout(() => {
						setQuery('');
					}, 1000),
				);
			}

			// determine next cursor position
			const nextCursor =
				(delta > 0 && cursor < options.length - 1) ||
				(delta < 0 && cursor > 0)
					? cursor + delta
					: cursor;

			// scroll item into view
			scrollIntoView(nextCursor);

			// update the cursor position
			setCursor(nextCursor);
		},
		[
			options,
			isActive,
			setIsActive,
			cursor,
			query,
			onSelect,
			extractLabel,
			queryResetTimeout,
			scrollIntoView,
		],
	);

	/**
	 * Initialization
	 */

	useLayoutEffect(() => {
		const node = ref.current;

		if (!node) {
			return;
		}

		// bind event handlers
		node.addEventListener('blur', onBlur);
		node.addEventListener('keydown', onKeydown);

		return () => {
			// cleanup event handlers
			node.removeEventListener('blur', onBlur);
			node.removeEventListener('keydown', onKeydown);
		};
	}, [ref, onBlur, onKeydown]);

	/**
	 * Return cursor value and setter
	 */

	return { cursor, setCursor };
};
