import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useLetteringUtils = (options = [], values = []) => {
	// lettering option IDs
	const {
		patch: letteringPatchId,
		style: letteringStyleId,
		color: outerShellColorId,
	} = useSelector((state) => state.staticData.letteringOptions);

	/**
	 * Get Option Value by ID
	 */

	const getOptionValueById = useCallback(
		(id) => {
			if (!id) {
				return null;
			}

			return options.reduce((value, option) => {
				if (!value) {
					return option.values.find(
						(item) =>
							item.product_option_value_id === id.toString(),
					);
				}

				return value;
			}, null);
		},
		[options],
	);

	/**
	 * Get patch num rows
	 */

	const getPatchNumRows = useCallback(
		(id = null) => {
			return parseInt(
				getOptionValueById(id)
					?.product_option_value_lettering_num_rows || 1,
			);
		},
		[getOptionValueById],
	);

	/**
	 * Get default patch color
	 */

	const getDefaultPatchColor = useCallback(() => {
		return values.find(
			(value) => value.product_option_id === outerShellColorId.toString(),
		);
	}, [values, outerShellColorId]);

	/**
	 * New item template
	 */

	const emptyItem = useMemo(() => {
		// use outer shell color for default patch color
		const defaultPatchColor = getDefaultPatchColor();

		return {
			product_option_id: letteringPatchId,
			product_line_id: null,
			lettering_placement_id: null,
			product_option_value_id: null,
			product_option_value_id_style: null,
			product_option_value_id_patch_color:
				defaultPatchColor?.product_option_value_id || null,
			quote_product_option_value_lettering_type: null,
			quote_product_option_value_lettering_custom_text: null,
		};
	}, [letteringPatchId, getDefaultPatchColor]);

	/**
	 * Determine lettering preview text color
	 */

	const getLetteringStyleColor = useCallback(
		(styleId) => {
			// find lettering style option
			const option = options.find(
				(option) =>
					option.product_option_id === letteringStyleId.toString(),
			);

			// find lettering style option value
			const optionValue = option?.values.find(
				(value) => value.product_option_value_id === styleId,
			);

			return optionValue?.product_option_value_lettering_color || null;
		},
		[options, letteringStyleId],
	);

	/**
	 * Get Lettering style description
	 */

	const getLetteringStyleDescription = useCallback(
		(styleId) => {
			// find lettering style option
			const option = options.find(
				(option) =>
					option.product_option_id === letteringStyleId.toString(),
			);

			// find lettering style option value
			const optionValue = option?.values.find(
				(value) => value.product_option_value_id === styleId,
			);

			return optionValue?.product_option_value_description || null;
		},
		[options, letteringStyleId],
	);

	/**
	 * Initialize new lettering item for position
	 */

	const initializeLetteringItem = useCallback(
		(values = {}) => {
			const item = {
				...emptyItem,
				// populate initial value(s)
				...values,
			};

			// set type to 'custom' if custom text is populated
			if (
				Object.keys(values).includes(
					'quote_product_option_value_lettering_custom_text',
				)
			) {
				item.quote_product_option_value_lettering_type = 'custom';
			}

			// set preview text color if lettering style is defined
			if (Object.keys(values).includes('product_option_value_id_style')) {
				item['product_option_value_lettering_color'] =
					getLetteringStyleColor(
						item['product_option_value_id_style'],
					);
			}

			return item;
		},
		[emptyItem, getLetteringStyleColor],
	);

	return {
		initializeLetteringItem,
		getOptionValueById,
		getPatchNumRows,
		getDefaultPatchColor,
		getLetteringStyleColor,
		getLetteringStyleDescription
	};
};
