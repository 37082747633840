import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import styles from './ConfirmationModal.module.scss';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

const ConfirmationModal = ({
	active = false,
	title = 'Are You Sure',
	message = 'Are you sure you want to...',
	onConfirm = async () => {},
	onClose = () => {},
	showClose = true,
	confirmLabel = 'Yes'
}) => {
	const [isConfirming, setIsConfirming] = useState(false);

	/**
	 * Confirm Button Handler
	 */

	const handleConfirm = useCallback(async () => {
		setIsConfirming(true);
		await onConfirm();
		setIsConfirming(false);
	}, [onConfirm]);

	return (
		<Modal active={active} onClose={() => onClose()}>
			<h4 className={modalStyles.modalTitle}>{title}</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<p>{message}</p>
			</div>
			<div className={modalStyles.modalActions}>
				{ showClose ?
					<Button variant="outlined" onClick={onClose}>
						No
					</Button>
					: null
				}
				<Button
					variant="solid"
					onClick={handleConfirm}
					loading={isConfirming}>
					{confirmLabel}
				</Button>
			</div>
		</Modal>
	);
};

export default ConfirmationModal;
