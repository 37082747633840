import { API_ROOT } from '@config';
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import styles from './Resource.module.scss';
import HeartIcon from '@components/Icons/HeartIcon';
import DownloadIcon from '@components/Icons/DownloadIcon';
import ShareIcon from '@components/Icons/Share1Icon';
import CheckIcon from '@components/Icons/CheckIcon';
import classNames from 'classnames';
import date from "@utilities/date";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";

const Resource = ({ resource, onSelect, viewMode, initializeShareModal, generateProductDownloadArchive, type="file", isDownload = false, isShare = false, isCheck = false, showFavoriteToggle = true,  toggleFavorite = () => {}, }) => {
	const [generateArchiveConfirmationActive, setGenerateArchiveConfirmationActive] = useState(false);

	return (
		<div className={classNames({
			[styles.container]: true,
			[styles.list]: viewMode == 'List View',
			[styles.file]: type == 'file'
		})}>
			<div className={classNames({
				[styles.fileImageContainer]: type == 'file'
			})}>
				{type == 'file' ?
					<>
						<div className={styles.buttonsContainer}>
							{
								showFavoriteToggle ?
									<div onClick={toggleFavorite}
										 className={classNames({
											 [styles.button]: true,
											 [styles.active]: resource.product_download_is_favorite != 0
										 })}><HeartIcon/></div>
									: null
							}
							<Link to={{pathname:`${API_ROOT}${resource.product_download_file_url}`}} target="_blank" rel="noopener noreferrer"
								className={classNames({
								[styles.button]: true,
								[styles.active]: isDownload
							})}><DownloadIcon /></Link>
							<div onClick={() => {
								initializeShareModal([resource.product_download_id]);
							}} className={classNames({
								[styles.button]: true,
								[styles.active]: isShare
							})}><ShareIcon /></div>
							<div onClick={onSelect} className={classNames({
								[styles.button]: true,
								[styles.active]: isCheck
							})}><CheckIcon /></div>
						</div>
						<div
							className={styles.image}
							style={{
								backgroundImage: `url("${API_ROOT}${resource.product_download_file_url_preview}")`,
								position: 'relative'
							}}
						>
							<Link
								to={{pathname:`${API_ROOT}${resource.product_download_file_url}`}}
								target="_blank" rel="noopener noreferrer"
								style={{
									position: 'absolute',
									top: 0,
									bottom: 0,
									right: 0,
									left: 0,
									width: '100%'
							}}
							/>
						</div>
					</>

					:

					<div
						className={classNames({
							[styles.image]: true,
							[styles.topLevel]: true,
						})}
						style={{
							backgroundImage: `url("${API_ROOT}/images/folder.png")`,
							cursor: 'pointer'
						}}
						onClick={() => {
							onSelect();
						}}
					/>
				}
			</div>

			{type != 'file' && <>
				<div
					className={styles.info}
					style={{cursor: 'pointer'}}
					onClick={onSelect}
				>
					<h6>{type === 'folder' ? resource.product_product_line_product_download_folder : resource.product_line_description}</h6>
				</div>
				<div className={styles.buttonsContainer}>
					{
						showFavoriteToggle ?
							<div onClick={toggleFavorite}
								 className={classNames({
									 [styles.button]: true,
									 [styles.active]: type === 'folder' ? resource.product_folder_is_favorite != 0 : resource.product_line_is_favorite != 0
								 })}><HeartIcon/></div>
							: null
					}
					<div onClick={() => {
						setGenerateArchiveConfirmationActive(true);
					}} className={classNames({
						[styles.button]: true,
						[styles.active]: isDownload
					})}><DownloadIcon /></div>
				</div>
			</>}
			{type == 'file' &&
			<div className={styles.infoContainer}>
				<div>
					<span>File Name</span>
					<span>{resource.product_download_file}</span>
				</div>
				<div>
					<span>File Type</span>
					<span>{resource.product_download_file_extension}</span>
				</div>
				<div>
					<span>Last Updated</span>
					<span>{date(resource.product_download_last_updated_timestamp || resource.product_download_creation_date).format('M/D/YY')}</span>
				</div>
				{
					resource.product_download_file_extension === 'png' || resource.product_download_file_extension === 'jpg' || resource.product_download_file_extension === 'jpeg' ?
						<div>
							<span>Dimensions</span>
							<span>{`${resource.product_download_width}x${resource.product_download_height}`}</span>
						</div>
						: null
				}
				<div>
					<span>File Size</span>
					<span>{resource.product_download_size}</span>
				</div>
			</div>}
			{type == 'file' &&
			<div className={styles.buttonsContainer}>
				{
					showFavoriteToggle ?
						<div onClick={toggleFavorite}
							 className={classNames({
								 [styles.button]: true,
								 [styles.active]: resource.product_download_is_favorite != 0
							 })}><HeartIcon/></div>
						: null
				}
				<Link  to={{pathname:`${API_ROOT}${resource.product_download_file_url}`}} target="_blank" rel="noopener noreferrer"
					className={classNames({
					[styles.button]: true,
					[styles.active]: isDownload
				})}><DownloadIcon /></Link>
				<div onClick={() => {
					initializeShareModal([resource.product_download_id]);
				}} className={classNames({
					[styles.button]: true,
					[styles.active]: isShare
				})}><ShareIcon /></div>
				<div onClick={onSelect} className={classNames({
					[styles.button]: true,
					[styles.active]: isCheck
				})}><CheckIcon /></div>
			</div>}
			<ConfirmationModal
				title="Warning"
				message="Are you sure you want to download all assets in this folder?"
				active={generateArchiveConfirmationActive}
				onConfirm={() => {
					generateProductDownloadArchive({
						productId: resource.product_id,
						productLineId: resource.product_line_id,
						forGeneral: resource.isGeneral,
						folder: resource.product_product_line_product_download_folder
					});
					setGenerateArchiveConfirmationActive(false);
				}}
				onClose={() => {
					setGenerateArchiveConfirmationActive(false);
				}}
			/>
		</div>
	);
};

export default Resource;
