import React from 'react';
import { API_ROOT } from '@config';
import styles from './Logo.module.scss';
import classNames from 'classnames';

const Logo = ({ user, className = null }) => {
	return (
		<div
			className={classNames({
				[styles.logo]: true,
				[className]: !!className,
			})}>
			{!!user.organization_logo && (
				<img
					className={styles.logoImage}
					src={`${API_ROOT}/uploaded/logos/${user.organization_logo}`}
				/>
			)}
		</div>
	);
};

export default Logo;
