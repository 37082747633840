import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 14"
			{...props}>
			<path d="M14.5 7A2.5 2.5 0 1012 4.5 2.492 2.492 0 0014.5 7zM7 6a3 3 0 10-3-3 2.987 2.987 0 003 3zm7.5 3C12.67 9 9 9.92 9 11.75V14h11v-2.25C20 9.92 16.33 9 14.5 9zM7 8c-2.33 0-7 1.17-7 3.5V14h7v-2.25a3.941 3.941 0 012.37-3.47A12.283 12.283 0 007 8z" />
		</svg>
	);
}

export default SvgComponent;
