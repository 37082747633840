import React, {useCallback, useMemo, useEffect} from 'react';
import styles from './LetteringPreviewFields.module.scss'
import classNames from 'classnames';
import LetteringTextArched from "@components/LetteringModal/components/LetteringPreview/components/LetteringTextArched";
import LetteringText from "@components/LetteringModal/components/LetteringPreview/components/LetteringText";
import {useMaxCharLengths} from "@components/LetteringModal/hooks/useMaxCharLengths";
import {usePlacementStyles} from "@components/LetteringSummary/hooks/usePlacementStyles";


const LetteringPreviewFields = ({
    showPlacements = false,
    data = [],
    outlineId,
    setLetteringPlacement = ()=>{},
    showLettering = false,
    letteringPlacements=[],
    isSummary = false,
    productId = null
}) => {
    const placementStyles = usePlacementStyles({outlineId:outlineId});

    const indexed = useMemo(() => {
        return data.reduce((indexed, value) => {
            if (!indexed[value.lettering_placement_id]) {
                indexed[value.lettering_placement_id] = value;
            }
            return indexed;
        }, {});
    }, [data]);


    const [charLengths,] = useMaxCharLengths(indexed);

    const getLetteringText = useCallback(
        (placementId) => {
            const item = indexed[placementId];
            if (!item) {
                return '';
            }

            switch (item.quote_product_option_value_lettering_type) {
                case 'custom': {
                    return (
                        item.quote_product_option_value_lettering_custom_text ||
                        ''
                    );
                }
                case 'name': {
                    return 'Name';
                }
                case 'rank': {
                    return 'Rank';
                }
                case 'ffid': {
                    return 'ID Number';
                }
                default:
                    return '';
            }
        },
        [indexed],
    );


    return (
        <div className={classNames({[styles.container]: true, [styles.insideSummary]: isSummary, [styles.coveralls]: productId === '3'})} style={{display:showLettering?'block':'none', pointerEvents: 'none'}}>
            {placementStyles && placementStyles.map((placement) =>{
                let item = indexed[placement.lettering_placement_id];

                if(!item){
                    let availableItem = letteringPlacements.find(
                        (dataItem) =>
                            dataItem.lettering_placement_id ==
                            placement.lettering_placement_id,
                    );
                    return ( <LetteringText
                        key={placement.lettering_placement_id}
                        placementId={placement.lettering_placement_id}
                        setPlacement={setLetteringPlacement}
                        getLetteringText={getLetteringText}
                        placementStyle={placement}
                        showShape={availableItem?showPlacements:false}
                    />)
                }
                if(item.product_option_value_lettering_is_arched === '1' && !item.product_option_value_lettering_num_rows) {
                    if(indexed[parseInt(placement.lettering_placement_id)-1]?.lettering_row_number == '0'){
                        item.product_option_value_lettering_num_rows = 2;
                    } else if(indexed[parseInt(placement.lettering_placement_id)+1]?.lettering_row_number == '1'){
                        item.product_option_value_lettering_num_rows = 2;
                    }
                }

                return (
                    item.product_option_value_lettering_is_arched ===
                '1' ? (
                        <LetteringTextArched
                            key={placement.lettering_placement_id}
                            placementId={placement.lettering_placement_id}
                            setPlacement={setLetteringPlacement}
                            item={item}
                            getLetteringText={getLetteringText}
                            placementStyle={placement}
                            showShape={showPlacements}
                            charLimit={charLengths[placement.lettering_placement_id]}
                        />
                    )  : (
                    <LetteringText
                        key={placement.lettering_placement_id}
                        placementId={placement.lettering_placement_id}
                        setPlacement={setLetteringPlacement}
                        item={item}
                        getLetteringText={getLetteringText}
                        placementStyle={placement}
                        showShape={showPlacements}
                        charLimit={charLengths[placement.lettering_placement_id]}
                    />
                )
            )}
            )
            }
        </div>
    )
}

export default LetteringPreviewFields;
