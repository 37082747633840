export const schema = {
	address_street_1: {
		initialValue: '',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your street address';
		},
	},
	address_city: {
		initialValue: '',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your city';
		},
	},
	address_state: {
		initialValue: '',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please select your state';
		},
	},
	address_county: {
		initialValue: '',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your county';
		},
	},
	address_country: {
		initialValue: 'US',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your country';
		},
	},
	address_zip: {
		initialValue: '',
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your zip code';
		},
	},
	fire_department_id: {
		initialValue: '',
		validate: (value, formData) => {
			if (formData.manual_department || formData.user_id) {
				return null;
			}
			return value ? null : 'Please select your fire department';
		},
	},
	manual_department: {
		initialValue: false,
		validate: () => null,
	},
	fire_department_name: {
		initialValue: false,
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your Fire Department';
		},
	},
	utk: {
		initialValue: '',
	},
};
