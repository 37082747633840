export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'production';
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const TCM_ROOT = process.env.REACT_APP_TCM_ROOT;

export const PLACE_HOLDER_IMAGE = 'https://via.placeholder.com/365';

export const OUTER_SHELL_OPTION_ID = '2';
export const THERMAL_LINER_OPTION_ID = '4';
export const MOISTURE_BARRIER_OPTION_ID = '5';

export const DISTRIBUTOR_USER_ROLE_ID = '3';
export const DISTRIBUTOR_ADMIN_ROLE_ID = '4';
export const STANDARD_USER_ROLE_ID = '5';
export const CSR_ROLE_ID = '6';
export const SUSPENDER_OPTION_ID = '72';

export const SKETCH_API_TOKEN = process.env.SKETCH_API_KEY;

export const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY_TEST;
