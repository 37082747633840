import classNames from 'classnames/bind';

function ScreenOverlay({children, className = "", ...props}) {
    if(children) {
        return (
            <div className={classNames(
                'overlay',
                className
            )}
                 {...props}>
                {children}
            </div>
        );
    }

    return (<div {...props}/>);
}

export default ScreenOverlay;
