import React, { useCallback, useEffect, useRef, useState } from 'react';
import MoreIcon from '@components/Icons/MoreIcon';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import classNames from 'classnames';
import { useClickOutside } from '@hooks/useClickOutside';
import styles from './Menu.module.scss';

const Menu = ({ actions = [], className = '' }) => {
	const containerNode = useRef(null);
	const [isMounted, setIsMounted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	/**
	 * Handle click outside
	 */

	useClickOutside(containerNode.current, () => {
		setIsOpen(false);
	});

	/**
	 * Handle Menu Item Click
	 */

	const handleAction = useCallback(
		async (action) => {
			setIsLoading(true);
			try {
				await action.action();
			} catch (error) {
				console.log(error);
			} finally {
				isMounted && setIsLoading(false);
			}
		},
		[isMounted],
	);

	/**
	 * Mounted check
	 */

	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		};
	}, []);

	return (
		<div
			ref={containerNode}
			className={classNames({
				[styles.container]: true,
				[styles.active]: isOpen,
				[className]: !!className,
			})}
			onClick={() => setIsOpen(!isOpen)}>
			{!isLoading && (
				<button className={styles.iconButton}>
					<MoreIcon className={styles.icon} />
				</button>
			)}
			{isLoading && <SpinnerIcon className={styles.spinner} />}
			<ul>
				{actions.map((action, a) => (
					<li key={a} onClick={() => handleAction(action)}>
						<span>{action.label}</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Menu;
