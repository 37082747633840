import React from 'react';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import styles from './LoadingMore.module.scss';

const LoadingMore = () => {
	return (
		<div className={styles.container}>
			<SpinnerIcon />
			<h5>Loading More...</h5>
		</div>
	);
};

export default LoadingMore;
