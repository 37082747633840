import {useCallback, useEffect, useRef, useState} from 'react';
import http from '@utilities/Http';
import {API_ROOT} from '@config';
import {CancelToken} from 'axios';
import {getParamString} from '@utilities/getParamString';
import {Link} from 'react-router-dom';
import Screen from '@components/Screen/Screen';
import Button from '@components/Button/Button';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';
import Collection from '@components/Collection/Collection';
import LegacyQuoteListItem from "@components/LegacyQuoteListItem/LegacyQuoteListItem";
import Filters from "./components/Filters/Filters";

/**
 * Items per Page
 */

const perPage = 10;

const filtersSchema = {
    'legacy_quote_name': '',
    'legacy_quote_quote_id': '',
    'legacy_quote_product_line_name': '',
};

const LegacyQuotes = () => {
    const fetchRequestSource = useRef(null);
    const [legacyQuotes, setLegacyQuotes] = useState([]);
    const [page, setPage] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [filters, setFilters] = useState({ ...filtersSchema });

    const fetchLegacyQuotes = useCallback((filterString = '',paginationString = '') => {
        // cancel any pending request
        fetchRequestSource.current?.cancel();
        // create new cancel token
        fetchRequestSource.current = CancelToken.source();

        setIsFetching(true);

        return http().get(`${API_ROOT}/legacy-quotes?${filterString}${paginationString}`,
            {cancelToken: fetchRequestSource.current?.token},
        ).then(({data}) => data.legacyQuotes)
            .catch(() => {
                return [];
            })
            .finally(() => {
                setInitialized(true);
                setIsFetching(false);
            });

    }, []);

    useEffect(() => {
        setPage(0);
        setIsLastPage(false);
    }, [filters]);

    /**
     * Fetch Legacy Quotes
     */
    useEffect(() => {
        fetchLegacyQuotes(getParamString(filters), getParamString({take: perPage, skip: page * perPage}))
            .then(legacyQuotes => {
                setIsLastPage(legacyQuotes.length < perPage);
                setLegacyQuotes((current) => [...(page === 0 ? [] : current), ...legacyQuotes]);
            })
    }, [page, fetchLegacyQuotes, filters]);

    return (
        <Screen
            headerContent={
                <>
                    <WelcomeHeader />
                    <Filters filters={filters} setFilters={setFilters} />
                </>
            }
            loading={isFetching && !initialized}
        >
            <Collection
                title="Legacy Quotes"
                items={legacyQuotes}
                itemIdField="legacy_quote_id"
                component={LegacyQuoteListItem}
                page={page}
                onPageChange={setPage}
                isLastPage={isLastPage}
                isFetching={isFetching}
                noResultsAction={
                    <Link to="/products">
                        <Button variant="outlined">View All Products</Button>
                    </Link>
                }
            />
        </Screen>
    );
};

export default LegacyQuotes;
