export function formatCurrency(value = '', digits = 2) {
	value = parseFloat(value.toString().replace(/[^\d.]/g, ''));

	if (isNaN(value)) {
		return '';
	}

	// strip trailing 0s
	if (value % 1 === 0) {
		digits = 0;
	}

	value = value.toLocaleString(undefined, {
		minimumFractionDigits: digits,
		maximumFractionDigits: digits,
	});

	return `$${value}`;
}
