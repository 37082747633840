import React from 'react';
import classNames from 'classnames';
import styles from './NoItems.module.scss';

const NoItems = ({ actions = null }) => {
	return (
		<div
			className={classNames({
				[styles.noItemsContainer]: true,
			})}>
			<p>This order has no items.</p>
			<div className={styles.actionsContainer}>{actions}</div>
		</div>
	);
};

export default NoItems;
