import * as React from 'react';

const SvgComponent = (props) => {
	const fill = props.fill || '#FFF';
	return (
		<svg
			width={36}
			height={36}
			viewBox="0 0 36 36"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			{...props}>
			<circle fill={fill} cx={18} cy={32} r={3} />
			<path fill={fill} d="M21 24a3 3 0 0 1-6 0V5a3 3 0 1 1 6 0v19z" />
		</svg>
	);
};

export default SvgComponent;
