import React from 'react';
import classNames from 'classnames';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import styles from './Button.module.scss';

/**
 * Button Variant ClassName Map
 */

const variants = {
	[styles.buttonVariantSolid]: 'solid',
	[styles.buttonVariantSolidGold]: 'solid-gold',
	[styles.buttonVariantSolidGray]: 'solid-gray',
	[styles.buttonVariantOutlined]: 'outlined',
	[styles.buttonVariantOutlinedAlt]: 'outlined-alt',
	[styles.buttonVariantMini]: 'mini',
	[styles.buttonVariantPlain]: 'plain',
	[styles.buttonVariantSquareSolid]: 'square-solid',
	[styles.buttonVariantSquareOutlined]: 'square-outlined',
	[styles.buttonVariantGrey]: 'grey',
	[styles.buttonVariantTransparentLight]: 'transparent-light',
	[styles.buttonVariantSolidRed]: 'solid-red',
	[styles.buttonVariantSolidOrange]: 'solid-orange',
};

/**
 * Adornment Component
 */

const Adornment = ({ start, end, children }) => {
	return (
		<div
			className={classNames({
				[styles.buttonAdornment]: true,
				[styles.buttonAdornmentStart]: start,
				[styles.buttonAdornmentEnd]: end,
			})}>
			{children}
		</div>
	);
};

/**
 * Button Component
 */

const Button = ({
	children,
	loading = false,
	onClick = () => {},
	className = '',
	type = 'button',
	variant = 'solid',
	adornment = null,
	adornmentPosition = 'start',
	tabIndex = null,
	...props
}) => {
	return (
		<button
			className={classNames({
				[styles.button]: true,
				// variant class map
				...Object.fromEntries(
					Object.entries(variants).map((entry) => [
						entry[0],
						entry[1] === variant,
					]),
				),
				[styles.active]: !!loading,
				[className]: !!className,
			})}
			onClick={onClick}
			type={type}
			tabIndex={tabIndex}
			{...props}>
			<div className={styles.buttonContent}>
				{!!adornment && adornmentPosition === 'start' && (
					<Adornment start>{adornment}</Adornment>
				)}
				{children}
				{!!adornment && adornmentPosition === 'end' && (
					<Adornment end>{adornment}</Adornment>
				)}
			</div>
			<SpinnerIcon className={styles.buttonSpinner} />
		</button>
	);
};

export default Button;
