import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { API_ROOT } from '@config';
import http from '@utilities/Http';
import ChevronIcon from '@components/Icons/ChevronIcon';
import CloseIcon from '@components/Icons/CloseIcon';
import SearchIcon from '@components/Icons/SearchIcon';
import TextField from '@components/TextField/TextField';
import SelectField from '@components/SelectField/SelectField';
import filterStyles from '@components/Collection/CollectionFilters.module.scss';
import styles from './Filters.module.scss';
import SuggestField from "@components/SuggestField/SuggestField";

const Filters = ({ filters, setFilters = () => {} }) => {
    const debounceTimeout = useRef(null);
    const [values, setValues] = useState({ ...filters });

    const isDistributorAdmin = useSelector((state) =>
        state.auth.user?.permissions?.includes('manage_organization'),
    );

    /**
     * Handle Remove Filter
     */

    const handleRemove = useCallback(
        (name) => {
            setFilters((filters) => ({ ...filters, [name]: '' }));
        },
        [setFilters],
    );

    /**
     * Handle Filter Change
     */

    const handleChange = useCallback(
        (e) => {
            setValues((values) => ({
                ...values,
                [e.target.name]: e.target.value,
            }));
        },
        [setValues],
    );

    /**
     * Debounce Updates
     */

    useEffect(() => {
        clearTimeout(debounceTimeout.current);

        const isTextFieldDiff = ['legacy_quote_name', 'legacy_quote_quote_id', 'legacy_quote_product_line_name'].some(
            (key) => filters[key] !== values[key],
        );

        if (JSON.stringify(filters) !== JSON.stringify(values)) {
            debounceTimeout.current = setTimeout(
                () => {
                    setFilters({ ...values });
                },
                isTextFieldDiff ? 350 : 0,
            );
        }
    }, [setFilters, filters, values]);

    /**
     * Sync Local 'Values' with Parent 'Filters'
     */

    useEffect(() => {
        setValues({ ...filters });
    }, [filters]);

    /**
     * Tags
     */

    const tags = useMemo(() => {
        const tags = [];

        // quote name
        if (filters?.legacy_quote_name) {
            tags.push(
                <li key="legacy_quote_name"  onClick={() => handleRemove('legacy_quote_name')}>
                    {filters?.legacy_quote_name}
                    <CloseIcon />
                </li>,
            );
        }

        // quote number
        if (filters?.legacy_quote_quote_id) {
            tags.push(
                <li key="legacy_quote_quote_id" onClick={() => handleRemove('legacy_quote_quote_id')} >
                    {filters?.legacy_quote_quote_id}
                    <CloseIcon/>
                </li>,
            );
        }

        // quote product line
        if (filters?.legacy_quote_product_line_name) {
            tags.push(
                <li key="legacy_quote_product_line_name" onClick={() => handleRemove('legacy_quote_product_line_name')}>
                    {filters?.legacy_quote_product_line_name}
                    <CloseIcon/>
                </li>,
            );
        }

        return tags.map((tag) => tag);
    }, [filters, handleRemove]);

    return (
        <div className={filterStyles.filtersContainer}>
            <div className={filterStyles.filtersContent}>
                <div
                    className={classNames({
                        [filterStyles.fieldsList]: true,
                        [styles.fieldsList]: true,
                        [styles.isAdmin]: isDistributorAdmin,
                    })}>
                    <TextField
                        className={classNames({
                            [filterStyles.filter]: true,
                        })}
                        inputClassName={filterStyles.filterInput}
                        name="legacy_quote_name"
                        value={values.legacy_quote_name}
                        onChange={handleChange}
                        placeholder="Quote Name"
                        adornment={<SearchIcon fill="#FFF" />}
                    />
                    <TextField
                        className={classNames({
                            [filterStyles.filter]: true,
                        })}
                        inputClassName={filterStyles.filterInput}
                        name="legacy_quote_quote_id"
                        value={values.legacy_quote_quote_id}
                        onChange={handleChange}
                        placeholder="Quote Number"
                        adornment={<SearchIcon fill="#FFF" />}
                    />
                    <TextField
                        className={classNames({
                            [filterStyles.filter]: true,
                        })}
                        inputClassName={filterStyles.filterInput}
                        name="legacy_quote_product_line_name"
                        value={values.legacy_quote_product_line_name}
                        onChange={handleChange}
                        placeholder="Product Line"
                        adornment={<SearchIcon fill="#FFF" />}
                    />
                </div>
                {!!tags.length && <ul className={filterStyles.tags}>{tags}</ul>}
            </div>
        </div>
    );
};

export default Filters;
