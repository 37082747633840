import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import SuggestField from '@components/SuggestField/SuggestField';
import Button from '@components/Button/Button';
import fieldStyles from '@components/Field/Fields.module.scss';
import styles from './AddAccessoryToOrderModal.module.scss';

const AddAccessoryToOrderModal = ({
	active = false,
	onClose = () => {},
	orderId,
	className = null,
}) => {
	const [productId, setProductId] = useState(null);
	const [error, setError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	/**
	 * Handle Modal Close
	 */

	const handleClose = useCallback(
		(newQuote = null) => {
			// clear fields
			setError(null);
			setProductId(null);
			onClose(newQuote);
		},
		[onClose],
	);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(async () => {
		if (!productId) {
			setError('Please select a product');
			return;
		}

		try {
			setIsSubmitting(true);

			await http()
				.post(`${API_ROOT}/orders/add-accessory-order/${orderId}`, {
					productId: productId,
				})
				.then(({ data }) => {
					//TODO: set up endpoint/response
					setIsSubmitting(false);
					handleClose(data?.new_quote);
				})
				.catch((error) => {
					console.log(error);
				});
		} catch {
			setIsSubmitting(false);
		}
	}, [productId, orderId, handleClose]);

	return (
		<Modal
			active={active}
			onClose={() => handleClose()}
			className={classNames({ [className]: !!className })}>
			<h4 className={modalStyles.modalTitle}>Add Accessory To Order</h4>
			<div
				className={classNames({
					[modalStyles.modalBody]: true,
					[styles.form]: true,
				})}>
				<SuggestField
					label="Accessories"
					value={productId}
					valueField="product_id"
					url={`${API_ROOT}/accessories`}
					getResults={(data) => data.accessories}
					labelExtractor={(product) =>
						`${product.product_line_description}`
					}
					onChange={() => {
						setError(null);
					}}
					onSelect={(product) => {
						setError(null);
						setProductId(product.product_id);
					}}
					clearOnEmpty={false}
				/>
			</div>
			{!!error && (
				<div className={styles.formErrorContainer}>
					<span className={fieldStyles.fieldError}>{error}</span>
				</div>
			)}
			<div className={modalStyles.modalActions}>
				<Button
					variant="solid"
					onClick={handleSubmit}
					loading={isSubmitting}>
					Add to Order
				</Button>
				<Button variant="plain" onClick={() => handleClose()}>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export default AddAccessoryToOrderModal;
