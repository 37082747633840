import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

const SaveToSwitchQuoteModal = ({
	active = false,
	onConfirm = async () => {},
	onReject = async () => {},
	onClose = () => {},
	fromProductTitle,
	toProductTitle,
}) => {
	const [isConfirming, setIsConfirming] = useState(false);

	/**
	 * Confirm Button Handler
	 */

	const handleConfirm = useCallback(async () => {
		setIsConfirming(true);
		await onConfirm();
		setIsConfirming(false);
	}, [onConfirm]);

	/**
	 * Reject Button Handler
	 */

	const handleReject = useCallback(async () => {
		setIsConfirming(true);
		await onReject();
		setIsConfirming(false);
	}, [onReject]);

	/**
	 * Close Button Handler
	 */

	const handleClose = useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal active={active} onClose={() => handleClose()}>
			<h4 className={modalStyles.modalTitle}>Save Your Changes</h4>
			<div className={classNames(modalStyles.modalBody)}>
				<p>
					You currently have unsaved changes to the {fromProductTitle}{' '}
					product. Would you like to save these change before
					switching to the {toProductTitle} product? Unsaved changes
					will be lost.
				</p>
			</div>
			<div className={modalStyles.modalActions}>
				<Button variant="outlined" onClick={handleReject}>
					No
				</Button>
				<Button
					variant="solid"
					onClick={handleConfirm}
					loading={isConfirming}>
					Yes
				</Button>
			</div>
		</Modal>
	);
};

export default SaveToSwitchQuoteModal;
