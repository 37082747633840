import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { API_ROOT } from '@config';
import Card from '@components/Card/Card';
import Product from '@components/Product/Product';
import PencilCircleIcon from '@components/Icons/PencilCircleIcon';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import Button from '@components/Button/Button';
import cardStyles from '@components/Card/Card.module.scss';
import styles from './FeaturedProducts.module.scss';
import Http from '@utilities/Http';
import CreateQuoteFromProductModal from '@components/CreateQuoteFromProductModal/CreateQuoteFromProductModal';
import { useSelector } from 'react-redux';
import ProductSelectModal from '@components/ProductSelectModal/ProductSelectModal';

const FeaturedProducts = () => {
	const history = useHistory();
	const [isFetching, setIsFetching] = useState(false);
	const [products, setProducts] = useState([]);
	const user = useSelector((state) => state.auth.user);

	const [customizeProductLineId, setCustomizeProductLineId] = useState(null);
	const [customizeProductId, setCustomizeProductId] = useState(null);
	const [productSelectModalActive, setProductSelectModalActive] =
		useState(false);
	const [createQuoteModalActive, setCreateQuoteModalActive] = useState(false);

	/**
	 * Fetch Featured Product Lines
	 */

	const fetchFeaturedProductLines = useCallback(async () => {
		setIsFetching(true);

		const endpoint = user?.organization_id
			? `organizations/featured-product-lines/${user.organization_id}`
			: 'product-lines/featured';

		await Http()
			.get(`${API_ROOT}/${endpoint}`)
			.then(({ data }) => {
				// ensure 6 products
				setProducts(
					new Array(6)
						.fill('')
						.map(() => [...data.featured_products])
						.reduce((result, item) => result.concat(item), [])
						.slice(0, 6),
				);
			})
			.finally(() => {
				setIsFetching(false);
			});
	}, [user]);

	function getSelectedProductLine() {
		if (!customizeProductLineId) {
			return null;
		}

		let productLines = [];

		for (let productLinesArrayKey in products) {
			productLines = productLines.concat(products[productLinesArrayKey]);
		}

		return productLines.find((productLine) => {
			return productLine.product_line_id === customizeProductLineId;
		});
	}

	useEffect(() => {
		fetchFeaturedProductLines();
	}, [fetchFeaturedProductLines]);

	/**
	 * Handle Customize Button Click
	 */

	const handleCustomize = useCallback(
		(customizeProductLineId, customizeProductId) => {
			setCustomizeProductLineId(customizeProductLineId);
			setCustomizeProductId([customizeProductId]);
			if(customizeProductLineId === null){
				setCreateQuoteModalActive(true);
			
			}else{
				setProductSelectModalActive(true);
			}
		},
		[],
	);

	/**
	 * Handle Product Select
	 */

	const handleProductSelect = useCallback((productIds) => {
		setCustomizeProductId(productIds);
		setProductSelectModalActive(false);
		setTimeout(() => {
			setCreateQuoteModalActive(true);
		}, 150);
	}, []);

	return (
		<Card
			title="Featured Products"
			headerContent={
				<Link className={cardStyles.cardHeaderLink} to="/products">
					Shop All
				</Link>
			}>
			<div className={styles.featuredProducts}>
				{isFetching && (
					<SpinnerIcon
						fill="#e87124"
						className={styles.loadingSpinner}
					/>
				)}
				{products.map((item, i) => (
					<div className={styles.featuredProductItem} key={i}
						 onClick={() =>
							 handleCustomize(
								 item.product_line_id,
								 item.product_id
							 )
						 }>
						<Product
							title={item.product_line_description}
							subTitle={item.product_line_copy}
							image={`${API_ROOT}/${item.product_line_image_file_url}`}
							headerContent={
								i > 0 ? (
									<button
										className={
											styles.featuredProductCustomizeButton
										}>
										<PencilCircleIcon fill="#ec9b21" />
										Customize
									</button>
								) : (
									<button
										className={
											styles.featuredProductCartButton
										}>
										<PencilCircleIcon fill="#ec9b21" />
										Customize
									</button>
								)
							}
						/>
					</div>
				))}
			</div>
			<Button
				variant="solid-gold"
				className={styles.featuredProductCta}
				onClick={() => {
					history.push('/products');
				}}>
				Shop All Products
			</Button>
			<ProductSelectModal
				productLine={getSelectedProductLine()}
				active={productSelectModalActive}
				onClose={() => {
					setProductSelectModalActive(false);
					setCustomizeProductLineId(null);
				}}
				onSelect={handleProductSelect}

			/>
			<CreateQuoteFromProductModal
				productLineId={customizeProductLineId}
				productIds={customizeProductId}
				active={createQuoteModalActive}
				onClose={() => {
					setCreateQuoteModalActive(false);
					setCustomizeProductLineId(null);
					setCustomizeProductId(null);
				}}
			/>
		</Card>
	);
};

export default FeaturedProducts;
