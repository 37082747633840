import { createSlice } from '@reduxjs/toolkit';
import { fetchStaticData } from '@store/thunks/fetch-static-data';

const staticDataSlice = createSlice({
	name: 'staticData',
	initialState: {
		staticDataSettled: false,
		contractPricingOptions: [],
		shippingOptions: [],
		productLines: [],
		productOptionSections: [],
		productCategories: [],
		orderStatuses: [],
		orderSettings: {},
		states: [],
		countries: [],
		fireDepartmentCountries: [],
		content: {},
		organizationSystemRoles: [],
		raqProducts: [],
		letteringOptions: [],
		letteringCategoryId: null,
	},
	extraReducers: {
		[fetchStaticData.fulfilled]: (state, action) => {
			return {
				...state,
				...action.payload,
				staticDataSettled: true,
			};
		},
		[fetchStaticData.rejected]: (state) => {
			return {
				...state,
				staticDataSettled: true,
			};
		},
	},
});

export const { setStaticData } = staticDataSlice.actions;
export default staticDataSlice.reducer;
