import React, {useCallback, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import SelectField from '@components/SelectField/SelectField';
import { letteringTextOptions } from '../../config';
import styles from './LetteringStyleForm.module.scss';
import CustomTextField from '../CustomTextField/CustomTextField';
import classNames from 'classnames';
import fieldStyles from '../../../Field/Fields.module.scss';
import selectStyles from '../../../SelectField/SelectField.module.scss';
import staticData from '@store/reducers/static-data';
import Select from 'react-select';
import {getProductOptionValueLabel} from "@utilities/methods";
import {useErrorAlert} from "@hooks/useErrorAlert";
import {API_ROOT} from "@config";

const LetteringStyleForm = ({
								placements = [],
	                            allPlacements = [],
								availableStyles = {},
								selectedPlacement,
								setPlacement = () => {},
								charLengths = {},
								options,
								data = {},
								onChange = () => {},
								onClear = () => {},
								errors = {},
								getPatchNumRows = () => {},
								getOptionValueById = () => {},
								getDefaultPatchColor = () => {},
								isEditable = true,
								onAdd = () => {},
								selectedPlacements = [],
								productId
							}) => {
	const {
		style: letteringStyleId,
		patch: letteringPatchId,
		color: outerShellColorId,
	} = useSelector((state) => state.staticData.letteringOptions);
	const [, setErrorAlert] = useErrorAlert();
	const orderSettings = useSelector((state) => state.staticData.orderSettings);
	const productLines = useSelector((state) => state.staticData.productLines);
	/**
	 * Get property name for lettering field
	 */

	const getLetteringFieldPropertyName = useCallback(
		(option) => {
			let property = 'product_option_value_id';

			switch (option.product_option_id) {
				// lettering style
				case letteringStyleId.toString(): {
					property = 'product_option_value_id_style';
					break;
				}
				// patch color
				case outerShellColorId.toString(): {
					property = 'product_option_value_id_patch_color';
					break;
				}
				default:
			}

			return property;
		},
		[letteringStyleId, outerShellColorId],
	);

	const getProductLine = useMemo(() => {
        return productLines.find((line) => {
            return line.product_line_id === options[0]?.product_line_id;
        });
    }, [productLines, options]);

	const numRows = useMemo(() => {
        return getPatchNumRows(data[selectedPlacement?.lettering_placement_id]?.product_option_value_id);
    }, [data]);

	/**
	 * Get label for lettering field
	 */

	const getLetteringFieldLabel = useCallback(
		(option) => {
			let label = option.product_option_description;

			switch (option.product_option_id) {
				// 'Outer Shell Color' => 'Patch Color'
				case outerShellColorId.toString(): {
					label = 'Patch Color';
					break;
				}
				default:
			}

			return `Choose ${label}`;
		},
		[outerShellColorId],
	);

	/**
	 * Get available option values
	 */

	const getAvailableOptionValues = useCallback(
		(option, placement = null) => {
			if (!option) {
				return [];
			}

			placement = placement || selectedPlacement;

			switch (option.product_option_id) {
				// patch options
				case letteringPatchId.toString(): {
					return (
						option.values
							// filter lettering placements assigned to patch
							.filter((value) => {
								return !!value.lettering_placements.find(
									(p) =>
										p.lettering_placement_id ===
										placement?.lettering_placement_id,
								);
							})
							// disable options based on other placements/num rows available
							.map((value) => {
								const numRows = parseInt(
									value.product_option_value_lettering_num_rows,
								);

								const start = parseInt(
									placement.lettering_placement_id,
								);

								const end = start + numRows;

								let disabled = false;

								for (let i = start + 1; i < end; i++) {
									if (
										data[i] &&
										parseInt(
											data[i].lettering_row_number,
										) === 0
									) {
										disabled = true;
									}
								}

								return { ...value, disabled };
							})
					);
				}
				// patch color options
				case outerShellColorId.toString(): {
					const defaultPatchColor = getDefaultPatchColor();

					const additionalPatchColors = Object.values(data).filter(
						(value) =>
							value.product_option_value_id_patch_color !=
							defaultPatchColor?.product_option_value_id,
					);

					if(getProductLine?.product_line_limit_patch_color == 1){
						let value = {...defaultPatchColor};
						value.product_option_value_label = 'Main Shell Color';
						value.disabled = false;
						return [value];

                    }

					const values = option.values.map((value) => {
						const item = { ...value };
						// replace default patch color with 'Main Shell Color'
						if (
							item.product_option_value_id ===
							defaultPatchColor?.product_option_value_id
						) {
							item.product_option_value_label =
								'Main Shell Color';
						}

						// allow only one additional patch color
						if (additionalPatchColors.length) {
							const additionalPatchColorId =
								additionalPatchColors[0]
									.product_option_value_id_patch_color;

							if (
								item.product_option_value_id !=
								additionalPatchColorId &&
								item.product_option_value_id !=
								defaultPatchColor?.product_option_value_id &&
								additionalPatchColors[0]
									.lettering_placement_id !=
								selectedPlacement?.lettering_placement_id
							) {
								item.disabled = true;
							} else {
								item.disabled = false;
							}
						} else {
							item.disabled = false;
						}
						return item;
					});
					return values;
				}
				case letteringStyleId.toString(): {
					if(availableStyles[placement?.lettering_placement_id]){
						return option.values.filter((value) => {
							return availableStyles[placement?.lettering_placement_id].includes(value.product_option_value_id);
						});
					}
					return option.values;
				}
				default: {
					return option.values;
				}
			}
		},
		[
			selectedPlacement,
			letteringPatchId,
			data,
			getDefaultPatchColor,
			outerShellColorId,
			letteringStyleId,
			availableStyles,
		],
	);


	const [hoverImage, setHoverImage] = useState(null);

	function getProductOptionValuePhoto(productOptionValue) {
		//Find photo for specific product, if found return it
		const productPhotos = productOptionValue?.photos.filter(
			(photo) => photo.product_id === productId,
		);
		if (productPhotos?.length) {
			return productPhotos[0];
		}

		//else return the first photo in the list, if any exist
		if (productOptionValue?.photos.length) {
			return productOptionValue.photos[0];
		}

		return null;
	}
	const handleSelectHover = (e, productOption) =>{
		if(!e?.target?.id.includes('react-select-') || !e?.target?.closest('.select-outer') || !e?.target?.closest('.select-outer').classList.contains('option')){
			setHoverImage(null);
			return;
		}

		let index = Math.max(parseInt(e.target.id.split('-')[4]), 0);
		let hoverOption = getAvailableOptionValues(productOption)[index];

		if (!hoverOption) {
			setHoverImage(null);
			return;
		}
		let photo = getProductOptionValuePhoto(hoverOption);

		if (!photo) {
			setHoverImage(null);
			return;
		}
		const rect = e.target.closest(".option-select-container").getBoundingClientRect();
		const innerRect = e.target.getBoundingClientRect();
		const y = e.clientY - rect.top;
		const innerY = e.clientY - innerRect.top;
		photo.topOffset = (y-100) + (innerY<5?innerRect.height/2:-innerRect.height/2);
		setHoverImage(photo);
	}

	/**
	 * Lettering type options
	 */

	const hasSpecialRequest = useMemo(() => {
		if(!selectedPlacement?.lettering_placement_id){
			return false;
		}
		for(let i = 0; i < placements.length; i++){
			if(placements[i].lettering_placement_id === selectedPlacement?.lettering_placement_id){
				return placements[i].lettering_placement_is_special_request == 1;
			}
		}
	}, [placements, selectedPlacement?.lettering_placement_id]);

	const letteringTypeOptions = useMemo(() => {
		return letteringTextOptions.map((item) => {
			// disable name, rank, ffid types for DRD position
			item.disabled =
				selectedPlacement?.lettering_placement_id === '1' &&
				['name', 'rank', 'ffid'].includes(item.value);

			const description = orderSettings?.lettering_text[item.value]?.value;
			if(description && item.label.indexOf(' - ') === -1){
				item.label += ' - ' +description;
			}
			return item;
		}).filter((item) => !item.disabled);
	}, [selectedPlacement]);

	/**
	 * Determine if a lettering placement should be disabled
	 */

	const isPlacementDisabled = useCallback(
		(placement) => {
			const item = data[placement.lettering_placement_id];

			// has no options
			if (!getAvailableOptionValues(options[0], placement).length) {
				return true;
			}

			// is not first row
			if (parseInt(item?.lettering_row_number)) {
				return true;
			}

			return false;
		},
		[options, data, getAvailableOptionValues],
	);

	/**
	 * Determine if an option should be disabled
	 */

	const isOptionDisabled = useCallback(
		(option) => {
			const patch = getOptionValueById(
				data[selectedPlacement?.lettering_placement_id]
					?.product_option_value_id,
			);

			switch (option.product_option_id) {
				case outerShellColorId.toString(): {
					// disable patch color for sewn-direct
					return !!parseInt(
						patch?.product_option_value_lettering_is_sewn_direct,
					);
				}
				default: {
					return false;
				}
			}
		},
		[outerShellColorId, data, getOptionValueById, selectedPlacement],
	);

	const indexedPlacements = useMemo(() => {
		return [{lettering_placement_id: '', lettering_placement_description_indexed: 'Select'}, ...placements.map((placement) => {
			return {
				...placement,
				// add index numbers
				lettering_placement_description_indexed: `${placement.lettering_placement_index} - ${placement.lettering_placement_description}`,
				disabled: isPlacementDisabled(placement),
			};
		})];
	}, [data, placements]);

	return (
		<form>
			<div className={styles.selectFieldWrapper}>
				<div className={styles.placementLabel}>
					<span>Choose Starting Position</span>
					{!!data[selectedPlacement?.lettering_placement_id] && (
						<span onClick={onClear}>Clear</span>
					)}
				</div>
				<Select
						options={indexedPlacements}
						isOptionSelected={(letteringPlacement) => letteringPlacement.lettering_placement_id === selectedPlacement?.lettering_placement_id}
						getOptionLabel={(letteringPlacement) => letteringPlacement.lettering_placement_description_indexed}
						getOptionValue={(letteringPlacement) => letteringPlacement.lettering_placement_id}
						value={selectedPlacement ? indexedPlacements.find((letteringPlacement) => letteringPlacement.lettering_placement_id === selectedPlacement?.lettering_placement_id) : ''}
						onChange={(letteringPlacement, details) => {
							onClear()
							setPlacement(letteringPlacement);
						}}
						className={styles.selectField}
						allowNull={true}
						isDisabled={!isEditable}
						isOptionDisabled={(letteringPlacement) => {
							return letteringPlacement.disabled || selectedPlacements.includes(letteringPlacement.lettering_placement_id)
						}}
					/>
			</div>

			{selectedPlacement?.lettering_placement_id &&
			<>
				{options.map((option, o) => {
					let value;
					if(data[selectedPlacement?.lettering_placement_id]) {
						value = getAvailableOptionValues(option).find((productOptionValue) => productOptionValue.product_option_value_id === data[selectedPlacement?.lettering_placement_id][getLetteringFieldPropertyName(option)]);
						if(!value) {
							value = '';
						}
					} else {
						value = '';
					}

					return (!isOptionDisabled(option) ? (
						<div className={styles.selectFieldWrapper}>
							<div className={styles.placementLabel}>
								{getLetteringFieldLabel(option)}
							</div>
							<div className="option-select-container" style={{position: "relative"}}  onMouseOver={(e)=>{handleSelectHover(e, option)}} onMouseLeave={e=>{setHoverImage(null)}}>
								{hoverImage && hoverImage.product_option_id === option.product_option_id && (
									<div style={{top:(hoverImage.topOffset||0)+'px'}} className="hover-image-container">
										<img src={`${API_ROOT}${hoverImage.product_option_value_photo_file_url}`} />
									</div>
								)}
								<Select
									key={o}
									label={getLetteringFieldLabel(option)}
									options={getAvailableOptionValues(option)}
									name={getLetteringFieldPropertyName(option)}
									value={value}
									labelField="product_option_value_label"
									valueField="product_option_value_id"
									className={classNames({
										'select-outer': true,
										'option': true,
										[styles.selectField]: true,
										[styles.selectFieldError]:
										errors[
											selectedPlacement?.lettering_placement_id
											] === getLetteringFieldPropertyName(option),
									})}
									inputClassName={classNames({
										[styles.selectInput]: true,
										[styles.selectInputError]:
										errors[
											selectedPlacement?.lettering_placement_id
											] === getLetteringFieldPropertyName(option),
									})}
									isOptionSelected={(productOptionValue) => data[selectedPlacement?.lettering_placement_id]?.product_option_value_id === productOptionValue.product_option_value_id}
									getOptionLabel={(productOptionValue) => productOptionValue.product_option_value_label}
									getOptionValue={(productOptionValue) => productOptionValue.product_option_value_id}
									menuClassName={styles.selectMenu}
									activeClassName={styles.selectActive}
									onChange={(productOptionValue, e) => {
										onChange({
											target: {
												...e,
												value: productOptionValue.product_option_value_id
											}
										}, selectedPlacement)
									}}
									isDisabled={isOptionDisabled(option) || !isEditable}
									isOptionDisabled={(productOptionValue) => productOptionValue.disabled}
								/>
							</div>
						</div>
					) : null)
				})
				}
				{new Array(numRows).fill({}).map((_, key) => {
					const placementId =
						parseInt(selectedPlacement?.lettering_placement_id) + key;
					const item = data[placementId];

					let placement = placements.find((placement) => {
						return (
							placement.lettering_placement_id ==
							item?.lettering_placement_id
						);
					});

					if(!placement) {
						placement = allPlacements.find((placement) => {
							return (
								placement.lettering_placement_id ==
								item?.lettering_placement_id
							);
						});
					}

					let letteringType;
					if(data[placementId]) {
						letteringType = letteringTypeOptions.find((optionType) => optionType.value === data[placementId].quote_product_option_value_lettering_type);
						if(!letteringType) {
							letteringType = '';
						}
					} else {
						letteringType = '';
					}

					return (
						<>
							<div key={key} className={styles.selectFieldWrapper}>
								<div className={styles.placementLabel}>
									<span>Choose Letters{numRows > 1 ? ' - Row #' + (key + 1) : ''}</span>
								</div>
								<Select
									label="Choose Letters"
									name="quote_product_option_value_lettering_type"
									options={letteringTypeOptions}
									value={letteringType}
									onChange={(productOptionValue, e)=>{onChange({target: {...e, value: productOptionValue.value}},placement)}}
									className={classNames({
										[styles.selectField]: true,
										[styles.selectFieldError]:
										errors[placementId] ===
										'quote_product_option_value_lettering_type',
									})}
									inputClassName={classNames({
										[styles.selectInput]: true,
										[styles.selectInputError]:
										errors[placementId] ===
										'quote_product_option_value_lettering_type',
									})}
									isOptionSelected={(letteringPlacement) => letteringPlacement === placement}
									getOptionLabel={(letteringPlacement) => letteringPlacement.label}
									getOptionValue={(letteringPlacement) => letteringPlacement.value}
									isDisabled={!isEditable}
									isOptionDisabled={(letteringPlacement) => letteringPlacement.disabled}
								/>
								{letteringType?.value === 'custom' && (
									<CustomTextField
										key={key}
										disabled={!isEditable}
										max={charLengths[selectedPlacement?.lettering_placement_id]}
										placeholder={
											numRows > 1
												? `Custom Lettering - Row #${key + 1}`
												: 'Custom Lettering'
										}
										name="quote_product_option_value_lettering_custom_text"
										value={
											data[placementId]
												?.quote_product_option_value_lettering_custom_text ||
											''
										}
										onChange={(e) => onChange(e, placement)}
										className={classNames({
											[styles.textField]: true,
											[styles.textFieldError]:
											errors[placementId] ===
											'quote_product_option_value_lettering_custom_text',
										})}
										inputClassName={classNames({
											[styles.textInputError]:
											errors[placementId] ===
											'quote_product_option_value_lettering_custom_text',
										})}
									/>
								)}
							</div>
						</>
					);
				})
				}
			</>
			}

			{hasSpecialRequest &&
				<div
				style={{marginTop:'24px'}}
				className={classNames({
					[fieldStyles.fieldContainer]: true,
				})}>
					<span className={selectStyles.selectFieldLabel} style={{marginBottom: 8}}>Special Instructions</span>
					<div>
						<textarea
						className={classNames({
							[styles.specialText]: true,
						})}
							name='quote_product_option_value_message'
							style={{minHeight: 80}}
							disabled={!isEditable}
							value={data[selectedPlacement?.lettering_placement_id]?data[selectedPlacement?.lettering_placement_id].quote_product_option_value_message:''}
							onChange={(e)=>{
								onChange(e, selectedPlacement)
							}}
							>
						</textarea>
					</div>
				</div>
			}

			{selectedPlacement?.lettering_placement_id && isEditable &&
			<button className={classNames([styles.addButton])}
					onClick={(e)=>{
						e.preventDefault();
						if(!isEditable) {
							return;
						}
						if(!data[selectedPlacement?.lettering_placement_id]) {
							return;
						}

						for (let key = 0; key < numRows; key++) {
							let placementId =
								parseInt(selectedPlacement?.lettering_placement_id) + key;

							if(
								options.filter((option) => {
									if(isOptionDisabled(option)) {
										return false;
									}
									return !data[placementId][getLetteringFieldPropertyName(option)]
								}).length > 0
								||
								!data[placementId].quote_product_option_value_lettering_type
								||
								(data[placementId]
										?.quote_product_option_value_lettering_type === 'custom'
									&&
									!data[placementId].quote_product_option_value_lettering_custom_text
								)
							) {
								setErrorAlert({
									errorTitle: 'Invalid Selections',
									errorMessage: 'Please select all patch options',
								});
								return;
							}
						}
						if(hasSpecialRequest && selectedPlacement?.lettering_placement_index >= 10 && !data[selectedPlacement?.lettering_placement_id].quote_product_option_value_message) {
							setErrorAlert({
								errorTitle: 'Invalid Selections',
								errorMessage: 'Please include Special Instructions',
							});
							return;
						}
						onAdd(selectedPlacement?.lettering_placement_id)
					}}>Add</button>
			}
		</form>
	);
}

export default LetteringStyleForm;
