import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import ConfiguratorBuild from './components/ConfiguratorBuild/ConfiguratorBuild';
import ConfiguratorSummary from './components/ConfiguratorSummary/ConfiguratorSummary';

function Configurator({
	productLineId = null,
	productId = null,
	quoteId = null,
	onClose = () => {},
	highlightErrors = false,
	setHighlightErrors = () => {},
}) {
	const screen = useSelector((state) => state.configurator.screen);

	const outerElement = useRef(null);

	//fire onClose when component unmounts
	useEffect(() => {
		return () => {
			onClose({ quoteId, productId, productLineId });
		};
	}, [onClose, quoteId, productId, productLineId]);

	return (
		<div className="configurator-screen" ref={outerElement}>
			{screen === 'build' ? (
				<ConfiguratorBuild
					productLineId={productLineId}
					productId={productId}
					quoteId={quoteId}
					outerParentElement={outerElement.current}
					highlightErrors={highlightErrors}
					setHighlightErrors={setHighlightErrors}
				/>
			) : (
				<ConfiguratorSummary
					quoteId={quoteId}
					highlightErrors={highlightErrors}
					setHighlightErrors={setHighlightErrors}
				/>
			)}
		</div>
	);
}

export default Configurator;
