import React from 'react';
import date from '@utilities/date';
import styles from './MessageNotification.module.scss';

const MessageNotification = ({notification}) => {
    return (
        <div className={styles.messageNotification}>
            <div
                className={styles.messageNotificationPreview}
                dangerouslySetInnerHTML={{__html: notification.quote_preview}}
            />
            <div>
                <p className={styles.messageNotificationTitle}>
                    {notification.notification_meta['message-from-user']} has sent you a new message on Order #{notification.order_id}
                </p>
                <span className={styles.messageNotificationDate}>
				    {date(notification.notification_timestamp).fromNow()}
			    </span>
            </div>
        </div>
    );
};

export default MessageNotification;
