import {React, useEffect, useState } from 'react';
import Modal from '@components/Modal/Modal';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';

import VirtualSizerForm from '@components/VirtualSizer/VirtualSizerFom';

const VirtualSizerModal = ({ quoteId, active = false, onClose = () => {} }) => {
	const [quote, setQuote] = useState(null);
	useEffect(() => {
		if(quoteId){
		Http().get(API_ROOT + '/sizing/get-quote-for-sizer/' + quoteId)
				.then(response => {
					if(response.data?.quote){
						setQuote(response.data.quote);
					}
				})
				.catch(error => {
					console.log(error);
				}
			);
		}
	}, [quoteId]);

	const submitHandler = (data) => {

		Http().post(API_ROOT + '/sizing/submit-sizer-response/'+data['quote_id'], data)
			.then(response => {
				onClose(response);
			})
			.catch(error => {
				console.log(error);
			}
		);
	};

	return (
		<Modal active={active} onClose={onClose} domNode={document.body} className={'sizer-modal'} isFullScreen={true}>
			<VirtualSizerForm closeHander={submitHandler} quote={quote} />
		</Modal>
	);
};
export default VirtualSizerModal;