import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Button from '@components/Button/Button';
import Avatar from '@components/Avatar/Avatar';
import fieldStyles from '@components/Field/Fields.module.scss';
import date from '@utilities/date';
import Http from '@utilities/Http';
import axios, { CancelToken } from 'axios';
import { API_ROOT } from '@config';
import InfoIcon from '@components/Icons/InfoIcon';
import SelectField from '@components/SelectField/SelectField';
import styles from './OrganizationUsersForm.module.scss';
import Tooltip from '@components/Tooltip/Tooltip';
import TextField from '@components/TextField/TextField';

const OrganizationUsersForm = ({
	query = '',
	refresh = null,
	filter = null,
	sort = null,
	setIsFetchingUsers = () => {},
}) => {
	const systemRoles = useSelector(
		(state) => state.staticData.organizationSystemRoles,
	);
	const user = useSelector((state) => state.auth.user);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState(false);
	const [users, setUsers] = useState([]);

	// user status tooltip
	const [tooltipVisible, setTooltipVisible] = useState(null);
	const [pricingTooltipVisible, setPricingTooltipVisible] = useState(null);

	const debounceTimeout = useRef(null);
	const fetchRequestSource = useRef(null);

	/**
	 * Form Submit
	 */

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			try {
				setIsSubmitting(true);

				await Http().post(`${API_ROOT}/organizations/users`, {
					organization: {
						organization_id: user.organization_id,
						users,
					},
				});

				setSubmitError(false);
			} catch (error) {
				setSubmitError(
					error.response?.data?.meta?.message ||
						'Something went wrong. Please try again later.',
				);
			} finally {
				setIsSubmitting(false);
			}
		},
		[user, users],
	);

	/**
	 * Fetch Organization Users
	 */

	useEffect(() => {
		clearTimeout(debounceTimeout.current);

		// cancel any pending request
		fetchRequestSource.current?.cancel();
		// create new cancel token
		fetchRequestSource.current = CancelToken.source();

		

		debounceTimeout.current = setTimeout(() => {
			setIsFetchingUsers(true);
			let sortString = '';
			let dir = '';
			if(sort){
				let sortParts = sort.split('_');
				dir = sortParts.pop();
				sortString = sortParts.join('_');
			}
			let filterString = filter || '';
			
			Http()
				.get(`${API_ROOT}/organizations/users?suggest=${query}&sort=${sortString}&dir=${dir}&filter=${filterString}`, {
					cancelToken: fetchRequestSource.current?.token,
				})
				.then(({ data }) => {
					setUsers(data.users);
				})
				.catch((err) => {
					if (axios.isCancel(err)) {
						console.log('canceled request');
					}
				})
				.finally(() => {
					setIsFetchingUsers(false);
				});
		}, 300);
	}, [query, refresh, setIsFetchingUsers, sort, filter]);

	return (
		<div className={styles.container}>
			<div className={styles.usersList}>
				<ul>
					{users.map((user, u) => (
						<li key={u} style={{ zIndex: users.length - u }}>
							<Avatar user={user} className={styles.avatar} />
							<p>{user.user_display_name}</p>
							<span>
								Joined{' '}
								{date(user.user_timestamp).format('M/D/YY')}
							</span>
							{!systemRoles
								.map((role) => role.role_id)
								.includes(user?.roles[0]?.role_id) && (
								<TextField
									label="Role"
									value={user?.roles[0]?.role_title}
									disabled={true}
									className={styles.select}
								/>
							)}
							{systemRoles
								.map((role) => role.role_id)
								.includes(user?.roles[0]?.role_id) && (
								<SelectField
									label="Role"
									value={
										systemRoles
											.map((role) => role.role_id)
											.includes(user?.roles[0]?.role_id)
											? user?.roles[0]?.role_id
											: '4'
									}
									options={systemRoles.map((role) => ({
										...role,
										role_title: role.role_title.replace(
											'Distributor ',
											'',
										),
									}))}
									labelField="role_title"
									valueField="role_id"
									className={styles.select}
									onChange={(e) => {
										setUsers((users) =>
											users.map((item) => {
												if (
													item.user_id ===
													user.user_id
												) {
													item.roles = [
														systemRoles.find(
															(role) =>
																role.role_id ===
																e.target.value,
														),
													];
												}

												return item;
											}),
										);
									}}
								/>
							)}

							<SelectField
								label="User Status"
								value={user.user_status_id}
								options={[
									{
										user_status_id: '1',
										user_status_title: 'Active',
									},
									{
										user_status_id: '2',
										user_status_title: 'Inactive',
									},
									{
										user_status_id: '3',
										user_status_title: 'Invited',
										disabled: true,
									},
								]}
								labelField="user_status_title"
								valueField="user_status_id"
								className={styles.select}
								disabled={user.user_status_id === '3'}
								adornment={
									<div
										className={
											styles.userStatusTooltipContainer
										}>
										<InfoIcon
											className={
												styles.userStatusTooltipIcon
											}
											onClick={(e) => {
												e.stopPropagation();
												setTooltipVisible(u);
											}}
										/>
										<Tooltip
											active={tooltipVisible === u}
											onClose={() =>
												setTooltipVisible(null)
											}
											className={
												styles.userStatusTooltip
											}>
											Inactive users will not be able to
											log in to FireWriter.
										</Tooltip>
									</div>
								}
								onChange={(e) => {
									setUsers((users) =>
										users.map((item) => {
											if (item.user_id === user.user_id) {
												item.user_status_id =
													e.target.value;
											}

											return item;
										}),
									);
								}}
							/>
							<SelectField
								label="Pricing Access"
								value={user.user_organization_pricing}
								options={[
									{
										value: '1',
										label: 'Yes',
									},
									{
										value: '0',
										label: 'No',
									},
								]}
								className={styles.select}
								adornment={
									<div
										className={
											styles.pricingAccessTooltipContainer
										}>
										<InfoIcon
											className={
												styles.userStatusTooltipIcon
											}
											onClick={(e) => {
												e.stopPropagation();
												setPricingTooltipVisible(u);
											}}
										/>
										<Tooltip
											active={pricingTooltipVisible === u}
											onClose={() =>
												setPricingTooltipVisible(null)
											}
											className={
												styles.pricingAccessTooltip
											}>
											Users with pricing access have the
											ability to submit orders.
										</Tooltip>
									</div>
								}
								onChange={(e) => {
									setUsers((users) =>
										users.map((item) => {
											if (item.user_id === user.user_id) {
												item.user_organization_pricing =
													e.target.value;
											}

											return item;
										}),
									);
								}}
							/>
						</li>
					))}
				</ul>
			</div>
			<div className={styles.formActions}>
				{!!submitError && (
					<p
						className={classNames(
							fieldStyles.fieldError,
							styles.submitError,
						)}>
						{submitError}
					</p>
				)}
				{!!users.length && (
					<Button
						variant="solid"
						type="submit"
						loading={isSubmitting}
						className={styles.submitButton}
						onClick={handleSubmit}>
						Save Changes
					</Button>
				)}
			</div>
		</div>
	);
};

export default OrganizationUsersForm;
