import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './Carousel.module.scss';

const Carousel = ({
	items = [],
	className = null,
	captionClassName = null,
	ratio = 60,
	defaultTransform = -166.66,
	onIndexChange = () => {},
}) => {
	const [index, setIndex] = useState(0);
	const listNode = useRef(null);

	/**
	 * Slides - Allow for infinite cycling
	 *
	 * Copy the last 2 slides to the beginning
	 * Copy the first 2 slides to the end
	 */

	const slides = useMemo(() => {
		return [
			...items.filter((_, i) => i >= items.length - 2),
			...items,
			...items.filter((_, i) => i <= 1),
		];
	}, [items]);

	/**
	 * After first/last slide transition, cycle index
	 */

	useEffect(() => {
		let nextIndex = index;

		if (index === items.length) {
			nextIndex = 0;
		}

		if (index === -1) {
			nextIndex = items.length - 1;
		}

		if (nextIndex !== index) {
			listNode.current?.addEventListener(
				'transitionend',
				() => {
					// add class to disable transition
					listNode.current.classList.add(styles.cycling);
					setIndex(nextIndex);
					onIndexChange(slides[nextIndex]);
				},
				{ once: true },
			);
		}
	}, [items, index, onIndexChange, slides]);

	useEffect(() => {
		// after index is reset, remove class that disables transition
		if (index === 0 || index === items.length - 1) {
			listNode.current?.classList.remove(styles.cycling);
		}
	}, [index, items.length]);

	return (
		<div
			className={classNames({
				[styles.carouselContainer]: true,
				[className]: !!className,
			})}>
			<ul
				ref={listNode}
				className={styles.carouselSlides}
				style={{
					transform: `translateX(calc(-1 * ${ratio * index}%))`,
				}}>
				{slides.map((item, i) => (
					<li
						key={i}
						onClick={() => {
							const next = i - 2 >= index ? index + 1 : index - 1;
							setIndex(next);
							onIndexChange(slides[next]);
						}}
						className={classNames({
							'carousel-item': true,
							[styles.carouselItem]: true,
							[styles.carouselItemActive]: index + 2 === i,
						})}
						style={{
							flex: `0 0 ${ratio}%`,
							transform:
								index + 2 === i
									? `translateX(calc(${defaultTransform}%)) translateY(8px) scale(1)`
									: `translateX(calc(${defaultTransform}%)) translateY(0) scale(0.8)`,
						}}>
						<div
							className={classNames({
								'carousel-item-image': true,
								[styles.carouselItemImage]: true,
							})}
							style={{ backgroundImage: `url('${item.image}')` }}
						/>
						<p
							className={classNames({
								[styles.carouselItemCaption]: true,
								[captionClassName]: !!captionClassName,
							})}>
							{item.caption}
						</p>
					</li>
				))}
			</ul>
			{slides[index + 2]?.url && slides[index + 2]?.urlText && (
				<div className={styles.carouselCta}>
					<a href={slides[index + 2]?.url}>
						{slides[index + 2]?.urlText}
					</a>
				</div>
			)}
			<ul className={styles.carouselIndicator}>
				{new Array(items.length).fill('').map((_, n) => (
					<li
						key={n}
						onClick={() => {
							setIndex(n);
							onIndexChange(slides[n]);
						}}
						className={classNames({
							[styles.carouselIndicatorItemActive]: n === index,
						})}
					/>
				))}
			</ul>
		</div>
	);
};

export default Carousel;
