import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import closeIcon from '@assets/images/close.svg';
import fieldStyles from '../Field/Fields.module.scss';
import styles from './FileField.module.scss';
import { API_ROOT } from '@config';

const FileField = ({
	label = null,
	value = '',
	name = '',
	onChange = () => {},
	onFileSelect = async () => {},
	inputProps = {},
	className = null,
	readonly = false,
	disabled = false,
	compact = false,
	placeholder = null,
	multiple = false,
	accept = '',
	labelField = 'label',
	error = null,
	allowDelete = true,
}) => {
	const fileInputNode = useRef(null);
	const [isUploading, setIsUploading] = useState(false);

	/**
	 * Handle Open File
	 */

	const openFile = useCallback(async (e, index = 0) => {
		setIsUploading(true);

		try {
			window
				.open(
					`${API_ROOT}/uploaded/orders/documents/${encodeURIComponent(e[index].order_document_name)}`,
					'_blank',
				)
				.focus();
		} catch (error) {
			console.log(error);
		} finally {
			setIsUploading(false);
		}
	}, []);

	/**
	 * Handle File Select
	 */

	const handleFileSelect = useCallback(
		async (e) => {
			setIsUploading(true);

			try {
				const { files } = e.target;
				await onFileSelect(files);
			} catch (error) {
				console.log(error);
			} finally {
				setIsUploading(false);
			}
		},
		[onFileSelect],
	);

	/**
	 * Handle File Delete
	 */

	const handleFileDelete = useCallback(
		(file) => {
			if(!allowDelete) {
				return;
			}
			if (Array.isArray(value)) {
				onChange({
					target: {
						name,
						value: value.filter((item) => item !== file),
					},
				});
				return;
			}

			onChange({ target: { name, value: null } });
		},
		[name, value, onChange, allowDelete],
	);

	/**
	 * Initialize File Input Node
	 */

	useEffect(() => {
		const node = document.createElement('input');
		node.type = 'file';
		node.multiple = !!multiple;
		node.accept = accept;
		node.onchange = handleFileSelect;

		fileInputNode.current = node;
	}, [multiple, accept, handleFileSelect]);

	return (
		<div
			className={classNames({
				[fieldStyles.fieldContainer]: true,
				[fieldStyles.readonly]: readonly,
				[fieldStyles.disabled]: disabled,
				[fieldStyles.compact]: compact,
				[className]: !!className,
			})}>
			{!!label && <label>{label}</label>}
			{Array.isArray(value) &&
				!!value.length &&
				value.map((file, f) => (
					<div key={f} className={styles.valueItemContainer}>
						<input
							type="text"
							value={file[labelField]}
							className={fieldStyles.fieldInput}
							readOnly={true}
							// disabled={disabled}
							onClick={() => openFile(value, f)}
						/>
						{!disabled && !readonly && allowDelete && (
							<img
								src={closeIcon}
								onClick={() => handleFileDelete(file)}
							/>
						)}
					</div>
				))}
			{!Array.isArray(value) && !!value && (
				<div className={styles.valueItemContainer}>
					<input
						type="text"
						value={value?.[labelField] || ''}
						className={fieldStyles.fieldInput}
						readOnly={true}
						// disabled={disabled}
						onClick={() => openFile(value)}
					/>
					{!disabled && !readonly && allowDelete && (
						<img
							src={closeIcon}
							onClick={() => handleFileDelete(value)}
						/>
					)}
				</div>
			)}
			{!disabled && !readonly && (
				<div className={styles.placeholderItemContainer}>
					<input
						onClick={() => fileInputNode.current?.click()}
						onFocus={(e) => e.preventDefault()}
						type="text"
						value={''}
						className={fieldStyles.fieldInput}
						placeholder={placeholder}
						readOnly={true}
						{...inputProps}
					/>
					{isUploading && <SpinnerIcon fill="#e87124" />}
				</div>
			)}
			{!!error && <span className={fieldStyles.fieldError}>{error}</span>}
		</div>
	);
};

export default FileField;
