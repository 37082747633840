import React, {
	useState,
} from 'react';
import PlacesAutocomplete, {geocodeByPlaceId} from 'react-places-autocomplete/dist';
import classNames from 'classnames';
import TextField from '@components/TextField/TextField';
import styles from './GooglePlacesAutocomplete.module.scss';

const GooglePlacesAutocomplete = ({
	isEditable = true,
	handleFieldChange = () => {},
	handleSelectState = () => {}
}) => {

	const [address, setAddress] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [cursor, setCursor] = useState(null);

	const handleChange = search => {
		setAddress(search);
		setErrorMessage('');
	};

	const handleSelect = (address, placeId, suggestion) => {
		setAddress(address);

		let foundState = null;
		geocodeByPlaceId(placeId)
			.then(results => {
				results[0].address_components.map((obj) => {
					if(obj.types.includes('postal_code')) {
						handleFieldChange({
							target: {
								name: "order_ship_to_zip",
								value: obj.long_name
							}
						});
					}
					if(obj.types.includes('country')) {
						handleFieldChange({
							target: {
								name: "order_ship_to_country",
								value: obj.short_name
							}
						});
					}
					if(obj.types.includes('administrative_area_level_1')) {
						foundState = obj;
					}
				});


				// Update state after country is updated to prevent the state filtering from setting the state to null
				if(foundState) {
					if (foundState.short_name?.length > 5) {
						handleSelectState(foundState.long_name);
					} else {
						handleFieldChange({
							target: {
								name: "order_ship_to_state",
								value: foundState.short_name
							}
						});
					}
				}
			})
			.catch(error => console.error(error));

		handleFieldChange({
			target: {
				name: "order_ship_to_street",
				value: suggestion.formattedSuggestion.mainText
			}
		});
		handleFieldChange({
			target: {
				name: "order_ship_to_city",
				value: suggestion.terms[2]?.value
			}
		});

		handleFieldChange({
			target: {
				name: "order_ship_to_attention",
				value: null
			}
		});

		handleFieldChange({
			target: {
				name: "order_ship_to_name",
				value: null
			}
		});
	};

	const handleError = (status, clearSuggestions) => {
		console.log('Error from Google Maps API', status); // eslint-disable-line no-console
		setErrorMessage(status);
		clearSuggestions();
	};

	const searchOptions = {
		types: ['street_address', 'premise']
	};


	return (
		<div className={styles.autocompleteContainer}>
			<PlacesAutocomplete
				onChange={handleChange}
				value={address}
				onSelect={handleSelect}
				onError={handleError}
				shouldFetchSuggestions={address.length > 2}
				searchOptions={searchOptions}
			>
				{({getInputProps, suggestions, getSuggestionItemProps}) => {
					return (
						<div className={styles.autocompleteContainerInner}>
							<TextField
								compact
								label={"Ship To"}
								{...getInputProps({
									placeholder: 'Search Address',
									className: styles.searchInput,
								})}
								disabled={!isEditable}
							/>
							{suggestions.length > 0 && (
								<div className={styles.resultsContainer}>
									<div className={styles.results}>
										{suggestions.map((suggestion, i) => {
											return (
												<div
													key={i}
													{...getSuggestionItemProps(suggestion)}
													className={classNames({
														[styles.resultItem]: true,
														[styles.cursor]: cursor === i
													})}
													onMouseOver={() => {
														setCursor(i);
													}}
												>
													<strong>
														{suggestion.formattedSuggestion.mainText}
													</strong>{' '}
													<small>
														{suggestion.formattedSuggestion.secondaryText}
													</small>
												</div>
											);
										})}

									</div>
								</div>
							)}
							{errorMessage.length > 0 && (
								<div className={styles.resultsContainer}>
									<div className={styles.results}>
										<div className={styles.resultItem}>No Results</div>
									</div>
								</div>
							)}
						</div>
					);
				}}
			</PlacesAutocomplete>
		</div>
	);
};

export default GooglePlacesAutocomplete;