import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import Http from '@utilities/Http';
import printJS from 'print-js';
import {useSelector, useDispatch} from 'react-redux';
import {
    API_ROOT,
    MOISTURE_BARRIER_OPTION_ID,
    OUTER_SHELL_OPTION_ID,
    THERMAL_LINER_OPTION_ID,
} from '@config';
import Header from '@components/Header';
import QuoteHeaderInformation from '@components/QuoteHeaderInformation';
import ConfiguratorPreview from '@components/ConfiguratorPreview/ConfiguratorPreview';
import OrderItemDetails from '@screens/Order/components/OrderItemDetails/OrderItemDetails';
import Collapsible from 'react-collapsible';
import ChevronIcon from '@components/Icons/ChevronIcon';
import CloseIcon from '@components/Icons/CloseIcon';
import classNames from 'classnames';
import Button from '@components/Button/Button';
import AssignQuoteToOrderOverlayForm from '@components/AssignQuoteToOrderOverlayForm';
import ShareQuoteModal from '@components/ShareQuoteModal/ShareQuoteModal';
import CollaborateQuoteModal from '@components/CollaborateQuoteModal/CollaborateQuoteModal';
import EmailQuoteSummaryModal from '@components/EmailQuoteSummaryModal/EmailQuoteSummaryModal';
import DialogModal from '@components/DialogModal/DialogModal';
import Switch from '@components/Switch/Switch';
import {setDemoModeActive} from '@store/reducers/demo-mode';
import {resetConfigurator, setScreen, updateConfiguratorOptionsQuoteId} from '@store/reducers/configurator';
import SizingModal from '@components/SizingModal/SizingModal';
import QuotePricing from '@components/QuotePricing/QuotePricing';
import {getQuoteTotalPrice, splitQuotePrices} from '@utilities/pricing';
import Avatar from '@components/Avatar/Avatar';
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';
import ExportModal from '@components/ExportModal/ExportModal';
import {
    download,
    getQuoteExport, getQuoteSpecifications,
} from '@utilities/export';
import styles from "@screens/Quotes/components/QuotesListItem/QuotesListItem.module.scss";
import SelectField from "@components/SelectField/SelectField";
import FireDepartmentField from "@components/FireDepartmentField/FireDepartmentField";
import CheckboxField from "@components/CheckboxField/CheckboxField";
import Tooltip from "@components/Tooltip/Tooltip";
import TextField from "@components/TextField/TextField";
import FieldRow from "@components/Field/FieldRow";
import axios, {CancelToken} from "axios";
import {isFinalized, isQuoteApproved, isSubmitted} from "@utilities/orders";
import {useForm} from "@hooks/useForm";
import {schema} from "@components/NewOrderModal/form";
import {getQuoteDisplayNumber} from "@utilities/methods";
import CopyToOrderModal from "@components/CopyToOrderModal/CopyToOrderModal";
import modalStyles from "@components/Modal/Modal.module.scss";
import {store} from "@store";
import {useErrorAlert} from "@hooks/useErrorAlert";
import NetPriceAdjustmentModal from "@components/NetPriceAdjustmentModal/NetPriceAdjustmentModal";
import SizerInviteModal from '@components/SizerInviteModal/SizerInviteModal';

function ConfiguratorSummary({
                                 quoteId, highlightErrors, setHighlightErrors = () => {
    }
                             }) {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);

    const {headerHeight, demoModeActive, demoModeVisible} = useSelector(
        (state) => ({
            headerHeight: state.dimensions.headerHeight,
            demoModeActive: state.demoMode.active,
            demoModeVisible: state.demoMode.visible,
        }),
    );
    const [manualFireDepartment, setManualFireDepartment] = useState(false);

    /**
     * Form data, validation, errors, etc
     */

    const {formData, formErrors, getValidation, handleChange, setFormData, setFormErrors} =
        useForm(schema);

    const [isEditable, setIsEditable] = useState(true);
    const isCSRUser = user?.permissions?.includes('csr_access');
    const isRSMUser = user?.permissions?.includes('rsm_access');
    const isDistributorAdmin = user?.permissions?.includes('manage_organization');
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [actionLoader, setActionLoader] = useState(false);
    const [shareModalActive, setShareModalActive] = useState(false);
    const [collaborateQuoteModalActive, setCollaborateQuoteModalActive] = useState(false);
    const [emailCopyActive, setEmailCopyActive] = useState(false);
    const [sizingModalActive, setSizingModalActive] = useState(false);
    const [exportModalActive, setExportModalActive] = useState(false);
    const [sizerInviteModalActive,setSizerInviteModalActive] = useState(false);
    const [specificationsModalActive, setSpecificationsModalActive] = useState(false);
    const [confirmationModalActive, setConfirmationModalActive] = useState(false);
    const [netPriceAdjustmentModalActive, setNetPriceAdjustmentModalActive] = useState(false);
    const [exportErrorMessage, setExportErrorMessage] = useState(false);
    const {contractPricingOptions} = useSelector(
        (state) => state.staticData,
    );

    const [organizationContracts, setOrganizationContracts] = useState([]);
    const countries = useSelector((state) => state.staticData.countries);
    const fireDepartmentCountries = useSelector((state) => state.staticData.fireDepartmentCountries);
    // loaders
    const [isUpdating, setIsUpdating] = useState(false);
    const [state, setState] = useState({
        quote: {
            productOptionValues: [],
            selectingOrderToAssign: false,
        },
        siblingQuote: {
            productOptionValues: [],
            selectingOrderToAssign: false,
        },
        allSiblingQuotes: [],
    });

    // shared with users
    const [sharedWith, setSharedWith] = useState([]);

    // collapsed/expanded sections
    const [collapsibles, setCollapsibles] = useState({
        summary: true,
        sharing: false,
        sizing: false,
    });

    const [composite, setComposite] = useState(null);

    const [originalQuotes, setOriginalQuotes] = useState([]);
    const [originalQuoteActiveSizingIndex, setOriginalQuoteActiveSizingIndex] = useState(null);

    const [copyModalActive, setCopyModalActive] = useState(false);

    const quoteNumbers = state.quote.quote_id ? [getQuoteDisplayNumber(state.quote)] : [];

    const [, setErrorAlert] = useErrorAlert();

    const [imageOrientation, setImageOrientation] = useState('front');


    if (state.quote.siblingQuote) {
        quoteNumbers.push(getQuoteDisplayNumber(state.quote.siblingQuote))
    }

    let quoteNumberDisplay = quoteNumbers.sort()[0];
    if (quoteNumbers.sort()[0]) {
        quoteNumberDisplay = quoteNumbers.sort()[0].toString().padStart(4, '0');
    }

    function toggleSelectingOrderToAssign() {
        setState((prevState) => ({
            ...prevState,
            selectingOrderToAssign: !prevState.selectingOrderToAssign,
        }));
    }

    function getProductOptionQuoteProductOptionValues(productOptionId) {
        return state.quote.productOptionValues.filter(
            (quoteProductOptionValue) =>
                quoteProductOptionValue.product_option_id === productOptionId,
        );
    }

    const compositeOptionValueIds = {
        outerShellId: getProductOptionQuoteProductOptionValues(
            OUTER_SHELL_OPTION_ID,
        )[0]?.product_option_value_id,
        thermalLinerId: getProductOptionQuoteProductOptionValues(
            THERMAL_LINER_OPTION_ID,
        )[0]?.product_option_value_id,
        moistureBarrierId: getProductOptionQuoteProductOptionValues(
            MOISTURE_BARRIER_OPTION_ID,
        )[0]?.product_option_value_id,
    };
    useEffect(() => {
        console.log('CONTRACTS')
        Http()
            .get(
                `${API_ROOT}/organizations/contracts?quote_id=${quoteId}`,
            )
            .then(({data}) => {
                setOrganizationContracts(data.contracts);
            });
    }, []);

    useEffect(() => {
        console.log('FETCH')
        Http()
            .get(`${API_ROOT}/build/${quoteId}`, {
                params: {
                    getSiblingQuote: 1
                }
            })
            .then((quoteResponse) => {
                if(!quoteResponse?.data?.quote){
                    return;
                }
                const quote = quoteResponse.data.quote;
                const siblingQuote = quoteResponse.data.siblingQuote;
                const allSiblingQuotes = quoteResponse.data.allSiblingQuotes.filter(
                    (siblingQuote) => {
                        return siblingQuote.quote_is_temporary != 1;
                    });

                if (isCSRUser) {
                    setIsEditable(isCSRUser && (quote?.user_id == user?.user_id || quote.shared_to_user === '1') && !isSubmitted(quote) && !isQuoteApproved(quote));
                } else if (isRSMUser) {
                    setIsEditable(isRSMUser && (quote?.user_id == user?.user_id || quote.shared_to_user === '1') && !isSubmitted(quote) && !isQuoteApproved(quote));
                } else {
                    setIsEditable(!isSubmitted(quote) && !isQuoteApproved(quote));
                }
                setFormData((formData) => ({
                    ...formData,
                    fire_department_name_manual: quote.fire_department_name_manual,
                    fire_department_address: quote.fire_department_address,
                    fire_department_city: quote.fire_department_city,
                    fire_department_state: quote.fire_department_state,
                    fire_department_zip_manual: quote.fire_department_zip_manual,
                    fire_department_county: quote.fire_department_county,
                    fire_department_country: quote.fire_department_country,
                }));
                setState((prevState) => {
                    return {
                        ...prevState,
                        quote,
                        siblingQuote,
                        allSiblingQuotes
                    };
                });
            })
            .catch((error) => {
                console.log('error: ', error);
            });

        Http().get(`${API_ROOT}/quotes/get-quote-original-quotes/${quoteId}`)
            .then(response => {
                if(response?.data?.originalQuotes){
                    setOriginalQuotes(response.data.originalQuotes.filter((originalQuote) => originalQuote.sibling_quote_id === null));
                }
            })
            .catch((error) => {
                console.log('error: ', error);
            });
    }, [quoteId]);

    useEffect(() => {
        console.log('COMPOSITES')
        Http()
            .get(`${API_ROOT}/composites/get-composite-by-values`, {
                params: {
                    outerShellId: compositeOptionValueIds.outerShellId,
                    thermalLinerId: compositeOptionValueIds.thermalLinerId,
                    moistureBarrierId:
                    compositeOptionValueIds.moistureBarrierId,
                },
            })
            .then((response) => {
                setComposite(response.data.composite);
            });
    }, [
        compositeOptionValueIds.outerShellId,
        compositeOptionValueIds.thermalLinerId,
        compositeOptionValueIds.moistureBarrierId,
        setComposite,
    ]);

    /**
     * Get Users Quote is Shared With
     */

    const getQuoteSharedUsers = useCallback(() => {
        return Http()
            .get(`${API_ROOT}/quotes/shared/${quoteId}`)
            .then((response) => {
                const users = response.data.users;
                setSharedWith(users);
            })
            .catch((error) => {
                console.log('error: ', error);
            });
    }, [quoteId]);

    useEffect(() => {
        console.log('SHAREDUSERS')
        getQuoteSharedUsers();
    }, [getQuoteSharedUsers]);

    /**
     * Remove Shared User
     */

    const removeQuoteSharedUser = useCallback(
        (userId) => {
            return Http()
                .post(`${API_ROOT}/quotes/unshare/${quoteId}`, {userId})
                .then((response) => {
                    const users = response.data.users;
                    setSharedWith(users);
                })
                .catch((error) => {
                    console.log('error: ', error);
                });
        },
        [quoteId],
    );

    /**
     * Update Quote
     */
    const quoteSaveRequestSource = useRef(null);
    // field save debounce timer
    const fieldSaveDebounceTimer = useRef(null);
    const updateQuote = useCallback(async (quote, refresh = true) => {
        if (isEditable) {
            const handleSave = () => {
                    // cancel any pending request
                quoteSaveRequestSource.current?.cancel();
                // create new cancel token
                quoteSaveRequestSource.current = CancelToken.source();
                return Http().post(
                    `${API_ROOT}/build/${quote.quote_id}`,
                    {
                        quote,
                    },
                    {cancelToken: quoteSaveRequestSource.current?.token},
                ).then(({data}) => {
                    if (refresh) {
                        const {quote: result1} = data;
                        const {siblingQuote: result2} = data;

                        setState((state) => {
                            return {
                                ...state,
                                quote: result1,
                                siblingQuote: result2
                            };
                        });
                    }
                })
                    .catch((err) => {
                        if (axios.isCancel(err)) {
                            // silent
                        }
                    });
            };

            // clear save timer
            clearTimeout(fieldSaveDebounceTimer.current);

            // start save timer
            fieldSaveDebounceTimer.current = setTimeout(() => {
                handleSave(quote);
            }, 500);

            setState(prevState => {
                return {
                    ...prevState,
                    quote: {
                        ...prevState.quote,
                        ...quote
                    },
                };
            })
        }

    }, [isEditable]);

    /**
     * Handle PDF Export
     */

    const handlePdfExport = useCallback(async () => {
        setActionLoader('export-pdf');
        try {
            const url = await getQuoteExport(quoteId, 'pdf', demoModeActive);
            download(url, `${getQuoteDisplayNumber(state.quote).padStart(4, '0')}.pdf`);
        } catch (error) {
            const data = JSON.parse(await error.response.data.text());
            setExportErrorMessage(data.meta.message);
        } finally {
            setActionLoader(false);
        }
    }, [quoteId, state.quote, demoModeActive]);

    /**
     * Handle Word Export
     */

    const handleWordExport = useCallback(async () => {
        setActionLoader('export-word');
        try {
            const url = await getQuoteExport(quoteId, 'word', demoModeActive);
            download(url, `${getQuoteDisplayNumber(state.quote).padStart(4, '0')}.doc`);
        } catch (error) {
            const data = JSON.parse(await error.response.data.text());
            setExportErrorMessage(data.meta.message);
        } finally {
            setActionLoader(false);
        }

        setActionLoader(false);
    }, [quoteId, state.quote, demoModeActive]);


    /**
     * Handle Specifications Export
     */

    const handleSpecificationsExport = useCallback(async () => {
        setActionLoader('export-specifications');
        try {
            const url = await getQuoteSpecifications(quoteId);
            download(url, `${getQuoteDisplayNumber(state.quote).padStart(4, '0')}-specifications.pdf`);
        } catch (error) {
            const data = JSON.parse(await error.response.data.text());
            setExportErrorMessage(data.meta.message);
        } finally {
            setActionLoader(false);
        }

        setActionLoader(false);
    }, [quoteId, state.quote]);

    /**
     * Handle PDF Print
     */

    const handlePrint = useCallback(async () => {
        setActionLoader('print');
        try {
            const url = await getQuoteExport(quoteId, 'pdf', demoModeActive);
            // print document
            printJS(url);
        } catch (error) {
            const data = JSON.parse(await error.response.data.text());
            setExportErrorMessage(data.meta.message);
        } finally {
            setActionLoader(false);
        }

        setActionLoader(false);
    }, [quoteId, demoModeActive]);

    /**
     * Handle Discount Percentage Change
     */

    const handleDiscountPercentageChange = useCallback(
        async (percentage) => {
            setActionLoader('update');

            try {
                state.quote.quote_discount_percentage = percentage;
                await updateQuote({
                    ...state.quote,
                    quote_discount: percentage / 100,
                });

                if(parseInt(percentage)) {
                    setState(prevState => {
                        let quote = {...prevState.quote};
                        let siblingQuote = {...prevState.siblingQuote};
                        quote.quote_net_price_adjustment = null;
                        siblingQuote.quote_net_price_adjustment = null;

                        return {
                            ...prevState,
                            quote,
                            siblingQuote: prevState.siblingQuote === null ? null : siblingQuote
                        }
                    });
                }
            } finally {
                setActionLoader(false);
            }
        },
        [state.quote, updateQuote],
    );


    /**
     * Handle Contract Pricing change
     */

    const handleContractPricingChange = useCallback(
        async (contractId) => {
            setIsUpdating(true);

            try {
                await updateQuote({...state.quote, contract_id: contractId});
            } finally {
                setIsUpdating(false);
            }
        },
        [state.quote, updateQuote],
    );

    /**
     * Handle Fire Department change
     */

    const handleFireDepartmentChange = useCallback(
        async (fireDepartmentId) => {
            setIsUpdating(true);

            try {
                await updateQuote({...state.quote, fire_department_id: fireDepartmentId});
            } finally {
                setIsUpdating(false);
            }
        },
        [state.quote, updateQuote],
    );

    /**
     * Handle stock flag change
     */
    const handleStockFlagChange = useCallback(
        async (e) => {
            setIsUpdating(true);

            try {
                await updateQuote({...state.quote, quote_stock_flag: e.target.value});
            } finally {
                setIsUpdating(false);
            }

        },
        [state.quote, updateQuote]
    );

    /**
     * Change handler
     */

    const handleDepartmentFieldChange = useCallback(async (e) => {
        console.log('FDChange')
        const events = Array.isArray(e) ? e : [e];

        setIsUpdating(true)
        try {
            // update fields in formData
            await setFormData((formData) => {
                const data = {...formData};

                events.forEach((e) => {
                    data[e.target.name] = e.target.value;
                });

                return data;
            });

            await updateQuote({
                ...state.quote,
                fire_department_name_manual: formData.fire_department_name_manual,
                fire_department_address: formData.fire_department_address,
                fire_department_city: formData.fire_department_city,
                fire_department_state: formData.fire_department_state,
                fire_department_zip_manual: formData.fire_department_zip_manual,
                fire_department_county: formData.fire_department_county,
                fire_department_country: formData.fire_department_country,
                [e.target.name]: e.target.value,
            });
        } finally {
            setIsUpdating(false);
        }

    }, [state.quote, updateQuote, formData]);

    /**
     * Handle manual department flag change
     */
    const handleManualDepartmentFlagChange = useCallback(
        async (e) => {
            setIsUpdating(true);

            try {
                await updateQuote({...state.quote, quote_manual_department_flag: e.target.value});
            } finally {
                setIsUpdating(false);
            }

        },
        [state.quote, updateQuote]
    );

    /**
     * Handle stock quote input change
     */
    const handleStockQuoteInputChange = useCallback(
        async (e) => {
            setIsUpdating(true);

            try {
                await updateQuote({...state.quote, quote_stock_quote_input: e.target.value});
            } finally {
                setIsUpdating(false);
            }

        },
        [state.quote, updateQuote]
    );

    /**
     * Handle 'Copy to Order' Modal Close
     */

    const handleCopyModalClose = useCallback(
        (order) => {
            if (order) {
                window.location = `/orders/${order.order_id}`;
            }

            setCopyModalActive(false);
        },
        [],
    );

    /**
     * Handle Net Price Adjustment
     */

    const handleNetPriceAdjustmentUpdates = useCallback(async (netPriceAdjustment1, netPriceAdjustment2) => {
            if(isEditable) {
                setIsUpdating(true);

                const handleSave = async (quote, refresh = true) => {
                    return await Http().post(
                        `${API_ROOT}/build/${quote.quote_id}`,
                        {
                            quote,
                        },
                    );
                };

                let quote = {...state.quote};
                let siblingQuote = {...state.siblingQuote};
                let allSiblingQuotes = [...state.allSiblingQuotes];

                if (netPriceAdjustment1) {
                    quote.quote_net_price_adjustment = netPriceAdjustment1;
                } else {
                    quote.quote_net_price_adjustment = null;
                }

                if (netPriceAdjustment2 && siblingQuote?.quote_id && siblingQuote.product_id == 2) {
                    siblingQuote.quote_net_price_adjustment = netPriceAdjustment2;
                } else if (siblingQuote?.quote_id && siblingQuote.product_id == 2) {
                    siblingQuote.quote_net_price_adjustment = null;
                }

                if (siblingQuote?.quote_id) {
                    if (siblingQuote.product_id == 2) {
                        await handleSave({...siblingQuote}, false);
                        quote.quote_discount = 0;
                    } else if (allSiblingQuotes.length) {
                        siblingQuote.quote_net_price_adjustment = netPriceAdjustment1;
                        for (let i = 0; i < allSiblingQuotes.length; i++) {
                            allSiblingQuotes[i].quote_net_price_adjustment = netPriceAdjustment1;
                            allSiblingQuotes[i].quote_discount = 0;
                        }
                    }
                }
                await handleSave({...quote});
                quote.quote_discount = 0;
                siblingQuote.quote_discount = 0;
                quote.quote_discount_percentage = 0;
                siblingQuote.quote_discount_percentage = 0;
                setState(prevState => {
                    return {
                        ...prevState,
                        quote,
                        siblingQuote: prevState.siblingQuote === null ? null : siblingQuote,
                        allSiblingQuotes: prevState.allSiblingQuotes === null ? null : allSiblingQuotes
                    };
                });
                setIsUpdating(false);
            }
        },
        [state.quote, state.siblingQuote, isEditable],
    );

    /**
     * Pricing
     */

    const pricing = useMemo(() => {
        console.log('PRICING')
        const quote = state.quote;
        const siblingQuote = state.siblingQuote
        const allSiblings = state.allSiblingQuotes;
        let quantity = quote.product_line_id
            ? quote.sizing_quantity
            : quote.quote_product_quantity;
        let splitPrices = null;
        let isAccessory = false;
        if(quote.product_configurable !== '1' && allSiblings) {
            quantity = Number(quantity) + allSiblings.reduce((total, current) => total = total + Number(current.quote_product_quantity), 0);
            quote.allSiblingQuotes = allSiblings;
            splitPrices = splitQuotePrices(quote);
            isAccessory = true;
        }

        return {
            coatPrice: getQuoteTotalPrice(
                quote,
                null,
                null,
                true,
                false,
                true
            ),
            pantsPrice: siblingQuote ? getQuoteTotalPrice(
                siblingQuote,
                null,
                false,
                true,
                false,
                true
            ) : null,
            totalPrice: getQuoteTotalPrice(
                { ...quote, quote_discount: 0 },
                siblingQuote ? siblingQuote : null,
                false,
                true,
                false,
                true
            ),
            netPrice: getQuoteTotalPrice(
                { ...quote, quote_discount: 0 },
                siblingQuote ? { ...siblingQuote, quote_discount: 0 } : null,
                false,
                false,
                false,
                false,
                true
            ),
            netPriceCoat: getQuoteTotalPrice(
                { ...quote, quote_discount: 0 },
                null,
                false,
                false,
                false,
                false,
                true
            ),
            netPricePants: siblingQuote ? getQuoteTotalPrice(
                { ...siblingQuote, quote_discount: 0 },
                null,
                false,
                false,
                false,
                false,
                true
            ) : null,
            discountPrice: getQuoteTotalPrice(quote, siblingQuote),
            discountAmount: quote.quote_discount_percentage > 0 ? quote.quote_discount_percentage : null,
            quantity: quantity,
            quantityCoat: quote.product_id === '1' ? (quantity > 0 ? quote.sizing_quantity_coat : 1) : quote.sizing_quantity_coat,
            quantityPants: quote.product_id === '1' && siblingQuote ? (quantity > 0 ? siblingQuote.sizing_quantity_pants : 1) : (quote.product_id === '2' ? (quantity > 0 ? quote.sizing_quantity_pants : 1) : quote.sizing_quantity_pants),
            quantityCoveralls: quote.product_id === '3' ? (quantity > 0 ? quote.sizing_quantity_coveralls : 1) : quote.sizing_quantity_coveralls,
            quoteDiscount: parseFloat(quote.quote_discount || 0),
            splitPrices: splitPrices,
            isAccessory: isAccessory,
        };
    }, [state.quote, state.siblingQuote]);





    function toggleImageOrientation(orientation = null) {
        if(orientation) {
            setImageOrientation(orientation);
        } else {
            if (imageOrientation === 'front') {
                setImageOrientation('back');
            } else {
                setImageOrientation('front');
            }
        }
    }

    console.log('RENDER')

    return (
        <>
            <Header
                actions={[
                    {
                        onClick: () => dispatch(resetConfigurator()),
                        label: 'Close',
                        className: 'text-button',
                    },
                ]}>
                {state.quote.quote_id ? (
                    <QuoteHeaderInformation
                        productLineTitle={state.quote.product_line_description}
                        productTitle={state.quote.siblingQuote && state.quote.product_configurable === '1' ? 'Full Set' : state.quote.product_title}
                        quoteNumber={quoteNumberDisplay}
                        quoteName={state.quote.quote_name}
                        quoteId={state.quote.quote_id}
                        orderStatus={state.quote.order_status}
                        composite={composite}
                        updateQuote={updateQuote}
                        isShared={state.quote.is_shared}
                    />
                ) : null}
            </Header>
            <section
                className="flex-row-wrap min-app-height-container"
                style={{
                    marginTop: headerHeight,
                }}>
                <div className="sixty-width configurator-preview-container">
                    {state.quote.quote_id ? (
                        state.quote.product_configurable === '1' ?
                            <ConfiguratorPreview
                            isSummary={true}
                                quote={state.quote}
                                selectedProductOptionValues={
                                    state.quote.productOptionValues
                                }
                                siblingQuote={state.siblingQuote}
                                siblingSelectedProductOptionValues={state.siblingQuote?.productOptionValues}
                            imageOrientation={imageOrientation}
                            toggleImageOrientation={toggleImageOrientation}
                            />
                            :
                            <div
                                className={styles.image}
                                style={{
                                    backgroundImage: `url("${API_ROOT}${state.quote.product_image_file_url}")`,
                                }}
                            />
                    ) : null}
                </div>
                <div className="forty-width options-column">
                    <div className="configurator-tabs">
                        {
                            !state.quote.siblingQuote || state.quote.product_configurable !== '1' ?
                                <div className={classNames("configurator-tab", {
                                    'configurator-tab-red': highlightErrors,
                                })} onClick={() => dispatch(setScreen('build'))}>
                                    Configure
                                    {state.quote.is_valid == 1
                                        ? <img src="/images/cog.png" style={{width: 24, height: 'auto', marginLeft: 10}}/>
                                        : <img src="/images/invalid-light.png" style={{width: 24, height: 'auto', marginLeft: 10}}/>
                                    }
                                </div>
                                :
                                <>
                                    {
                                        [state.quote, state.quote.siblingQuote].sort((quote1, quote2) => {
                                            return quote1.product_id - quote2.product_id;
                                        }).map((quote) => {
                                            return (
                                                <div
                                                    key={quote.quote_id}
                                                    className={classNames("configurator-tab", {
                                                        'configurator-tab-red': highlightErrors && quote.is_valid != 1,
                                                    })}
                                                    onClick={() => {
                                                        if (state.quoteId !== quote.quote_id) {
                                                            dispatch(updateConfiguratorOptionsQuoteId(quote.quote_id));
                                                        }
                                                        dispatch(setScreen('build'));
                                                    }}
                                                >
                                                    {quote.product_title}
                                                    {
                                                        quote.product_id === '1' ?
                                                            <img src="/images/coat-small.png" style={{width: 19, height: 'auto', marginLeft: 10}}/>
                                                            : null
                                                    }
                                                    {
                                                        quote.product_id === '2' ?
                                                            <img src="/images/pants-small.png" style={{width: 13, height: 'auto', marginLeft: 10}}/>
                                                            : null
                                                    }
                                                    {quote.is_valid != 1 && (
                                                        <img src="/images/invalid-light.png" style={{width: 24, height: 'auto', marginLeft: 10}}/>
                                                    )}
                                                </div>
                                            );
                                        })
                                    }
                                </>
                        }
                        <div className={classNames("configurator-tab", "active")}>
                            Summary <img src="/images/lines.png" style={{width: 24, height: 'auto', marginLeft: 10}}/>
                        </div>
                    </div>
                    <div className="quote-summary-top section-container">
                        {!!state.quote.quote_id && demoModeVisible && (
                            <div className="summary-demo-mode-toggle">
                                <Switch
                                    label="Demo Mode"
                                    value={demoModeActive}
                                    onChange={(value) => {
                                        dispatch(setDemoModeActive(value));
                                    }}
                                />
                            </div>
                        )}
                        {state.quote.quote_id ? (
                            <>
                                <p className="quote-title">
                                    {state.quote.quote_name}
                                </p>
                                <p className="quote-product">
                                    {state.quote.product_line_description}{' '}
                                    {state.quote.siblingQuote && state.quote.product_configurable === '1' ? 'Full Set' : state.quote.product_title}
                                </p>
                                <div className="quote-summary-top-actions">
                                    {isEditable && (<>
                                            <Button
                                                className="action"
                                                variant="grey"
                                                onClick={() => {
                                                    dispatch(setScreen('build'));
                                                }}>
                                                Configure Product
                                            </Button>
                                            <Button
                                                className="action"
                                                variant="grey"
                                                onClick={() =>
                                                    setCollaborateQuoteModalActive(true)
                                                }>
                                                Collaborate
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        className="action"
                                        variant="grey"
                                        onClick={() =>
                                            setShareModalActive(true)
                                        }>
                                        Send A Copy
                                    </Button>
                                    <Button
                                        className="action"
                                        variant="grey"
                                        onClick={() => {
                                            setEmailCopyActive(true)
                                        }}>
                                        Email A Copy
                                    </Button>
                                    <Button
                                        className="action"
                                        variant="grey"
                                        onClick={() =>
                                            setExportModalActive(true)
                                        }>
                                        Export Summary
                                    </Button>
                                    <Button
                                        className="action"
                                        variant="grey"
                                        onClick={handlePrint}
                                        loading={actionLoader === 'print'}>
                                        Print Summary
                                    </Button>
                                    <Button
                                        className="action"
                                        variant="grey"
                                        onClick={() => {
                                            setSpecificationsModalActive(true);
                                        }}>
                                        Export Specifications
                                    </Button>
                                    <Button
                                        className="action"
                                        variant="grey"
                                        onClick={async () => {
                                            await Http().post(`${API_ROOT}/quotes/copy/${state.quote.sibling_quote_id ? state.quote.sibling_quote_id : state.quote.quote_id}`)
                                                .then(() => {
                                                    setErrorAlert({errorTitle: '', errorMessage: 'Quote Copied Successfully.'})
                                                })
                                        }}
                                    >
                                        Copy Quote
                                    </Button>
	                                {!demoModeActive && isEditable && (
                                        <Button
                                            className="action"
                                            variant="grey"
                                            onClick={async () => {
                                                setNetPriceAdjustmentModalActive(true);
                                            }}
                                        >
                                            Net Price Adjustment
                                        </Button>
                                    )}
                                    {user?.permissions?.includes('manage_orders') && (isEditable || isDistributorAdmin) && (
                                        <>
                                            {
                                                state.quote.order_id ? (
                                                    <>
                                                        <Button
                                                            className="action"
                                                            variant="grey"
                                                            onClick={() => {
                                                                window.location = `/orders/${state.quote.order_id}`;
                                                            }}>
                                                            View Order
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button
                                                            className="action"
                                                            variant="grey"
                                                            loading={
                                                                actionLoader ===
                                                                'create-order'
                                                            }
                                                            onClick={() => {
                                                                if (state.quote.is_valid != 1 || (state.siblingQuote && state.siblingQuote.is_valid != 1 && state.siblingQuote.quote_is_temporary != 1) || (state.allSiblingQuotes?.filter((quote) => quote.is_valid != 1 && quote.quote_is_temporary != 1)?.length > 0)) {
                                                                    setExportErrorMessage('Quote is missing required options');
                                                                    setHighlightErrors(true);
                                                                    return;
                                                                }

                                                                setActionLoader(
                                                                    'create-order',
                                                                );

                                                                Http()
                                                                    .post(
                                                                        `${API_ROOT}/orders/create-from-quote`,
                                                                        {
                                                                            quoteId,
                                                                        },
                                                                    )
                                                                    .then(
                                                                        (orderResponse) => {
                                                                            const order =
                                                                                orderResponse
                                                                                    .data
                                                                                    .order;
                                                                            window.location = `/orders/${order.order_id}`;
                                                                        },
                                                                    )
                                                                    .catch(() => {
                                                                        setActionLoader(
                                                                            false,
                                                                        );
                                                                    });
                                                            }}>
                                                            Create New Order
                                                        </Button>
                                                        <Button
                                                            className="action"
                                                            variant="grey"
                                                            onClick={() => {
                                                                if (state.quote.is_valid != 1 || (state.siblingQuote && state.siblingQuote.is_valid != 1 && state.siblingQuote.quote_is_temporary != 1) || (state.allSiblingQuotes?.filter((quote) => quote.is_valid != 1 && quote.quote_is_temporary != 1)?.length > 0)) {
                                                                    setExportErrorMessage('Quote is missing required options');
                                                                    setHighlightErrors(true);
                                                                    return;
                                                                }

                                                                toggleSelectingOrderToAssign();
                                                            }}>
                                                            Assign to Order
                                                        </Button>
                                                    </>
                                                )
                                            }
                                        </>
                                    )}
                                    {
                                        user?.permissions?.includes('manage_orders') && state.quote.quote_status === 'approved'
                                        && isFinalized(state.quote) && state.quote.quote_not_for_reorder !== '1' &&
                                        (

                                            <Button
                                                className="action"
                                                variant="grey"
                                                onClick={() => {
                                                    setCopyModalActive(true);
                                                }}>
                                                Reorder the Exact Quote
                                            </Button>
                                        )
                                    }
                                    {!!user?.roles?.find(role => role.role_token === 'user') && (
                                        <Button className="action" variant="solid" onClick={() => {
                                            window.location = `/request-quote?quote_id=${state.quote.quote_id}`;
                                        }}>Request a Quote</Button>
                                    )}
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className="section-container" style={{marginBottom: 0}}>
                        {state.quote.quote_id && !demoModeActive && (<>
                                <SelectField
                                    compact
                                    label="Contract Pricing"
                                    placeholder="Select Pricing"
                                    value={state.quote.contract_id}
                                    options={organizationContracts}
                                    name="contract_id"
                                    onChange={(e) =>
                                        handleContractPricingChange(
                                            e.target.value,
                                        )
                                    }
                                    labelField="contract_title"
                                    valueField="contract_id"
                                    loading={isUpdating}
                                    className={styles.orderItemContract}
                                    disabled={!isEditable}
                                    style={{marginBottom: 10}}
                                />
                                <QuotePricing
                                    price={pricing.totalPrice}
                                    priceCoat={pricing.coatPrice}
                                    pricePants={pricing.pantsPrice}
                                    discountPrice={pricing.discountPrice}
                                    discountAmount={pricing.discountAmount}
                                    handleDiscountChange={
                                        handleDiscountPercentageChange
                                    }
                                    // className={styles.orderItemPricing}
                                    discountModalClassName={modalStyles.withSidebar}
                                    isEditable={isEditable}
                                    quantity={pricing.quantity}
                                    quantityCoat={pricing.quantityCoat}
                                    quantityPants={pricing.quantityPants}
                                    quantityCoveralls={pricing.quantityCoveralls}
                                    hasSibling={!!state.siblingQuote?.quote_id}
                                    quoteDiscount={pricing.quoteDiscount}
                                    netPrice={pricing.netPrice}
                                    netPriceCoat={pricing.netPriceCoat}
                                    netPricePants={pricing.netPricePants}
                                    splitPrices={pricing.splitPrices}
                                    isAccessory={pricing.isAccessory}
                                />
                            </>

                        )}

                    </div>
                    <div className="section-container quote-pricing" style={{marginTop: 0}}>
                        {state.quote.quote_id && state.quote.quote_stock_flag === 1 && (
                            <TextField
                                label={
                                    <div className={styles.stockOrderToggle}>
                                        Gear Use
                                        <span
                                            className={styles.noFindLink}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                handleManualDepartmentFlagChange({
                                                    target: {
                                                        name: 'quote_manual_department_flag',
                                                        value: 1,
                                                    },
                                                });
                                                handleStockFlagChange({
                                                    target: {
                                                        value: 0
                                                    }
                                                });
                                            }}>
                                    Can&apos;t Find Department?
                                    </span>
                                        <div
                                            className={styles.stockOrderSwitch}
                                            onMouseEnter={() => setTooltipVisible(true)}
                                            onMouseLeave={() =>
                                                setTooltipVisible(false)
                                            }>
                                            <CheckboxField
                                                label="Not For Fire Department"
                                                value={state.quote.quote_stock_flag}
                                                name="order_stock_flag"
                                                onChange={(e) => {
                                                    handleStockFlagChange({
                                                        target: {
                                                            value: 0
                                                        }
                                                    });
                                                }}
                                                className={styles.stockOrderSwitch}
                                                disabled={!isEditable}
                                            />
                                            <Tooltip
                                                active={tooltipVisible}
                                                className={styles.stockOrderTooltip}>
                                                Use text field to enter gear use
                                            </Tooltip>
                                        </div>
                                    </div>
                                }
                                name="order_stock_order_input"
                                value={state.quote.quote_stock_quote_input}
                                onChange={handleStockQuoteInputChange}
                            />
                        )}
                        {state.quote.quote_id && state.quote.quote_stock_flag !== 1 && state.quote.quote_manual_department_flag !== 1 && (
                            <FireDepartmentField
                                label={
                                    <div className={styles.stockOrderToggle}>
                                        Fire Department Zip / Name
                                        <span
                                            className={styles.noFindLink}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                handleManualDepartmentFlagChange({
                                                    target: {
                                                        value: 1,
                                                    },
                                                });
                                            }}>
                                    Can&apos;t Find Department?
                                    </span>
                                        <div
                                            className={styles.stockOrderSwitch}
                                            onMouseEnter={() => setTooltipVisible(true)}
                                            onMouseLeave={() =>
                                                setTooltipVisible(false)
                                            }>
                                            <CheckboxField
                                                label="Not For Fire Department"
                                                value={state.quote.quote_stock_flag}
                                                name="quote_stock_flag"
                                                onChange={(e) => {
                                                    handleStockFlagChange({
                                                        target: {
                                                            value: 1
                                                        }
                                                    });
                                                }}
                                                className={styles.stockOrderSwitch}
                                                disabled={!isEditable}
                                            />
                                            <Tooltip
                                                active={tooltipVisible}
                                                className={styles.stockOrderTooltip}>
                                                Use text field to enter gear use
                                            </Tooltip>
                                        </div>
                                    </div>
                                }
                                value={state.quote.fire_department_id}
                                name="fire_department_id"
                                onChange={e => handleFireDepartmentChange(e.target.value)}
                                defaultValues={[
                                    state.quote.fire_department_zip,
                                    state.quote.fire_department_name,
                                    state.quote.fire_department_country,
                                ]}
                                className={classNames({
                                    [styles.fireDepartmentField]: true,
                                })}
                                disabled={!isEditable}
                                countries={fireDepartmentCountries}
                                isCSRUser={isCSRUser}
                            />)}
                        {state.quote.quote_id && state.quote.quote_manual_department_flag === 1 && (
                            <FieldRow
                                className={classNames({
                                    [styles.verticalRow]: true,
                                })}>
                                <FieldRow
                                    className={classNames({
                                        [styles.fireDepartmentField]: true,
                                        [styles.hideField]: state.quote.quote_stock_flag === 1,
                                    })}>
                                    <TextField
                                        label={
                                            <div
                                                className={styles.stockOrderToggle}>
                                                Fire Department Name*
                                                <span
                                                    className={styles.noFindLink}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleManualDepartmentFlagChange({
                                                            target: {
                                                                name: 'quote_manual_department_flag',
                                                                value: 0,
                                                            },
                                                        });
                                                    }}>
												Can&apos;t Find Department?
                                                </span>
                                                <div>
                                                    <div
                                                        className={
                                                            styles.stockOrderSwitch
                                                        }
                                                        onMouseEnter={() =>
                                                            setTooltipVisible(true)
                                                        }
                                                        onMouseLeave={() =>
                                                            setTooltipVisible(false)
                                                        }>
                                                        <CheckboxField
                                                            label="Not For Fire Department"
                                                            value={state.quote.quote_stock_flag}
                                                            name="order_stock_flag"
                                                            onChange={() => {
                                                                handleStockFlagChange({
                                                                    target: {
                                                                        name: 'quote_stock_flag',
                                                                        value: 1,
                                                                    },
                                                                });
                                                                handleManualDepartmentFlagChange({
                                                                    target: {
                                                                        name: 'quote_manual_department_flag',
                                                                        value: 0,
                                                                    },
                                                                });
                                                            }}
                                                            className={
                                                                styles.stockOrderSwitch
                                                            }
                                                        />
                                                        <Tooltip
                                                            active={tooltipVisible}
                                                            className={
                                                                styles.stockOrderTooltip
                                                            }>
                                                            Use text field to enter
                                                            gear use
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        name="fire_department_name_manual"
                                        value={
                                            formData.fire_department_name_manual ||
                                            ''
                                        }
                                        error={
                                            formErrors.fire_department_name_manual
                                        }
                                        onChange={(e) => {
                                            handleDepartmentFieldChange({
                                                target: {
                                                    name: 'fire_department_name_manual',
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                </FieldRow>

                                <FieldRow
                                    className={classNames({
                                        [styles.fireDepartmentField]: true,
                                    })}>
                                    <TextField
                                        label={'Fire Department Address'}
                                        name="fire_department_address"
                                        value={
                                            formData.fire_department_address
                                        }
                                        error={formErrors.fire_department_address}
                                        onChange={(e) => {
                                            handleDepartmentFieldChange({
                                                target: {
                                                    name: 'fire_department_address',
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                    <TextField
                                        label={'Fire Department City'}
                                        name="fire_department_city"
                                        value={formData.fire_department_city}
                                        error={formErrors.fire_department_city}
                                        onChange={(e) => {
                                            handleDepartmentFieldChange({
                                                target: {
                                                    name: 'fire_department_city',
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                </FieldRow>
                                <FieldRow
                                    className={classNames({
                                        [styles.fireDepartmentField]: true,
                                    })}>
                                    <TextField
                                        label={'Fire Department State / Province / Region'}
                                        name="fire_department_state"
                                        value={formData.fire_department_state}
                                        error={formErrors.fire_department_state}
                                        onChange={(e) => {
                                            handleDepartmentFieldChange({
                                                target: {
                                                    name: 'fire_department_state',
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                    <TextField
                                        label={'Fire Department Zip / Postal Code'}
                                        name="fire_department_zip_manual"
                                        value={
                                            formData.fire_department_zip_manual ||
                                            ''
                                        }
                                        error={
                                            formErrors.fire_department_zip_manual
                                        }
                                        onChange={(e) => {
                                            handleDepartmentFieldChange({
                                                target: {
                                                    name: 'fire_department_zip_manual',
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                </FieldRow>
                                <FieldRow
                                    className={classNames({
                                        [styles.fireDepartmentField]: true,
                                    })}>
                                    <TextField
                                        label={'Fire Department County'}
                                        name="fire_department_county"
                                        value={
                                            formData.fire_department_county
                                        }
                                        error={formErrors.fire_department_county}
                                        onChange={(e) => {
                                            handleDepartmentFieldChange({
                                                target: {
                                                    name: 'fire_department_county',
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    />

                                    <SelectField
                                    label="Fire Department Country"
                                        placeholder="Country"
                                        value={formData.fire_department_country || ''}
                                        name="fire_department_country"
                                        onChange={(e) => {

                                            if(!e.target.value) {
                                                e.target.value = 'US';
                                            }
                                            handleDepartmentFieldChange({
                                                target: {
                                                    name: 'fire_department_country',
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                        options={countries}
                                        labelField="country_name"
                                        valueField="country_code"
                                        className={classNames({ [styles.countryField]: true })}
                                        inputClassName={classNames({[styles.fullHeight]: true})}
                                        menuClassName={classNames({[styles.selectMenu]: true})}
                                    />
                                </FieldRow>
                            </FieldRow>
                        )}
                    </div>
                    {state.quote.quote_id && state.quote.product_line_id ? (
                        <>
                            <Collapsible
                                open={collapsibles.sizing}
                                easing="ease-out"
                                transitionTime={200}
                                contentInnerClassName="section-container"
                                trigger={
                                    <div
                                        className="section-heading"
                                        onClick={() => {
                                            setCollapsibles((sections) => ({
                                                ...sections,
                                                sizing: !sections.sizing,
                                            }));
                                        }}>
										<span>
											SIZING{' '}
                                            {parseInt(
                                                state.quote.sizing_quantity,
                                            )
                                                ? `(${state.quote.sizing_quantity})`
                                                : null}
										</span>
                                        {parseInt(
                                            state.quote.sizing_quantity,
                                        ) && state.quote.sizing_valid == 1
                                            ? null : <img src="/images/invalid.png" style={{width: 24, height: 'auto', marginLeft: 'auto', marginRight: 10}}/>}
                                        <ChevronIcon
                                            className={classNames({
                                                chevron: true,
                                                active: collapsibles.sizing,
                                            })}
                                        />
                                    </div>
                                }>
                                <div className="sizing-options">
                                    <div>
                                        <p>Add or Edit Sizing</p>
                                        <span>
											Add new or edit current sizing
											information
										</span>
                                    </div>
                                    <div ></div>
                                        <Button
                                            onClick={() =>
                                                setSizingModalActive(true)
                                            }>
                                            {isEditable ? "Add or Edit Sizing" : "View Sizing"}
                                        </Button>
                                        {isEditable &&
                                            <Button
                                                onClick={() =>
                                                    setSizerInviteModalActive(true)
                                                }>
                                                Send Sizing Invite
                                            </Button>
                                        }
                                    {
                                        originalQuotes.length > 0 && (
                                            <>
                                                <div style={{width: '100%', marginTop: 30}}>
                                                    <span>
                                                        Quote Sizing History
                                                    </span>
                                                </div>
                                                {
                                                    originalQuotes.map((originalQuote, index) => {
                                                        return (
                                                            <div key={originalQuote.quote_id} style={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                margin: '10px 0'
                                                            }}>
                                                                <Button
                                                                    style={{marginRight: 5}}
                                                                    onClick={() => {
                                                                        setOriginalQuoteActiveSizingIndex(index);
                                                                    }}
                                                                >
                                                                    View Sizing
                                                                </Button>
                                                                <span>From Order #{originalQuote.order_id?.padStart(4, '0')}</span>
                                                                <SizingModal
                                                                    quoteId={originalQuote.quote_id}
                                                                    productLineId={originalQuote.product_line_id}
                                                                    productIds={[originalQuote.product_id, originalQuote.siblingQuote?.product_id]}
                                                                    active={index === originalQuoteActiveSizingIndex}
                                                                    onClose={() => setOriginalQuoteActiveSizingIndex(null)}
                                                                    isEditable={false}
                                                                />
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </Collapsible>
                        </>
                    ) : null}
                    {!state.quote.is_shared && (
                        <Collapsible
                            open={collapsibles.sharing}
                            easing="ease-out"
                            transitionTime={200}
                            contentInnerClassName="section-container"
                            trigger={
                                <div
                                    className="section-heading"
                                    onClick={() => {
                                        setCollapsibles((sections) => ({
                                            ...sections,
                                            sharing: !sections.sharing,
                                        }));
                                    }}>
                                    <span>COLLABORATING{' '}
                                        {sharedWith.length
                                            ? `(${sharedWith.length})`
                                            : null}
                                    </span>
                                    <ChevronIcon
                                        className={classNames({
                                            chevron: true,
                                            active: collapsibles.sharing,
                                        })}
                                    />
                                </div>
                            }>
                            <div className="sharing-options">
                                <ul>
                                    {sharedWith.map((user, u) => (
                                        <li key={u}>
                                            <Avatar user={user}/>
                                            <span>
														{[
                                                            user.profile_first_name +
                                                            ' ',
                                                            user.profile_last_name,
                                                        ].join('')}
													</span>
                                            <button onClick={() => {
                                            }}>
                                                <CloseIcon
                                                    fill="rgba(0,0,0,0.5)"
                                                    onClick={() =>
                                                        setConfirmationModalActive(
                                                            user.user_id,
                                                        )
                                                    }
                                                />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Collapsible>
                    )}
                    <Collapsible
                        open={collapsibles.summary}
                        easing="ease-out"
                        transitionTime={200}
                        contentInnerClassName="section-container"
                        trigger={
                            <div
                                className="section-heading"
                                onClick={() => {
                                    setCollapsibles((sections) => ({
                                        ...sections,
                                        summary: !sections.summary,
                                    }));
                                }}>
                                <span>SELECTION SUMMARY</span>
                                <ChevronIcon
                                    className={classNames({
                                        chevron: true,
                                        active: collapsibles.summary,
                                    })}
                                />
                            </div>
                        }>
                        <OrderItemDetails
                            quote={state.quote}
                            siblingQuote={state.siblingQuote}
                            allSiblings={state.allSiblingQuotes}
                            compact={true}
                        />
                    </Collapsible>
                </div>
                {state.selectingOrderToAssign ? (
                    <AssignQuoteToOrderOverlayForm
                        quoteId={quoteId}
                        portalId="overlay-portal"
                        closeAction={() => toggleSelectingOrderToAssign()}
                    />
                ) : null}
            </section>
            <ShareQuoteModal
                quoteId={quoteId}
                active={shareModalActive}
                onClose={(didChange) => {
                    if (didChange) {
                        getQuoteSharedUsers();
                    }

                    setShareModalActive(false);
                }}
            />
            <CollaborateQuoteModal
                quoteId={quoteId}
                active={collaborateQuoteModalActive}
                onClose={(didChange) => {
                    if (didChange) {
                        getQuoteSharedUsers();
                    }

                    setCollaborateQuoteModalActive(false);
                }}
            />
            <EmailQuoteSummaryModal
                quoteId={quoteId}
                active={emailCopyActive}
                onClose={() => {
                    setEmailCopyActive(false);
                }}
            />
            <DialogModal
                active={!!exportErrorMessage}
                onConfirm={() => setExportErrorMessage(false)}
                onClose={() => setExportErrorMessage(false)}
                message={exportErrorMessage}
                title="Something went wrong"
                labels={['Ok']}
            />
            <ConfirmationModal
                active={!!confirmationModalActive}
                title="Are you sure?"
                message="Are you sure you want to remove access to this quote?"
                onConfirm={() => {
                    removeQuoteSharedUser(confirmationModalActive);
                    setConfirmationModalActive(false);
                }}
                onClose={() => setConfirmationModalActive(false)}
            />
            <SizingModal
                quoteId={quoteId}
                productLineId={state.quote.product_line_id}
                productIds={[state.quote.product_id, state.quote.siblingQuote?.product_id]}
                active={sizingModalActive}
                onClose={async (refresh = false, quantity_coat = null, quantity_pants = null, quantity_coveralls = null) => {
                    if (refresh) {
                        if (state.quote.product_configurable) {
                            // Update values of quote in state immediately to update price
                            setState((prevState) => {
                                let quote = prevState.quote;
                                let siblingQuote = prevState.siblingQuote;
                                if(quote.product_id === '1') {
                                    quote.sizing_quantity_coat = quantity_coat;
                                    if(siblingQuote) {
                                        siblingQuote.sizing_quantity_pants = quantity_pants;
                                    }
                                }  else if(quote.product_id === '2') {
                                    quote.sizing_quantity_pants = quantity_pants;
                                } else {
                                    quote.sizing_quantity_coveralls = quantity_coveralls;
                                }

                                return {
                                    ...prevState,
                                    quote,
                                    siblingQuote,
                                };
                            });
                        }
                        await updateQuote({...state.quote});
                    }
                    setSizingModalActive(false);
                }}
                isEditable={isEditable}
            />
            <ExportModal
                active={exportModalActive}
                onClose={() => setExportModalActive(false)}
                onWord={handleWordExport}
                onPdf={handlePdfExport}
            />
            <SizerInviteModal
                active={sizerInviteModalActive}
                onClose={() => setSizerInviteModalActive(false)}
                quoteId={quoteId}
            />
            <CopyToOrderModal
                active={copyModalActive}
                onClose={handleCopyModalClose}
                quoteId={state.quote.quote_id}
                className={modalStyles.withSidebar}
            />
            <NetPriceAdjustmentModal
                active={netPriceAdjustmentModalActive}
                quote={state.quote}
                siblingQuote={state.siblingQuote}
                onClose={() => setNetPriceAdjustmentModalActive(false)}
                onSubmit={async (newNetPrice1, newNetPrice2) => {
                    await handleNetPriceAdjustmentUpdates(newNetPrice1, newNetPrice2);
                    setNetPriceAdjustmentModalActive(false)
                }}
            />
        </>
    );
}

export default ConfiguratorSummary;
