import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import styles from './ProductSelectModal.module.scss';
import classNames from 'classnames';
import { useMemo } from 'react';
import { getAvailableProducts} from '@utilities/products';



const ProductSelectModal = ({
	active = false,
	onClose = () => {},
	onSelect = () => {},
	productLine = {},
}) => {

	const availableOptions = useMemo(()=>{
		return getAvailableProducts(productLine);
	},[productLine])

	return (
		<Modal
			active={active}
			onClose={() => onClose()}
			className={styles.modal}>
			<h4 className={modalStyles.modalTitle}>
				What would you like to build?
			</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<ul>
					{availableOptions.map((option) => (
						<li key={option.key} onClick={() => onSelect(option.ids)}>
							<span>{option.label}</span>
							<div
								style={{
									backgroundImage: option.image,
								}}
							/>
						</li>
					))}
				
				</ul>
			</div>
		</Modal>
	);
};

export default ProductSelectModal;
