import { useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMobileMenuState } from '@hooks/useMobileMenuState';
import UserMenu from '../../UserMenu/UserMenu';
import NavigationItem from '../NavigationItem/NavigationItem';
import styles from './AuthenticatedNavigation.module.scss';

// icons
import ChevronIcon from '../../Icons/ChevronIcon';
import DashboardIcon from '../../Icons/DashboardIcon';
import NotificationsIcon from '../../Icons/NotificationsIcon';
import ProductsIcon from '../../Icons/ProductsIcon';
import QuotesIcon from '../../Icons/QuotesIcon';
import OrdersIcon from '../../Icons/OrdersIcon';
import ResourcesIcon from '../../Icons/ResourcesIcon';
import AdminIcon from '../../Icons/AdminIcon';
import { API_ROOT } from '@config';

const AuthenticatedNavigation = () => {
	const { user } = useSelector((state) => state.auth);
	const [mobileMenuOpen, setMobileMenuOpen] = useMobileMenuState();
	const content = useSelector((state) => state.staticData.content);
	const logo = content?.logos?.indexed['logo-light'];

	const navItems = useMemo(() => {
		const items = [];
		const isCSRUser = !!user?.permissions.includes('csr_access');
		const isRSMUser = !!user?.permissions.includes('rsm_access');

		// authenticated nav items
		if (user) {
			items.push({
				path: '/dashboard',
				icon: <DashboardIcon />,
				title: 'Dashboard',
			});

			items.push(
				{
					path: '/notifications',
					icon: <NotificationsIcon />,
					title: 'Notifications',
				},
				{
					path: '/products',
					icon: <ProductsIcon />,
					title: 'Products',
				},
				{
					path: '/quotes',
					icon: <QuotesIcon />,
					title: 'Quotes',
				},
			);

			if (user?.permissions?.includes('manage_orders')) {
				items.push({
					path: '/orders',
					icon: <OrdersIcon />,
					title: 'Orders',
				});
			}

			if (user?.permissions?.includes('resource_access')) {
				items.push(
					{
						path: '/resources',
						icon: <ResourcesIcon/>,
						title: 'Resources',
					}
				);
			}

			if (isCSRUser) {
				items.push({
					path: '/csr-dashboard',
					icon: (
						<AdminIcon fill="#FFF" className={styles.adminIcon} />
					),
					title: 'CSR Dashboard',
				});
			}
		}

		if (user?.permissions?.includes('access_admin')) {
			items.push({
				onClick: () => {
					window.open(`${API_ROOT}/iris`, '_blank');
				},
				icon: <AdminIcon fill="#FFF" className={styles.adminIcon} />,
				title: 'Admin',
			});
		}

		return items;
	}, [user]);

	return (
		<section
			className={classNames({
				[styles.appNavigation]: true,
				[styles.appNavigationActive]: mobileMenuOpen,
			})}>
			{!!logo && (
				<Link to="/dashboard" className={styles.logo}>
					<img
						src={`${API_ROOT}/uploaded/content/${logo?.content_item_image}`}
					/>
				</Link>
			)}
			<button
				onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
				className={styles.mobileNavButton}>
				<ChevronIcon />
			</button>
			<nav>
				<ul>
					{navItems.map((item, i) => (
						<NavigationItem key={i} item={item} />
					))}
				</ul>
				{!!user && <UserMenu />}
			</nav>
		</section>
	);
};

export default AuthenticatedNavigation;
