import styles from './QuoteShareNotification.module.scss';

const QuoteShareNotification = ({ notification }) => {
	return (
		<div className={styles.quoteShareNotification}>
			<div
				className={styles.quoteShareNotificationPreview}
				dangerouslySetInnerHTML={{ __html: notification.quote_preview }}
			/>
			<div>
				<p className={styles.quoteShareNotificationTitle}>
					Quote <b>#{notification.quote_number}</b> has been shared
					with you
				</p>
				<span className={styles.quoteShareNotificationSubtitle}>
					{notification.quote_user_display_name} wants to collaborate
					on a quote with you
				</span>
			</div>
		</div>
	);
};

export default QuoteShareNotification;
