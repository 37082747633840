import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router';

import Http from '@utilities/Http';
import { API_ROOT } from '@config';
import ScreenOverlay from '@components/ScreenOverlay';
import FieldSet from '@components/FieldSet';
import { resetConfigurator } from '@store/reducers/configurator';
import http from '@utilities/Http';
import {useErrorAlert} from "@hooks/useErrorAlert";

function AssignQuoteToOrderOverlayForm({
	quoteId,
	portalId,
	closeAction = () => {},
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [orders, setOrders] = useState([]);
	const [selectedOrderId, setSelectedOrderId] = useState(null);
	const isCSRUser = useSelector((state) =>
		state.auth.user?.permissions?.includes('csr_access'),
	);
	const isRSMUser = useSelector((state) =>
		state.auth.user?.permissions?.includes('rsm_access'),
	);

	const [, setErrorAlert] = useErrorAlert();


	useEffect(() => {
		let url = `${API_ROOT}/orders?order_status[0]=Not Submitted&order_status[1]=Requires Edits`;

		if (isCSRUser || isRSMUser) {
			url += '&ignore_user=1';
		}

		Http()
			.get(url)
			.then((ordersResponse) => {
				const orders = ordersResponse.data.orders;
				setOrders(orders);
			})
			.catch((error) => {
				console.log('error: ', error);
			});
	}, [setOrders]);

	return ReactDOM.createPortal(
		<ScreenOverlay>
			<div className="overlay-content">
				<h1 className="title">Assign Quote to Order</h1>
				<FieldSet
					fields={[
						{
							key: 'orders',
							type: 'select',
							title: 'Select Order',
							fieldProps: {
								options: orders,
								getOptionLabel: (order) =>
									order.order_id + ' - ' + order.order_name,
								getOptionValue: (order) => order.order_id,
								onChange: (order) => {
									setSelectedOrderId(order.order_id);
								},
							},
						},
					]}
					actions={[
						{
							label: 'Confirm',
							type: 'submit',
							onClick: () => {
								if (selectedOrderId) {
									Http()
										.post(
											`${API_ROOT}/quotes/assign-quote-to-order/${quoteId}`,
											{
												orderId: selectedOrderId,
											},
										)
										.then(() => {
											history?.push(
												`/orders/${selectedOrderId}`,
											);
											dispatch(resetConfigurator());
										})
										.catch((error) => {
											const { response } = error;

											if (response.data.error_type) {
												let resubmit = confirm(
													response.data.error_message,
												);

												setErrorAlert({
													errorTitle: 'Overwrite',
													errorMessage: response.data.error_message,
													errorType: 'confirm',
													errorAction: () => {
														http()
															.post(
																`${API_ROOT}/quotes/assign-quote-to-order/${quoteId}`,
																{
																	orderId:
																	selectedOrderId,
																	overwrite:
																	resubmit,
																},
															)
															.then(() => {
																history?.push(
																	`/orders/${selectedOrderId}`,
																);
																dispatch(
																	resetConfigurator(),
																);
															});
													}
												});
											}
										});
								}
							},
						},
						{
							label: 'Close',
							onClick: () => closeAction(),
						},
					]}
				/>
			</div>
		</ScreenOverlay>,
		document.getElementById(portalId),
	);
}

export default AssignQuoteToOrderOverlayForm;
