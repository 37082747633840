import styles from '../Messenger.module.scss';
import React from "react";

const MessageWindow = ({children, title="Order Support"}) => {
    return (
        <div className={styles.messengerWrapperTop}>
            <h3 className={styles.messengerHeader}>{title}</h3>
            <div className={styles.messengerWindowBackground}>
                <div className={styles.messengerWindow}>
                    <div className={styles.messengerWindowInner}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MessageWindow;
