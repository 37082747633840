import styles from './NewFireDepartmentNotification.module.scss';

const NewFireDepartmentNotification = ({ notification }) => {
	return (
		<div className={styles.newsNotification}>
			<div>
				<p className={styles.newsNotificationTitle}>
					New Fire Department Request
				</p>
				<p className={styles.newsNotificationContent}>
					{notification.notification_meta.action_name}({notification.notification_meta.action_email}){' '}
					{notification.notification_meta.action} with the Fire
					Department:
					{'\n'}
					{notification.notification_meta.fire_department_name}
					{'\n'}
					{notification.notification_meta.fire_department_address}
					{'\n'}
					{notification.notification_meta.fire_department_city},{' '}
					{notification.notification_meta.fire_department_state},{' '}
					{notification.notification_meta.fire_department_zip}
					{'\n'}
					{notification.notification_meta.fire_department_county}
					{'\n'}
					{notification.notification_meta.fire_department_country}
				</p>
			</div>
		</div>
	);
};

export default NewFireDepartmentNotification;
