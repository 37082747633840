import { useCallback, useEffect, useState } from 'react';
import { useForm } from '@hooks/useForm';
import FieldRow from '@components/Field/FieldRow';
import TextField from '@components/TextField/TextField';
import Button from '@components/Button/Button';
import classNames from 'classnames';
import { schema } from './form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import fieldStyles from '@components/Field/Fields.module.scss';
import styles from './RequestQuoteConfirmationForm.module.scss';
import { Link } from 'react-router-dom';

const RequestQuoteConfirmationForm = ({
	onSubmit = async () => {},
	raqFormData = null,
	children = null,
}) => {
	const history = useHistory();
	const [isMounted, setIsMounted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState(false);
	const [showExistingAccountMessage, setShowExistingAccountMessage] =
		useState(false);

	useEffect(() => {
		if (!raqFormData) {
			history.push('/request-quote');
		}
	}, [history, raqFormData]);

	/**
	 * Form data, validation, errors, etc
	 */

	const { formData, formErrors, getValidation, handleChange } =
		useForm(schema);

	/**
	 * Form Submit
	 */

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			const formErrors = await getValidation();

			if (!Object.values(formErrors).every((error) => error === null)) {
				return;
			}

			try {
				setIsSubmitting(true);
				await onSubmit({ ...formData });
				setSubmitError(false);
			} catch (error) {
				if (error.response?.data?.reason === 'user-exists') {
					setShowExistingAccountMessage(true);
					setSubmitError(null);
					return;
				}

				setSubmitError(
					error.response?.data?.meta?.message ||
						'Something went wrong. Please try again later.',
				);
			} finally {
				isMounted && setIsSubmitting(false);
			}
		},
		[getValidation, formData, isMounted, onSubmit],
	);

	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		};
	}, []);

	return (
		<form onSubmit={handleSubmit}>
			{children}
			<FieldRow className={styles.fieldRow}>
				<TextField
					label="Email Address"
					value={raqFormData?.user_email}
					disabled={true}
				/>
			</FieldRow>
			<FieldRow className={styles.fieldRow}>
				<TextField
					type="password"
					label="Password"
					name="user_password"
					value={formData.user_password}
					error={formErrors.user_password}
					onChange={handleChange}
				/>
				<TextField
					type="password"
					label="Confirm Password"
					name="confirm_password"
					value={formData.confirm_password}
					error={formErrors.confirm_password}
					onChange={handleChange}
				/>
			</FieldRow>
			{!!submitError && (
				<p
					className={classNames(
						fieldStyles.fieldError,
						styles.submitError,
					)}>
					{submitError}
				</p>
			)}
			{showExistingAccountMessage && (
				<div className={styles.existsMessage}>
					<p>
						It looks like there is already an account associated
						with that email address.{' '}
						<Link to="/login">Click here to log in.</Link>
					</p>
				</div>
			)}
			<div className={styles.formActions}>
				<Button
					variant="outlined"
					onClick={() => {
						history.push('/');
					}}>
					Learn More
				</Button>
				<Button variant="solid" type="submit" loading={isSubmitting}>
					Create Account
				</Button>
			</div>
		</form>
	);
};

export default RequestQuoteConfirmationForm;
