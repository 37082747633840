import classNames from 'classnames';
import React from 'react';
import styles from './Fields.module.scss';

const FieldColumns = ({ children = null, className = null }) => {
	return (
		<div
			className={classNames({
				[styles.fieldColumns]: true,
				[className]: !!className,
			})}>
			{children.map((child, c) =>
				React.cloneElement(child, {
					key: c,
					siblingCount: children.length,
				}),
			)}
		</div>
	);
};

export default FieldColumns;
