import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { fetchUser } from '../thunks/fetch-user';

const initialState = {
	token: false,
	isNewQuote: false,
};

const buildSlice = createSlice({
	name: 'build',
	initialState,
	reducers: {
		setBuildToken(state, action) {
			return {
				...state,
				token: action.payload,
			};
		},
		setIsNewQuote(state, action) {
			return {
				...state,
				isNewQuote: action.payload,
			};
		}
	},
	extraReducers: {
		[fetchUser.fulfilled]: () => {
			return initialState;
		},
	},
});

export const { setBuildToken, setIsNewQuote } = buildSlice.actions;
export default persistReducer({ key: 'build', storage }, buildSlice.reducer);
