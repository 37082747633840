import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { API_ROOT } from '@config';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import TextField from '@components/TextField/TextField';
import Button from '@components/Button/Button';
import fieldStyles from '@components/Field/Fields.module.scss';
import styles from './NewQuoteModal.module.scss';
import classNames from 'classnames';
import { generateToken } from '@utilities/token';
import { useDispatch, useSelector } from 'react-redux';
import { setConfiguratorOptions, updateConfiguratorOptionsQuoteId } from '@store/reducers/configurator';
import { setBuildToken, setIsNewQuote } from '@store/reducers/build';
import Http from '@utilities/Http';
import FieldSet from '@components/FieldSet';
import CheckboxField from '@components/CheckboxField/CheckboxField';
import Tooltip from '@components/Tooltip/Tooltip';
import FireDepartmentField from '@components/FireDepartmentField/FireDepartmentField';
import { useForm } from '@hooks/useForm';
import { schema } from '@components/NewOrderModal/form';
import FieldRow from '@components/Field/FieldRow';
import SelectField from "@components/SelectField/SelectField";
import {getAvailableProducts} from "@utilities/products";

const NewQuoteModal = ({
	active = false,
	onClose = () => {},
	className = null,
}) => {
	const dispatch = useDispatch();
	const [quoteName, setQuoteName] = useState('');
	const [error, setError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const buildToken = useSelector((state) => state.build.token);
	// const productLines = useSelector((state) => state.staticData.productLines);
	const user = useSelector((state) => state.auth.user);
	const [isMounted, setIsMounted] = useState(false);
	const [productLines, setProductLines] = useState([]);
	const [selectedProductLine, setSelectedProductLine] = useState(null);
	const [productIds, setProductIds] = useState([]);
	const [fireDepartmentId, setFireDepartmentId] = useState(null);
	const [stockFlag, setStockFlag] = useState(0);
	const [stockQuoteInput, setStockQuoteInput] = useState('');
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const [manualFireDepartment, setManualFireDepartment] = useState(false);
	const countries = useSelector((state) => state.staticData.countries);
	const fireDepartmentCountries = useSelector((state) => state.staticData.fireDepartmentCountries);
	const isCSRUser = useSelector((state) =>
		state.auth.user?.permissions?.includes('csr_access'),
	);
	/**
	 * Form data, validation, errors, etc
	 */

	const { formData, formErrors, getValidation, handleChange, setFormData } =
		useForm(schema);
	const fetchProductLines = useCallback(async () => {
		const endpoint = user?.organization_id
			? `organizations/product-lines/${user.organization_id}`
			: 'product-lines/all';

		const { data } = await Http().get(`${API_ROOT}/${endpoint}`);

		return data.productLines;
	}, [user]);

	/**
	 * Organize Product Lines By Category
	 */

	useEffect(() => {
		if (!isMounted) {
			return;
		}

		fetchProductLines().then((productLines) => {
			if (isMounted) {
				setProductLines(
					productLines.sort((a, b) =>
						a.product_line_description.toLowerCase() >
						b.product_line_description.toLowerCase()
							? 1
							: -1,
					),
				);
			}
		});
	}, [fetchProductLines, isMounted]);

	/**
	 * Handle Modal Close
	 */

	const handleClose = useCallback(() => {
		// clear fields
		setQuoteName('');
		setError(null);
		setProductIds([]);
		setSelectedProductLine(null);
		setFireDepartmentId(null);
		setStockFlag(0);
		setStockQuoteInput('');
		onClose();
		setIsSubmitting(false);
		setFormData([null]);
	}, [onClose]);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			if (!quoteName) {
				setError('Please enter a name for this quote');
				return;
			}

			if (!selectedProductLine && productIds.length < 1) {
				setError('Please select your product line');
				return;
			}

			if (selectedProductLine && productIds.length < 1) {
				setError('Please select your product option');
				return;
			}

			if (!fireDepartmentId) {
				// if (!stockFlag && !manualFireDepartment) {
				// 	setError('Please choose a fire department');
				// 	return;
				// }
				if (stockFlag && !stockQuoteInput) {
					setError('Please provide gear use');
					return;
				}
				if (manualFireDepartment) {
					setError(null);
				}
			}

			if (manualFireDepartment) {
				const formErrors = await getValidation();
				if (
					!Object.values(formErrors).every((error) => error === null)
				) {
					return;
				}
			}

			setIsSubmitting(true);

			const token = buildToken || generateToken(64);

			Http()
				.post(`${API_ROOT}/build`, {
					quotes: productIds.map((productId) => {
						return {
							quote_name: quoteName,
							product_id: productId,
							product_line_id:
								selectedProductLine?.product_line_id,
							quote_token: token,
							fire_department_id: fireDepartmentId,
							quote_stock_flag: stockFlag,
							quote_stock_quote_input: stockQuoteInput,
							quote_manual_department_flag: manualFireDepartment
								? 1
								: 0,
						};
					}),
					manualDepartment: formData,
				})
				.then(({ data }) => {
					const quote = data.quote;
					handleClose();
					dispatch(setBuildToken(token));
					dispatch(setIsNewQuote(true));
					dispatch(
						updateConfiguratorOptionsQuoteId(quote.quote_id),
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		[
			dispatch,
			quoteName,
			selectedProductLine,
			productIds,
			buildToken,
			handleClose,
			getValidation,
			formData,
			fireDepartmentId,
			stockQuoteInput,
			stockFlag
		],
	);

	const availableProducts = useMemo(() => {
		const availableOptions = getAvailableProducts(selectedProductLine);



		return availableOptions.map((option,_,arr)=>{
			const key = option.key === 'full-set' ? 'full-set' : option.ids[0];
			return{
				value:key,
				label:option.label
			}
		});

	}, [selectedProductLine]);

	useEffect(() => {
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	return (
		<Modal
			active={active}
			onClose={() => handleClose()}
			className={classNames({
				[className]: !!className,
				[styles.newQuoteModal]: true,
			})}>
			<h4 className={modalStyles.modalTitle}>Create Quote</h4>
			<form onSubmit={handleSubmit}>
				<div
					className={classNames({
						[modalStyles.modalBody]: true,
						[styles.form]: true,
					})}>
					<TextField
						label="Quote Name"
						value={quoteName}
						onChange={(e) => {
							setQuoteName(e.target.value);
							setError(null);
						}}
						className={styles.fieldInput}
					/>
					{stockFlag === 1 && (
						<TextField
							label={
								<div className={styles.stockOrderToggle}>
									Gear Use
									<span
										className={styles.noFindLink}
										onClick={(e) => {
											e.preventDefault();

											setManualFireDepartment(
												!manualFireDepartment,
											);
											setStockFlag(0);
										}}>
										Can&apos;t Find Department?
									</span>
									<div
										className={styles.stockOrderSwitch}
										onMouseEnter={() =>
											setTooltipVisible(true)
										}
										onMouseLeave={() =>
											setTooltipVisible(false)
										}>
										<CheckboxField
											label="Not For Fire Department"
											value={stockFlag}
											name="quote_stock_flag"
											onChange={(e) => {
												setStockFlag(e.target.value ? 1 : 0);
												setManualFireDepartment(false);
											}}
											className={styles.stockOrderSwitch}
										/>
										<Tooltip
											active={tooltipVisible}
											className={
												styles.stockOrderTooltip
											}>
											Use text field to enter gear use
										</Tooltip>
									</div>
								</div>
							}
							name="quote_stock_quote_input"
							value={stockQuoteInput || ''}
							onChange={(e) => {
								setStockQuoteInput(e.target.value);
							}}
						/>
					)}
					{stockFlag !== 1 && !manualFireDepartment && (
						<FireDepartmentField
							label={
								<div className={styles.stockOrderToggle}>
									Fire Department Zip / Name
									<span
										className={styles.noFindLink}
										onClick={(e) => {
											e.preventDefault();

											setManualFireDepartment(
												!manualFireDepartment,
											);
										}}>
										Can&apos;t Find Department?
									</span>
									<div
										className={styles.stockOrderSwitch}
										onMouseEnter={() =>
											setTooltipVisible(true)
										}
										onMouseLeave={() =>
											setTooltipVisible(false)
										}>
										<CheckboxField
											label="Not For Fire Department"
											value={stockFlag}
											name="quote_stock_flag"
											onChange={(e) => {
												setStockFlag(e.target.value ? 1 : 0);
											}}
											className={styles.stockOrderSwitch}
										/>
										<Tooltip
											active={tooltipVisible}
											className={
												styles.stockOrderTooltip
											}>
											Use text field to enter gear use
										</Tooltip>
									</div>
								</div>
							}
							value={fireDepartmentId}
							name="fire_department_id"
							onChange={(e) => {
								setFireDepartmentId(e.target.value);
							}}
							className={classNames({
								[styles.fireDepartmentField]: true,
								[styles.hideField]: stockFlag,
							})}
							countries={fireDepartmentCountries}
							isCSRUser={isCSRUser}
						/>
					)}
					{manualFireDepartment && (
						<>
							<FieldRow
								className={classNames({
									[styles.fireDepartmentField]: true,
									[styles.hideField]: stockFlag,
								})}>
								<TextField
									label={
										<div
											className={styles.stockOrderToggle}>
											Fire Department Name*
											<span
												className={styles.noFindLink}
												onClick={(e) => {
													e.preventDefault();

													setManualFireDepartment(
														!manualFireDepartment,
													);
												}}>
												Search For Department?
											</span>
											<div>
												<div
													className={
														styles.stockOrderSwitch
													}
													onMouseEnter={() =>
														setTooltipVisible(true)
													}
													onMouseLeave={() =>
														setTooltipVisible(false)
													}>
													<CheckboxField
														label="Not For Fire Department"
														value={stockFlag}
														name="order_stock_flag"
														onChange={() => {
															setStockFlag(stockFlag === 1 ? 0 : 1);
															setManualFireDepartment(
																false,
															);
														}}
														className={
															styles.stockOrderSwitch
														}
													/>
													<Tooltip
														active={tooltipVisible}
														className={
															styles.stockOrderTooltip
														}>
														Use text field to enter
														gear use
													</Tooltip>
												</div>
											</div>
										</div>
									}
									name="fire_department_name_manual"
									value={
										formData.fire_department_name_manual ||
										''
									}
									error={
										formErrors.fire_department_name_manual
									}
									onChange={handleChange}
								/>
							</FieldRow>
							<SelectField
                                    label="Fire Department Country"
                                        placeholder="Country"
                                        value={formData.fire_department_country || ''}
                                        name="fire_department_country"
                                        onChange={handleChange}
                                        options={countries}
                                        labelField="country_name"
                                        valueField="country_code"
                                        className={classNames({ [styles.countryField]: true })}
                                        inputClassName={classNames({[styles.fullHeight]: true})}
                                        menuClassName={classNames({[styles.selectMenu]: true})}
                                    />
							<FieldRow
								className={classNames({
									[styles.fireDepartmentField]: true,
								})}>
								<TextField
									label={'Fire Department Address'}
									name="fire_department_address"
									value={
										formData.fire_department_address || ''
									}
									disabled={!formData.fire_department_country}
									error={formErrors.fire_department_address}
									onChange={handleChange}
								/>
								<TextField
									label={'Fire Department City'}
									name="fire_department_city"
									value={formData.fire_department_city || ''}
									disabled={!formData.fire_department_country}
									error={formErrors.fire_department_city}
									onChange={handleChange}
								/>
							</FieldRow>
							<FieldRow
								className={classNames({
									[styles.fireDepartmentField]: true,
								})}>
								<TextField
									label={'Fire Department State / Province / Region'}
									name="fire_department_state"
									value={formData.fire_department_state || ''}
									disabled={!formData.fire_department_country}
									error={formErrors.fire_department_state}
									onChange={handleChange}
								/>
								<TextField
									label={'Fire Department Zip / Postal Code'}
									name="fire_department_zip_manual"
									value={
										formData.fire_department_zip_manual ||
										''
									}
									disabled={!formData.fire_department_country}
									error={
										formErrors.fire_department_zip_manual
									}
									onChange={handleChange}
								/>
							</FieldRow>
							<FieldRow
								className={classNames({
									[styles.fireDepartmentField]: true,
								})}>
								<TextField
									label={'Fire Department County'}
									name="fire_department_county"
									value={
										formData.fire_department_county || ''
									}
									disabled={!formData.fire_department_country}
									error={formErrors.fire_department_county}
									onChange={handleChange}
								/>

							</FieldRow>
						</>
					)}
				</div>
				<FieldSet
					containerClassName={styles.fieldSetContainer}
					fields={[
						{
							key: 'product_lines',
							type: 'select',
							fieldProps: {
								className: styles.fieldInput,
								options: productLines,
								placeholder: 'Product Line',
								getOptionLabel: (productLine) =>
									productLine.product_line_description,
								getOptionValue: (productLine) =>
									productLine.product_line_id
										? productLine.product_line_id + '-pl'
										: productLine.product_id + '-p',
								onChange: (productLine) => {
									if (productLine.product_line_id) {
										setSelectedProductLine(productLine);
										setProductIds([]);
									} else {
										setSelectedProductLine(null);
										setProductIds([productLine.product_id]);
									}
								},
							},
						},
					]}
				/>
				{selectedProductLine && (
					<FieldSet
						containerClassName={styles.fieldSetContainer}
						fields={[
							{
								key: 'product_options',
								type: 'select',
								fieldProps: {
									className: styles.fieldInput,
									options: availableProducts,
									placeholder: 'Product Options',
									getOptionLabel: (productOption) =>
										productOption.label,
									getOptionValue: (productOption) =>
										productOption.value,
									onChange: (productOption) => {
										if (productOption.value == 'full-set') {
											setProductIds([1, 2]);
										} else {
											setProductIds([
												productOption.value,
											]);
										}
									},
								},
							},
							...(productLines.find(
								(productLine) =>
									productLine.product_line_has_coveralls ===
									'1',
							)
								? [{ value: 3, label: 'Coveralls' }]
								: []),
						]}
					/>
				)}
				{!!error && (
					<div className={styles.formErrorContainer}>
						<span className={fieldStyles.fieldError}>{error}</span>
					</div>
				)}
				<div className={modalStyles.modalActions}>
					<Button
						type="submit"
						variant="solid"
						loading={isSubmitting}>
						Create Quote
					</Button>
					<Button variant="plain" onClick={() => handleClose()}>
						Close
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default NewQuoteModal;
