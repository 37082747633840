import { useCallback, useEffect, useRef, useState } from 'react';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import { CancelToken } from 'axios';
import { getParamString } from '@utilities/getParamString';
import Screen from '@components/Screen/Screen';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';
import Collection from '@components/Collection/Collection';
import NotificationsListItem from '@components/NotificationsListItem/NotificationsListItem';
import styles from './Notifications.module.scss';

/**
 * Items per Page
 */

const perPage = 10;

/**
 * Sort Options
 */

const sortOptions = [
	// { label: 'Quote Update', value: 'quote_update', sort: 'DESC' },
	// { label: 'Order Name', value: 'order_name', sort: 'ASC' },
	// { label: 'Fire Department', value: 'fire_department_name', sort: 'ASC' },
	// { label: 'Price', value: 'price', sort: 'DESC' },
	// { label: 'Last Modified', value: 'order_modified_timestamp', sort: 'DESC' },
	// {
	// 	label: 'Date Submitted',
	// 	value: 'order_submitted_timestamp',
	// 	sort: 'DESC',
	// },
];

/**
 * Filter Options
 */

const filterOptions = [
	{ label: 'All', value: 'all' },
	{ label: 'News', value: 'news' },
	{ label: 'General', value: 'general' },
	{ label: 'Quote Update', value: 'quote-update' },
	{ label: 'Order Status', value: 'order-status' },
	{ label: 'New Message', value: 'new-message' },
	{ label: 'Other', value: 'custom' },
];

const Notifications = () => {
	const fetchRequestSource = useRef(null);
	const [notifications, setNotifications] = useState([]);
	const [page, setPage] = useState(0);
	const [isLastPage, setIsLastPage] = useState(false);
	const [sortField, setSortField] = useState('');
	const [sortDirection, setSortDirection] = useState('DESC');
	const [filterField, setFilterField] = useState('all');
	const [initialized, setInitialized] = useState(false);
	const [isFetching, setIsFetching] = useState(false);

	/**
	 * Fetch Notifications
	 */

	const fetchNotifications = useCallback(
		(
			paginationString = '',
			sortField = null,
			sortDirection = null,
			filterField = null,
		) => {
			// cancel any pending request
			fetchRequestSource.current?.cancel();
			// create new cancel token
			fetchRequestSource.current = CancelToken.source();

			setIsFetching(true);

			return http()
				.get(
					`${API_ROOT}/notifications?${paginationString}&sort=${sortField}&direction=${sortDirection}&filter=${filterField}`,
					{ cancelToken: fetchRequestSource.current?.token },
				)
				.then(({ data }) => data.notifications)
				.catch(() => {
					return [];
				})
				.finally(() => {
					setInitialized(true);
					setIsFetching(false);
				});
		},
		[],
	);

	/**
	 * Reset Page on Sort/Filter change
	 */

	useEffect(() => {
		setPage(0);
		setIsLastPage(false);
	}, [sortField, sortDirection]);

	/**
	 * Fetch Notifications on Sort/Filter/Page Change
	 */

	useEffect(() => {
		fetchNotifications(
			// pagination
			getParamString({ take: perPage, skip: page * perPage }),
			// sort
			sortField,
			sortDirection,
			// filter
			filterField,
		).then((notifications) => {
			setIsLastPage(notifications.length < perPage);
			setNotifications((current) => [
				...(page === 0 ? [] : current),
				...notifications,
			]);
		});
	}, [fetchNotifications, sortField, sortDirection, filterField, page]);

	/**
	 * Handle Dismiss Notification
	 */

	const handleNotificationDismiss = useCallback(async (id) => {
		return http()
			.post(`${API_ROOT}/notifications/${id}`, {
				notification: {
					user_notification_dismissed: true,
				},
			})
			.then(() => {
				setNotifications((notifications) =>
					notifications.filter(
						(notification) => notification.notification_id !== id,
					),
				);
			});
	}, []);

	return (
		<Screen
			headerContent={
				<>
					<WelcomeHeader />
				</>
			}
			loading={isFetching && !initialized}>
			<Collection
				title="Notifications"
				items={notifications}
				component={NotificationsListItem}
				page={page}
				onPageChange={setPage}
				isLastPage={isLastPage}
				sortField={sortField}
				sortDirection={sortDirection}
				sortOptions={sortOptions}
				onSortChange={([field, direction]) => {
					setSortField(field);
					setSortDirection(direction);
				}}
				filterField={filterField}
				filterOptions={filterOptions}
				filterPlaceholder="Type"
				onFilterChange={(field) => {
					setSortField('');
					setSortDirection('DESC');
					setPage(0);
					setFilterField(field);
				}}
				isFetching={isFetching}
				onRemove={handleNotificationDismiss}
				collectionCardClassName={styles.notificationsCard}
				collectionCardHeaderClassName={styles.notificationsCardHeader}
				collectionListContainerClassName={
					styles.notificationsListContainer
				}
			/>
		</Screen>
	);
};

export default Notifications;
