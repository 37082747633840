export const schema = {
	user_password: {
		initialValue: '',
		validate: (value, formData) => {
			if (value?.length < 8) {
				return 'Please enter a password with at least 8 characters';
			}

			if (value && value !== formData.confirm_password) {
				return 'Passwords do not match';
			}

			return null;
		},
	},
	confirm_password: {
		initialValue: '',
		validate: (value, formData) => {
			if (value?.length < 8) {
				return 'Please enter a password with at least 8 characters';
			}

			if (value && value !== formData.user_password) {
				return 'Passwords do not match';
			}

			return null;
		},
	},
};
