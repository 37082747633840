import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '@hooks/useQueryParams';
import { useDispatch } from 'react-redux';
import { setToken } from '@store/reducers/auth';
import { fetchUser } from '@store/thunks/fetch-user';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';
import AuthHeader from '@components/Auth/AuthHeader';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import TextField from '@components/TextField/TextField';
import Button from '@components/Button/Button';

const generalError =
	'Something went wrong while attempting to verify your account. Enter your email address below to receive a new verification link:';

function Activate({ location }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useQueryParams(location);
	const [error, setError] = useState(false);
	const [resendAddress, setResendAddress] = useState('');
	const [emailError, setEmailError] = useState(null);
	const [isResending, setIsResending] = useState(false);

	/**
	 * Activate Account
	 */

	useEffect(() => {
		if (error) {
			return;
		}

		const sentAt = new Date().getTime();

		Http()
			.post(`${API_ROOT}/auth/activate`, { code: params.code })
			.then(({ data }) => {
				const delay = Math.max(
					0,
					2000 - (new Date().getTime() - sentAt),
				);
				setTimeout(() => {
					dispatch(setToken(data.token));
					dispatch(fetchUser());
					history.push('/dashboard');
				}, delay);
			})
			.catch(() => {
				setError(generalError);
			});
	}, [error, params.code, dispatch, history]);

	/**
	 * Resend Verification Email
	 */

	const handleResend = useCallback(
		(e) => {
			e.preventDefault();

			if (!/\S+@\S+\.\S+/.test(resendAddress)) {
				setEmailError('Please enter a valid email address');
				return;
			}

			setIsResending(true);

			Http()
				.post(`${API_ROOT}/auth/resend-verification`, {
					email: resendAddress,
				})
				.then(() => {
					history.push(`/verify?email=${resendAddress}`);
				})
				.catch(() => {
					setError(generalError);
				})
				.finally(() => {
					setIsResending(false);
				});
		},
		[resendAddress, history],
	);

	return (
		<>
			<AuthHeader />
			<Hero heroTitle="ACCOUNT<br />VERIFICATION" />
			<section className="content-wrapper">
				<div className="content-container flex-center-content">
					{!error && (
						<div className="verification-container">
							<SpinnerIcon fill="#e87124" />
							<p className="form-text">
								Please wait while we verify your account...
							</p>
						</div>
					)}
					{error && (
						<form
							className="form-container verification-form"
							onSubmit={handleResend}>
							<p className="form-text">{error}</p>
							<TextField
								label="Email Address"
								type="email"
								value={resendAddress}
								onChange={(e) =>
									setResendAddress(e.target.value)
								}
								error={emailError}
							/>
							<Button
								variant="solid"
								type="submit"
								loading={isResending}>
								Resend Verification Email
							</Button>
						</form>
					)}
				</div>
			</section>
			<Footer className="auth-footer" />
		</>
	);
}

export default Activate;
