import { useEffect } from 'react';
import Card from '@components/Card/Card';
import Screen from '@components/Screen/Screen';
import WhyCreateAccountContent from '@components/WhyCreateAccountContent/WhyCreateAccountContent';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Banner from '@screens/Home/components/Banner/Banner';
import ContactForm from './components/ContactForm/ContactForm';
import CreateAccountCTA from './components/CreateAccountCTA/CreateAccountCTA';
import PublicFooter from '../../components/PublicFooter/PublicFooter';
import styles from './Home.module.scss';

const Home = () => {
	const history = useHistory();
	const user = useSelector((state) => state.auth.user);
	const content = useSelector((state) => state.staticData.content);
	const blurb = content['home-page']?.indexed['intro-blurb'];

	const headerTitle = useSelector(
        (state) => state.staticData.orderSettings['app_header_title']|| "",
    );

	useEffect(() => {
		if (user && window.location.hash !== '#contact-form') {
			history.push('/dashboard');
		}
	}, [user, history]);

	return (
		<Screen
			hideSidebar={true}
			hideFooter={true}
			headerContent={<Banner />}
			footerContent={
				<>
					<CreateAccountCTA />
					<ContactForm />
					<PublicFooter />
				</>
			}
			pageContentClassName={styles.pageContent}>
			<Helmet>
				<title>{headerTitle}</title>
			</Helmet>
			<Card className={styles.contentCard}>
				<div
					className={styles.introBlurb}
					dangerouslySetInnerHTML={{
						__html: blurb?.content_item_html,
					}}
				/>
				<WhyCreateAccountContent />
			</Card>
		</Screen>
	);
};

export default Home;
