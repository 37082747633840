export const isSubmitted = (order = {}) => {
	return [
		'Submitted',
		'Processing',
		'Approved',
		'In Production',
		'Shipped',
		'Cancelled',
		'Hold',
	].includes(order?.order_status);
};

export const isFinalized = (order = {}) => {
	return [
		'Processing',
		'Approved',
		'In Production',
		'Shipped',
		'Cancelled',
		'Hold',
	].includes(order?.order_status);
};

export const isApproved = (order = {}) => {
	return order?.quotes.filter(quote => !quote.sibling_quote_id).every((quote) => quote.quote_status === 'approved');
};

export const isQuoteApproved = (quote = {}) => {
	return quote?.quote_status === 'approved';
}
