import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import FixedHeader from '@components/FixedHeader';
import styles from './CreateAccountHeader.module.scss';
import { useSelector } from 'react-redux';
import { API_ROOT } from '@config';

const CreateAccountHeader = () => {
	const history = useHistory();
	const content = useSelector((state) => state.staticData.content);
	const logo = content?.logos?.indexed['logo-light'];

	return (
		<FixedHeader className={styles.createAccountHeader}>
			<div className={styles.createAccountHeaderContent}>
				<Link to="/">
					{!!logo && (
						<img
							className={styles.logo}
							src={`${API_ROOT}/uploaded/content/${logo?.content_item_image}`}
						/>
					)}
				</Link>
				<div className={styles.info}>
					<div>
						<h5>Create Account</h5>
						<h4>User Information</h4>
					</div>
					<button onClick={() => history.goBack()}>Back</button>
				</div>
			</div>
		</FixedHeader>
	);
};

export default CreateAccountHeader;
