import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import http from '@utilities/Http';
import axios, { CancelToken } from 'axios';
import { useClickOutside } from '@hooks/useClickOutside';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import fieldStyles from '../Field/Fields.module.scss';
import styles from './SuggestField.module.scss';

const SuggestField = ({
	label = null,
	value = '',
	name = '',
	labelField = 'label',
	labelExtractor = null,
	valueField = 'value',
	url = '',
	getResults = () => {},
	onChange = () => {},
	onSelect = () => {},
	onTextChange = () => {},
	className = null,
	inputClassName = null,
	menuClassName = null,
	activeClassName = null,
	readonly = false,
	disabled = false,
	compact = false,
	placeholder = 'Search',
	error = null,
	threshold = 2,
	adornment = null,
	adornmentClassName = null,
	paramName = 'suggest',
	noResultsMessage = 'No Results',
	additionalParams = '',
	clearOnEmpty = true,
}) => {
	const inputNode = useRef(null);
	const debounceTimeout = useRef(null);
	const fetchRequestSource = useRef(null);
	const [isActive, setIsActive] = useState(false);
	const [query, setQuery] = useState('');
	const [results, setResults] = useState([]);
	const [isFetching, setIsFetching] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	/**
	 * Handle Item Select
	 */

	const handleSelect = useCallback(
		(result) => {
			setQuery(
				typeof labelExtractor === 'function'
					? labelExtractor(result)
					: result[labelField],
			);

			onSelect(result);

			onChange({
				target: { name, value: result[valueField] },
			});
		},
		[name, valueField, labelExtractor, labelField, onChange, onSelect],
	);

	/**
	 * Handle Click Outside
	 */

	useClickOutside(inputNode.current, () => setIsActive(false));

	/**
	 * Fetch Results
	 */

	useEffect(() => {
		clearTimeout(debounceTimeout.current);

		// cancel any pending request
		fetchRequestSource.current?.cancel();
		// create new cancel token
		fetchRequestSource.current = CancelToken.source();

		if (query.length <= threshold && clearOnEmpty) {
			setResults([]);
			return;
		}

		if (!isMounted) {
			return;
		}

		debounceTimeout.current = setTimeout(() => {
			setIsFetching(true);
			http()
				.get(`${url}?${paramName}=${query}&${additionalParams}`, {
					cancelToken: fetchRequestSource.current?.token,
				})
				.then(({ data }) => {
					setResults(getResults(data));
				})
				.catch((err) => {
					if (axios.isCancel(err)) {
						// silent
					}
				})
				.finally(() => {
					setIsFetching(false);
				});
		}, 300);
	}, [
		url,
		getResults,
		query,
		paramName,
		isMounted,
		threshold,
		additionalParams,
	]);

	/**
	 * Clear Query when Value is Emptied
	 */

	useEffect(() => {
		if (!value) {
			setQuery('');
		}
	}, [value]);

	/**
	 * Is Mounted Check
	 */

	useEffect(() => {
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	return (
		<div
			className={classNames({
				[fieldStyles.fieldContainer]: true,
				[fieldStyles.readonly]: readonly,
				[fieldStyles.disabled]: disabled,
				[fieldStyles.compact]: compact,
				[styles.fieldContainer]: true,
				[styles.suggestFieldCompact]: compact,
				[styles.suggestFieldReadonly]: readonly,
				[styles.suggestFieldDisabled]: disabled,
				[styles.suggestFieldLoading]: isFetching,
				[styles.suggestFieldActive]: isActive,
				[className]: !!className,
				[activeClassName]: !!activeClassName && isActive,
			})}>
			{!!label && <label>{label}</label>}
			{!!adornment && (
				<div
					className={classNames({
						[fieldStyles.adornmentContainer]: true,
						[adornmentClassName]: !!adornmentClassName,
					})}>
					{adornment}
				</div>
			)}
			<div className={styles.positioningContainer}>
				<div
					className={classNames({
						[styles.loader]: true,
						[styles.loaderActive]: isFetching,
					})}>
					<SpinnerIcon fill="#e87124" />
				</div>
				<input
					type="text"
					ref={inputNode}
					value={query}
					onChange={(e) => {
						setQuery(e.target.value);
						onTextChange(e);
					}}
					tabIndex={readonly || disabled ? '-1' : '0'}
					className={classNames({
						[fieldStyles.fieldInput]: true,
						[styles.suggestFieldInput]: true,
						[styles.suggestFieldPlaceholder]: !value,
						[inputClassName]: !!inputClassName,
					})}
					disabled={disabled}
					readOnly={readonly}
					placeholder={placeholder}
					onFocus={() => setIsActive(true)}
					onBlur={() => setIsActive(false)}
				/>
				{!!error && (
					<span className={fieldStyles.fieldError}>{error}</span>
				)}
				<div
					className={classNames({
						[styles.suggestFieldMenuContainer]: true,
						[menuClassName]: !!menuClassName,
					})}>
					<ul className={styles.suggestFieldMenu}>
						{results.map((result, r) => (
							<li
								key={r}
								onMouseDown={() => handleSelect(result)}>
								{typeof labelExtractor === 'function'
									? labelExtractor(result)
									: result[labelField]}
							</li>
						))}
						{!results.length && (
							<li
								className={classNames({
									[styles.placeholderItem]: true,
								})}>
								{noResultsMessage}
							</li>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default SuggestField;
