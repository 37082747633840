import styles from './ActionButton.module.scss';

const ActionButton = ({info, disabled = false, handleClick = null}) => {
    return (<>
        <div className={styles.button} style={{backgroundColor: info.background, color: info.color, opacity: disabled ? '.5' : '1', cursor: disabled ? 'default' : 'pointer'}} onClick={() => {
            if (!disabled) {
                handleClick()
            }
        }}>
			<span style={{
                borderColor: info.background == 'white' ? '#222222' : info.background,
                color: info.background,
                fill: info.background == 'white' ? '#222222' : info.background
            }}>{info.icon}</span>
            <span>{info.label}</span>
        </div>
    </>);
};

export default ActionButton;
