import {useState} from 'react';
import Modal from '@components/Modal/Modal';
import classNames from "classnames";
import modalStyles from "@components/Modal/Modal.module.scss";
import Button from "@components/Button/Button";
import date from '@utilities/date';

const UpdateQuotesPromiseDateModal = ({
                                          active = false,
                                          onClose = () => {
                                          },
                                          promiseDate,
                                          quotesToUpdate = [],
                                          updateQuote = async () => {
                                          },
                                          className = null,
                                      }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    function updateQuotesPromiseDate() {
        setIsSubmitting(true);
        for (let quote of quotesToUpdate) {
            updateQuote({...quote, quote_promised_date: promiseDate});
        }
        setIsSubmitting(false);
        onClose();
    }

    return (
        <Modal
            active={active}
            className={classNames({[className]: !!className})}>
            <h4 className={modalStyles.modalTitle}>Update Promise Date on all items to {date(promiseDate + ' 12:00:00').format('MM/DD/YY')}?</h4>
            <div className={modalStyles.modalActions}>
                <Button
                    type="submit"
                    variant="solid"
                    loading={isSubmitting}
                    onClick={() => updateQuotesPromiseDate()}>
                    Yes
                </Button>
                <Button variant="plain" onClick={() => onClose()}>
                    No
                </Button>
            </div>
        </Modal>
    );
};

export default UpdateQuotesPromiseDateModal;
