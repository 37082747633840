import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from '@hooks/useForm';
import FieldRow from '@components/Field/FieldRow';
import TextField from '@components/TextField/TextField';
import FireDepartmentField from '@components/FireDepartmentField/FireDepartmentField';
import Button from '@components/Button/Button';
import fieldStyles from '@components/Field/Fields.module.scss';
import classNames from 'classnames';
import { schema } from './form';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '@store/reducers/auth';
import styles from './AccountForm.module.scss';
import DialogModal from '@components/DialogModal/DialogModal';
import { useHistory } from 'react-router-dom';
import SelectField from '@components/SelectField/SelectField';

const AccountForm = ({
	data = null,
	onSubmit = async () => {},
	isDistributor = null,
	hidePassword = false,
	hideCompany = false,
	hideFireDepartment = false,
	disableCompany = false,
	submitButtonClassName = null,
	submitButtonLabel = 'Submit',
	onIsDistributorChange = () => {},
	distributorContent = null,
}) => {
	const history = useHistory();
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();

	const [isMounted, setIsMounted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState(false);
	const [distributorModalActive, setDistributorModalActive] = useState(false);
	const countries = useSelector((state) => state.staticData.countries);
	const fireDepartmentCountries = useSelector((state) => state.staticData.fireDepartmentCountries);
	const isCSRUser = useSelector((state) =>
		state.auth.user?.permissions?.includes('csr_access'),
	);

	/**
	 * Form data, validation, errors, etc
	 */

	const { formData, formErrors, getValidation, handleChange, setFormData } =
		useForm(schema, { ...data, is_distributor: !!isDistributor });

	/**
	 * Form Submit
	 */

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			const formErrors = await getValidation();

			if (!Object.values(formErrors).every((error) => error === null)) {
				return;
			}

			try {
				setIsSubmitting(true);

				if (user) {
					delete formData.user_password;
				}

				const result = await onSubmit(formData);

				if (result) {
					dispatch(setUser({ ...result }));
				}

				setSubmitError(false);
			} catch (error) {
				console.log(error);
				setSubmitError(
					error.response?.data?.meta?.message ||
						'Something went wrong. Please try again later.',
				);
			} finally {
				isMounted && setIsSubmitting(false);
			}
		},
		[getValidation, formData, isMounted, onSubmit, dispatch, user],
	);

	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		};
	}, []);

	useEffect(() => {
		onIsDistributorChange(formData.is_distributor);
	}, [formData.is_distributor, onIsDistributorChange]);

	return (
		<form onSubmit={handleSubmit}>
			<FieldRow className={styles.fieldRow}>
				<TextField
					name="profile_first_name"
					label="First Name*"
					value={formData.profile_first_name}
					error={formErrors.profile_first_name}
					onChange={handleChange}
				/>
				<TextField
					label="Last Name*"
					name="profile_last_name"
					value={formData.profile_last_name}
					error={formErrors.profile_last_name}
					onChange={handleChange}
				/>
			</FieldRow>
			<FieldRow className={styles.fieldRow}>
				<TextField
					name="profile_title"
					label="Title*"
					value={formData.profile_title || ''}
					error={formErrors.profile_title}
					onChange={handleChange}
				/>
				<TextField
					label="Phone Number"
					name="profile_phone"
					value={formData.profile_phone || ''}
					error={formErrors.profile_phone}
					onChange={handleChange}
				/>
			</FieldRow>
			{!formData.manual_department && !hideFireDepartment && (
				<FieldRow className={styles.fieldRow}>
					<FireDepartmentField
						label={
							<div>
								Fire Department Zip / Name
								<span
									className={styles.noFindLink}
									onClick={() =>
										setFormData((formData) => ({
											...formData,
											manual_department:
												!formData.manual_department,
											fire_department_id: '',
										}))
									}>
									Can&apos;t Find Department?
								</span>
							</div>
						}
						name="fire_department_id"
						value={formData.fire_department_id}
						error={formErrors.fire_department_id}
						onChange={handleChange}
						defaultValues={[
							user?.fire_department_zip,
							user?.fire_department_name,
							user?.fire_department_country,
						]}
						countries={fireDepartmentCountries}
						isCSRUser={isCSRUser}
					/>
				</FieldRow>
			)}
			{formData.manual_department && (
				<>
					<FieldRow className={styles.fieldRow}>
						<TextField
							label={
								<div>
									Fire Department Name*
									<span
										className={styles.noFindLink}
										onClick={() =>
											setFormData((formData) => ({
												...formData,
												manual_department:
													!formData.manual_department,
												fire_department_id: '',
											}))
										}>
										Can&apos;t Find Department?
									</span>
								</div>
							}
							name="fire_department_name"
							value={formData.fire_department_name || ''}
							error={formErrors.fire_department_name}
							onChange={handleChange}
						/>
					</FieldRow>
					<FieldRow className={styles.fieldRow}>
						{' '}
						<TextField
							label="Fire Department Address*"
							name="fire_department_address"
							value={formData.fire_department_address || ''}
							error={formErrors.fire_department_address}
							onChange={handleChange}
						/>
						<TextField
							label="Fire Department City*"
							name="fire_department_city"
							value={formData.fire_department_city || ''}
							error={formErrors.fire_department_city}
							onChange={handleChange}
						/>{' '}
					</FieldRow>
					<FieldRow className={styles.fieldRow}>
						<TextField
							label="Fire Department State / Province / Region*"
							name="fire_department_state"
							value={formData.fire_department_state || ''}
							error={formErrors.fire_department_state}
							onChange={handleChange}
						/>
						<TextField
							label="Fire Department Zip / Postal Code*"
							name="fire_department_zip"
							value={formData.fire_department_zip || ''}
							error={formErrors.fire_department_zip}
							onChange={handleChange}
						/>{' '}
					</FieldRow>
					<FieldRow className={styles.fieldRow}>
						<TextField
							label="Fire Department County*"
							name="fire_department_county"
							value={formData.fire_department_county || ''}
							error={formErrors.fire_department_county}
							onChange={handleChange}
						/>
						<SelectField
							compact
							placeholder="Fire Department Country*"
							value={formData.fire_department_country || ''}
							name="fire_department_country"
							onChange={handleChange}
							options={countries}
							labelField="country_name"
							valueField="country_code"
							className={classNames({ [styles.countryField]: true })}
							inputClassName={classNames({[styles.fullHeight]: true})}
							menuClassName={classNames({[styles.selectMenu]: true})}
						/>
					</FieldRow>
				</>
			)}
			{formData.is_distributor && !hideCompany && (
				<FieldRow className={styles.fieldRow}>
					<TextField
						label="Company*"
						name="organization_name"
						value={formData.organization_name || ''}
						error={formErrors.organization_name}
						onChange={handleChange}
						disabled={disableCompany}
					/>
				</FieldRow>
			)}
			<FieldRow className={styles.fieldRow}>
				<TextField
					label="Email*"
					name="user_email"
					value={formData.user_email}
					error={formErrors.user_email}
					onChange={handleChange}
				/>
			</FieldRow>
			{!hidePassword && (
				<FieldRow>
					<TextField
						type="password"
						label="Password*"
						name="user_password"
						value={formData.user_password}
						error={formErrors.user_password}
						onChange={handleChange}
					/>
				</FieldRow>
			)}
			<div className={styles.formActions}>
				{!!submitError && (
					<p
						className={classNames(
							fieldStyles.fieldError,
							styles.submitError,
						)}>
						{submitError}
					</p>
				)}
				<Button
					variant="solid"
					type="submit"
					loading={isSubmitting}
					className={classNames({
						[submitButtonClassName]: !!submitButtonClassName,
					})}>
					{submitButtonLabel}
				</Button>
				{isDistributor === null && (
					<span
						className={styles.distributorLink}
						onClick={() => setDistributorModalActive(true)}>
						{formData.is_distributor
							? 'Not a Distributor?'
							: 'I Am a Distributor'}
					</span>
				)}
			</div>
			<DialogModal
				active={!!distributorModalActive}
				title="Distributor Sign Up"
				onConfirm={() => setDistributorModalActive(false)}
				onClose={() => setDistributorModalActive(false)}
				message={
					<>
						<span dangerouslySetInnerHTML={{__html: distributorContent?.content_item_html}}></span>
						<a
							style={{
								color: '#e87124',
								lineHeight: '1.6em',
								cursor: 'pointer',
							}}
							onClick={(e) => {
								e.preventDefault();
								history.push(distributorContent?.content_item_url);
							}}>
							{distributorContent?.content_item_url_text}
						</a>
					</>
				}
				labels={['Close']}
			/>
		</form>
	);
};

export default AccountForm;
