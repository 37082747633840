import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 361.77 361.77"
			{...props}>
			<path d="M323.885 43.77h-27.5V25c0-13.807-11.193-25-25-25h-1c-13.807 0-25 11.193-25 25v18.77h-129V25c0-13.807-11.193-25-25-25h-1c-13.807 0-25 11.193-25 25v18.77h-27.5c-13.807 0-25 11.193-25 25v268c0 13.809 11.193 25 25 25h286c13.807 0 25-11.191 25-25v-268c0-13.807-11.194-25-25-25zm-17 278.5h-252v-203h252v203z" />
			<path d="M89.136 211.134h43.498a4 4 0 004-4v-43.498a4 4 0 00-4-4H89.136a4 4 0 00-4 4v43.498a4 4 0 004 4zM159.136 211.134h43.498a4 4 0 004-4v-43.498a4 4 0 00-4-4h-43.498a4 4 0 00-4 4v43.498a4 4 0 004 4zM229.136 211.134h43.498a4 4 0 004-4v-43.498a4 4 0 00-4-4h-43.498a4 4 0 00-4 4v43.498a4 4 0 004 4zM89.136 281.134h43.498a4 4 0 004-4v-43.498a4 4 0 00-4-4H89.136a4 4 0 00-4 4v43.498a4 4 0 004 4zM159.136 281.134h43.498a4 4 0 004-4v-43.498a4 4 0 00-4-4h-43.498a4 4 0 00-4 4v43.498a4 4 0 004 4zM229.136 281.134h43.498a4 4 0 004-4v-43.498a4 4 0 00-4-4h-43.498a4 4 0 00-4 4v43.498a4 4 0 004 4z" />
		</svg>
	);
}

export default SvgComponent;
