import { useCallback } from 'react';
import classNames from "classnames";
import date from '@utilities/date';
import styles from './OrderStatusNotification.module.scss';

const OrderStatusNotification = ({ notification, previewSize = 94, small = false }) => {
	const getStatusText = useCallback((status) => {
		switch (status) {
			case 'Not Submitted':
				return 'is Not Submitted';
			case 'Submitted':
				return 'has been Submitted';
			case 'Approved':
				return 'is Approved';
			case 'Cancelled':
				return 'has been Cancelled';
			case 'Requires Edits':
				return 'Requires Edits';
			case 'In Production':
				return 'is In Production';
			case 'Shipped':
				return 'has been Shipped';
			case 'Rejected':
				return 'is Rejected';
			case 'Hold':
				return 'is on Hold';
		}
	}, []);

	return (
		<div className={classNames({
			[styles.orderStatusNotification]: true,
			[styles.small]: small
		})}>
			<div
				className={classNames({
					[styles.orderStatusNotificationPreview]: true,
					[styles.small]: small
				})}
				style={{
					width: previewSize,
					minWidth: previewSize,
					height: previewSize
				}}
				dangerouslySetInnerHTML={{ __html: notification.quote_preview }}
			/>
			<div>
				<p className={styles.orderStatusNotificationTitle}>
					Order <b>#{notification.order_id}</b>{' '}
					{getStatusText(
						notification.notification_meta?.order_status,
					)}
				</p>
				<span className={styles.orderStatusNotificationSubtitle}>
					Status changed on{' '}
					{date(notification.notification_timestamp).format(
						'MM/DD/YY',
					)}{' '}
					at{' '}
					{date(notification.notification_timestamp).format('h:mm A')}
					{notification.notification_meta['notification_from_user'] && (
						<> by {notification.notification_meta['notification_from_user']}</>
					)}
				</span>
				{!!notification.notification_meta?.customer_alert && (
					<p className={styles.orderStatusNotificationAlert}>
						{notification.notification_meta.customer_alert}
					</p>
				)}
			</div>
		</div>
	);
};

export default OrderStatusNotification;
