import Button from '@components/Button/Button';
import { API_ROOT } from '@config';
import React from 'react';
import styles from './Product.module.scss';
// import ResourcesIcon from '../../Icons/ResourcesIcon';

import ResourcesIcon from '../../../../components/Icons/ResourcesIcon';

const Product = ({ productLine = {}, onCustomize = () => {} }) => {
	return (
		<div className={styles.container}>
			<div
				className={styles.image}
				style={{
					backgroundImage: `url("${API_ROOT}${productLine.product_line_image_file_url}")`,
				}}
			/>
			<div className={styles.info}>
				<h6>{productLine.product_line_description}</h6>
				<p>{productLine.product_line_copy}</p>
			</div>
			<div className={styles.actions}>
				<Button
					variant="solid"
					onClick={onCustomize}
					className={
						productLine.product_line_marketing_link
							? styles.customizeButton
							: null
					}>
					Start Customizing
				</Button>
				{productLine.product_line_marketing_link && (
					<Button
						variant="outlined"
						className={styles.viewButton}
						onClick={() =>
							window.open(productLine.product_line_marketing_link)
						}>
						View Product
					</Button>
				)}
			</div>
		</div>
	);
};

export default Product;
