import { useCallback, useEffect, useState } from 'react';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import fieldStyles from '@components/Field/Fields.module.scss';
import styles from './ExportModal.module.scss';
import classNames from 'classnames';

const ExportModal = ({
	active = false,
	onClose = () => {},
	onPdf = async () => {},
	onWord = async () => {},
	title = 'Export Quote',
}) => {
	const [exporting, setIsExporting] = useState(false);
	const [error, setError] = useState('');

	const handleExport = useCallback(

		async (type) => {
			try {
				switch (type) {
					case 'pdf':
						setIsExporting('pdf');
						await onPdf();
						break;
					case 'word':
						setIsExporting('word');
						await onWord();
						break;
				}

				onClose();
			} catch (error) {
				const response = error.response;
				const data = response && (await response.data.text());
				const message = data && JSON.parse(data).meta.message;
				setError(message);
				setIsExporting(false);
			} finally {
				setIsExporting(false);
			}
		},
		[onPdf, onWord, onClose],
	);


	useEffect(() => {
		setError('');
	}, [active]);

	return (
		<Modal active={active} onClose={() => onClose()}>
			<h4 className={modalStyles.modalTitle}>{title}</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<p>Exporting PDF</p>
			</div>
			<div className={modalStyles.modalActions}>
				<Button
					variant="solid"
					className={styles.button}
					onClick={() => handleExport('pdf')}
					loading={exporting}
					disabled={!!error || exporting}>
					PDF
				</Button>
				{error && (
					<span
						className={classNames(
							fieldStyles.fieldError,
							styles.error,
						)}>
						{error}
					</span>
				)}
			</div>
		</Modal>
	);
};

export default ExportModal;
