import { useCallback, useEffect, useState } from 'react';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import styles from './DiscountModal.module.scss';
import classNames from 'classnames';
import TextField from '@components/TextField/TextField';
import { formatCurrency } from '@utilities/currency';

const DiscountModal = ({
	active = false,
	price = 0,
	discount: discountAmount = 0,
	onClose = () => {},
	onSubmit = async () => {},
	className = null,
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	// discount amount
	const [discount, setDiscount] = useState(discountAmount);

	// discounted price
	const [discountPrice, setDiscountPrice] = useState(
		price - price * (discountAmount / 100),
	);

	/**
	 * Handle Percentage Input Change
	 */

	const handlePercentageChange = useCallback(
		(value) => {
			if (isNaN(value) || value > 100 || value < 0) {
				setDiscount(0);
				setDiscountPrice(price);
				return;
			}

			setDiscount(value);
			setDiscountPrice(price - price * (value / 100));
		},
		[price],
	);

	/**
	 * Handle Modal Close (reset)
	 */

	const handleClose = useCallback(() => {
		setDiscount(discountAmount);
		setDiscountPrice(price - price * (discountAmount / 100));
		onClose();
	}, [onClose, discountAmount, price]);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(async () => {
		setIsSubmitting(true);
		await onSubmit(discount);
		setIsSubmitting(false);
		onClose();
	}, [discount, onSubmit, onClose]);

	/**
	 * Sync values from parent
	 */

	useEffect(() => {
		setDiscount(discountAmount);
		setDiscountPrice(price - price * (discountAmount / 100));
	}, [discountAmount, price]);

	return (
		<Modal
			active={active}
			onClose={() => handleClose()}
			className={classNames({ [className]: !!className })}>
			<h4 className={modalStyles.modalTitle}>Apply Discount</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				<p>Apply a percentage discount to this item:</p>
				<div className={styles.fields}>
					<TextField
						label="Discount"
						value={discount}
						onChange={(e) => handlePercentageChange(e.target.value)}
						className={styles.discountPercentage}
						onFocus={() => setDiscount('')}
					/>
					<TextField
						label="Original Price"
						value={formatCurrency(price)}
						disabled={true}
						className={styles.totalPrice}
					/>
					<TextField
						label="Discount Price"
						value={formatCurrency(discountPrice)}
						disabled={true}
						className={styles.discountPrice}
					/>
				</div>
			</div>
			<div className={modalStyles.modalActions}>
				<Button variant="outlined" onClick={handleClose}>
					Cancel
				</Button>
				<Button
					variant="solid"
					onClick={handleSubmit}
					loading={isSubmitting}>
					Apply
				</Button>
			</div>
		</Modal>
	);
};

export default DiscountModal;
