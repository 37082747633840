import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { fetchUser } from '@store/thunks/fetch-user';

const initialState = {
	summary: [],
};

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchUser.fulfilled]: (state, action) => {
			return {
				...state,
				summary: action.payload.notifications,
			};
		},
		[fetchUser.rejected]: () => {
			return { ...initialState };
		},
	},
});

export const persistConfig = {
	key: 'notifications',
	storage,
};

export default persistReducer(persistConfig, notificationsSlice.reducer);
