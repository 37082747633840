import React, { useCallback, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import date from '@utilities/date';
import Menu from '@components/Menu/Menu';
import TextField from '@components/TextField/TextField';
import OrderItemPreview from '@screens/Order/components/OrderItemPreview/OrderItemPreview';
import ArrowCircleIcon from '@components/Icons/ArrowCircleIcon';
import CountIcon from '@components/Icons/CountIcon';
import AddQuoteToOrderModal from '@components/AddQuoteToOrderModal/AddQuoteToOrderModal';
import collectionStyles from '@components/Collection/Collection.module.scss';
import styles from './OrdersListItem.module.scss';
import { getOrderStatusText } from '@utilities/methods';
import { useSelector } from 'react-redux';
import { API_ROOT } from '@config';
import ExportModal from '@components/ExportModal/ExportModal';
import { download, getOrderQuoteExportUrl } from '@utilities/export';
import OrdersListItemMetadata from './components/OrdersListItemMetadata/OrdersListItemMetadata';

const OrdersListItem = ({ item: order, onRemove = () => {}, onCancelOrder = () => {} }) => {
	const history = useHistory();
	const user = useSelector((state) => state.auth.user);
	const [showAddQuoteModal, setShowAddQuoteModal] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);
	const demoModeActive = useSelector((state) => state.demoMode.active);

	/**
	 * Handle Remove
	 */

	const handleRemove = useCallback(() => {}, []);

	/**
	 * Menu Actions
	 */

	const menuActions = useMemo(() => {
		const actions = [];

		const orderIsComplete = [
			'Submitted',
			'Approved',
			'In Production',
			'Shipped',
			'Cancelled',
		].includes(order.order_status);

		if (!orderIsComplete) {
			actions.push({
				label: 'Add Quote to Order',
				action: () => {
					setShowAddQuoteModal(true);
				},
			});
		}

		// if(order.order_status === 'Requires Edits') {
		// 	actions.push({
		// 		label: 'Cancel Order',
		// 		action: () => onCancelOrder(order),
		// 	});
		// }

		if(order.order_status === 'Not Submitted') {
			actions.push({
				label: 'Delete Order',
				action: () => onRemove(order),
			});
		}

		if (orderIsComplete) {
			actions.push({
				label: 'Export Quotes',
				action: () => {
					setShowExportModal(true);
				},
			});
		}

		return actions;
	}, [onRemove, order]);

	/**
	 * Handle Export
	 */

	const handleExport = useCallback(
		async (type = 'pdf') => {
			const url = await getOrderQuoteExportUrl(order.order_id, type, demoModeActive);
			download(url, `${order.order_id}.zip`);
		},
		[order, demoModeActive],
	);

	return (
		<div className={collectionStyles.collectionItem}>
			<Link
				to={`/orders/${order.order_id}`}
				className={collectionStyles.collectionItemImageContainer}>
				{!!order.quotes.length &&
					(order.quotes?.[0]?.product_line_id ? (
						<OrderItemPreview quote={order.quotes?.[0]} />
					) : (
						<div
							className={styles.image}
							style={{
								backgroundImage: `url("${API_ROOT}${order.quotes?.[0].product_image_file_url}")`,
							}}
						/>
					))}
				<label>
					<CountIcon />
					{`+${order.quotes.length} Item${
						order.quotes.length === 0 || order.quotes.length > 1
							? 's'
							: ''
					}`}
				</label>
			</Link>
			<div
				className={classNames({
					[collectionStyles.collectionItemFieldPair]: true,
					[styles.numberPair]: true,
				})}>
				<TextField
					readonly
					label="FireWriter Order #"
					value={order.order_id}
				/>
				{!!order.order_fire_dex_number && (
					<TextField
						readonly
						label="Fire-Dex Order #"
						value={order.order_fire_dex_number}
					/>
				)}
				<TextField
					readonly
					label="Status"
					value={order.order_status}
				/>
				{!!order.order_po_number && (
					<TextField
						readonly
						label="PO #"
						value={order.order_po_number}
					/>
				)}
			</div>
			<div
				className={classNames({
					[collectionStyles.collectionItemFieldPair]: true,
					[styles.namePair]: true,
				})}>
				<TextField
					readonly
					label="Order Name"
					value={order.order_name}
				/>
				{!!order.order_original_submitted_timestamp && (
					<TextField
						readonly
						label="Original Submitted Date"
						value={date(order.order_original_submitted_timestamp).format(
							'M/DD/YY',
						)}
					/>
				)}
				{!!order.fire_department_name && (
					<TextField
						readonly
						label="Fire Department"
						value={order.fire_department_name || ''}
					/>
				)}
			</div>
			<div
				className={classNames({
					[collectionStyles.collectionItemFieldPair]: true,
					[styles.statusPair]: true,
				})}>
				{order.user_id !== user.user_id && (
					<TextField
						readonly
						label="Created By"
						value={order.user_display_name}
					/>
				)}
				{getOrderStatusText(order.order_status) !== 'Submitted' &&
					getOrderStatusText(order.order_status) !== 'Approved' &&
					!!order.order_modified_timestamp && (
						<TextField
							readonly
							label="Last Modified"
							value={date(order.order_modified_timestamp).format(
								'M/D/YY',
							)}
						/>
					)}
				{!!order.order_submitted_timestamp && (
					<TextField
						readonly
						label="Submitted Date"
						value={date(order.order_submitted_timestamp).format(
							'M/DD/YY',
						)}
					/>
				)}
				{order.order_alert_code && (
					<TextField
						readonly
						label="Order Alert Code"
						value={order.order_alert_code}
					/>
				)}
			</div>
			<div className={collectionStyles.collectionItemActionsContainer}>
				<Link to={`/orders/${order.order_id}`}>
					<ArrowCircleIcon />
				</Link>
			</div>
			<div className={collectionStyles.productsListContainer}>
				<strong>Products:</strong>{' '}
				{order.quotes
					.filter((quote) => !quote.sibling_quote_id)
					.map((quote) => {
						return (
							(quote.product_line_id
								? quote.product_line_description + ' '
								: '') +
							(quote.siblingQuote &&
							quote.product_configurable === '1'
								? 'Full Set'
								: quote.product_title)
						);
					})
					.join(', ')}
			</div>
			<OrdersListItemMetadata
				order={order}
				className={styles.ordersListItemMetadata}
				onClick={() => {
					history.push(`/orders/${order.order_id}`);
				}}
			/>
			<Menu actions={menuActions} className={styles.orderMenu} />
			<AddQuoteToOrderModal
				active={showAddQuoteModal}
				onClose={(quote) => {
					setShowAddQuoteModal(false);

					if (quote?.order_id) {
						history.push(`/orders/${quote.order_id}`);
					}
				}}
				orderId={order.order_id}
			/>
			<ExportModal
				active={showExportModal}
				onClose={setShowExportModal}
				onPdf={() => handleExport('pdf')}
				onWord={() => handleExport('word')}
				title="Export Quotes"
			/>
		</div>
	);
};

export default OrdersListItem;
