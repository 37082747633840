import React, {useCallback, useMemo} from 'react'
import classNames from "classnames/bind";
import trashcan from "@assets/images/trashcan.svg";
import styles from './LetteringItem.module.scss'

const LetteringItem = ({item = null, onRemove = ()=>{}, onSelect = ()=>{}, placements = [], isEditable = true, lettersArray = []}) => {

    const getLetteringText = useCallback(
        () => {
            if (!item) {
                return '';
            }
            if(lettersArray.length > 1) {
                return lettersArray.map((letteringType) => {
                    switch (letteringType) {
                        case 'name': {
                            return 'Name';
                        }
                        case 'rank': {
                            return 'Rank';
                        }
                        case 'ffid': {
                            return 'ID Number';
                        }
                        default:
                            return letteringType;
                    }
                }).join(', ');
            }
            switch (item.quote_product_option_value_lettering_type) {
                case 'custom': {
                    return (
                        item.quote_product_option_value_lettering_custom_text ||
                        ''
                    );
                }
                case 'name': {
                    return 'Name';
                }
                case 'rank': {
                    return 'Rank';
                }
                case 'ffid': {
                    return 'ID Number';
                }
                default:
                    return '';
            }
        },
    [item]);

    const getLetteringIndex = useCallback(
        () => {
            if (!item) {
                return '';
            }
            const placement = placements?.find((placement) => placement.lettering_placement_id === item.lettering_placement_id);
            return placement?.lettering_placement_index;
        },
    [item]);

    const getTitle = useMemo(() => {
        if (!item) {
            return '';
        }
        const letteringType = item.quote_product_option_value_lettering_type || item.product_option_value_lettering_type;
        let titleString = 'Position '+getLetteringIndex()+': '+((item.product_option_value_display_option_code != '' && typeof item.product_option_value_display_option_code !== 'undefined') ?
        '(' + item.product_option_value_display_option_code + ') ' + item.product_option_value_description + ', ' + item.lettering_style_description +
        ', ' + getLetteringText()
        : '')
       
        return titleString;
    }, [item]);

    return (
        <>
        {item &&
            <div className={classNames(
                styles.container
            )}>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        onSelect(item)
                    }}
                    className={classNames(
                        'option-value',
                        'configured',
                    )}>
                    <div className="configured-option-value-info-container">
                        <div className="configured-option-value-info">
                            Position {getLetteringIndex()}: {(item.product_option_value_display_option_code != '' && typeof item.product_option_value_display_option_code !== 'undefined') ?
                            '(' + item.product_option_value_display_option_code + ') ' + item.product_option_value_description + ', ' + item.lettering_style_description +
                            ', ' + getLetteringText()
                            : ''
                        }
                        </div>
                    </div>
                </div>
                {isEditable && (
                    <img src={trashcan}
                         className={classNames(styles.deleteAction)}
                         onClick={(e) => {
                             e.stopPropagation();
                             onRemove(item.lettering_placement_id)
                         }}
                    />
                )}
            </div>
        }
        </>
    );
};

export default LetteringItem;