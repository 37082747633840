import * as React from 'react';

const SvgComponent = ({attributes,isLocked}) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...attributes}>
		{isLocked &&
			<path d="M12.6667 6.66668H13.3333C13.7015 6.66668 14 6.96514 14 7.33334V14C14 14.3682 13.7015 14.6667 13.3333 14.6667H2.66667C2.29848 14.6667 2 14.3682 2 14V7.33334C2 6.96514 2.29848 6.66668 2.66667 6.66668H3.33333V6.00001C3.33333 3.42268 5.42267 1.33334 8 1.33334C10.5773 1.33334 12.6667 3.42268 12.6667 6.00001V6.66668ZM3.33333 8.00001V13.3333H12.6667V8.00001H3.33333ZM7.33333 9.33334H8.66667V12H7.33333V9.33334ZM11.3333 6.66668V6.00001C11.3333 4.15906 9.84093 2.66668 8 2.66668C6.15905 2.66668 4.66667 4.15906 4.66667 6.00001V6.66668H11.3333Z" fill="#E87023"/>
		}
		{!isLocked &&
			<path d="M4.66667 6.66666H13.3333C13.7015 6.66666 14 6.96513 14 7.33333V14C14 14.3682 13.7015 14.6667 13.3333 14.6667H2.66667C2.29848 14.6667 2 14.3682 2 14V7.33333C2 6.96513 2.29848 6.66666 2.66667 6.66666H3.33333V5.99999C3.33333 3.42267 5.42267 1.33333 8 1.33333C9.827 1.33333 11.4087 2.3832 12.1749 3.91254L10.9821 4.50895C10.4348 3.41657 9.305 2.66666 8 2.66666C6.15905 2.66666 4.66667 4.15905 4.66667 5.99999V6.66666ZM3.33333 7.99999V13.3333H12.6667V7.99999H3.33333ZM6.66667 9.99999H9.33333V11.3333H6.66667V9.99999Z" fill="#E87023"/>
		}
	</svg>
);

export default SvgComponent;
