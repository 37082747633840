import { useCallback, useMemo, useRef, useState } from 'react';
import AccountForm from '@components/AccountForm/AccountForm';
import Card from '@components/Card/Card';
import Screen from '@components/Screen/Screen';
import Button from '@components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import MyAccountHeader from './components/MyAccountHeader/MyAccountHeader';
import ProfileForm from '@components/ProfileForm/ProfileForm';
import PasswordForm from '@components/PasswordForm/PasswordForm';
import OrganizationUsersForm from '@components/OrganizationUsersForm/OrganizationUsersForm';
import styles from './MyAccount.module.scss';
import { API_ROOT } from '@config';
import Http from '@utilities/Http';
import { setUser } from '@store/reducers/auth';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import CloseIcon from '@components/Icons/CloseIcon';
import InviteUserModal from '@components/InviteUserModal/InviteUserModal';
import OrganizationAvatarForm from '@components/OrganizationLogoForm/OrganizationLogoForm';
import OrganizationShippingForm from '@components/OrganizationShippingForm/OrganizationShippingForm';
import TextField from '@components/TextField/TextField';
import { Link } from 'react-router-dom';
import SelectField from '@components/SelectField/SelectField';
import Avatar from "@components/Avatar/Avatar";
import DistributorAdminListCard from "@components/DistribturorAdminListCard/DistributorAdminListCard";

const userStatuses = [
	{
		user_status_id: '1',
		user_status_title: 'Active',
	},
	{
		user_status_id: '2',
		user_status_title: 'Inactive',
	},
];

const userSortOptions = [
	{
		label: 'Name (A-Z)',
		value: 'name_asc',
	},
	{
		label: 'Name (Z-A)',
		value: 'name_desc',
	},
]

const MyAccount = () => {
	const dispatch = useDispatch();
	const [inviteUserModalActive, setInviteUserModalActive] = useState(false);
	const [usersRefreshTimestamp, setUsersRefreshTimestamp] = useState(null);

	const [userSearchQuery, setUserSearchQuery] = useState('');
	const [isFetchingUsers, setIsFetchingUsers] = useState(false);

	const [userStatusFilter,setUserStatusFilter] = useState(userStatuses[0].user_status_id);
	const [userSort,setUserSort] = useState(null);

	const accountNode = useRef(null);
	const profileNode = useRef(null);
	const passwordNode = useRef(null);
	const orgUsersNode = useRef(null);
	const orgAvatarNode = useRef(null);
	const shippingNode = useRef(null);
	const distributorAdminNode = useRef(null);

	const user = useSelector((state) => state.auth.user);

	const isCSRUser = user?.permissions?.includes('csr_access');

	const isDistributor = !!user?.roles?.find(
		(role) =>
			role.role_token === 'distributor-user' ||
			role.role_token === 'distributor-admin',
	);

	const hasLegacyQuotes = user.legacy_quotes_count > 0;

	const content = useSelector((state) => state.staticData.content);
	const bannerText =
		content['my-account-page']?.indexed['banner'][
			'content_item_description'
		];
	const bannerImage =
		content['my-account-page']?.indexed['banner']['content_item_image'];

	/**
	 * Handle Account Form Submit
	 */

	const handleAccountSubmit = useCallback(
		async (formData) => {
			const { data } = await Http().post(
				`${API_ROOT}/users/${user.user_id}`,
				{
					user: formData,
				},
			);

			dispatch(setUser(data.user));

			return data.user;
		},
		[user, dispatch],
	);

	const userSearchAdornment = useMemo(() => {
		if (isFetchingUsers) {
			return <SpinnerIcon className={styles.userSearchLoader} />;
		}

		return userSearchQuery ? (
			<CloseIcon onClick={() => setUserSearchQuery('')} />
		) : null;
	}, [isFetchingUsers, userSearchQuery]);

	console.log(user)

	return (
		<Screen
			headerContent={
				<MyAccountHeader>
					<nav
						className={styles.nav}
						style={{
							backgroundImage: bannerImage
								? `url("${API_ROOT}/uploaded/content/${bannerImage}")`
								: null,
						}}>
						{!!bannerText && <h4>{bannerText}</h4>}
						<div>
							<Button
								onClick={() => {
									accountNode.current?.scrollIntoView({
										behavior: 'smooth',
									});
								}}
								variant="plain">
								Contact Information
							</Button>
							<Button
								onClick={() => {
									profileNode.current?.scrollIntoView({
										behavior: 'smooth',
									});
								}}
								variant="plain">
								Profile
							</Button>
							<Button
								onClick={() => {
									passwordNode.current?.scrollIntoView({
										behavior: 'smooth',
									});
								}}
								variant="plain">
								Change Password
							</Button>
							{user?.permissions?.includes(
								'manage_organization',
							) &&
								!isCSRUser && (
									<>
										<Button
											onClick={() => {
												orgUsersNode.current?.scrollIntoView(
													{
														behavior: 'smooth',
													},
												);
											}}
											variant="plain">
											Company Users
										</Button>
										<Button
											onClick={() => {
												orgAvatarNode.current?.scrollIntoView(
													{
														behavior: 'smooth',
													},
												);
											}}
											variant="plain">
											Company Logo
										</Button>
									</>
								)}
							{user?.permissions?.includes('manage_orders') && (
								<>
								<Button
									onClick={() => {
										shippingNode.current?.scrollIntoView({
											behavior: 'smooth',
										});
									}}
									variant="plain">
									Shipping Details
								</Button>
									<Button
										onClick={() => {
											distributorAdminNode.current?.scrollIntoView(
												{
													behavior: 'smooth',
												},
											);
										}}
										variant="plain">
										Distributor Admin
									</Button>
								</>
							)}
							{hasLegacyQuotes && (
								<Link to="/legacy-quotes">
								<Button
									variant="plain">
									Legacy Quotes
								</Button>
								</Link>
							)}
						</div>
					</nav>
				</MyAccountHeader>
			}
			hideNavigation={true}>
			<div ref={accountNode}>
				<Card title="Contact Information" className={styles.formCard}>
					<AccountForm
						data={user}
						onSubmit={handleAccountSubmit}
						isDistributor={isDistributor}
						hidePassword={true}
						disableCompany={true}
						submitButtonClassName={styles.accountSubmitButton}
						submitButtonLabel="Save Changes"
					/>
				</Card>
			</div>
			<div className={styles.row}>
				<div ref={profileNode}>
					<Card
						title="Profile"
						className={styles.formCard}
						contentClassName={styles.cardContent}>
						<ProfileForm onSubmit={handleAccountSubmit} />
					</Card>
				</div>
				<div ref={passwordNode}>
					<Card title="Change Password" className={styles.formCard}>
						<PasswordForm />
					</Card>
				</div>
			</div>
			{user?.permissions?.includes('manage_organization') && !isCSRUser && (
				<>
					<div className={styles.row}>
						<div ref={orgUsersNode}>
							<Card
								title="Company Users"
								className={styles.formCard}
								headerClassName={styles.usersCardHeader}
								headerContent={
									<div
										className={
											styles.usersCardHeaderContent
										}>
										<TextField
										style={{zIndex: 1000}}
											value={userSearchQuery}
											placeholder="Search Users"
											onChange={(e) => {
												setUserSearchQuery(
													e.target.value,
												);
											}}
											className={
												styles.searchInputContainer
											}
											inputClassName={styles.searchInput}
											adornment={userSearchAdornment}
											adornmentClassName={
												styles.searchInputClearButton
											}
										/>
										<SelectField
											value={userStatusFilter}
											options={userStatuses}
											labelField="user_status_title"
											valueField="user_status_id"
											className={styles.userFilterSelect}
											placeholder={'Filter by Status'}
											onChange={(e) => {
												setUserStatusFilter(e.target.value);
											}}
										/>
										<SelectField
											value={userSort}
											options={userSortOptions}
											labelField="label"
											valueField="value"
											className={styles.userFilterSelect}
											placeholder={'Sort Users'}
											onChange={(e) => {
												setUserSort(e.target.value);
											}}
										/>
										<Button
											variant="outlined"
											onClick={() =>
												setInviteUserModalActive(true)
											}
											className={styles.inviteUserButton}>
											+ Invite User
										</Button>
									</div>
								}>
								<OrganizationUsersForm
									refresh={usersRefreshTimestamp}
									query={userSearchQuery}
									filter={userStatusFilter}
									sort={userSort}
									setIsFetchingUsers={setIsFetchingUsers}
								/>
							</Card>
						</div>
					</div>
					<div className={styles.row}>
						<div ref={orgAvatarNode}>
							<Card
								title="Company Logo"
								className={styles.formCard}>
								<OrganizationAvatarForm />
							</Card>
						</div>
					</div>
				</>
			)}
			{user?.permissions?.includes('manage_orders') && (
				<div className={styles.row}>
					<div ref={shippingNode}>
						<Card
							title="Shipping Details"
							className={styles.formCard}>
							<OrganizationShippingForm />
						</Card>
					</div>
				</div>
			)}

			<div className={styles.row}>
				<div ref={distributorAdminNode}>
					<Card
						title="Distributor Admin"
						className={styles.formCard}>
						<DistributorAdminListCard />
					</Card>
				</div>
			</div>

			<InviteUserModal
				active={inviteUserModalActive}
				onClose={() => {
					setUsersRefreshTimestamp(new Date().getTime());
					setInviteUserModalActive(false);
				}}
				refresh={usersRefreshTimestamp}
			/>
		</Screen>
	);
};

export default MyAccount;
