import Http from '../utilities/Http';
import { API_ROOT } from '@config';

export default {
	async login(email, password) {
		let response = await Http().post(`${API_ROOT}/auth/login`, {
			email,
			password,
		});

		return response.data;
	},
	async logout() {
		await Http().post(`${API_ROOT}/auth/logout`);
	},
	async forgot(email) {
		const response = await Http().post(`${API_ROOT}/auth/forgot`, {
			email,
		});

		return response.data;
	},
	async reset(password, code) {
		const response = await Http().post(`${API_ROOT}/auth/reset`, {
			password,
			code,
		});

		return response.data;
	},
};
