import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * Get lettering-related options (patch, patch color, style)
 */

export const useLetteringOptions = (
	productOptions = [],
	optionIsAvailable = () => {},
) => {
	// lettering option IDs
	const {
		patch: letteringPatchId,
		style: letteringStyleId,
		color: outerShellColorId,
	} = useSelector((state) => state.staticData.letteringOptions);

	return useMemo(() => {
		// patch
		const patchOption = productOptions.find(
			(option) =>
				option.product_option_id === letteringPatchId.toString(),
		);

		// patch color
		const patchColorOption = productOptions.find(
			(option) =>
				option.product_option_id === outerShellColorId.toString(),
		);

		// style
		const styleOption = productOptions.find(
			(option) =>
				option.product_option_id === letteringStyleId.toString(),
		);

		// filter by is available // Filter style options separately, they have separate filtering
		return [...[patchOption, patchColorOption]
			.filter((o) => !!o)
			.map((option) => {
				const values = option.values.filter((value) => {

					return optionIsAvailable(
						option.product_option_id,
						value.product_option_value_id,
					);
				});

				return { ...option, values };
			}), styleOption];
	}, [
		productOptions,
		letteringPatchId,
		outerShellColorId,
		letteringStyleId,
		optionIsAvailable,
	]);
};
