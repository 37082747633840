import { useMemo } from 'react';
import classNames from 'classnames';
import date from '@utilities/date';
import styles from './OrdersListItemMetadata.module.scss';
import { isFinalized } from '@utilities/orders';
import { useSelector } from 'react-redux';

const OrdersListItemMetadata = ({
	order = {},
	className = null,
	onClick = () => {},
}) => {
	const user = useSelector((state) => state.auth.user);
	const isCSRUser = user.permissions?.includes('csr_access');

	/**
	 * Approved Quotes Count
	 */

	const quotesApprovedCount = useMemo(() => {
		if (!order.quotes?.length) {
			return 0;
		}

		return order.quotes.reduce((result, quote) => {
			if (quote.quote_status === 'approved' && !quote.sibling_quote_id) {
				result += 1;
			}

			return result;
		}, 0);
	}, [order]);

	/**
	 * Rejected Quotes Count
	 */

	const quotesRejectedCount = useMemo(() => {
		if (!order.quotes?.length) {
			return 0;
		}

		return order.quotes.reduce((result, quote) => {
			if (quote.quote_status === 'rejected' && !quote.sibling_quote_id) {
				result += 1;
			}

			return result;
		}, 0);
	}, [order]);

	/**
	 * Should show last edited text
	 */

	const showLastEdited = useMemo(() => {
		if (!isCSRUser || !order.csr_last_edited) {
			return false;
		}

		return true;
	}, [order, isCSRUser]);

	/**
	 * Should show approved/rejected counts
	 */

	const showCounts = useMemo(() => {
		if ((!quotesApprovedCount || !isCSRUser) && !quotesRejectedCount) {
			return false;
		}

		if (isFinalized(order)) {
			return false;
		}

		return true;
	}, [order, quotesApprovedCount, quotesRejectedCount, isCSRUser]);

	if (!showLastEdited && !showCounts && !order.order_customer_alert) {
		return null;
	}

	return (
		<div
			className={classNames({
				[styles.container]: true,
				[className]: !!className,
			})}>
			{showLastEdited && (
				<div className={styles.lastEdited}>
					<span>
						Edited:{' '}
						<em>
							{date(order.csr_last_edited.order_csr_log_timestamp).format(
								'M/D/YY',
							)}
						</em>{' '}
						at{' '}
						<em>
							{date(order.csr_last_edited.order_csr_log_timestamp).format(
								'h:mm A',
							)}
						</em>{' '}
						by <em>{order.csr_last_edited.user_display_name}</em>
					</span>
				</div>
			)}
			{showCounts && (
				<div className={styles.indicators}>
					{isCSRUser && !!quotesApprovedCount && (
						<div
							className={styles.quotesApproved}
							onClick={onClick}>
							Approved:{' '}
							<i className={styles.quotesApprovedCount}>
								{quotesApprovedCount}
							</i>{' '}
						</div>
					)}
					{!!quotesRejectedCount && order.order_status === 'Requires Edits' && (
						<div
							className={styles.quotesRejected}
							onClick={onClick}>
							Requires Edits:{' '}
							<i className={styles.quotesRejectedCount}>
								{quotesRejectedCount}
							</i>
						</div>
					)}
				</div>
			)}
			{!showCounts &&
				!!order.order_customer_alert &&
				 order.order_status === 'Requires Edits' &&
				!isFinalized(order) && (
					<div className={styles.indicators}>
						<div
							className={styles.quotesRejected}
							onClick={onClick}>
							Requires Edits:{' '}
							<i className={styles.quotesRejectedCount}>1</i>
						</div>
					</div>
				)}
		</div>
	);
};

export default OrdersListItemMetadata;
