import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			width={16}
			height={13}
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<g>
				<path d="M0 .91v5.58h15.81V.91zM0 13h15.81V7.42H0z" />
			</g>
		</svg>
	);
}

export default SvgComponent;
