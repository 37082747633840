import classNames from 'classnames';
import collectionStyles from '@components/Collection/Collection.module.scss';
import styles from './LegacyQuoteListItem.module.scss';
import React from "react";
import TextField from "@components/TextField/TextField";
import {Link} from "react-router-dom";

const LegacyQuoteListItem = ({
                                 item: quote,
                                 link = true
                             }) => {

    let productsString;
    if (quote.legacy_quote_quote.IsCoatQuote === '1' && quote.legacy_quote_quote.IsPantQuote === '1') {
        productsString = 'Full Set';
    } else if (quote.legacy_quote_quote.IsCoatQuote) {
        productsString = 'Coat';
    } else {
        productsString = 'Pants';
    }

    return (
        <Link to={`/legacy-quotes/${quote.legacy_quote_id}`} className={classNames({'disable-link': !link})}>
            <div style={{width: '100%', paddingLeft: 20, paddingTop: 18}}>
                <TextField
                    readonly
                    label="Quote Name"
                    value={quote.legacy_quote_name}
                />
            </div>
            <div
                className={classNames({
                    [collectionStyles.collectionItem]: true,
                    [styles.collectionItem]: true,
                })}>
                <div
                    className={classNames({
                        [collectionStyles.collectionItemFieldPair]: true,
                        [styles.numberPair]: true,
                    })}>
                    <TextField
                        readonly
                        label="Quote Number"
                        value={quote.legacy_quote_quote_id}
                    />
                    <TextField
                        readonly
                        label="Contract"
                        value={quote.legacy_quote_contract_name}
                    />
                </div>
                <div
                    className={classNames({
                        [collectionStyles.collectionItemFieldPair]: true,
                        [styles.numberPair]: true,
                    })}>
                    <TextField
                        readonly
                        label="Status"
                        value={quote.legacy_quote_status.WebStatus}
                    />
                </div>
                <div
                    className={classNames({
                        [collectionStyles.collectionItemFieldPair]: true,
                        [styles.numberPair]: true,
                    })}>
                    <TextField
                        readonly
                        label="Product Line"
                        value={quote.legacy_quote_product_line_name}
                    />
                </div>
                <div
                    className={classNames({
                        [collectionStyles.collectionItemFieldPair]: true,
                        [styles.numberPair]: true,
                    })}>
                    <TextField
                        readonly
                        label="Products"
                        value={productsString}
                    />
                </div>
            </div>
        </Link>
    );
}

export default LegacyQuoteListItem;
