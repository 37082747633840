import classNames from 'classnames';
import React from 'react';
import styles from './Card.module.scss';

const Card = ({
	title = '',
	className = '',
	contentClassName = '',
	headerContent = null,
	headerClassName = null,
	children = null,
	cardId = '',
}) => {
	return (
		<div
			className={classNames({
				[styles.card]: true,
				[className]: !!className,
			})}
			id={cardId}>
			{(!!title || !!headerContent) && (
				<header
					className={classNames({
						[headerClassName]: !!headerClassName,
					})}>
					<h4>{title}</h4>
					{headerContent}
				</header>
			)}
			<div
				className={classNames({
					[contentClassName]: !!contentClassName,
				})}>
				{children}
			</div>
		</div>
	);
};

export default Card;
