import * as React from 'react';

function SvgComponent(props) {
	const fill = props.fill || '#e87124';
	return (
		<svg viewBox="0 0 22 19" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M12 12h-2V8h2zm0 4h-2v-2h2zM0 19h22L11 0z" fill={fill} />
		</svg>
	);
}

export default SvgComponent;
