import { useCallback, useEffect, useRef, useState } from 'react';
import Avatar from '@components/Avatar/Avatar';
import Button from '@components/Button/Button';
import { API_ROOT } from '@config';
import Http from '@utilities/Http';
import { useSelector } from 'react-redux';
import styles from './ProfileForm.module.scss';

const ProfileForm = ({ onSubmit = async () => {} }) => {
	const { user } = useSelector((state) => state.auth);

	const [data, setData] = useState({ ...user });
	const [isSubmitting, setIsSubmitting] = useState(false);
	const fileInputNode = useRef(null);
	const [isUploading, setIsUploading] = useState(false);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(async () => {
		setIsSubmitting(true);

		try {
			await onSubmit(data);
		} finally {
			setIsSubmitting(false);
		}
	}, [data, onSubmit]);

	/**
	 * Handle File Select
	 */

	const handleFileSelect = useCallback(async (e) => {
		setIsUploading(true);

		try {
			const { files } = e.target;

			const formData = new FormData();

			Array.from(files).forEach((file, f) => {
				formData.append(`files[${f}]`, file);
			});

			const { data } = await Http().post(
				`${API_ROOT}/users/upload-avatar`,
				formData,
			);

			setData((user) => ({
				...user,
				profile_avatar: data.profile_avatar[0],
			}));
		} catch (error) {
			console.log(error);
		} finally {
			setIsUploading(false);
		}
	}, []);

	/**
	 * Initialize File Input Node
	 */

	useEffect(() => {
		const node = document.createElement('input');
		node.type = 'file';
		node.accept = 'image/*';
		node.onchange = handleFileSelect;

		fileInputNode.current = node;
	}, [handleFileSelect]);

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.avatarContainer}>
					<Avatar
						user={data}
						className={styles.avatar}
						avatarSize={'80%'}
						innerAvatarSize={'100%'}
					/>
				</div>
				<div className={styles.actions}>
					<Button
						variant="plain"
						onClick={() => fileInputNode.current?.click()}
						loading={isUploading}>
						Upload New Image
					</Button>
					{!!data.profile_avatar && (
						<Button
							variant="plain"
							onClick={() =>
								setData((data) => ({
									...data,
									profile_avatar: null,
								}))
							}>
							Remove Image
						</Button>
					)}
				</div>
			</div>
			<Button
				variant="solid"
				onClick={handleSubmit}
				loading={isSubmitting}
				className={styles.submitButton}>
				Save Changes
			</Button>
		</div>
	);
};

export default ProfileForm;
