import React from 'react';
import { API_ROOT } from '@config';
import date from '@utilities/date';
import { Wrapper } from './LinkWrapper';
import styles from './NewsNotification.module.scss';

const NewsNotification = ({ notification }) => {
	return (
		<div className={styles.newsNotification}>
			{notification.news_image && (
				<Wrapper notification={notification}>
					<img
						className={styles.newsNotificationImage}
						src={`${API_ROOT}/uploaded/news/${notification.news_image}`}
					/>
				</Wrapper>
			)}
			<div>
				<h6 className={styles.newsNotificationTitle}>
					<Wrapper notification={notification}>
						{notification.news_headline}
					</Wrapper>
				</h6>
				<p
					dangerouslySetInnerHTML={{
						__html: notification.news_content,
					}}
					className={styles.newsNotificationContent}
				/>
				<span className={styles.newsNotificationDate}>
					{date(notification.notification_timestamp).fromNow()}
				</span>
			</div>
		</div>
	);
};

export default NewsNotification;
