import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import AuthHeader from '@components/Auth/AuthHeader';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import Button from '@components/Button/Button';
import AuthService from '@services/AuthService';
import { useQueryParams } from '@hooks/useQueryParams';

function Reset({ location }) {
	const history = useHistory();

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [error, setError] = useState('');
	const params = useQueryParams(location);

	/**
	 * Submit handler
	 */

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			if (password !== confirmPassword) {
				setError('Passwords do not match.');
				return;
			}

			if (password.length < 8) {
				setError('Password should be at least 8 characters');
				return;
			}

			setIsSubmitting(true);

			try {
				await AuthService.reset(password, params.code);
				history.push('/login?msg=reset-success');
			} catch (error) {
				setIsSubmitting(false);
				setError(
					error.response?.data?.meta?.message ||
						'Something went wrong.',
				);
			}
		},
		[password, confirmPassword, params, history],
	);

	return (
		<>
			<AuthHeader />
			<Hero heroTitle="FORGOT<br />PASSWORD?" />
			<section className="content-wrapper">
				<div className="content-container flex-center-content">
					<form className="form-container" onSubmit={handleSubmit}>
						<p className="form-text">
							Please enter a new password:
						</p>
						<div className="input-container">
							<label>Password*</label>
							<input
								autoComplete="off"
								type="password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className="input-container">
							<label>Confirm Password*</label>
							<input
								autoComplete="off"
								type="password"
								onChange={(e) =>
									setConfirmPassword(e.target.value)
								}
							/>
						</div>
						{!!error && (
							<div className="form-errors">
								<p>{error}</p>
							</div>
						)}
						<div className="form-actions forgot-form-actions">
							<Button
								type="submit"
								loading={isSubmitting}
								className="form-submit-button">
								Reset Password
							</Button>
						</div>
					</form>
				</div>
			</section>
			<Footer className="auth-footer" />
		</>
	);
}

export default Reset;
