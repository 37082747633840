import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { fetchUser } from '@store/thunks/fetch-user';

const initialState = {
	addresses: [],
};

const addressesSlice = createSlice({
	name: 'addresses',
	initialState,
	reducers: {
		setAddresses(state, action) {
			return {
				...state,
				addresses: action.payload,
			};
		},
		addAddress(state, action) {
			return {
				...state,
				addresses: [...state.addresses, action.payload],
			};
		},
	},
	extraReducers: {
		[fetchUser.fulfilled]: (state, action) => {
			return {
				...state,
				addresses: action.payload.addresses,
			};
		},
		[fetchUser.rejected]: () => {
			return {
				...initialState,
			};
		},
	},
});

export const persistConfig = {
	key: 'addresses',
	storage,
};

export const { setAddresses, addAddress } = addressesSlice.actions;
export default persistReducer(persistConfig, addressesSlice.reducer);
