import React from 'react';
import date from '@utilities/date';
import styles from './CustomNotification.module.scss';

const CustomNotification = ({ notification }) => {
	return (
		<div className={styles.customNotification}>
			<div
				className={styles.customNotificationContent}
				dangerouslySetInnerHTML={{
					__html: notification.content_html,
				}}
			/>
			<span className={styles.customNotificationDate}>
				{date(notification.notification_timestamp).fromNow()}
			</span>
		</div>
	);
};

export default CustomNotification;
