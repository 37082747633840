import {createSlice} from '@reduxjs/toolkit';

const dimensionsSlice = createSlice({
    name: 'dimensions',
    initialState: {
        headerHeight: 0
    },
    reducers: {
        setHeaderHeight(state, action) {
            return {
                ...state,
                headerHeight: action.payload
            }
        }
    }
});

export const {setHeaderHeight} = dimensionsSlice.actions;
export default dimensionsSlice.reducer;
