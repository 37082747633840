import React from 'react';
import classNames from 'classnames';
import styles from './NotificationType.module.scss';

const labels = {
	'order-status': 'Order Update',
	'quote-share': 'Quote Update',
	'quote-copy': 'Quote Update',
	'quote-update': 'Quote Update',
	'quote-submitted': 'Quote Update',
	'news': 'News',
	'general': 'General Update',
	'new-fire-department': 'Fire Department',
	'custom': '',
	'new-message': 'New Message',
};

function getNotificationLabel(notification) {
	switch (notification.notification_type) {
		case 'custom': {
			return notification.notification_meta?.label || 'Other';
		}
		default: {
			return labels[notification.notification_type];
		}
	}
}

const NotificationType = ({
	notification,
	className = null,
	onClick = () => {},
}) => {
	return (
		<div
			onClick={onClick}
			className={classNames({
				[styles.notificationType]: true,
				[styles[
					notification.notification_type.replace(
						/-([a-z])/g,
						function (g) {
							return g[1].toUpperCase();
						},
					)
				]]: true,
				[className]: !!className,
			})}>
			<span>{getNotificationLabel(notification)}</span>
		</div>
	);
};

export default NotificationType;
