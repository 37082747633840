import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Screen from '@components/Screen/Screen';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';
import ProductLineCategories from '@components/ProductLineCategories/ProductLineCategories';
import { Helmet } from 'react-helmet';
import ProductLines from '@components/ProductLines/ProductLines';
import styles from './Products.module.scss';

const Products = () => {
	const user = useSelector((state) => state.auth.user);
	const content = useSelector((state) => state.staticData.content);
	const [availableCategories, setAvailableCategories] = useState([]);

	/**
	 * Scroll to Category
	 */

	const handleCategorySelect = useCallback(
		(categoryId) => {
			const node = document.getElementById(
				`product-line-category-${categoryId}`,
			);

			if (node) {
				if (window.innerWidth >= 1200 && !!user) {
					node.scrollIntoView({ behavior: 'smooth' });
				} else {
					// account for fixed header
					const offset = node.getBoundingClientRect().top;
					window.scrollTo({ top: offset - 80, behavior: 'smooth' });
				}
			}
		},
		[user],
	);

	return (
		<Screen
			hideSidebar={!user}
			headerContent={
				<>
					{!!user && <WelcomeHeader />}
					<ProductLineCategories
						availableCategories={availableCategories}
						onSelect={handleCategorySelect}
						containerStyle={user ? null : styles.publicBanner}>
						{!user && (
							<div
								className={styles.cta}
								dangerouslySetInnerHTML={{
									__html: content['products-page']?.indexed[
										'banner'
									]['content_item_html'],
								}}
							/>
						)}
					</ProductLineCategories>
				</>
			}>
			<Helmet>
				<title>All Products</title>
			</Helmet>
			<ProductLines setAvailableCategories={setAvailableCategories} />
		</Screen>
	);
};

export default Products;
