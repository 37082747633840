import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			viewBox="0 0 92 92"
			width={13}
			height={13}
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path xmlns="http://www.w3.org/2000/svg" d="M73.8 57.9l-25 24.9C48 83.6 47 84 46 84s-2-.4-2.8-1.2l-25-24.9c-1.6-1.6-1.6-4.1 0-5.7 1.6-1.6 4.1-1.6 5.7 0L42 70.4V12c0-2.2 1.8-4 4-4s4 1.8 4 4v58.4l18.2-18.1c1.6-1.6 4.1-1.6 5.7 0 1.5 1.5 1.5 4-.1 5.6z"/>
		</svg>
	);
}

export default SvgComponent;
