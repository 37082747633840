export function getAddressString(address = {}) {
	const segments = [];

	segments.push(
		address.address_attention ? 'Attn: ' + address.address_attention : '',
		address.address_name
			? (address.address_attention ? ' - ' : '') +
					address.address_name +
					': '
			: '',
		address.address_street_1 || '',
		address.address_street_2 || '',
		address.address_city ? address.address_city + ',' : '',
		address.state_name,
		address.address_zip,
	);

	return segments.join(' ');
}

export function compareOrderAddress(orderAddress = {}, dbAddress = {}) {
	const dbFields = [
		'address_attention',
		'address_name',
		'address_street_1',
		'address_number',
		'address_city',
		'state_code',
		'address_zip',
	];

	const orderFields = [
		'order_ship_to_attention',
		'order_ship_to_name',
		'order_ship_to_street',
		'order_ship_to_number',
		'order_ship_to_city',
		'order_ship_to_state',
		'order_ship_to_zip',
	];

	let isEqual = true;

	orderFields.forEach((orderField, i) => {
		if (orderAddress[orderField] !== dbAddress[dbFields[i]]) {
			isEqual = false;
		}
	});

	return isEqual;
}