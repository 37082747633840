import {useRef, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames/bind';

import {setHeaderHeight} from "@store/reducers/dimensions";

function FixedHeader({children, className = '', ...props}) {
    const dispatch = useDispatch();

    const element = useRef(null);

    useEffect(() => {
        if (typeof element.current?.clientHeight === 'number') {
            dispatch(setHeaderHeight(element.current?.clientHeight))
        }
    }, [dispatch, element.current?.clientHeight])

    return (
        <header
            ref={element}
            className={classNames(
                'fixed-header',
                className
            )}
            {...props}
        >
            {children}
        </header>
    );
}

export default FixedHeader;
