import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			viewBox="0 0 512 512"
			width={13}
			height={13}
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path xmlns="http://www.w3.org/2000/svg" d="M448 248 288 96v85.334C138.666 202.667 85.333 309.334 64 416c53.333-74.666 117.333-108.802 224-108.802v87.469L448 248z"/>
		</svg>
	);
}

export default SvgComponent;
