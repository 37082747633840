import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { initialRoute } from '@config/routes';

const AuthRoute = ({ ...rest }) => {
	const { authSettled = false, user } = useSelector((state) => state.auth);

	if (authSettled && user) {
		return <Redirect to={{ pathname: initialRoute }} />;
	}

	return <Route {...rest} />;
};

export default AuthRoute;
