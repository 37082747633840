import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { API_ROOT } from '@config';
import styles from './OrderItemPreview.module.scss';
import classNames from 'classnames';

const OrderItemPreview = ({
	quote,
	orientation: defaultOrientation = 'front',
	className = null,
	onClick = () => {},
}) => {
	const [orientation, setOrientation] = useState(defaultOrientation);

	useEffect(() => {
		setOrientation(defaultOrientation);
	}, [defaultOrientation]);

	/**
	 * Product Option Value IDs for lookup
	 */

	const optionValueIds = useMemo(() => {
		return quote.productOptionValues.map(
			(item) => item.product_option_value_id,
		);
	}, [quote]);

	/**
	 * Background (Outline) Image
	 */

	const backgroundImage = useMemo(() => {
		// find image for selected orientation
		const backgroundImage = quote.outlineImages.find((image) =>
			[orientation].includes(image.product_outline_image_location),
		);

		// render background image
		return backgroundImage ? (
			<img
				src={`${API_ROOT}/uploaded/products/product-outline-images/${backgroundImage.product_outline_image_file}`}
				className={styles.backgroundImage}
			/>
		) : null;
	}, [quote, orientation]);

	const getOptionValueImage = useCallback(
		(option, getMirrorImage = false) => {
			// find images for selected orientation
			let images =
				option[getMirrorImage ? 'mirrorImages' : 'images']?.filter(
					(image) => {
						if(option.product_option_value_custom_position_side) {
							return [orientation].includes(
								option.product_option_value_custom_position_side,
							)
						}
						return [orientation].includes(
							image.product_outline_image_location,
						)
					},
				) || [];
			if (images.length) {

				// If product option type uses quantity
				// check for quantity requirement on images that match
				// otherwise use one that is null
				if(option.product_option_type_id === '5') {
					images = images.filter((productOptionValueImage) => {
						return productOptionValueImage.product_option_value_image_quantity === option.quote_product_option_value_quantity
							|| productOptionValueImage.product_option_value_image_quantity == null;
					});
				}

				// get default image (without combination)
				const standaloneImage = images.find(
					(image) => !image.product_option_value_combo_id,
				);

				// get image in combination
				const combinationImage = images.find(
					(image) =>
						image.product_option_value_combo_id &&
						optionValueIds.includes(
							image.product_option_value_combo_id,
						),
				);

				// get image in dual combination
				const dualCombinationImage = images.find(
					(image) =>
						image.product_option_value_combo_id && image.product_option_value_combo_id_2 &&
						optionValueIds.includes(
							image.product_option_value_combo_id,
						) &&
						optionValueIds.includes(
							image.product_option_value_combo_id_2,
						),
				);

				// use combination image, fall back to default image
				return dualCombinationImage || combinationImage || standaloneImage || null;
			}
			return null;
		},
		[optionValueIds, orientation],
	);

	const getOptionValueStyle = useCallback(
		(option, getMirrorStyle) => {
			// find image styles for selected orientation
			const imageStyles =
				option[
					getMirrorStyle ? 'mirrorImageStyles' : 'imageStyles'
				]?.filter((image) => {
						if(option.product_option_value_custom_position_side) {
							return [orientation].includes(
								option.product_option_value_custom_position_side,
							)
						}
						return [orientation].includes(
							image.product_outline_image_location,
						)
					},
				) || [];

			// get default style (without combination)
			const standaloneStyle = imageStyles.find(
				(style) => !style.product_option_value_combo_id,
			);

			// get combination style
			const combinationStyle = imageStyles.find(
				(style) =>
					style.product_option_value_combo_id &&
					optionValueIds.includes(
						style.product_option_value_combo_id,
					),
			);

			// get combination style
			const dualCombinationStyle = imageStyles.find(
				(style) =>
					style.product_option_value_combo_id && style.product_option_value_combo_id_2 &&
					optionValueIds.includes(
						style.product_option_value_combo_id,
					) &&
					optionValueIds.includes(
						style.product_option_value_combo_id_2,
					),
			);

			return (
				dualCombinationStyle ||
				combinationStyle ||
				standaloneStyle ||
				imageStyles.find(
					(style) => !style.product_option_value_combo_id,
				)
			);
		},
		[optionValueIds, orientation],
	);

	/**
	 * Overlay Images
	 */

	const overlayImages = useMemo(() => {
		return quote.productOptionValues.reduce((result, option, index) => {
			let image = getOptionValueImage(option);
			let imageStyle = getOptionValueStyle(option);
			let mirrorImageStyle = false;

			if (!image || !imageStyle) {
				image = getOptionValueImage(option, true);
				imageStyle = getOptionValueStyle(option, true);
				mirrorImageStyle = true;
			}

			// render overlay images
			if (image && imageStyle) {
				let top = imageStyle.product_option_image_style_top
					? imageStyle.product_option_image_style_top + '%'
					: null;
				let bottom = imageStyle.product_option_image_style_bottom
					? imageStyle.product_option_image_style_bottom + '%'
					: null;
				const width = imageStyle.product_option_image_style_width
					? imageStyle.product_option_image_style_width + '%'
					: null;
				const zIndex = imageStyle.product_option_image_style_z_index;

				let left, right, transform;

				if (option.product_option_value_position_id === '3' || (option.quote_product_option_value_custom_position_top || option.quote_product_option_value_custom_position_left)) {
					top = (option.quote_product_option_value_custom_position_top ? option.quote_product_option_value_custom_position_top : imageStyle.product_option_image_style_top) + '%';
					left = (option.quote_product_option_value_custom_position_left ? option.quote_product_option_value_custom_position_left : imageStyle.product_option_image_style_left) + '%';
					bottom = null;
				} else {
					if (mirrorImageStyle) {
						left = imageStyle.product_option_image_style_right
							? imageStyle.product_option_image_style_right + '%'
							: null;
						right = imageStyle.product_option_image_style_left
							? imageStyle.product_option_image_style_left + '%'
							: null;
						transform =
							imageStyle.product_option_image_style_transform
								? null
								: 'scaleX(-1)';
					} else {
						left = imageStyle.product_option_image_style_left
							? imageStyle.product_option_image_style_left + '%'
							: null;
						right = imageStyle.product_option_image_style_right
							? imageStyle.product_option_image_style_right + '%'
							: null;
						transform =
							imageStyle.product_option_image_style_transform;
					}

					if (
						option.product_option_value_position_id &&
						option.product_option_value_position_id !== '3' &&
						image.product_option_value_position_id !== option.product_option_value_position_id
						&& !mirrorImageStyle
					) {
						let leftCopy = right;
						let rightCopy = left;

						left = leftCopy;
						right = rightCopy;

						transform = transform ? null : 'scaleX(-1)';
					}
				}
				result.push(
					<img
						src={`${API_ROOT}${image.product_option_value_image_file_url}`}
						key={index}
						style={{
							top,
							right,
							bottom,
							left,
							width,
							zIndex: zIndex ? parseInt(zIndex) : null,
							transform,
						}}
					/>,
				);
			}
			return result;
		}, []);
	}, [quote, getOptionValueImage, getOptionValueStyle]);

	return (
		<div
			className={classNames({
				[styles.preview]: true,
				[className]: !!className,
			})}
			onClick={onClick}>
			{backgroundImage}
			{overlayImages}
		</div>
	);
};

export default OrderItemPreview;
