/**
 * Sizing Form Field Formatters
 */

export function formatFloat(value = '', digits = 2) {
	if (value === null) {
		return '';
	}

	value = parseFloat(value.toString().replace(/[^\d.]/g, ''));

	if (isNaN(value)) {
		return '';
	}

	// strip trailing 0s
	if (value % 1 === 0) {
		digits = 0;
	}
	value = value.toLocaleString(undefined, {
		minimumFractionDigits: digits,
		maximumFractionDigits: digits,
	});

	return `${value} ″`;
}

export function formatInteger(value = '') {
	if (value === null) {
		return '';
	}

	value = parseInt(value.toString().replace(/[^\d.]/g, ''));

	if (isNaN(value)) {
		return '';
	}

	if (value === 0) {
		return '0';
	}

	return value;
}

export function formatIntegerInches(value = '') {
	if (value === null) {
		return '';
	}

	value = parseInt(value.toString().replace(/[^\d.]/g, ''));

	if (isNaN(value)) {
		return '';
	}

	return `${value} ″`;
}

export const applyFormatters = (schema = {}, data = []) => {
	return data.map((item) => {
		let data = Object.keys(schema).reduce((result, key) => {
			switch (schema[key].sizing_field_type) {
				case 'integer': {
					result[key] = formatInteger(item[key]);
					break;
				}
				case 'integer_inches': {
					result[key] = formatIntegerInches(item[key]);
					break;
				}
				case 'integer_even': {
					result[key] = formatIntegerInches(item[key]);
					break;
				}
				case 'float': {
					result[key] = formatFloat(item[key]);
					break;
				}
				case 'boolean': {
					result[key] = item[key];
					break;
				}
				case 'dropdown': {
					const matchIndex = schema[key].options.findIndex((sizingFieldOption) => {
						return sizingFieldOption?.sizing_field_option_value.replace(/\s+/g, "") === item[key]?.replace(/\s+/g, "")
					});

					if(matchIndex >= 0) {
						result[key] = schema[key].options[matchIndex].sizing_field_option_value;
					} else {
						result[key] = item[key];
					}

					break;
				}
				case 'text':
				case 'longtext':
				default: {
					result[key] = item[key];
				}
			}

			return result;
		}, {});

		if(item.quote_sizing_id) {
			data.quote_sizing_id = item.quote_sizing_id;
		}

		if(item.sizer_anon_id && !data.edit_status){
			data.edit_status = 'locked';
		}
		return data;
	});
};

export const formatters = {
	'integer': formatInteger,
	'integer_inches': formatIntegerInches,
	'integer_even': formatIntegerInches,
	'float': formatFloat,
};
