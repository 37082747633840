import * as React from 'react';

const SvgComponent = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
		<path
			data-name="Path 3645"
			d="M294.5 384.5h24v24h-24Z"
			transform="translate(-294.5 -384.5)"
			style={{
				fill: 'none',
			}}
		/>
		<path
			data-name="Path 3646"
			d="M300.5 403.5a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-12h-12Zm13-15H310l-1-1h-5l-1 1h-3.5v2h14Z"
			transform="translate(-294.5 -384.5)"
			style={{ fill: 'inherit' }}
		/>
	</svg>
);

export default SvgComponent;
