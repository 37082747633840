import { API_ROOT } from '@config';
import Http from './Http';

/**
 * Get Order Zip Export
 */

export const getOrderQuoteExportUrl = async (orderId, type = 'pdf', demoMode = false) => {
	return Http()
		.get(`${API_ROOT}/orders/export/${orderId}?type=${type}&demoMode=${demoMode}`, {
			responseType: 'blob',
		})
		.then(({ data }) => {
			return window.URL.createObjectURL(data);
		});
};

/**
 * Get Quote Export
 */

export const getQuoteExport = async (quoteId, type = 'pdf', demoMode = false) => {
	return Http()
		.get(`${API_ROOT}/quotes/export/${quoteId}?type=${type}&demoMode=${demoMode}`, {
			responseType: 'blob',
		})
		.then(({ data }) => {
			return window.URL.createObjectURL(new Blob([data]));
		});
};


/**
 * Get Quote Specifications
 */

export const getQuoteSpecifications = async (quoteId) => {
	return Http()
		.get(`${API_ROOT}/quotes/specifications/${quoteId}`, {
			responseType: 'blob',
		})
		.then(({ data }) => {
			return window.URL.createObjectURL(new Blob([data]));
		});
};

/**
 * Trigger Download
 */

export const download = async (url, name, newWindow = false) => {
	if (!url) {
		return;
	}

	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', name);

	if (newWindow) {
		link.setAttribute('target', '_blank');
	}

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
/**
 * Get Quote Export
 */

export const getQuoteExportSketch = async (quoteId, side = undefined) => {
	return Http()
		.get(`${API_ROOT}/quotes/export-sketch/${quoteId}?side=${side}`, {
			responseType: 'blob',
		})
		.then(({ data }) => {
			return window.URL.createObjectURL(new Blob([data]));
		});
};

