import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {ReactComponent as FlipIcon} from "@assets/images/change_circle_black_24dp.svg";
import ConfiguratorPreviewBody from "@components/ConfiguratorPreview/ConfiguratorPreviewBody";
import LetteringPreviewFields
    from "@components/LetteringSummary/components/LetteringPreviewFields/LetteringPreviewFields";
import { usePlacementStyles } from '@components/LetteringSummary/hooks/usePlacementStyles';

function ConfiguratorPreview({
    quote,
    selectedProductOptionValues,
    siblingQuote = null,
    siblingSelectedProductOptionValues = [],
    showLetteringPlacements = false,
    letteringData = [],
    letteringPlacement='',
    setLetteringPlacement = ()=>{},
    allLetteringPlacements = [],
    hideLettering = false,
    isSummary = false,
    handleSavePosition = ()=>{},
    setCustomPlacementsValid = ()=>{},
    toggleImageOrientation = ()=>{},
    imageOrientation = 'front',
}) {
    const {headerHeight} = useSelector((state) => state.dimensions);

    let quotes = [{
        quote,
        selectedProductOptionValues
    }];

    if (siblingQuote) {
        quotes.push({
            quote: siblingQuote,
            selectedProductOptionValues: siblingSelectedProductOptionValues
        })
    }

    const getProductOutlineId = useMemo(()=> {
        let outlineImage = quote.outlineImages.find((outlineImage) => {
            return (
                outlineImage.product_outline_image_location === imageOrientation
            );
        });

        if (outlineImage) {
            return outlineImage.product_outline_image_id;
        }

        return false;
    },[imageOrientation,quote.outlineImages]);

    usePlacementStyles({outlineId:getProductOutlineId})

    useEffect(()=>{
        if(showLetteringPlacements) {
            toggleImageOrientation('back');
        }
    },[showLetteringPlacements])

    return (
        <div className="configurator-flip-container" style={{
            top: headerHeight + 20
        }}>
            <div className="configurator-actions-container">

                    <button
                        onClick={() => toggleImageOrientation()}
                        className="button flip-button"
                        style={{visibility:showLetteringPlacements?'hidden':"visible"}}
                    >
                        <span>Flip</span>
                        <FlipIcon/>
                    </button>

            </div>
            <div className="configurator-previews-container">
                {(!hideLettering && ['1', '3'].includes(quote.product_id)) &&
                    <LetteringPreviewFields showPlacements={showLetteringPlacements }
                                            data={letteringData}
                                            letteringPlacement={letteringPlacement}
                                            setLetteringPlacement={setLetteringPlacement}
                                            letteringPlacements={allLetteringPlacements}
                                            outlineId={getProductOutlineId}
                                            showLettering={imageOrientation === 'back'}
                                            productId={quote.product_id}
                    />
                }
                {
                    quotes.sort((quoteObj1, quoteObj2) => {
                        return quoteObj1.quote.product_id - quoteObj2.quote.product_id;
                    }).map((quoteObj) => {
                        return (

                            <ConfiguratorPreviewBody outlineId={getProductOutlineId}
                            handleSavePosition={handleSavePosition}
                            key={quoteObj.quote.quote_id}
                            isSummary={isSummary}
                            quote={quoteObj.quote}
                            selectedProductOptionValues={quoteObj.selectedProductOptionValues}
                            setCustomPlacementsValid={setCustomPlacementsValid}
                            imageOrientation={imageOrientation}/>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default ConfiguratorPreview;
