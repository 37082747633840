import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import bodySlim from './img/mannequins/body-slim.png'
import bodyAverage from './img/mannequins/body-average.png'
import bodyLarger from './img/mannequins/body-larger.png'
import bodyFemaleSlim from './img/mannequins/body-female-slim.png'
import bodyFemaleAverage from './img/mannequins/body-female-average.png'
import bodyFemaleLarger from './img/mannequins/body-female-larger.png'
import bellyFlat from './img/mannequins/belly-flat.png'
import bellyAverage from './img/mannequins/belly-average.png'
import bellyLarger from './img/mannequins/belly-larger.png'
import braSmaller from './img/mannequins/bra-smaller.png'
import braAverage from './img/mannequins/bra-average.png'
import braLarger from './img/mannequins/bra-larger.png'

import StyledSelect from "./components/formFields/styledSelect";
import StyledNumberInput from "./components/formFields/styledNumberInput";
import ImageSelect from "./components/formFields/imageSelect";
import styles from './VirtualSizer.module.scss';
import classNames from 'classnames';
import { API_ROOT } from '@config';
import { useSelector } from 'react-redux';


const virtualSizerUrl = 'https://api.boldmetrics.io/virtualsizer/get';
const virtualSizerClient = process.env.REACT_APP_BOLDMETRICS_CLIENT;
const virtualSizerKey = process.env.REACT_APP_BOLDMETRICS_API_KEY;
const virtualSizerBrand = process.env.REACT_APP_BOLDMETRICS_BRAND;

export const fieldTypes = {
	select: 'select',
	number: 'number',
	imageSelect: 'image-select',
	text: 'text'
}

const formOptions = {
	sex: [
		{ label: 'Man', value: 'male' },
		{ label: 'Woman', value: 'female' }
	],
	fit: [
		{ label: 'Relaxed', value: 'relaxed' },
		{ label: 'Fitted', value: 'fitted' }
	],
	bodyType: [
		{ label: 'SLIM', value: 'slim', imgSrc: bodySlim },
		{ label: 'Average', value: 'average', imgSrc: bodyAverage },
		{ label: 'Larger', value: 'larger', imgSrc: bodyLarger }
	],
	bodyTypeFemale: [
		{ label: 'SLIM', value: 'slim', imgSrc: bodyFemaleSlim },
		{ label: 'Average', value: 'average', imgSrc: bodyFemaleAverage },
		{ label: 'Larger', value: 'larger', imgSrc: bodyFemaleLarger }
	],
	bellyType: [
		{ label: 'FLAT', value: 'flat', imgSrc: bellyFlat },
		{ label: 'Average', value: 'average', imgSrc: bellyAverage },
		{ label: 'Larger', value: 'larger', imgSrc: bellyLarger }
	],
	braSize: [
		{ label: 'Smaller', value: 'smaller', imgSrc: braSmaller },
		{ label: 'Average', value: 'average', imgSrc: braAverage },
		{ label: 'Larger', value: 'larger', imgSrc: braLarger }
	],
}

const convertFeetToInches = (size) => {
	if (!size) {
		return '';
	}
	size = size.split('\'').map((item) => {
		return item.trim();
	});
	let feet = parseInt(size[0].replace(/\D/g, ''));
	let inches = 0;
	if (size[1]) {
		inches = parseInt(size[1].replace(/\D/g, ''));
	}
	const total = (feet * 12) + inches;
	return total;
}

const uniqid = (length) => {
	let result = [];
	let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	let charactersLength = characters.length;

	for (let i = 0; i < length; i++) {
		result.push(
			characters.charAt(
				Math.floor(Math.random() * charactersLength)
			)
		);
	}

	return result.join('');
};

const validateNumberFormat = (input, format, formatted = true) => {
	if (formatted) {
		if (!input) {
			return false;
		}
		let valid = true;
		format.split('').forEach((char, index) => {
			if (char === '#') {
				if (input.charAt(index) && isNaN(parseInt(input.charAt(index)))) {
					valid = false;
				}
			} else {
				if (char !== input.charAt(index)) {
					valid = false;
				}
			}
		});
		return valid;
	} else {
		if (!input || input.length < 1) {
			return false;
		}
		let formatDigits = format.match(/#/g);
		let inputDigits = input.match(/\d/g);

		if (!inputDigits) {
			return false;
		}
		if (!formatDigits) {
			return true;
		}
		return formatDigits.length === inputDigits.length;
	}
};

/**
 * @typedef {{name:String,label:String,value:String,type:('select'|'number'|'image-select'|'text'),placeholder:String,formatted:Boolean,format:String,suffix:String,decimals:Number,sexRequired:("male"|"female")}} customField
 */

const defaultFormFields = {
	first_name: { label: 'First Name', value: '', type: fieldTypes.text, placeholder: 'Enter First Name' },
	last_name: { label: 'Last Name', value: '', type: fieldTypes.text, placeholder: 'Enter Last Name' },
	sex: { label: 'Sex', value: '', type: fieldTypes.select, optionsKey: 'sex', placeholder: 'Select Sex' },
	age: { label: 'Age', value: '', type: fieldTypes.number, placeholder: 'Enter Age' },
	height: { label: 'Height', value: '', type: fieldTypes.number, format: '#\'##', placeholder: 'Enter Height' },
	weight: { label: 'Weight', value: '', type: fieldTypes.number, placeholder: 'Enter Weight (lbs)', suffix: ' lbs' },
	shoe_size: { label: 'Shoe Size', value: '', type: fieldTypes.number, decimals: 1, placeholder: 'Enter Shoe Size' },
	waist_circum_preferred: { label: 'Jean Waist', value: '', type: fieldTypes.number, format: '##', mask: '_', placeholder: 'Enter Waist (in.)', suffix: ' in', sexRequired: 'male' },
	inseam: { label: 'Jean Inseam', value: '', type: fieldTypes.number, placeholder: 'Enter Length (in.)', suffix: ' in', sexRequired: 'male' },
	desired_garment_fit: { label: 'Fit Preference', value: '', type: fieldTypes.select, optionsKey: 'fit', placeholder: 'Select Preference' },
	bra_size: { label: 'Bra Size', value: '', type: fieldTypes.text, placeholder: 'Enter Bra Size', sexRequired: 'female', pattern: '^[0-9]{2}(AA|aa|A|a|B|b|C|c|D|d|DD|dd|DDD|ddd|DDDD|dddd|E|e|F|f|G|g|H|h|I|i|J|j)$' },
	body_type: { label: 'Body Type', value: '', type: fieldTypes.imageSelect, placeholder: 'Select Body Type', optionsKey: 'bodyType' },
	belly_type: { label: 'Belly Type', value: '', type: fieldTypes.imageSelect, placeholder: 'Select Belly Type', optionsKey: 'bellyType', sexRequired: 'male' },
	belly_size: { label: 'Belly Size', value: '', type: fieldTypes.number, placeholder: 'Enter Belly Size'},
	
	// bra_size:{label:'Bra Size',value:'', type:fieldTypes.imageSelect, placeholder:'Select Bra Size', optionsKey:'braSize', sexRequired:'female'},
}
const customFieldsToSkip = ['quote_id', 'name', 'ffid', 'weight', 'beg', 'fit_type'];

const mapCustomFieldType = (fieldType) => {

	switch (fieldType) {
		case 'integer':
		case 'integer_even':
			return fieldTypes.number;
		case 'integer_inches':
			return fieldTypes.number;
		case 'float':
			return fieldTypes.number;
		case 'dropdown':
			return fieldTypes.select;
		case 'text':
		case 'longtext':
		default:
			return fieldTypes.text;
	}
}


const VirtualSizerForm = ({
	closeHander = () => { },
	quote = null,
}) => {
	const content = useSelector((state) => state.staticData.orderSettings);
	const [, setUpdateKey] = useState(0);
	const [selectedSex, setSelectedSex] = useState(null);
	const [formFields, setFormFields] = useState(defaultFormFields);
	const fireDept = useMemo(() => {
		return quote?.fire_department_name || '';
	}, [quote]);

	useEffect(() => {
		let newFields = { ...defaultFormFields };

		if (quote?.custom_fields) {
			quote.custom_fields.forEach((field) => {
				if (customFieldsToSkip.includes(field.sizing_field_name)) {
					return;
				}
				if (field?.sizing_field_name && newFields[field.sizing_field_name]) {
					return;
				}
				const fieldType = mapCustomFieldType(field.sizing_field_type);

				let newField = {
					label: field.sizing_field_title,
					value: '',
					type: fieldType,
					placeholder: fieldType === fieldTypes.select ? 'Select ' + field.sizing_field_title : 'Enter ' + field.sizing_field_title,

				};
				if (fieldType === fieldTypes.select) {
					newField.optionsKey = field.sizing_field_name;
					formOptions[field.sizing_field_name] = field.options.map((option) => {
						return { label: option.sizing_field_option_label, value: option.sizing_field_option_value }
					});
				}

				newFields[field.sizing_field_name] = newField;
			});
		}
		newFields
		setFormFields(newFields);
	}, [quote?.custom_fields]);

	const shouldIncludeField = (field) => {
		if (customFieldsToSkip.includes(field.sizing_field_name)) {
			return false;
		}
		if (field.sizing_field_name.toLowerCase().includes('quantity')) {
			return false;
		}
		if (field.sizing_field_name.toLowerCase().includes('lettering')) {
			return false;
		}
		if (field.sizing_field_name.toLowerCase().includes('patch')) {
			return false;
		}
	};

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	}

	const showMessage = ({ message }) => {
		alert(message);
	};

	const clearForm = () => {
		Object.entries(formFields).forEach((entry) => {
			entry[1].value = '';
		})
		setFormFields(formFields);
	}

	const updateFormField = (key, value) => {
		if (key === 'sex') {
			setSelectedSex(value);
		}
		if (formFields[key]) {
			formFields[key].value = value || '';
			formFields[key].error = !value;

			if (!formFields[key].error) {
				if (formFields[key].pattern) {
					let pattern = new RegExp(formFields[key].pattern);
					formFields[key].error = !pattern.test(value);
				}
			}


			setFormFields(formFields);
			setUpdateKey(Math.random())
			if (formFields[key].changeHandler) {
				formFields[key].changeHandler(value);
			}
			setTimeout(() => {
				validateFormObject(formFields);
				setFormFields(formFields);
			}, 0)
		}
	};

	const getBraSize = (formData = null) => {
		if (!formData) {
			formData = formFields;
		}
		let band = '';
		let cup = '';
		switch (formData.body_type) {
			case 'slim':
				band = '32'
				break;
			case 'larger':
				band = '44';
				break;
			default:
				band = '38';
		}
		switch (formData.bra_size) {
			case 'smaller':
				cup = 'A'
				break;
			case 'larger':
				cup = 'DD'
				break;
			default:
				cup = 'C';
		}
		return band + cup;
	}

	const getQueryString = (formData) => {
		let qString = '';
		Object.entries(formData).forEach((entry) => {
			if (!entry[1] || (entry[1].sexRequired && entry[1].sexRequired !== selectedSex)) {
				return;
			}
			if (entry[0] === 'height') {
				qString += entry[0] + '=' + convertFeetToInches(entry[1].value) + '&';
			} else if (entry[0] !== 'first_name' && entry[0] !== 'last_name') {
				qString += entry[0] + '=' + entry[1].value + '&';
			}
		});
		qString = qString.substr(0, qString.length - 1);

		return qString;
	}

	const validateFormObject = (formObject, missingFields = []) => {
		Object.entries(formObject).forEach((entry) => {
			if (!entry[1]) {
				return;
			}
			if (entry[1].optional) {
				formObject[entry[0]].error = false;
				return;
			}
			if (entry[1].sexRequired && entry[1].sexRequired !== selectedSex) {
				formObject[entry[0]].error = false;
				return;
			}
			if(entry[0] === 'height'){
				const inches = convertFeetToInches(entry[1].value);
				if(!inches){
					formObject[entry[0]].error = true;
					missingFields.push(entry[0]);
				}
				return;
			}
			if (!entry[1].value) {
				formObject[entry[0]].error = true;
				missingFields.push(entry[0]);
			} else if (entry[1].type && entry[1].type === 'number' && entry[1].format) {
				if (entry[1].formatted) {
					entry[1].value = entry[1].value.replace('_', '')
				}
				if (!validateNumberFormat(entry[1].value, entry[1].format, !!entry[1].formatted)) {
					formObject[entry[0]].error = true;
					missingFields.push(entry[0]);
				} else {
					formObject[entry[0]].error = false;
				}
			} else if (formObject[entry[0]].pattern) {
				let pattern = new RegExp(formObject[entry[0]].pattern)
				formObject[entry[0]].error = !pattern.test(entry[1].value);
			} else {
				formObject[entry[0]].error = false;
			}
		});
	};

	const submitHandler = async () => {
		let missingFields = [];
		let anonId = 'FD-' + uniqid(6);

		// validateFormObject(formFields,missingFields);
		setFormFields(formFields);
		setUpdateKey(Math.random());
		if (missingFields.length > 0) {
			showMessage({ messge: 'All Fields are Required.', type: 'error' });
			return;
		}

		let qString = '?client_id=' + virtualSizerClient
			+ '&user_key=' + virtualSizerKey
			+ '&anon_id=' + anonId
			+ '&desired_brand=' + virtualSizerBrand
			+ '&desired_garment_type=mens_fire'
			+ '&';
		qString += getQueryString(formFields);
		let isError = false;

		Object.keys(formFields).forEach((key) => {
			if (formFields[key].error) {
				isError = key;
			}
		});

		if (isError) {
			if (isError === 'bra_size') {
				showMessage({
					message: 'Bra size must be two digits followed by a letter! (e.g. 32B)',
					type: 'error'
				});
			} else {
				showMessage({
					message: formFields[isError].label + ' is invalid! Please update your entry and try again.',
					type: 'error'
				});
			}

			return;
		}

		try {
			let response = await axios.get(virtualSizerUrl + qString);
			let goodMatches = response.data?.size_recommendations.good_matches;

			if (goodMatches?.length > 0) {
				let match = goodMatches[0];
				match.anon_id = anonId;

				let fields = {};

				Object.keys(formFields).forEach((key) => {
					if (key === 'height') {
						fields[key] = convertFeetToInches(formFields[key].value);
					} else {
						fields[key] = formFields[key].value;
					}
				});

				fields.anon_id = anonId;

				const data = {
					match,
					fields,
					"quote_id": quote.quote_id,
				}
				clearForm();
				closeHander(data);

				scrollToTop();
			} else if (response?.data?.description){
				showMessage({ message: 'Unable to get a good size match: ' + response.data.description, type: 'error' })
			}else{
				console.log(response)
				showMessage({ message: 'Unable to get a good size match.', type: 'error' })
			}
		} catch (e) {
			let specifics = e.response?.data?.message?.specifics;
			if (specifics) {
				showMessage({ message: specifics[0].field + ' ' + specifics[0].message, type: 'error' });
			} else {
				showMessage({ message: 'An unknown error occurred. Check the information you entered or contact Firedex for assistance', type: 'error' });
			}
		}
	};

	useEffect(() => {
		if (selectedSex === 'female') {
			formFields.body_type.optionsKey = 'bodyTypeFemale';
		} else {
			formFields.body_type.optionsKey = 'bodyType';
		}
		setFormFields(formFields);
		setUpdateKey(Math.random());
	}, [formFields, selectedSex])

	if (!quote?.quote_id) {
		return null;
	}

	return (
		<div className={classNames({
			[styles.formContainer]: true,
			[styles.virtualSizer]: true
		})}>
			{content &&
				<>
					<h1 className={classNames({
						[styles.formTitle]: true
					})}>{content.virtual_sizer_name}</h1>
					{content.virtual_sizer_instructions &&
						<p className={classNames({ [styles.formDescription]: true })} dangerouslySetInnerHTML={{
							__html: content.virtual_sizer_instructions,
						}}>	
						</p>
					}
				</>
			}
			{fireDept &&
				<>
					<span className={classNames({ [styles.fdLabel]: true })}>Fire Dept</span>
					<h3 className={classNames({ [styles.fdTitle]: true })}>{fireDept}</h3>
				</>
			}
			<div className={classNames({
				[styles.fieldList]: true
			})}>
				{Object.entries(formFields).map((entry, index) => {
					if (!entry[1] || (entry[1].sexRequired && entry[1].sexRequired !== selectedSex) || entry[1].type === fieldTypes.imageSelect) {
						return null;
					}
					if (entry[1].optionsKey && entry[1].type === fieldTypes.select) {
						return (
							<label key={'size' + index}>
								<span className={classNames({ [styles.title]: true })}>{entry[1].label}</span>
								<StyledSelect
									options={formOptions[entry[1].optionsKey]}
									changeHandler={updateFormField}
									inputDisabled={false}
									field={entry}
								/>
							</label>
						)
					} else if (entry[1].type && entry[1].type === fieldTypes.number) {
						return (
							<label key={'size' + index}>
								<span className={classNames({ [styles.title]: true })}>{entry[1].label}</span>
								<StyledNumberInput
									field={entry}
									changeHandler={updateFormField}
								/>
							</label>
						)
					}
					return (
						<label key={'size' + index}>
							<span className={classNames({ [styles.title]: true })}>{entry[1].label}</span>
							<div className={classNames({
								[styles.markedInput]: true,
								[styles.valid]: !entry[1].error && entry[1].value,
								[styles.invalid]: entry[1].error || !entry[1].value,
								[styles.empty]: !entry[1].value
							})}>
								<input name={'size' + entry[0]} className={classNames({
									[styles.invalid]: entry[1].error || !entry[1].value,
								})}
									type={'text'} value={entry[1].value} placeholder={entry[1].placeholder || entry[1].label} onChange={(e) => { updateFormField(entry[0], e.target.value) }} />
							</div>
						</label>
					)
				})
				}
				{Object.entries(formFields).map((entry, index) => {
					if (!entry[1] || (entry[1].sexRequired && entry[1].sexRequired !== selectedSex) || entry[1].type !== fieldTypes.imageSelect) {
						return null;
					}
					if (entry[1].optionsKey && entry[1].type && entry[1].type === fieldTypes.imageSelect) {
						return (
							<label key={'size' + index} className={classNames({
								[styles.fullRow]: true,
								[styles.imageOptionSelect]: true
							})}>
								<span className={classNames({ [styles.title]: true })}>{entry[1].label}</span>
								<ImageSelect
									options={formOptions[entry[1].optionsKey]}
									field={entry}
									changeHandler={updateFormField}
								/>
							</label>
						)
					}
				})
				}
			</div>
			<div className={classNames({
				[styles.formSubmit]: true
			})}>
				<button onClick={() => { submitHandler() }} className={classNames({ [styles.submitButton]: true })}>Submit</button>
			</div>
		</div>
	)
};

export default VirtualSizerForm;