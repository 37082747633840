/**
 * Sizing Form Validation Helpers
 */

export function validateIntegerField(value) {
	return (
		value?.toString()?.length &&
		/\d/.test(value) &&
		!isNaN(value?.toString()?.replace(/[^\d.]/g, ''))
	);
}

export function validateStringField(value) {
	return !!(typeof value === 'string' && value.length);
}

export function validateBooleanField(value) {
	return [0, 1].includes(parseInt(value || 0));
}

export function validateDropdownField(value) {
	return !!value;
}

/**
 * Field Validation
 */

export const validation = (field = {}, value, data = {}) => {
	if (!parseInt(field.product_line_sizing_field_required)) {
		return true;
	}

	if (field.sizing_field_type === 'dropdown') {
		return validateDropdownField(value);
	}

	if (field.sizing_field_type === 'boolean') {
		return validateBooleanField(value);
	}

	if (field.sizing_field_type === 'float') {
		return validateIntegerField(value);
	}

	if (field.sizing_field_type === 'integer') {
		return validateIntegerField(value);
	}

	if (field.sizing_field_type === 'integer_inches') {
		return validateIntegerField(value);
	}

	if (field.sizing_field_type === 'integer_even') {
		return validateIntegerField(value);
	}

	if (field.sizing_field_type === 'text') {
		return validateStringField(value);
	}

	if (field.sizing_field_type === 'longtext') {
		return validateStringField(value);
	}
};
