import { API_ROOT } from '@config';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { contactPhone } from '@config/contact';
import Button from './Button/Button';
import { useHistory } from 'react-router-dom';

const Footer = ({ className }) => {
	const content = useSelector((state) => state.staticData.content);
	const logo = content?.logos?.indexed['footer-logo-dark'];
	const history = useHistory();

	function doMail() {
		history.push('/#contact-form');
	}

	function doCall() {
		window.open(`tel://${contactPhone}`);
	}

	return (
		<footer
			className={classNames({
				'content-wrapper': true,
				[className]: !!className,
			})}>
			<div className="content-container">
				<div
					className="footer-content"
					dangerouslySetInnerHTML={{
						__html: content['footer-content']?.content_html,
					}}
				/>
				<div className="footer-divider" />
				<div className="footer-contact">
					<form onSubmit={doMail}>
						<Button
							variant="plain"
							type="submit"
							adornment={<img src="/images/mail.svg" />}>
							Message Us
						</Button>
					</form>
					<form onSubmit={doCall}>
						<Button
							variant="plain"
							type="submit"
							adornment={<img src="/images/phone.svg" />}>
							Call Us: (330) 723-0000
						</Button>
					</form>
					{!!logo && (
						<a
							href={logo?.content_item_url}
							target="_blank"
							rel="noopener noreferrer">
							<img
								src={`${API_ROOT}/uploaded/content/${logo?.content_item_image}`}
								className="footer-logo"
							/>
						</a>
					)}
				</div>
			</div>
		</footer>
	);
};

export default Footer;
