import * as React from 'react';

const SvgComponent = (props) => (
	<svg width={11} height={3} xmlns="http://www.w3.org/2000/svg" {...props}>
		<g opacity={0.3}>
			<path d="M5.178.191C4.486.191 3.92.757 3.92 1.45c0 .692.566 1.258 1.258 1.258.691 0 1.257-.566 1.257-1.258C6.435.757 5.87.191 5.178.191zm3.772 0c-.691 0-1.257.566-1.257 1.258 0 .692.566 1.258 1.257 1.258.692 0 1.258-.566 1.258-1.258 0-.692-.566-1.258-1.258-1.258zm-7.545 0C.713.191.147.757.147 1.45c0 .692.566 1.258 1.258 1.258.692 0 1.258-.566 1.258-1.258 0-.692-.566-1.258-1.258-1.258z" />
		</g>
	</svg>
);

export default SvgComponent;
