import { API_ROOT } from '@config';
import axios from 'axios';

export const schema = {
	profile_first_name: {
		initialValue: '',
		validate: (value) => {
			return value?.length ? null : 'Please enter your first name';
		},
	},
	profile_last_name: {
		initialValue: '',
		validate: (value) => {
			return value?.length ? null : 'Please enter your last name';
		},
	},
	profile_title: {
		initialValue: '',
		validate: (value) => {
			return value?.length ? null : 'Please enter your title';
		},
	},
	profile_phone: {
		initialValue: '',
		validate: () => {
			return null;
		},
	},
	fire_department_id: {
		initialValue: '',
		validate: (value, formData) => {
			if (formData.manual_department || formData.user_id) {
				return null;
			}

			return value ? null : 'Please select your fire department';
		},
	},
	organization_name: {
		initialValue: '',
		validate: (value, formData) => {
			if (!formData.is_distributor || formData.user_id) {
				return null;
			}

			return value.length ? null : 'Please enter your company name';
		},
	},
	user_email: {
		initialValue: '',
		validate: (value, formData) => {
			if (!/\S+@\S+\.\S+/.test(value)) {
				return 'Please enter a valid email address';
			}

			if (formData.user_id) {
				return null;
			}

			return axios
				.post(`${API_ROOT}/auth/email-available`, { email: value })
				.then(() => {
					return null;
				})
				.catch((error) => {
					const { response } = error;
					if (response && response.status === 403) {
						return response.data.meta.message;
					}
				});
		},
	},
	user_password: {
		initialValue: '',
		validate: (value, formData) => {
			if (formData.user_id) {
				return null;
			}

			return value.length && value.length >= 8
				? null
				: 'Please enter a password with at least 8 characters';
		},
	},
	is_distributor: {
		initialValue: false,
		validate: () => null,
	},
	manual_department: {
		initialValue: false,
		validate: () => null,
	},
	fire_department_name: {
		initialValue: false,
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length ? null : 'Please enter your Fire Department';
		},
	},
	fire_department_address: {
		initialValue: false,
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length
				? null
				: 'Please enter your Fire Department address';
		},
	},
	fire_department_city: {
		initialValue: false,
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length
				? null
				: 'Please enter your Fire Department city';
		},
	},
	fire_department_state: {
		initialValue: false,
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length
				? null
				: 'Please enter your Fire Department State / Province / Region';
		},
	},
	fire_department_zip: {
		initialValue: false,
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length
				? null
				: 'Please enter your Fire Department Postal Code';
		},
	},
	fire_department_country: {
		initialValue: false,
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length
				? null
				: 'Please enter your Fire Department country';
		},
	},
	fire_department_county: {
		initialValue: false,
		validate: (value, formData) => {
			if (!formData.manual_department) {
				return null;
			}
			return value?.length
				? null
				: 'Please enter your Fire Department county';
		},
	},
};
