import classNames from 'classnames';
import React from 'react';
import styles from './Product.module.scss';

const Product = ({
	title = null,
	subTitle = null,
	image = null,
	headerContent = null,
	className = '',
}) => {
	return (
		<div
			className={classNames({
				[styles.product]: true,
				[className]: !!className,
			})}>
			<header>{headerContent}</header>
			<img src={image} className={styles.productImage} />
			<footer>
				{!!title && <p className={styles.productTitle}>{title}</p>}
				{!!subTitle && (
					<span className={styles.productSubTitle}>{subTitle}</span>
				)}
			</footer>
		</div>
	);
};

export default Product;
