import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			width={10}
			height={10}
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M5.777 0H3.852v3.852H0v1.925h3.852V9.63h1.925V5.777H9.63V3.852H5.777z"
				fill={props.fill || '#fe9d1b'}
			/>
		</svg>
	);
}

export default SvgComponent;
