import {useCallback, useEffect, useMemo, useState} from 'react';
import Card from '@components/Card/Card';
import { useDispatch } from 'react-redux';
import { useQueryParams } from '@hooks/useQueryParams';
import RequestQuoteForm from '../RequestQuote/components/RequestQuoteForm/RequestQuoteForm';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';
import { setRAQFormData } from '@store/reducers/raq';
import styles from './RequestQuoteFrame.module.scss';
import './request-quote-frame.scss';

const RequestQuoteFrame = () => {
	const dispatch = useDispatch();
	const params = useQueryParams(location);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback( (formData) => {
			const {data} = Http().post(
				`${API_ROOT}/request-quote`,
				formData,
			);
		},
		[dispatch],
	);

	useEffect(() => {
		dispatch(setRAQFormData(null));
	}, [dispatch]);

	useEffect(() => {
		const script = document.createElement('script');
		script.src= `${API_ROOT}/js/iframeResizer.contentWindow.min.js`;
		document.body.appendChild(script);
	}, []);


	/**
	 * Initial form data
	 */

	const initialData = useMemo(() => {
		const data = {};

		if (params.quote_id) {
			data.notes = `Quote #${params.quote_id.padStart(4, '0')}`;
		}

		return data;
	}, [params]);

	useEffect(()  => {
		document.body.classList.add('no-background');
	});

	return (
		<Card className={styles.contentCard}>
			<h3>Request a Quote</h3>
			<RequestQuoteForm
				onSubmit={handleSubmit}
				data={initialData}
			/>
		</Card>
	);
};

export default RequestQuoteFrame;
