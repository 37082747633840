import styles from './NewsNotification.module.scss';

export const Wrapper = ({ notification, children }) => {
	const NoOp = <div className={styles.linkWrapper}>{children}</div>;
	const Link = (
		<a
			className={styles.linkWrapper}
			href={notification.news_url}
			target="_blank"
			rel="noopener noreferrer">
			{children}
		</a>
	);

	return notification.news_url ? Link : NoOp;
};
