import UserMenu from '@components/UserMenu/UserMenu';
import { API_ROOT } from '@config';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styles from './MyAccountHeader.module.scss';

const MyAccountHeader = ({ children }) => {
	const history = useHistory();
	const content = useSelector((state) => state.staticData.content);
	const logo = content?.logos?.indexed['logo-dark'];

	return (
		<header className={styles.myAccountHeader}>
			<div className={styles.myAccountHeaderContent}>
				<Link to="/dashboard">
					{logo && (
						<img
							className={styles.logo}
							src={`${API_ROOT}/uploaded/content/${logo?.content_item_image}`}
						/>
					)}
				</Link>
				<div className={styles.menuContainer}>
					<span
						className={styles.back}
						onClick={() => history.goBack()}>
						Back
					</span>
					<div className={styles.separator} />
					<UserMenu variant="my-account" />
				</div>
			</div>
			{children}
		</header>
	);
};

export default MyAccountHeader;
