import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import SuggestField from '@components/SuggestField/SuggestField';
import Button from '@components/Button/Button';
import fieldStyles from '@components/Field/Fields.module.scss';
import styles from './AddQuoteToOrderModal.module.scss';
import {useErrorAlert} from "@hooks/useErrorAlert";

const AddQuoteToOrderModal = ({
	active = false,
	onClose = () => {},
	orderId,
	className = null,
}) => {
	const [quoteId, setQuoteId] = useState(null);
	const [error, setError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [, setErrorAlert] = useErrorAlert();

	/**
	 * Handle Modal Close
	 */

	const handleClose = useCallback(
		(quote = null, quotes = []) => {
			// clear fields
			setError(null);
			setQuoteId(null);
			onClose(quote, quotes);
		},
		[onClose],
	);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(async () => {
		if (!quoteId) {
			setError('Please select a quote');
			return;
		}

		try {
			setIsSubmitting(true);

			await http()
				.post(`${API_ROOT}/quotes/assign-quote-to-order/${quoteId}`, {
					orderId: orderId,
				})
				.then(({ data }) => {
					setIsSubmitting(false);
					handleClose(data?.quote, data?.orderQuotes);
				})
				.catch((error) => {
					const { response } = error;

					setErrorAlert({
						errorTitle: 'Overwrite',
						errorMessage: response.data.error_message,
						errorType: 'confirm',
						errorAction: () => {
							http()
							.post(
								`${API_ROOT}/quotes/assign-quote-to-order/${quoteId}`,
								{
									orderId: orderId,
									overwrite: true,
								},
							)
							.then(({ data }) => {
								setIsSubmitting(false);
								handleClose(data?.quote);
							});
						}
					});
				});
		} catch {
			setIsSubmitting(false);
		}
	}, [quoteId, orderId, handleClose]);

	return (
		<Modal
			active={active}
			onClose={() => handleClose()}
			className={classNames({ [className]: !!className })}>
			<h4 className={modalStyles.modalTitle}>Add Quote to Order</h4>
			<div
				className={classNames({
					[modalStyles.modalBody]: true,
					[styles.form]: true,
				})}>
				<SuggestField
					label="Quote Name or Number"
					value={quoteId}
					valueField="quote_id"
					url={`${API_ROOT}/quotes`}
					getResults={(data) => data.quotes.filter((item) => {
						return item["sibling_quote_id"] === null;
					})}
					labelExtractor={(quote) =>
						`${quote.quote_number} - ${quote.quote_name}`
					}
					onChange={() => {
						setError(null);
					}}
					onSelect={(quote) => {
						setError(null);
						setQuoteId(quote.quote_id);
					}}
					additionalParams="has_order=false&is_valid=true"
					clearOnEmpty={false}
				/>
			</div>
			{!!error && (
				<div className={styles.formErrorContainer}>
					<span className={fieldStyles.fieldError}>{error}</span>
				</div>
			)}
			<div className={modalStyles.modalActions}>
				<Button
					variant="solid"
					onClick={handleSubmit}
					loading={isSubmitting}>
					Add to Order
				</Button>
				<Button variant="plain" onClick={() => handleClose()}>
					Close
				</Button>
			</div>
		</Modal>
	);
};

export default AddQuoteToOrderModal;
