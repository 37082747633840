import { useSelector } from 'react-redux';
import { API_ROOT } from '@config';

function Hero(props) {
	const content = useSelector((state) => state.staticData.content);
	const bannerImage =
		content['login-page']?.indexed['login-banner']['content_item_image'];
	return (
		<section
			className="hero"
			style={{
				backgroundImage: `url("${API_ROOT}/uploaded/content/${bannerImage}")`,
			}}>
			<div className="hero-content">
				<h1
					className="hero-title"
					dangerouslySetInnerHTML={{ __html: props.heroTitle }}
				/>
			</div>
		</section>
	);
}

export default Hero;
