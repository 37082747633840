import TextField from '@components/TextField/TextField';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import styles from './CustomTextField.module.scss';

const CustomTextField = (props) => {
	const { value, onChange, max } = props;

	/**
	 * Change Handler (enforce char limit, restricted chars, all caps)
	 */

	const handleChange = useCallback(
		(e) => {
			if (
				e.target.value.length > parseInt(max) &&
				e.target.value.length >= value.length
			) {
				return;
			}

			e.target.value =
				'' +
				e.target.value
					.replaceAll(/[^A-Za-z0-9#.\-/\\&\s]/g, '')
					.toUpperCase();

			onChange(e);
		},
		[onChange, max, value],
	);

	/**
	 * Character counter adornment
	 */

	const adornment = useMemo(() => {
		if (!max) {
			return false;
		}

		if (['Name', 'Rank', 'ID Number'].includes(value)) {
			return <span>({max})</span>;
		}

		return (
			<>
				{!value?.length && <span>({max})</span>}
				{!!value?.length && (
					<span>
						({value?.length} / {max})
					</span>
				)}
			</>
		);
	}, [value, max]);

	return (
		<TextField
			{...props}
			onChange={handleChange}
			inputClassName={classNames({
				[props.inputClassName]: !!props.inputClassName,
				[styles.input]: true,
			})}
			adornment={adornment}
			adornmentClassName={styles.counterAdornment}
		/>
	);
};

export default CustomTextField;
