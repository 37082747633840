import { useCallback } from 'react';
import Switch from '@components/Switch/Switch';
import classNames from 'classnames';
import TrashIcon from '@components/Icons/TrashIcon';
import styles from './AddressList.module.scss';

const AddressList = ({ addresses = [], onChange = () => {} }) => {
	/**
	 * Handle Default Change
	 */

	const handleChangeDefault = useCallback(
		(index, value) => {
			value = value ? '1' : '0';
			onChange(
				addresses.map((address, i) => {
					return {
						...address,
						user_address_is_default: i === index ? value : '0',
					};
				}),
			);
		},
		[addresses, onChange],
	);

	/**
	 * Handle Delete
	 */

	const handleDelete = useCallback(
		(index) => {
			const next = addresses.filter((_, i) => i !== index);

			if (
				next.length &&
				!next.find((address) => address.user_address_is_default === '1')
			) {
				next[0].user_address_is_default = '1';
			}

			onChange(next);
		},
		[addresses, onChange],
	);

	return (
		<div className={styles.container}>
			<ul className={styles.list}>
				{addresses.map((address, a) => (
					<li key={a}>
						<Switch
							label="Default Address"
							onChange={(value) => handleChangeDefault(a, value)}
							value={address.user_address_is_default === '1'}
							className={classNames({
								[styles.toggle]: true,
								[styles.toggleActive]:
									address.user_address_is_default === '1',
							})}
						/>
						<div className={styles.address}>
							{!!address.address_attention && (
								<p>Attn: {address.address_attention}</p>
							)}
							<p>{address.address_name}</p>
							<p>
								{address.address_street_1}{' '}
								{address.address_street_2 || ''}
							</p>
							<p>
								{address.address_city}, {address.state_name}{' '}
								{address.address_zip}
							</p>
						</div>
						<button
							className={styles.deleteButton}
							onClick={() => handleDelete(a)}>
							<TrashIcon /> Delete
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

export default AddressList;
