import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg width="17" height="15" viewBox="0 0 17 15" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
<path d="M8.51472 11.8705L3.81242 14.5027L4.86264 9.21714L0.90625 5.55841L6.25761 4.92391L8.51472 0.0305405L10.7718 4.92391L16.1231 5.55841L12.1668 9.21714L13.217 14.5027L8.51472 11.8705ZM8.51472 10.3425L11.3459 11.9273L10.7135 8.74501L13.0956 6.54214L9.87366 6.1601L8.51472 3.21389L7.15575 6.1601L3.9338 6.54214L6.31586 8.74501L5.68354 11.9273L8.51472 10.3425Z" fill="white"/>
</svg>

	);
}

export default SvgComponent;
