import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { API_ROOT } from '@config';
import http from '@utilities/Http';
import ChevronIcon from '@components/Icons/ChevronIcon';
import CloseIcon from '@components/Icons/CloseIcon';
import SearchIcon from '@components/Icons/SearchIcon';
import TextField from '@components/TextField/TextField';
import SelectField from '@components/SelectField/SelectField';
import filterStyles from '@components/Collection/CollectionFilters.module.scss';
import styles from './Filters.module.scss';
import SuggestField from '@components/SuggestField/SuggestField';

const Filters = ({ filters, setFilters = () => {} }) => {
	const debounceTimeout = useRef(null);
	const [values, setValues] = useState({ ...filters });
	const [productLines, setProductLines] = useState([]);
	const [getProductLines, setGetProductLines] = useState([]);

	const user = useSelector((state) => state.auth.user);

	const [selectedOrganization, setSelectedOrganization] = useState(null);

	const [selectedUser, setSelectedUser] = useState({
		user_display_name: user?.user_display_name,
	});
	const [organizationUsers, setOrganizationUsers] = useState([]);
	const [isFetchingOrganizationUsers, setIsFetchingOrganizationUsers] =
		useState(false);
	const [selectedFireDepartment, setSelectedFireDepartment] = useState(null);

	const isDistributorAdmin = useSelector((state) =>
		state.auth.user?.permissions?.includes('manage_organization'),
	);
	const isCSRUser = useSelector((state) =>
		state.auth.user?.permissions?.includes('csr_access'),
	);
	const isRSMUser = useSelector((state) =>
		state.auth.user?.permissions?.includes('rsm_access'),
	);
	/**
	 * Handle Remove Filter
	 */

	const handleRemove = useCallback(
		(name) => {
			setFilters((filters) => ({ ...filters, [name]: '' }));
			setValues((values) => ({ ...values, [name]: '' }));
		},
		[setFilters],
	);

	/**
	 * Handle Filter Change
	 */

	const handleChange = useCallback(
		(e) => {
			setValues((values) => ({
				...values,
				[e.target.name]: e.target.value,
			}));
		},
		[setValues],
	);

	/**
	 * Debounce Updates
	 */

	useEffect(() => {
		clearTimeout(debounceTimeout.current);

		const isTextFieldDiff = ['quote_name', 'quote_number'].some(
			(key) => filters[key] !== values[key],
		);

		if (JSON.stringify(filters) !== JSON.stringify(values)) {
			debounceTimeout.current = setTimeout(
				() => {
					setFilters({ ...values });
				},
				isTextFieldDiff ? 350 : 0,
			);
		}
	}, [setFilters, filters, values]);

	/**
	 * Sync Local 'Values' with Parent 'Filters'
	 */

	useEffect(() => {
		setValues({ ...filters });
	}, [filters]);

	/**
	 * Tags
	 */

	const tags = useMemo(() => {
		const tags = [];

		// quote name
		if (filters?.quote_name) {
			tags.push(
				<li key="quote_name" onClick={() => handleRemove('quote_name')}>
					{filters?.quote_name}
					<CloseIcon />
				</li>,
			);
		}

		// quote number
		if (filters?.quote_number) {
			tags.push(
				<li
					key="quote_number"
					onClick={() => handleRemove('quote_number')}>
					{filters?.quote_number}
					<CloseIcon />
				</li>,
			);
		}

		// quote product line
		if (filters?.product_line_description) {
			tags.push(
				<li
					key="product_line_description"
					onClick={() => {
						handleRemove('product_line_description');
					}}>
					{filters?.product_line_description}
					<CloseIcon />
				</li>,
			);
		}

		// user
		if (filters?.user_id && selectedUser) {
			tags.push(
				<li
					key="user_id"
					onClick={() => {
						setSelectedUser(null);
						handleRemove('user_id');
					}}>
					{selectedUser.user_display_name}
					<CloseIcon />
				</li>,
			);
		}

		// fire department
		if (filters?.fire_department_id && selectedFireDepartment) {
			tags.push(
				<li
					key="fire_department_id"
					onClick={() => {
						setSelectedFireDepartment(null);
						handleRemove('fire_department_id');
					}}>
					{selectedFireDepartment.fire_department_name}
					<CloseIcon />
				</li>,
			);
		}

		// fire department
		if (filters?.status) {
			tags.push(
				<li
					key="status"
					onClick={() => {
						handleRemove('status');
					}}>
					{filters?.status}
					<CloseIcon />
				</li>,
			);
		}

		// distributor
		if (filters?.organization_id) {
			tags.push(
				<li
					key="organization_id"
					onClick={() => handleRemove('organization_id')}>
					Distributor: {selectedOrganization?.organization_name}
					<CloseIcon />
				</li>,
			);
		}

		// return tags.map((tag, t) => <li key={t}>{tag}</li>);
		return tags.map((tag) => tag);
	}, [filters, handleRemove, selectedUser, selectedFireDepartment]);

	/**
	 * Fetch Organization Users
	 */

	useEffect(() => {
		if (!isDistributorAdmin) {
			return;
		}

		setIsFetchingOrganizationUsers(true);
		let route = isCSRUser
			? `${API_ROOT}/users`
			: `${API_ROOT}/organizations/users`;
		http()
			.get(route)
			.then(({ data }) => {
				setOrganizationUsers(data.users);
			})
			.finally(() => {
				setIsFetchingOrganizationUsers(false);
			});
	}, [isDistributorAdmin, isCSRUser]);

	useEffect(() => {
		if (productLines.length) {
			setGetProductLines(
				productLines?.sort((a, b) =>
					a.product_line_description.toLowerCase() >
					b.product_line_description.toLowerCase()
						? 1
						: -1,
				),
			);
		}
	}, [productLines]);

	/**
	 * Fetch Product Lines
	 */

	const fetchProductLines = useCallback(async () => {
		const endpoint = user?.organization_id
			? `organizations/product-lines/${user.organization_id}`
			: 'product-lines/all';

		const { data } = await http().get(`${API_ROOT}/${endpoint}`);

		setProductLines(data.productLines);
	}, [user]);

	useEffect(() => {
		fetchProductLines();
	}, [fetchProductLines])



	return (
		<div className={filterStyles.filtersContainer}>
			<div className={filterStyles.filtersContent}>
				<div
					className={classNames({
						[filterStyles.fieldsList]: true,
						[styles.fieldsList]: true,
						[styles.isAdmin]: isDistributorAdmin,
					})}>
					<TextField
						className={classNames({
							[filterStyles.filter]: true,
							[styles.nameFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						name="quote_name"
						value={values.quote_name}
						onChange={handleChange}
						placeholder="Quote Name"
						adornment={<SearchIcon fill="#FFF" />}
					/>
					<TextField
						className={classNames({
							[filterStyles.filter]: true,
							[styles.numberFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						name="quote_number"
						value={values.quote_number}
						onChange={handleChange}
						placeholder="Quote Number"
						adornment={<SearchIcon fill="#FFF" />}
					/>
					<SelectField
						className={classNames({
							[filterStyles.filter]: true,
							[styles.productFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						menuClassName={filterStyles.filterInputMenu}
						name="product_line_description"
						value={values.product_line_description}
						onChange={handleChange}
						placeholder="Product"
						options={[
							{
								product_line_description: 'Accessories',
							},
							...getProductLines,
						]}
						labelField="product_line_description"
						valueField="product_line_description"
						indicator={<ChevronIcon fill="rgba(255,255,255,0.5)" />}
						allowNull={false}
					/>
					<SuggestField
						placeholder="Fire Department"
						name="fire_department_id"
						value={values.fire_department_id}
						valueField="fire_department_id"
						labelField="fire_department_name"
						paramName="fire_department_name"
						url={`${API_ROOT}/fire-departments`}
						getResults={(data) => data.fireDepartments}
						onChange={handleChange}
						onSelect={setSelectedFireDepartment}
						className={classNames({
							[filterStyles.filter]: true,
							[styles.departmentFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						menuClassName={filterStyles.filterInputMenu}
						adornment={<SearchIcon fill="#FFF" />}
						additionalParams={'used_by_organization_quotes'}
					/>
					{isDistributorAdmin && !isRSMUser && !isCSRUser && (
						<SelectField
							className={classNames({
								[filterStyles.filter]: true,
								[styles.filterInputMenu]: true,
							})}
							inputClassName={filterStyles.filterInput}
							menuClassName={filterStyles.filterInputMenu}
							name="user_id"
							value={values.user_id}
							onChange={handleChange}
							onSelect={setSelectedUser}
							placeholder="Created By"
							options={organizationUsers}
							labelField="user_display_name"
							valueField="user_id"
							indicator={
								<ChevronIcon fill="rgba(255,255,255,0.5)" />
							}
							allowNull={false}
							loading={isFetchingOrganizationUsers}
						/>
					)}
					{(isRSMUser || isCSRUser) && (
						<SuggestField
							placeholder="Created By"
							name="user_id"
							value={values.fire_department_id}
							valueField="user_id"
							labelField="user_display_name"
							paramName="user_display_name"
							url={`${API_ROOT}/users`}
							getResults={(data) => data.users}
							onChange={handleChange}
							onSelect={setSelectedUser}
							className={classNames({
								[filterStyles.filter]: true,
								[styles.departmentFilter]: true,
							})}
							inputClassName={filterStyles.filterInput}
							menuClassName={filterStyles.filterInputMenu}
							adornment={<SearchIcon fill="#FFF" />}
						/>
					)}
					<SelectField
						className={classNames({
							[filterStyles.filter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						menuClassName={filterStyles.filterInputMenu}
						name="status"
						value={values.status}
						onChange={handleChange}
						placeholder="Status"
						options={[
							{valueLabel: 'Approved'},
							{valueLabel: 'Requires Edits'},
							{valueLabel: 'Not Submitted'},
							{valueLabel: 'Submitted'}
						]}
						labelField="valueLabel"
						valueField="valueLabel"
						indicator={<ChevronIcon fill="rgba(255,255,255,0.5)" />}
						allowNull={false}
					/>

					{(isRSMUser || isCSRUser) &&
						<SuggestField
						placeholder="Distributor"
						name="organization_id"
						value={values.organization_id}
						valueField="organization_id"
						labelField="organization_name"
						paramName="organization_name"
						url={`${API_ROOT}/distributors`}
						getResults={(data) => data.distributors}
						onChange={handleChange}
						onSelect={(organization)=>{setSelectedOrganization(organization);}}
						className={classNames({
							[filterStyles.filter]: true,
							[styles.departmentFilter]: true,
						})}
						inputClassName={filterStyles.filterInput}
						menuClassName={filterStyles.filterInputMenu}
						adornment={<SearchIcon fill="#FFF" />}
						additionalParams={'used_by_organization_quotes'}
					/>
					}
				</div>

				{!!tags.length && <ul className={filterStyles.tags}>{tags}</ul>}
			</div>
		</div>
	);
};

export default Filters;
