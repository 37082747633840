import styles from './PublicBanner.module.scss';

import classNames from 'classnames';

const PublicBanner = ({
	children = null,
	backgroundImage = 'images/embers.png',
	className = null,
}) => {
	return (
		<div
			className={classNames({
				[styles.container]: true,
				[className]: !!className,
			})}
			style={
				backgroundImage
					? { backgroundImage: `url('${backgroundImage}')` }
					: null
			}>
			<div className={styles.content}>{children}</div>
		</div>
	);
};

export default PublicBanner;
