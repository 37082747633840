import { useMemo, useState, useCallback } from 'react';

export const useForm = (schema, initialFormData = {}) => {
	/**
	 * Populate form data object
	 */

	const [formData, setFormData] = useState({
		...Object.keys(schema).reduce((data, key) => {
			data[key] = schema[key].initialValue;
			return data;
		}, {}),
		...initialFormData,
	});

	/**
	 * Populate form error object
	 */

	const [formErrors, setFormErrors] = useState(
		Object.keys(formData).reduce(
			(errors, field) => ({
				...errors,
				[field]: Array.isArray(formData[field]) ? [] : null,
			}),
			{},
		),
	);

	/**
	 * Get validation errors
	 */

	const getValidation = useCallback(
		async (fields = null) => {
			const validation = {};
			for (let key in schema) {
				validation[key] =
					(fields === null || fields.includes(key)) &&
					typeof schema[key].validate === 'function'
						? await schema[key].validate(formData[key], formData)
						: null;
			}

			setFormErrors(validation);

			return validation;
		},
		[formData, schema],
	);

	/**
	 * Change handler
	 */

	const handleChange = useCallback((e) => {
		const events = Array.isArray(e) ? e : [e];

		// update fields in formData
		setFormData((formData) => {
			const data = { ...formData };

			events.forEach((e) => {
				data[e.target.name] = e.target.value;
			});

			return data;
		});
		// reset validation errors
		setFormErrors((formErrors) => {
			const errors = { ...formErrors };

			events.forEach((e) => {
				errors[e.target.name] = null;
			});

			return errors;
		});
	}, []);

	return useMemo(
		() => ({
			formData,
			formErrors,
			getValidation,
			handleChange,
			setFormData,
			setFormErrors,
		}),
		[
			formData,
			formErrors,
			getValidation,
			handleChange,
			setFormData,
			setFormErrors,
		],
	);
};
