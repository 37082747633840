import * as React from 'react';

const SvgComponent = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 482.568 482.568"
		style={{
			enableBackground: 'new 0 0 482.568 482.568',
		}}
		xmlSpace="preserve"
		{...props}>
		<path d="M116.993 203.218c13.4-1.8 26.8 2.8 36.3 12.3l24 24 22.7-22.6-32.8-32.7c-5.1-5.1-5.1-13.4 0-18.5s13.4-5.1 18.5 0l32.8 32.8 22.7-22.6-24.1-24.1c-9.5-9.5-14.1-23-12.3-36.3 4-30.4-5.7-62.2-29-85.6-23.8-23.8-56.4-33.4-87.3-28.8-4.9.7-6.9 6.8-3.4 10.3l30.9 30.9c14.7 14.7 14.7 38.5 0 53.1l-19 19c-14.7 14.7-38.5 14.7-53.1 0l-31-30.9c-3.5-3.5-9.5-1.5-10.3 3.4-4.6 30.9 5 63.5 28.8 87.3 23.4 23.3 55.2 33 85.6 29zM309.193 243.918l-22.7 22.6 134.8 134.8c5.1 5.1 5.1 13.4 0 18.5s-13.4 5.1-18.5 0l-134.8-134.8-22.7 22.6 138.9 138.9c17.6 17.6 46.1 17.5 63.7-.1s17.6-46.1.1-63.7l-138.8-138.8z" />
		<path d="M361.293 153.918h59.9l59.9-119.7-29.9-29.9-119.8 59.8v59.9l-162.8 162.3-29.3-29.2-118 118c-24.6 24.6-24.6 64.4 0 89s64.4 24.6 89 0l118-118-29.9-29.9 162.9-162.3z" />
	</svg>
);

export default SvgComponent;
