import React from 'react';
import classNames from 'classnames';
import styles from './FieldLabel.module.scss';

const FieldLabel = ({ label = '' }) => {
	return (
		<div className={classNames(styles.fieldLabel)}>
			<span>{label}</span>
		</div>
	);
};

export default FieldLabel;
