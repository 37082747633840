import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import AuthHeader from '@components/Auth/AuthHeader';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import Button from '@components/Button/Button';
import AuthService from '@services/AuthService';
import { validateEmail } from '@utilities/validateEmail';

function Forgot() {
	const history = useHistory();

	const [email, setEmail] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [didSubmit, setDidSubmit] = useState(false);
	const [error, setError] = useState('');

	/**
	 * Submit handler
	 */

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();

			if (!validateEmail(email)) {
				setError('Please enter a valid email address');
				return;
			}

			setIsSubmitting(true);

			try {
				await AuthService.forgot(email);
				setDidSubmit(true);
			} catch (error) {
				console.log(error);
			} finally {
				setIsSubmitting(false);
			}
		},
		[email],
	);

	/**
	 * Form content
	 */

	const formContent = useMemo(() => {
		return (
			<form className="form-container" onSubmit={handleSubmit} noValidate>
				<p className="form-text">
					Please enter the email address associated with your Fire-Dex
					account and we’ll send you instructions to reset your
					password.
				</p>
				<div className="input-container">
					<input
						autoComplete="email"
						type="email"
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				{!!error && (
					<div className="form-errors">
						<p>{error}</p>
					</div>
				)}
				<div className="form-actions forgot-form-actions">
					<Button
						type="submit"
						loading={isSubmitting}
						className="form-submit-button">
						Submit
					</Button>
				</div>
			</form>
		);
	}, [isSubmitting, handleSubmit, error]);

	/**
	 * Success content
	 */

	const successContent = useMemo(() => {
		return (
			<form
				className="form-container"
				onSubmit={(e) => e.preventDefault()}>
				<p className="form-text">
					If you have a Fire-Dex account, you’ll receive instructions
					for resetting your password at {email}
				</p>
				<div className="form-actions forgot-form-actions">
					<button
						onClick={() => history.push('/login')}
						className="button form-submit-button"
						type="button">
						Login
					</button>
				</div>
			</form>
		);
	}, [email, history]);

	return (
		<>
			<AuthHeader />
			<Hero heroTitle="FORGOT<br />PASSWORD?" />
			<section className="content-wrapper">
				<div className="content-container flex-center-content">
					{didSubmit ? successContent : formContent}
				</div>
			</section>
			<Footer className="auth-footer" />
		</>
	);
}

export default Forgot;
