import React from 'react';
import  NumberFormat  from 'react-number-format';
import styles from '../../VirtualSizer.module.scss';
import classNames from 'classnames';
export default function StyledNumberInput({field,changeHandler})
{

	return (
		<div className={classNames({
			[styles.markedInput]: true,
			[styles.invalid]: field[1].error || !field[1].value,
			[styles.valid]: !field[1].error && field[1].value,
		})}>
			<NumberFormat
				className={((field[1].error || !field[1].value)?'invalid':'')}
				placeholder={field[1].placeholder || field[1].label}
				format={field[1].format}
				mask={field[1].mask}
				decimalScale={field[1].decimals || 0}
				prefix={field[1].prefix || ''}
				suffix={field[1].suffix || ''}
				value={field[1].value}
				onValueChange={(values) => {
					const {formattedValue,value} = values;
					if(field[1].formatted || field[0] == 'height'){
						changeHandler(field[0], formattedValue);

					}else {
						changeHandler(field[0], value);
					}
				}} />
		</div>
	)
}
